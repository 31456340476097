import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import stringify from 'json-stable-stringify';
import { Edit2, ExternalLink, Trash2 } from 'react-feather';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useStiggTheme } from '@stigg-theme';
import { useModal, useQueryParam } from '@stigg-common';
import {
  AccountPermissionActions,
  EnvironmentPermissions,
  useAccountPermissionsCheck,
  useEnvironmentsPermissions,
} from '@stigg-permissions';
import { ApiKeyType, Environment } from '@stigg-types/apiTypes';
import { GridFlex, Icon, OptionsDropdown, Text, TwoLinesLayout } from '@stigg-components';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { ENVIRONMENT_DEFAULT_COLOR, EnvironmentSticklight } from './EnvironmentSwitch';
import { EnvironmentTypeIcon } from './EnvironmentTypeIcon';
import { EnvironmentApiKey } from './EnvironmentApiKey';
import config from '../../../../env.config';
import { ClientSideSecurityIndicator } from './ClientSideSecurityIndicator';
import { archiveEnvironmentAction, dumpEnvironmentProductCatalogAction } from '../../accountsSlice';
import { DeleteEnvironment } from './DeleteEnvironment';
import { ClientSideSecurityDrawer } from './ClientSideSecurityDrawer';
import { MergeEnvironmentsDrawer } from '../mergeEnvironments/MergeEnvironmentsDrawer';
import { useNavigation } from '../../../navigation/useNavigation';
import { useCurrentMember } from '../../../auth/hooks/useCurrentMember';
import Table, { HeadCell } from '../../../../components/table/Table';
import { EditEnvironmentDrawer } from './EnvironmentDetailsDrawer';

const headCells = ({
  onEditClick,
  onDeleteClick,
  onMergeClick,
  onSetClientSideSecurity,
  shouldShowOpenDemo,
  currentEnvironmentId,
  onDumpClick,
  permissions,
  allowCreateNewEnvironment,
}: {
  onEditClick: (environment: Environment) => void;
  onDeleteClick: (environment: Environment) => void;
  onMergeClick: (environment: Environment) => void;
  onSetClientSideSecurity: (environment: Environment) => void;
  shouldShowOpenDemo?: boolean;
  currentEnvironmentId?: string;
  onDumpClick: (environment: Environment) => Promise<void>;
  permissions: Record<string, EnvironmentPermissions>;
  allowCreateNewEnvironment: boolean;
}): Array<HeadCell<Environment, any>> => {
  return [
    {
      id: 'name',
      alignment: 'left',
      label: t('accounts.environmentName'),
      render: (environment) => (
        <GridFlex.RowSpaceBetween>
          <GridFlex.RowCenter>
            <EnvironmentSticklight $color={environment.color || ENVIRONMENT_DEFAULT_COLOR} sx={{ height: 42 }} />
            <GridFlex.Column data-stigg-environment-id={environment.id}>
              <TwoLinesLayout firstRow={environment.displayName} secondRow={environment.description || ''} />
              {currentEnvironmentId === environment.id ? (
                <Text.B2 $bold overrideColor={environment.color || ENVIRONMENT_DEFAULT_COLOR}>
                  {t('account.selectedEnvironment')}
                </Text.B2>
              ) : null}
            </GridFlex.Column>
          </GridFlex.RowCenter>

          <EnvironmentTypeIcon environment={environment} />
        </GridFlex.RowSpaceBetween>
      ),
    },
    {
      id: 'serverApiKey',
      alignment: 'left',
      label: t('accounts.serverApiKey'),
      render: (environment) => {
        const serverApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Server);
        return serverApiKey ? <EnvironmentApiKey apiKey={serverApiKey} truncateApiKey /> : '';
      },
    },
    {
      id: 'clientApiKey',
      alignment: 'left',
      label: t('accounts.clientSdkKey'),
      render: (environment) => {
        const clientApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Client);
        return clientApiKey ? <EnvironmentApiKey apiKey={clientApiKey} truncateApiKey /> : '';
      },
    },
    {
      id: 'clientSideSecurity',
      alignment: 'left',
      label: t('accounts.clientSideSecurity'),
      render: (environment: Environment) => (
        <ClientSideSecurityIndicator
          enabled={environment.hardenClientAccessEnabled}
          allowChange={permissions[environment.id].WriteEnvironment}
          onClick={() => onSetClientSideSecurity(environment)}
        />
      ),
    },
    {
      id: 'options',
      alignment: 'right',
      width: 56,
      maxWidth: 36 + 8,
      label: '',
      render: (environment) => {
        const clientApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Client);
        const serverApiKey = environment.apiKeys.find((apiKey) => apiKey.keyType === ApiKeyType.Server);
        return (
          <OptionsDropdown
            options={[
              {
                icon: Edit2,
                text: t('customers.edit'),
                hide: !permissions[environment.id].WriteEnvironment,
                onClick: () => {
                  onEditClick(environment);
                },
              },
              {
                icon: () => <Icon icon="Duplicate" color="default" size={20} type="custom" overrideStroke />,
                text: t('accounts.copyMergeEnvironments.CTA'),
                hide: !allowCreateNewEnvironment,
                onClick: () => {
                  onMergeClick(environment);
                },
              },
              {
                icon: () => <Icon icon="JSONDocument" type="custom" size={20} color="default" overrideStroke />,
                text: t('accounts.dumpEnvironmentCTA'),
                onClick: () => {
                  void onDumpClick(environment);
                },
              },
              {
                icon: Trash2,
                text: t('sharedComponents.delete'),
                hide: !permissions[environment.id].WriteEnvironment,
                onClick: () => {
                  onDeleteClick(environment);
                },
              },
              ...(shouldShowOpenDemo
                ? [
                    {
                      icon: ExternalLink,
                      text: t('accounts.openDemoApp'),
                      onClick: () => {
                        const configs = [
                          `environmentName=${environment.slug}`,
                          `serverSdkKey=${serverApiKey?.token}`,
                          `clientSdkKey=${clientApiKey?.token}`,
                          `signingToken=${environment.signingToken}`,
                        ];
                        window.open(`${config.demoBaseUrl}?${configs.join('&')}`, '_blank');
                      },
                    },
                  ]
                : []),
            ]}
          />
        );
      },
    },
  ];
};

export default function AccountEnvironmentsTable() {
  const dispatch = useAppDispatch();
  const { theme } = useStiggTheme();
  const { openEnvironmentDemo: shouldShowOpenDemo } = useFlags<FeatureFlags>();
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const environmentPermissions = useEnvironmentsPermissions(environments);
  const allowCreateNewEnvironment = useAccountPermissionsCheck([
    AccountPermissionActions.CreateProductionEnvironment,
    AccountPermissionActions.CreateNonProductionEnvironment,
  ]).some((p) => p);

  const currentMember = useCurrentMember();

  const { navigateTo, appRoutes } = useNavigation();
  const { value: environmentQueryParam } = useQueryParam('clientSideSecuritySettings');

  const [currentEnvironment, setCurrentEnvironment] = useState<Environment | undefined>();
  const [currentClientSideSecurityEnv, setCurrentClientSideSecurityEnv] = useState<Environment | null>(null);
  const [currentMergedEnvironment, setCurrentMergedEnvironment] = useState<Environment | null>(null);

  const [isEditEnvironmentOpen, setIsEditEnvironmentOpen] = useState(false);
  const [DeleteEnvironmentModal, setIsDeleteOpen] = useModal({ title: t('accounts.deleteEnv') });

  const onEditClick = (env: Environment) => {
    setCurrentEnvironment(env);
    setIsEditEnvironmentOpen(true);
  };

  const onCloseEditDialog = () => {
    setIsEditEnvironmentOpen(false);
  };

  const handleDeleteSubmit = async () => {
    if (currentEnvironment?.id) {
      await dispatch(archiveEnvironmentAction({ environmentId: currentEnvironment.id }));
    }
    setIsDeleteOpen(false);
  };

  const onDeleteClick = (env: Environment) => {
    setCurrentEnvironment(env);
    setIsDeleteOpen(true);
  };

  const onCloseDeleteDialog = () => {
    setIsDeleteOpen(false);
  };

  const onDumpClick = async (env: Environment) => {
    if (!currentMember?.serviceApiKey) {
      console.warn('No API key for logged in user');
      return;
    }

    const dump = await dispatch(dumpEnvironmentProductCatalogAction({ environmentSlug: env.slug })).unwrap();
    const content = stringify(dump, { space: 2 });
    const blob = new Blob([content], { type: 'application/octet-stream' });
    const blobUrl = window.URL.createObjectURL(blob);
    const tempAnchor = document.createElement('a');
    tempAnchor.href = blobUrl;
    tempAnchor.style.display = 'none';
    tempAnchor.setAttribute('download', `${env.displayName}.json`);
    document.body.appendChild(tempAnchor);
    tempAnchor.click();
    setTimeout(() => {
      document.body.removeChild(tempAnchor);
      window.URL.revokeObjectURL(blobUrl);
    }, 500);
  };

  useEffect(() => {
    const environment = environments.find((env) => env.slug === environmentQueryParam) || null;
    setCurrentClientSideSecurityEnv(environment);
  }, [environmentQueryParam, setCurrentClientSideSecurityEnv, environments]);

  const onSetClientSideSecurity = (environment: Environment | null) => {
    const slug = environment?.slug;
    navigateTo(appRoutes.settingsPage({ clientSideSecurityEnvironmentSlug: slug }), { isGlobal: true });
  };

  return (
    <>
      <Table
        rowHeight={100}
        rowColor={(environment) => {
          if (theme.isLightTheme) {
            return environment?.id === currentEnvironmentId ? '#EBEDF3' : 'white';
          }

          return environment?.id === currentEnvironmentId ? theme.itamar.palette.background.fadedBackground : undefined;
        }}
        headCells={headCells({
          onEditClick,
          onDeleteClick,
          onSetClientSideSecurity,
          shouldShowOpenDemo,
          currentEnvironmentId,
          onDumpClick,
          onMergeClick: setCurrentMergedEnvironment,
          permissions: environmentPermissions,
          allowCreateNewEnvironment,
        })}
        label={t('accounts.environmentsTitle')}
        data={environments}
      />

      <EditEnvironmentDrawer
        isOpen={isEditEnvironmentOpen}
        onClose={onCloseEditDialog}
        environment={currentEnvironment}
      />

      <DeleteEnvironmentModal>
        <DeleteEnvironment
          onSubmit={handleDeleteSubmit}
          environment={currentEnvironment}
          onCancel={onCloseDeleteDialog}
        />
      </DeleteEnvironmentModal>

      <ClientSideSecurityDrawer
        open={!!currentClientSideSecurityEnv}
        onClose={() => onSetClientSideSecurity(null)}
        environment={currentClientSideSecurityEnv}
      />

      <MergeEnvironmentsDrawer
        open={!!currentMergedEnvironment}
        onClose={() => {
          setCurrentMergedEnvironment(null);
        }}
        environment={currentMergedEnvironment}
      />
    </>
  );
}
