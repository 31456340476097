import { configureStore, PayloadAction } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { browserHistory } from '../browserHistory';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '../localStorage';
import { rootReducer } from './rootReducer';
import config from '../env.config.ts';

export const store = configureStore({
  preloadedState: loadStateFromLocalStorage(),
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(browserHistory), saveStateToLocalStorage()),
});

if (config.devMode) {
  if (import.meta.hot) {
    import.meta.hot.accept('./rootReducer', (newModule) => {
      if (newModule?.default) {
        const newRootReducer = newModule.default;
        store.replaceReducer(newRootReducer);
      }
    });
  }
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type DispatchResponse = PayloadAction<any, string, { requestStatus: 'rejected' | 'fulfilled' }>;
export type DispatchResponseOptional = DispatchResponse | null | undefined;
