import { OptionsDropdown, GridFlex, Grid, Card } from '@stigg-components';
import styled from 'styled-components';
import { Star, Trash2 } from 'react-feather';
import { IntegrationListItemFragment, VendorType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import countBy from 'lodash/countBy';
import { IntegrationStatus } from './IntegrationStatus';
import { IntegrationRow } from './common/IntegrationRow';

const ActiveIntegrationCard = styled(Card)`
  &:hover {
    background-color: ${({ theme }) => theme.itamar.palette.action.hover};
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  }
`;

export function ActiveIntegrationsList({
  integrations,
  vendorType,
  onIntegrationClick,
  onIntegrationRemove,
  onSetIntegrationAsDefault,
  allowIntegrationWrite,
}: {
  integrations: IntegrationListItemFragment[];
  vendorType?: VendorType;
  onIntegrationClick: (integration: IntegrationListItemFragment) => void;
  onIntegrationRemove: (integration: IntegrationListItemFragment) => void;
  onSetIntegrationAsDefault: (integration: IntegrationListItemFragment) => void;
  allowIntegrationWrite: boolean;
}) {
  const activeIntegrations = integrations.filter((integration) => !vendorType || integration.vendorType === vendorType);
  const countByVendorType = countBy(activeIntegrations, 'vendorType');

  return (
    <GridFlex.Column pl={4} pr={4} rowGap={4}>
      {activeIntegrations.map((integration) => (
        <ActiveIntegrationCard
          key={integration.id}
          sx={{ height: 80, cursor: 'pointer' }}
          onClick={() => onIntegrationClick(integration)}>
          <GridFlex.RowCenter
            gap={2}
            sx={{
              p: 4,
              height: '100%',
              justifyContent: 'space-between',
            }}>
            <IntegrationRow
              integration={integration}
              iconSize={50}
              showDefault={integration.isDefault && countByVendorType[integration.vendorType] > 1 ? 'long' : 'none'}
            />

            <IntegrationStatus integration={integration} />

            {allowIntegrationWrite ? (
              <Grid item>
                <OptionsDropdown
                  options={[
                    {
                      icon: Star,
                      text: t('integrations.setAsDefault', { context: integration.vendorType }),
                      hide: countByVendorType[integration.vendorType] > 1 && !!integration.isDefault,
                      onClick: () => {
                        onSetIntegrationAsDefault(integration);
                      },
                    },
                    {
                      icon: Trash2,
                      text: t('integrations.deleteButtonText'),
                      onClick: () => {
                        onIntegrationRemove(integration);
                      },
                    },
                  ]}
                />
              </Grid>
            ) : null}
          </GridFlex.RowCenter>
        </ActiveIntegrationCard>
      ))}
    </GridFlex.Column>
  );
}
