import { useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Check, Trash2 as RevokeIcon, Edit2 as EditIcon } from 'react-feather';
import { t } from 'i18next';
import {
  Alert,
  Link,
  VisibilityOffIcon,
  VisibilityOnIcon,
  GridFlex,
  Grid,
  OptionsDropdown,
  Text,
  TwoLinesLayout,
  ConfirmationDialog,
  EmptyCardContent,
  Button,
  Hidable,
} from '@stigg-components';
import { EnvironmentPermissionActions, useEnvironmentPermissionCheck } from '@stigg-permissions';
import { getIconColor } from '@stigg-theme';
import { isEmpty } from 'lodash';
import {
  CustomerPromotionalEntitlementsDataFragment,
  CustomerResponseFragment,
  FeatureType,
} from '@stigg-types/apiTypes';
import { formatFeatureUnits } from '@stigg-common';
import Table, { HeadCell } from '../../../../../../components/table/Table';
import { formatResetPeriod } from '../../../../../entitlements/formatResetPeriod';
import { Dialog } from '../../../../../../components/Dialog';
import { Navigation, useNavigation } from '../../../../../navigation/useNavigation';
import { AddPromotionalEntitlementForm } from './AddPromotionalEntitlementForm';
import { PromotionalEntitlementStatusChip } from './PromotionalEntitlementStatusChip';
import { PromotionalEntitlementPeriodBox } from './PromotionalEntitlementPeriodBox';
import { deletePromotionalEntitlementAction } from '../../../../customersSlice';
import { useAppDispatch } from '../../../../../../redux/store';
import { EditPromotionalEntitlementForm } from './EditPromotionalEntitlementForm';
import { PageCard } from '../../../../../../components/PageCard';

const SMALL_COL = 80;
const MID_COL = 160;
const BIG_COL = 240;

const createHeadCells = (
  navigation: Navigation,
  setDeleteDialogOpen: (open: boolean) => void,
  setEditDialogOpen: (open: boolean) => void,
  setPromotionalEntitlementToDelete: (promotionalEntitlementId: string) => void,
  setPromotionalEntitlementToEdit: (promotionalEntitlement: CustomerPromotionalEntitlementsDataFragment | null) => void,
  isReadOnly: boolean,
): Array<HeadCell<CustomerPromotionalEntitlementsDataFragment, any>> => [
  {
    id: 'id',
    width: MID_COL,
    alignment: 'left',
    label: t('entitlements.featureId'),
    render: (entity: CustomerPromotionalEntitlementsDataFragment) => (
      <Link
        onClick={() => {
          navigation.navigateTo(`/features/${entity.feature?.refId}`);
        }}>
        {entity.feature?.refId}
      </Link>
    ),
  },
  {
    id: 'name',
    width: MID_COL,
    alignment: 'left',
    label: t('entitlements.featureName'),
    render: (entity: CustomerPromotionalEntitlementsDataFragment) => (
      <TwoLinesLayout firstRow={entity.feature?.displayName} secondRow={entity.feature.description || ''} />
    ),
  },
  {
    id: 'value',
    width: SMALL_COL,
    alignment: 'left',
    label: t('entitlements.featureValue'),
    render: (entity: CustomerPromotionalEntitlementsDataFragment) => {
      if (entity.feature?.featureType === FeatureType.Boolean) {
        return (
          <Grid container justifyContent="left" alignItems="center">
            <Grid item pt={1} mr={2}>
              <Check color={getIconColor('active')} />
            </Grid>
          </Grid>
        );
      }

      return (
        <Grid container justifyContent="left" alignItems="center">
          <Grid item container flexDirection="column">
            <Grid item>
              <Text.B2>
                {entity.hasUnlimitedUsage
                  ? t('entitlements.unlimitedUsage')
                  : formatFeatureUnits(entity.feature, entity.usageLimit)}
              </Text.B2>
            </Grid>
            {entity.resetPeriod && (
              <Grid item>
                <Text.Sub2>{formatResetPeriod(entity.resetPeriod)}</Text.Sub2>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 'status',
    width: SMALL_COL,
    alignment: 'left',
    label: t('entitlements.featureStatus'),
    render: (entity: CustomerPromotionalEntitlementsDataFragment) => (
      <PromotionalEntitlementStatusChip status={entity.status} />
    ),
  },
  {
    id: 'period',
    width: BIG_COL,
    alignment: 'left',
    label: t('entitlements.featurePromotionalPeriod'),
    render: (entity: CustomerPromotionalEntitlementsDataFragment) => (
      <PromotionalEntitlementPeriodBox period={entity.period} endDate={entity.endDate} />
    ),
  },
  {
    id: 'isVisible',
    width: MID_COL,
    alignment: 'left',
    label: t('entitlements.featureCustomerVisibility'),
    render: (entity: CustomerPromotionalEntitlementsDataFragment) => (
      <GridFlex.RowCenter container>
        <Grid item mr={2}>
          {entity.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
        </Grid>
        <Text.B2>
          {entity.isVisible
            ? t('entitlements.featureCustomerVisibilityVisible')
            : t('entitlements.featureCustomerVisibilityHidden')}
        </Text.B2>
      </GridFlex.RowCenter>
    ),
  },
  {
    id: 'options',
    width: 40,
    maxWidth: 36 + 8,
    alignment: 'right',
    disablePadding: true,
    label: '',
    visible: !isReadOnly,
    render: (entity: CustomerPromotionalEntitlementsDataFragment) => {
      const onRevoke = () => {
        setPromotionalEntitlementToDelete(entity.id);
        setDeleteDialogOpen(true);
      };

      const onEdit = () => {
        setPromotionalEntitlementToEdit(entity);
        setEditDialogOpen(true);
      };
      const options = [
        {
          icon: EditIcon,
          text: 'Edit',
          onClick: onEdit,
        },
        {
          icon: RevokeIcon,
          text: 'Revoke',
          onClick: onRevoke,
        },
      ];
      return <OptionsDropdown options={options} />;
    },
  },
];

export type PromotionalEntitlementsProps = {
  customer: CustomerResponseFragment;
};

export function PromotionalEntitlements({ customer }: PromotionalEntitlementsProps) {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const [editPromotionalEntitlementOpen, setEditPromotionalEntitlementOpen] = useState(false);
  const [createPromotionalEntitlementOpen, setCreatePromotionalEntitlementOpen] = useState(false);
  const [promotionalEntitlementToDelete, setPromotionalEntitlementToDelete] = useState('');
  const [promotionalEntitlementToEdit, setPromotionalEntitlementToEdit] =
    useState<CustomerPromotionalEntitlementsDataFragment | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const allowCustomerWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.CustomerWrite);
  const { hasActiveSubscription } = customer;
  const onCreateCancel = () => {
    setCreatePromotionalEntitlementOpen(false);
  };

  const onEditCancel = () => {
    setEditPromotionalEntitlementOpen(false);
  };

  const handleDeleteDialogClose =
    (promotionalEntitlementId: string, customerRefId: string) => async (shouldDelete: boolean) => {
      if (shouldDelete) {
        await dispatch(deletePromotionalEntitlementAction({ promotionalEntitlementId, customerRefId }));
      }

      setDeleteDialogOpen(false);
    };
  const hasNoPromotionalEntitlements = isEmpty(customer.promotionalEntitlements);

  return (
    <>
      <PageCard>
        <Grid container justifyContent="space-between">
          <Text.H3 mb={2}>{t('customers.promotionalEntitlementsSectionTitle')}</Text.H3>
          <Hidable show={allowCustomerWrite}>
            <Button color="primary" $outlined onClick={() => setCreatePromotionalEntitlementOpen(true)}>
              <AddIcon />
              {t('customers.addPromotionalEntitlement')}
            </Button>
          </Hidable>
        </Grid>
        <Text.Sub2 mb={4}>{t('customers.promotionalEntitlementsSectionSubtitle')}</Text.Sub2>
        <>
          {hasNoPromotionalEntitlements ? (
            <EmptyCardContent>
              <Text.Sub2>{t('customers.promotionalEntitlementsSectionEmpty')}</Text.Sub2>

              <Hidable show={allowCustomerWrite}>
                <Link variant="body2" ml={1} onClick={() => setCreatePromotionalEntitlementOpen(true)}>
                  {t('customers.promotionalEntitlementsSectionEmptyCTA')}
                </Link>
              </Hidable>
            </EmptyCardContent>
          ) : (
            <Table
              label={t('customers.promotionalEntitlementsTableLabel')}
              data={customer.promotionalEntitlements}
              headCells={createHeadCells(
                navigation,
                setDeleteDialogOpen,
                setEditPromotionalEntitlementOpen,
                setPromotionalEntitlementToDelete,
                setPromotionalEntitlementToEdit,
                !allowCustomerWrite,
              )}
            />
          )}
          {!hasActiveSubscription && !hasNoPromotionalEntitlements && (
            <Alert mt={4} severity="warning">
              {t('customers.promotionalEntitlementsNoActiveSubscriptionWarning')}
            </Alert>
          )}
        </>
      </PageCard>
      <Dialog
        width={730}
        onCancel={onCreateCancel}
        content={
          <AddPromotionalEntitlementForm
            onCancel={onCreateCancel}
            customer={customer}
            hasActiveSubscription={hasActiveSubscription}
          />
        }
        isOpen={createPromotionalEntitlementOpen}
      />
      <Dialog
        onCancel={onEditCancel}
        content={
          <EditPromotionalEntitlementForm
            setEditDialogOpen={setEditPromotionalEntitlementOpen}
            entitlement={promotionalEntitlementToEdit}
            customerId={customer.customerId}
          />
        }
        isOpen={editPromotionalEntitlementOpen}
      />
      <ConfirmationDialog
        content={t('promotionalEntitlements.deleteDialogContent')}
        title={t('promotionalEntitlements.deleteDialogTitle')}
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose(promotionalEntitlementToDelete, customer.customerId)}
        confirmationButtonText={t('promotionalEntitlements.deleteDialogCTA')}
      />
    </>
  );
}
