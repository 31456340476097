import { useIntegrations } from '@stigg-common';
import { SelectValue } from '@stigg-components/types';
import { IntegrationRow } from '../../../../integrations/components/common/IntegrationRow';
import { GridFlex } from '@stigg-components';

export function BillingProviderPicker(): Array<SelectValue> {
  const { billingIntegrations } = useIntegrations();

  return billingIntegrations.map((integration) => {
    return {
      overrideDisplayValue: (
        <GridFlex.Row>
          <IntegrationRow hideIntegrationId integration={integration} iconSize={32} showDefault="none" />
        </GridFlex.Row>
      ),
      value: integration.integrationId,
      displayValue: (
        <GridFlex.Row>
          <IntegrationRow integration={integration} iconSize={32} showDefault="long" />
        </GridFlex.Row>
      ),
    };
  });
}
