import { GridFlex, Grid, Text, Tabs } from '@stigg-components';
import sortBy from 'lodash/sortBy';
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IntegrationListItemFragment, VendorType } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { VendorIdentifier } from '../types';
import { IntegrationCard } from './IntegrationCard';
import { AvailableIntegration } from '../models';
import { useIntegrations } from '@stigg-common';

type AddIntegrationProps = {
  title: string;
  withOuterBorder?: boolean;
  onAddIntegration?: (vendorIdentifier: VendorIdentifier) => void;
  activeIntegrations?: IntegrationListItemFragment[];
};

const VENDOR_TYPE_TABS = [
  VendorType.Billing,
  VendorType.Crm,
  VendorType.DataExport,
  VendorType.Marketplace,
  VendorType.Auth,
];

function Integrations({
  integrations,
  vendorType,
  onAddIntegration,
  activeIntegrations,
  multipleBillingIntegrationsEnabled,
}: {
  integrations: AvailableIntegration[];
  vendorType?: VendorType;
  onAddIntegration?: (vendorIdentifier: VendorIdentifier) => void;
  activeIntegrations?: IntegrationListItemFragment[];
  multipleBillingIntegrationsEnabled: boolean;
}) {
  const availableIntegrations = sortBy(
    integrations.filter((integration) => (!vendorType || integration.vendorType === vendorType) && !integration.hidden),
    (x) => !!x.isComingSoon,
  );

  const canAddIntegration = (vendorIdentifier: VendorIdentifier) => {
    const integration = availableIntegrations.find((integration) => integration.vendorIdentifier === vendorIdentifier);
    const hasActiveIntegration = activeIntegrations?.some(
      (activeIntegration) => activeIntegration.vendorIdentifier === vendorIdentifier,
    );
    const canAddMultipleIntegrations = multipleBillingIntegrationsEnabled && !!integration?.allowMultiple;

    console.log(
      'canAddMultipleIntegrations',
      multipleBillingIntegrationsEnabled,
      canAddMultipleIntegrations,
      vendorIdentifier,
    );
    return canAddMultipleIntegrations || !hasActiveIntegration;
  };

  return (
    <Grid item container columnSpacing={4} rowSpacing={4} p={6}>
      {availableIntegrations.map((availableIntegration) => (
        <Grid key={availableIntegration.vendorIdentifier} item xs={12} md={6} lg={6} xl={4} pt={0}>
          <IntegrationCard
            availableIntegration={availableIntegration}
            onAddIntegration={onAddIntegration}
            isDisabled={!canAddIntegration(availableIntegration.vendorIdentifier)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export function AddIntegration({
  title,
  withOuterBorder = true,
  onAddIntegration,
  activeIntegrations,
}: AddIntegrationProps) {
  const { multipleBillingIntegrations: multipleBillingIntegrationsEnabled } = useFlags<FeatureFlags>();

  const { availableIntegrations } = useIntegrations();

  const tabs = [undefined, ...VENDOR_TYPE_TABS].map((vendorType) => ({
    title: vendorType ? t('integrations.vendorType', { context: vendorType }) : t('integrations.allIntegrationsTab'),
    content: (
      <Integrations
        integrations={availableIntegrations}
        vendorType={vendorType}
        onAddIntegration={onAddIntegration}
        activeIntegrations={activeIntegrations}
        multipleBillingIntegrationsEnabled={multipleBillingIntegrationsEnabled}
      />
    ),
  }));

  return (
    <GridFlex.Column $fullHeight $fullWidth>
      <Grid flexDirection="column" container spacing={12} mb={6}>
        <Grid item>
          <Text.H1 gutterBottom display="inline">
            {title}
          </Text.H1>
        </Grid>
      </Grid>

      <GridFlex.Column
        sx={{
          backgroundColor: (theme) => theme.itamar.palette.background.paper,
          border: (theme) => (withOuterBorder ? `1px solid ${theme.itamar.border.borderColor}` : 'none'),
          borderRadius: (theme) => theme.itamar.border.radius,
          flex: 1,
        }}
        $fullHeight
        $fullWidth>
        <Tabs orientation="vertical" wrapperStyle={{ minWidth: 222, pt: 4 }} data={tabs} withoutQueryParams />
      </GridFlex.Column>
    </GridFlex.Column>
  );
}
