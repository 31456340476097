import * as Yup from 'yup';
import {
  BillingCadence,
  BillingModel,
  BillingPeriod,
  Currency,
  PricingType,
  VendorIdentifier,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { SchemaLike } from 'yup/lib/types';
import isNil from 'lodash/isNil';
import {
  AbstractUsageBasedChargeType,
  Charge,
  ChargeType,
  PriceTier,
  PriceTiersPeriods,
  TIERS_SCHEMA_PER_UNIT,
  TiersSchema,
} from './SetPriceWizardForm.types';
import { splitChargesByChargeType } from './SetPriceWizardForm.utils';
import { MAX_FRACTION_DIGITS, validateMaximumFractionDigits } from '../../../utils/priceValidationUtils';

type ValidationSchemaProps = {
  vendorIdentifier?: VendorIdentifier;
};

const TIERS_SCHEMA_DISALLOWED_MULTIPLE_FIELDS_WHEN_ZUORA = [TiersSchema.GraduatedPerUnit, TiersSchema.VolumePerUnit];

const endUnitValidation = (tiers: PriceTier[], tiersSchema: TiersSchema, currentEndUnit?: number) => {
  let isInValid = false;
  tiers.forEach((tier, index) => {
    const isLastPerUnitTier = TIERS_SCHEMA_PER_UNIT.includes(tiersSchema) && index === tiers.length - 1;
    const lastTierEndUnit = tiers[index - 1]?.endUnit;
    if (
      index > 0 &&
      tier.endUnit &&
      lastTierEndUnit &&
      tier.endUnit <= lastTierEndUnit &&
      currentEndUnit === tier.endUnit &&
      !isLastPerUnitTier
    ) {
      isInValid = true;
    }
  });
  return !isInValid;
};

const priceAmountValidation = ({ numberOfDecimalDigits }: { numberOfDecimalDigits?: number } = {}) =>
  Yup.number()
    .typeError(t('pricing.yup.shouldBePositiveNumber'))
    .min(0, t('pricing.yup.shouldBePositiveNumber'))
    .test(
      'decimal-digits',
      t('pricing.yup.maxDecimalDigits', { numberOfDecimalDigits: numberOfDecimalDigits || MAX_FRACTION_DIGITS }),
      (val) => validateMaximumFractionDigits(val, numberOfDecimalDigits),
    )
    .required(t('pricing.yup.required'));

const priceValidation = ({
  numberOfDecimalDigits,
  isBlockPricing,
}: { numberOfDecimalDigits?: number; isBlockPricing?: boolean } = {}) =>
  Yup.object()
    .shape({
      amount: priceAmountValidation({ numberOfDecimalDigits }),
      blockSize: isBlockPricing
        ? Yup.number().positive().min(2, t('pricing.yup.packagePricingMinimum')).required(t('pricing.yup.required'))
        : Yup.number().nullable().optional(),
    })
    .required(t('pricing.yup.required'));

const priceBillingPeriodValidation = (billingPeriods: BillingPeriod[]) =>
  Yup.object().when('isBlockPricing', {
    is: true,
    then: Yup.object().shape(
      Object.fromEntries(
        billingPeriods.map((billingPeriod) => [billingPeriod, priceValidation({ isBlockPricing: true })]),
      ),
    ),
    otherwise: Yup.object().shape(
      Object.fromEntries(billingPeriods.map((billingPeriod) => [billingPeriod, priceValidation()])),
    ),
  });

function tiersPricesValidation({
  numberOfDecimalDigits,
  disallowTierBothPrices,
}: {
  numberOfDecimalDigits?: number;
  disallowTierBothPrices?: boolean;
}) {
  return priceAmountValidation({ numberOfDecimalDigits }).test(
    'both-prices',
    t('pricing.yup.disallowTierBothPrices'),
    function validateBothPrices(): boolean {
      const { unitPrice, tierPrice } = this.parent;
      return !disallowTierBothPrices || isNil(unitPrice) || unitPrice === 0 || isNil(tierPrice) || tierPrice === 0;
    },
  );
}

const tiersValidation = (
  tiersSchema: TiersSchema,
  tiers: PriceTiersPeriods,
  {
    validateEndUnit = true,
    requireAllTiers,
    vendorIdentifier,
  }: { validateEndUnit?: boolean; requireAllTiers?: boolean; vendorIdentifier?: VendorIdentifier } = {},
) => {
  const disallowTierBothPrices =
    vendorIdentifier === VendorIdentifier.Zuora &&
    TIERS_SCHEMA_DISALLOWED_MULTIPLE_FIELDS_WHEN_ZUORA.includes(tiersSchema);
  const schema = Yup.array().of(
    Yup.object()
      .shape(
        {
          ...(validateEndUnit
            ? {
                endUnit: Yup.number()
                  .test('tiers-ascending', t('pricing.yup.unitsShouldBeHigherThanPreviousTier'), (tier) =>
                    endUnitValidation(tiers?.MONTHLY || [], tiersSchema, tier),
                  )
                  .nullable(),
              }
            : {}),
          unitPrice: Yup.number().when('unitPrice', (unitPrice?: number | null) => {
            return isNil(unitPrice)
              ? Yup.number().nullable().optional()
              : tiersPricesValidation({ disallowTierBothPrices });
          }),
          tierPrice: Yup.number().when('tierPrice', (tierPrice?: number | null) => {
            return isNil(tierPrice)
              ? Yup.number().nullable().optional()
              : tiersPricesValidation({ numberOfDecimalDigits: 2, disallowTierBothPrices });
          }),
        },
        [
          ['unitPrice', 'unitPrice'],
          ['tierPrice', 'tierPrice'],
        ],
      )
      .required(t('pricing.yup.required')),
  );

  if (requireAllTiers) {
    const tiersLength = tiers?.MONTHLY?.length || tiers?.ANNUALLY?.length || 1;
    return schema.length(tiersLength, t('pricing.yup.required')).required(t('pricing.yup.required'));
  }

  return schema.min(1, t('pricing.yup.minimumOneTierRequired')).required(t('pricing.yup.required'));
};

const chargesValidation = ({
  pricingType,
  billingPeriods,
  billingCadence,
  vendorIdentifier,
}: {
  pricingType: PricingType;
  billingPeriods: BillingPeriod[];
  billingCadence: BillingCadence;
  vendorIdentifier?: VendorIdentifier;
}): SchemaLike => {
  if (pricingType !== PricingType.Paid) {
    return Yup.array().optional();
  }
  if (billingCadence === BillingCadence.OneOff) {
    return Yup.array().of(
      Yup.object().shape({
        pricePeriods: priceBillingPeriodValidation([BillingPeriod.Monthly]),
      }),
    );
  }

  return Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.mixed<ChargeType>().oneOf(Object.values(ChargeType)).required(t('pricing.yup.required')),

        billingModel: Yup.mixed<BillingModel>().oneOf(Object.values(BillingModel)).required(t('pricing.yup.required')),

        tiersSchema: Yup.mixed<TiersSchema>().oneOf(Object.values(TiersSchema)).optional(),

        tiers: Yup.lazy((tiers: PriceTiersPeriods) => {
          return Yup.object().when('tiersSchema', (tiersSchema: TiersSchema) => {
            if (!tiersSchema || [TiersSchema.Standard, TiersSchema.StandardPerBlock].includes(tiersSchema)) {
              return Yup.object().optional();
            }

            return Yup.object().shape(
              Object.fromEntries(
                billingPeriods.map((billingPeriod) => [
                  billingPeriod,
                  tiersValidation(tiersSchema, tiers, { vendorIdentifier }),
                ]),
              ),
            );
          });
        }),

        pricePeriods: Yup.object().when('tiersSchema', (tiersSchema: TiersSchema) => {
          if (tiersSchema && ![TiersSchema.Standard, TiersSchema.StandardPerBlock].includes(tiersSchema)) {
            return Yup.object().optional();
          }

          return priceBillingPeriodValidation(billingPeriods);
        }),

        feature: Yup.object().when('billingModel', {
          is: (billingModel) => billingModel === BillingModel.PerUnit || billingModel === BillingModel.UsageBased,
          then: Yup.object().required(t('pricing.yup.required')),
        }),

        minUnitQuantity: Yup.number().when('billingModel', {
          is: BillingModel.PerUnit,
          then: Yup.number()
            .nullable()
            .typeError(t('pricing.yup.shouldBePositiveNumber'))
            .moreThan(0, t('pricing.yup.shouldBePositiveNumber')),
          otherwise: Yup.number().nullable().optional(),
        }),
        maxUnitQuantity: Yup.number().when('billingModel', {
          is: BillingModel.PerUnit,
          then: Yup.number()
            .nullable()
            .typeError(t('pricing.yup.shouldBePositiveNumber'))
            .moreThan(0, t('pricing.yup.shouldBePositiveNumber'))
            .when('minUnitQuantity', {
              is: (minUnitQuantity) => !isNil(minUnitQuantity),
              then: Yup.number().nullable().moreThan(Yup.ref('minUnitQuantity'), t('pricing.minShouldBeSmallerAlert')),
            }),
          otherwise: Yup.number().nullable().optional(),
        }),

        isConfirmed: Yup.boolean().oneOf([true]),
      }),
    )
    .min(1, t('pricing.yup.minimumOneChargeRequired'));
};

const overageChargesValidation = (
  pricingType: PricingType,
  billingPeriods: BillingPeriod[],
  charges: Charge[],
): SchemaLike => {
  if (pricingType !== PricingType.Paid) {
    return Yup.object().optional();
  }

  return Yup.object().shape({
    enabled: Yup.boolean().optional(),
    charges: Yup.array().when('enabled', {
      is: true,
      then: Yup.array().of(
        Yup.object().shape({
          pricePeriods: priceBillingPeriodValidation(billingPeriods),
          feature: Yup.object().required(t('pricing.yup.required')),
          isBlockPricing: Yup.boolean().oneOf([true, false]),
          isConfirmed: Yup.boolean().oneOf([true]),
          entitlement: Yup.object().when('feature', {
            is: (feature) =>
              !charges?.some(
                (charge) => charge.billingModel === BillingModel.PerUnit && charge.feature?.id === feature?.id,
              ),
            then: Yup.object().shape({
              usageLimit: Yup.number()
                .required(t('pricing.yup.required'))
                .typeError(t('pricing.yup.shouldBePositiveNumber'))
                .min(0, t('pricing.yup.shouldBePositiveNumber')),
            }),
            otherwise: Yup.object().nullable().optional(),
          }),
        }),
      ),
    }),
  });
};

const oneOffValidation = (pricingType: PricingType, billingCadence: BillingCadence): SchemaLike => {
  if (pricingType !== PricingType.Paid || billingCadence === BillingCadence.OneOff) {
    return Yup.array().optional();
  }
  return Yup.array()
    .of(Yup.mixed<BillingPeriod>().oneOf(Object.values(BillingPeriod)))
    .min(1, t('pricing.yup.billingPeriodRequired'));
};

const priceLocalizationValidation = ({
  pricingType,
  charges,
  billingPeriods,
  billingCadence,
  vendorIdentifier,
}: {
  pricingType: PricingType;
  charges: Charge[];
  billingPeriods: BillingPeriod[];
  billingCadence: BillingCadence;
  vendorIdentifier?: VendorIdentifier;
}): SchemaLike => {
  if (pricingType !== PricingType.Paid) {
    return Yup.object().optional();
  }

  const { tieredCharges, otherCharges } = splitChargesByChargeType(charges);
  const actualBillingPeriods = billingCadence === BillingCadence.OneOff ? [BillingPeriod.Monthly] : billingPeriods;

  return Yup.object().shape({
    enabled: Yup.boolean().required(t('pricing.yup.required')),

    countries: Yup.array().when('enabled', {
      is: true,
      then: Yup.array().of(
        Yup.object().shape({
          billingCountryCode: Yup.string().required(t('pricing.yup.required')),
          currency: Yup.mixed<Currency>().oneOf(Object.values(Currency)).required(t('pricing.yup.required')),
          countryAndCurrencyConfirmed: Yup.boolean().oneOf([true]),
          isConfirmed: Yup.boolean().oneOf([true]),
          chargesTieredPricePeriods: Yup.object().shape(
            Object.fromEntries(
              (tieredCharges as AbstractUsageBasedChargeType[])
                .filter((charge) => charge.isConfirmed)
                .map((charge) => [
                  charge.uuid,
                  Yup.object().shape(
                    Object.fromEntries(
                      actualBillingPeriods.map((billingPeriod) => [
                        billingPeriod,
                        tiersValidation(charge.tiersSchema!, charge.tiers!, {
                          validateEndUnit: false,
                          requireAllTiers: true,
                          vendorIdentifier,
                        }),
                      ]),
                    ),
                  ),
                ]),
            ),
          ),
          chargesPricePeriods: Yup.object().shape(
            Object.fromEntries(
              otherCharges
                .filter((charge) => charge.isConfirmed)
                .map((charge) => [charge.uuid, priceBillingPeriodValidation(actualBillingPeriods)]),
            ),
          ),
        }),
      ),
    }),
  });
};

export const validationSchema = ({ vendorIdentifier }: ValidationSchemaProps) =>
  Yup.object().shape({
    pricingType: Yup.mixed<PricingType>().oneOf(Object.values(PricingType)).required(t('pricing.yup.required')),

    defaultCurrency: Yup.mixed().when('pricingType', {
      is: PricingType.Paid,
      then: Yup.mixed<Currency>().oneOf(Object.values(Currency)).required(t('pricing.yup.required')),
    }),

    // @ts-ignore
    billingPeriods: Yup.array().when(['pricingType', 'billingCadence'], oneOffValidation),

    charges: Yup.array().when(
      ['pricingType', 'billingPeriods', 'billingCadence'],
      // @ts-ignore
      (pricingType, billingPeriods, billingCadence) =>
        chargesValidation({ pricingType, billingPeriods, billingCadence, vendorIdentifier }),
    ),

    // @ts-ignore
    overageCharges: Yup.object().when(['pricingType', 'billingPeriods', 'charges'], overageChargesValidation),

    priceLocalization: Yup.array().when(
      ['pricingType', 'charges', 'billingPeriods', 'billingCadence'],
      // @ts-ignore
      (pricingType, charges, billingPeriods, billingCadence) =>
        priceLocalizationValidation({ pricingType, charges, billingPeriods, billingCadence, vendorIdentifier }),
    ),

    freeTrial: Yup.object().when('pricingType', {
      is: (pricingType) => pricingType === PricingType.Paid || pricingType === PricingType.Custom,
      then: Yup.object().shape({
        enabled: Yup.boolean().required(t('pricing.yup.required')),
        durationDays: Yup.number()
          .moreThan(0, t('pricing.yup.shouldBePositiveNumber'))
          .required(t('pricing.yup.required')),
      }),
      otherwise: Yup.object().optional(),
    }),
  });
