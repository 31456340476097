import { GridFlex, PageCard, Text } from '@stigg-components';
import { t } from 'i18next';
import { ExperimentType } from '../commom/ExperimentType';
import ExperimentCustomerJourneyIcon from '../../../../assets/icons/experiments/ExperimentCustomerJourney.svg?react';
import ExperimentPlanEntitlementsIcon from '../../../../assets/icons/experiments/ExperimentPlanEntitlements.svg?react';

export function ExperimentTypeCard({ experimentType }: { experimentType?: ExperimentType }) {
  // TODO: improve this component to be interactive and code-reusable

  if (experimentType === ExperimentType.CUSTOMER_JOURNEY) {
    return (
      <PageCard>
        <GridFlex.Row container>
          <ExperimentCustomerJourneyIcon />
          <GridFlex.Column pl={4}>
            <GridFlex.Row item>
              <Text.B2>{t('experiments.whatWouldYouLikeToTest')}</Text.B2>
            </GridFlex.Row>
            <GridFlex.Row item>
              <Text.B2 fontWeight="bold">{t('experiments.useCases.customerJourney.title')}</Text.B2>
            </GridFlex.Row>
          </GridFlex.Column>
        </GridFlex.Row>
      </PageCard>
    );
  }
  if (experimentType === ExperimentType.PLAN_ENTITLEMENTS) {
    return (
      <PageCard>
        <GridFlex.Row container>
          <ExperimentPlanEntitlementsIcon />
          <GridFlex.Column pl={4}>
            <GridFlex.Row item>
              <Text.B2>{t('experiments.whatWouldYouLikeToTest')}</Text.B2>
            </GridFlex.Row>
            <GridFlex.Row item>
              <Text.B2 fontWeight="bold">{t('experiments.useCases.planFeatures.title')}</Text.B2>
            </GridFlex.Row>
          </GridFlex.Column>
        </GridFlex.Row>
      </PageCard>
    );
  }
  // shouldn't reach here for now as we have only one experiment
  return <></>;
}
