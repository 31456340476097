import { StyledEngineProvider } from '@mui/styled-engine-sc';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from 'styled-components/macro';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { KoalaProvider } from '@getkoala/react';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { CookiesProvider } from 'react-cookie';
import { BreadcrumbsProvider } from '@stigg-common';
import { StiggIntercomProvider } from './components/StiggIntercom/StiggIntercomProvider';
import { store } from './redux/store';
import GlobalStyle from './components/GlobalStyle';
import ErrorPage from './components/ErrorPage';
import config from './env.config';
import Router, { BroadcastChannelRouter } from './modules/navigation/Router';
import Notifier from './modules/notifications/components/Notifier';
import './i18n/config';
import { AnalyticsProvider } from './modules/common/AnalyticsProvider';
import ReactQueryProvider from './providers/ReactQueryProvider';
import { BroadcastChannelProvider } from './components/broadcastChannel/useBroadcastChannel';
import { StiggThemeContextProvider } from './theme/StiggTheme';

function KoalaProviderWrapper({ children }: { children: React.ReactNode }) {
  if (!config.koalaApiKey) {
    return <>{children}</>;
  }

  return <KoalaProvider publicApiKey={config.koalaApiKey}>{children}</KoalaProvider>;
}

function App() {
  return (
    <Provider store={store}>
      <Helmet titleTemplate="%s | Stigg" defaultTitle="Stigg" />
      <CssBaseline />
      <CookiesProvider>
        <KoalaProviderWrapper>
          <Auth0Provider {...config.auth0} redirectUri={window.location.origin}>
            <StyledEngineProvider injectFirst>
              <StiggThemeContextProvider>
                {({ theme }) => (
                  <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                      <Sentry.ErrorBoundary fallback={ErrorPage}>
                        <BreadcrumbsProvider>
                          <SnackbarProvider
                            autoHideDuration={5000}
                            disableWindowBlurListener
                            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                            maxSnack={5}>
                            <StiggIntercomProvider>
                              <AnalyticsProvider writeKey={config.segmentKey}>
                                <ReactQueryProvider>
                                  <BroadcastChannelProvider>
                                    <GlobalStyle />
                                    <Notifier />
                                    <Router />
                                    <BroadcastChannelRouter />
                                  </BroadcastChannelProvider>
                                </ReactQueryProvider>
                              </AnalyticsProvider>
                            </StiggIntercomProvider>
                          </SnackbarProvider>
                        </BreadcrumbsProvider>
                      </Sentry.ErrorBoundary>
                    </ThemeProvider>
                  </MuiThemeProvider>
                )}
              </StiggThemeContextProvider>
            </StyledEngineProvider>
          </Auth0Provider>
        </KoalaProviderWrapper>
      </CookiesProvider>
    </Provider>
  );
}

export default withLDProvider({
  context: {
    kind: 'user',
    key: 'shared-anonymous-key',
    anonymous: true,
  },
  clientSideID: config.launchDarklyKey,
})(App);
