import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import axios from 'axios';
import { RetryLink } from '@apollo/client/link/retry';
import config from './env.config';

let accessTokenProvider: () => Promise<string> | undefined;
let currentAccountId: string | undefined;

export const setAccessTokenProvider = (provider: () => Promise<string>) => {
  accessTokenProvider = provider;
};
export const setCurrentAccountId = (accountId?: string) => {
  currentAccountId = accountId;
};

const axiosInstance = axios.create({
  timeout: 60 * 1000,
});

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const accessToken = await accessTokenProvider();
    config.headers = {
      ...config.headers,
      authorization: `Bearer ${accessToken}`,
      'x-account-id': currentAccountId || '',
    };
    return config;
  } catch (e: any) {
    if (!axios.isAxiosError(e) && e.error === 'login_required') {
      const controller = new AbortController();
      // this is an initial solution, we should also dispatch a logout action
      window.location.assign('/login');
      return {
        ...config,
        signal: () => controller.abort(),
      };
    }
    return config;
  }
});

function getAuthLink() {
  return new ApolloLink((operation, forward) => {
    const headers: Record<string, string | undefined> = {
      'x-graphql-operation-name': operation.operationName,
    };

    operation.setContext({ headers });
    return forward(operation);
  });
}

const retryIf = (error: any) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
  attempts: { max: 5, retryIf },
  delay: { initial: 500, max: 2000, jitter: true },
});

const httpLink = new HttpLink({
  uri: config.graphQLUrl,
  fetch: buildAxiosFetch(axiosInstance) as any, // todo fix types
});

const apolloLink = getAuthLink();

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: apolloLink.concat(retryLink.concat(httpLink)),
  name: 'web',
  version: config.version,
});
