import { IntegrationListItemFragment } from '@stigg-types/apiTypes';
import { useIntegrations } from '@stigg-common';

export const useIntegrationDisplayParameters = (integration: IntegrationListItemFragment) => {
  const { availableIntegrationsByVendorIdentifier } = useIntegrations();
  const integrationMetadata = availableIntegrationsByVendorIdentifier[integration.vendorIdentifier];
  const { icon, name: integrationName } = integrationMetadata;
  const displayName =
    integration.credentials?.__typename === 'StripeCredentials'
      ? `${integration.credentials.accountDisplayName}`
      : undefined;
  const integrationId = integration.integrationId;

  return { icon, integrationName, displayName, integrationId };
};
