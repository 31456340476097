import { t } from 'i18next';
import { useIntegrations } from './useIntegrations';

type UseSearchPlaceholderProps = {
  placeholder: string;
  searchParams?: string;
  withBillingIntegration?: boolean;
  withCRMIntegration?: boolean;
};

export const useSearchPlaceholder = ({
  placeholder,
  searchParams,
  withBillingIntegration = true,
  withCRMIntegration = true,
}: UseSearchPlaceholderProps) => {
  const { hasBillingIntegration, hasCRMIntegration } = useIntegrations();
  const integrations: string[] = [];

  if (withBillingIntegration && hasBillingIntegration) {
    integrations.push(t('searchPlaceholder.billingId'));
  }

  if (withCRMIntegration && hasCRMIntegration) {
    integrations.push(t('searchPlaceholder.crmId'));
  }

  if (!integrations.length && !searchParams?.length) {
    return placeholder;
  }

  const searchParamsParts = searchParams?.split(', ') || [];
  const placeholderSearchParams = [...searchParamsParts, ...integrations];

  const searchParamsString = placeholderSearchParams
    .slice(0, -1)
    .join(', ')
    .concat(` or ${placeholderSearchParams.slice(-1)}`);

  return `${placeholder} ${searchParamsString}`;
};
