import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Edit2, Trash2, Star } from 'react-feather';
import { EnvironmentPermissionActions, useEnvironmentPermissionCheck } from '@stigg-permissions';
import {
  Alert,
  Box,
  Chip,
  ConfirmationDialog,
  Divider,
  ExternalLink,
  Grid,
  GridFlex,
  IconButton,
  OptionsDropdown,
  PageCardWithEvenPadding,
  Text,
} from '@stigg-components';
import { IntegrationFragment } from '@stigg-types/apiTypes';
import { VendorIdentifier } from '../types';
import { RootState, useAppDispatch } from '../../../redux/store';
import { deleteIntegrationByIdAction, fetchIntegrationByIdAction, updateIntegrationAction } from '../integrationsSlice';
import Loader from '../../../components/Loader';
import { IntegrationIcon } from './IntegrationCard';
import { isIntegrationActive } from './isIntegrationActive';
import { integrationsComponents } from './IntegrationsComponents';
import { DefaultIntegrationChip } from './DefaultIntegrationChip';
import { countBy } from 'lodash';
import { useIntegrations } from '@stigg-common';

const StyledEditIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const DetailsBox = styled(Box)`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export const StyledAlert = styled(Alert)<{ $muted: boolean }>`
  ${({ theme, $muted }) => {
    if ($muted) {
      return css`
        background-color: ${theme.itamar.palette.grey[25]};

        & .MuiAlert-icon {
          color: ${theme.itamar.palette.primary.main};
        }
      `;
    }
    return '';
  }}
`;

type IntegrationPageProps = {
  vendorIdentifier?: VendorIdentifier | null;
  integrationId?: string | null;
};

export function IntegrationPage({ vendorIdentifier, integrationId }: IntegrationPageProps) {
  const dispatch = useAppDispatch();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  const [editedIntegration, setEditedIntegration] = useState<IntegrationFragment | null>(null);
  const integration = useSelector((state: RootState) => state.integrations.integration);
  const allIntegrations = useSelector((state: RootState) => state.integrations.integrations);
  const isLoading = useSelector((state: RootState) => state.integrations.isLoadingIntegration);
  const allowIntegrationWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const { availableIntegrations } = useIntegrations();
  const integrationVendorIdentifier = integration?.vendorIdentifier || vendorIdentifier;
  const availableIntegration = availableIntegrations.find((x) => x.vendorIdentifier === integrationVendorIdentifier);

  // We want to show the default indication only if there are multiple integrations of the same type
  const countByVendorType = countBy(allIntegrations, 'vendorType');
  const shouldShowDefaultChip = integration?.vendorType && countByVendorType[integration.vendorType] > 1;

  useEffect(() => {
    if (integrationId) {
      void dispatch(fetchIntegrationByIdAction({ integrationId }));
    }
  }, [dispatch, integrationId]);

  const handleDeleteDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && integration) {
      await dispatch(deleteIntegrationByIdAction(integration.id));
    }
    setDeleteDialogOpen(false);
  };

  const handleDefaultDialogClose = async (shouldSetDefault: boolean) => {
    setDefaultDialogOpen(false);

    if (shouldSetDefault && integration) {
      await dispatch(
        updateIntegrationAction({
          integrationId: integration.id,
          integrationData: {
            vendorIdentifier: integration.vendorIdentifier,
            isDefault: true,
          },
        }),
      );
    }
  };

  if (isLoading || !availableIntegration) {
    return <Loader />;
  }

  const { renderPage, renderForm, renderDetails, renderOAuthProvider, renderFooterSection } =
    integrationsComponents[availableIntegration.vendorIdentifier];

  const headerComponent = (
    <GridFlex.Column gap={4}>
      <GridFlex.RowCenter gap={4}>
        <GridFlex.Item>
          <IntegrationIcon src={availableIntegration.icon} alt={availableIntegration.name} />
        </GridFlex.Item>

        <GridFlex.Column item flex={1}>
          <Text.Sub2>{t('integrations.cardSubtitle')}</Text.Sub2>
          <Grid container alignItems="center">
            <Grid item alignItems="center">
              <Text.H3>{availableIntegration.name}</Text.H3>
              {availableIntegration.beta && <Text.B2 ml={2}>{t('integrations.beta')}</Text.B2>}
            </Grid>
            {isIntegrationActive(integration) && (
              <Grid item ml={4}>
                <Chip size="small" label="Active" color="primary" />
              </Grid>
            )}
            {shouldShowDefaultChip && !!integration?.isDefault && (
              <Grid item ml={2}>
                <DefaultIntegrationChip vendorType={integration.vendorType} />
              </Grid>
            )}
          </Grid>
        </GridFlex.Column>

        <Grid item display="flex" alignItems="center">
          <Grid item>
            {allowIntegrationWrite && !!integration && (
              <OptionsDropdown
                options={[
                  {
                    text: 'Set as default',
                    icon: Star,
                    hide: !!integration.isDefault,
                    onClick: () => {
                      setDefaultDialogOpen(true);
                    },
                  },
                  {
                    text: 'Remove',
                    icon: Trash2,
                    onClick: () => {
                      setAddDialogOpen(false);
                      setEditedIntegration(null);
                      setDeleteDialogOpen(true);
                    },
                  },
                ]}
              />
            )}
          </Grid>
        </Grid>
      </GridFlex.RowCenter>
      <GridFlex.Column gap={6}>
        <Text.B2 color="secondary" maxWidth={650}>
          {availableIntegration.getDescription()}
        </Text.B2>

        {availableIntegration.docsUrl && (
          <GridFlex.Item>
            {/* TODO: fix the color of this styled alert */}
            <StyledAlert $muted severity="info" sx={{ width: '100%' }}>
              <Text.B2 gap={1}>
                {t('integrations.integrationGuidePart1')}
                <ExternalLink
                  label={t('integrations.integrationGuidePart2', { integrationName: availableIntegration.name })}
                  url={availableIntegration.docsUrl}
                />
              </Text.B2>
            </StyledAlert>
          </GridFlex.Item>
        )}
      </GridFlex.Column>
    </GridFlex.Column>
  );

  let pageContent: React.ReactNode;

  if (renderPage) {
    pageContent = renderPage({ headerComponent, integration, isReadOnly: !allowIntegrationWrite });
  } else {
    pageContent = (
      <GridFlex.Column gap={8}>
        <PageCardWithEvenPadding>
          <GridFlex.Column gap={6}>
            {headerComponent}

            {integration && !addDialogOpen && renderDetails && (
              <>
                {renderForm && (
                  <StyledEditIconButton
                    onClick={() => {
                      setEditedIntegration(integration);
                      setAddDialogOpen(true);
                    }}>
                    <Edit2 size={20} color="rgba(0, 0, 0, 0.26)" />
                  </StyledEditIconButton>
                )}

                {renderDetails({ integration, isReadOnly: !allowIntegrationWrite })}
              </>
            )}

            {allowIntegrationWrite && !integration && renderOAuthProvider && (
              <>
                <Divider my={4} />
                {renderOAuthProvider()}
              </>
            )}

            {allowIntegrationWrite && (!integration || addDialogOpen) && renderForm && (
              <>
                <Divider my={4} />
                {renderForm({ integration: editedIntegration, onCancel: () => setAddDialogOpen(false) })}
              </>
            )}
          </GridFlex.Column>
        </PageCardWithEvenPadding>

        {renderFooterSection &&
          renderFooterSection({
            integration,
            isReadOnly: !allowIntegrationWrite,
            dispatch,
          })}
      </GridFlex.Column>
    );
  }

  return (
    <>
      <Grid container flexWrap="nowrap" flexDirection="column" rowSpacing={6}>
        <Grid flexDirection="column" container item alignSelf="center" justifyContent="center" sx={{ maxWidth: 800 }}>
          {pageContent}
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        title={t('integrations.delete')}
        content={t('integrations.deleteConfirmation')}
      />

      <ConfirmationDialog
        open={defaultDialogOpen}
        handleClose={handleDefaultDialogClose}
        confirmButtonColor="primary"
        // TODO: Change texts according to Vendor Type?
        confirmationButtonText={t('integrations.setDefaultDialogCTA')}
        title={t('integrations.setDefaultDialogTitle')}
        content={t('integrations.setDefaultDialogContent')}
      />
    </>
  );
}
