import { isUndefined, keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchIntegrationsAction } from '../integrations/integrationsSlice';
import { VendorType } from '@stigg-types/apiTypes';
import { getAvailableIntegrations } from './integrationList';

interface IntegrationsHookResult {
  isLoading: boolean;
  configuredIntegrations: any[];
  availableIntegrations: any[];
  availableIntegrationsByVendorIdentifier: Record<string, any>;
  hasStripeIntegration: boolean;
  hasBillingIntegration: boolean;
  defaultBillingIntegration: any;
  hasMultipleBillingIntegrations: boolean;
  billingIntegrations: any[];
  hasCRMIntegration: boolean;
  isSyncingCustomerAndSubscriptions: boolean;
}

export function useIntegrations(): IntegrationsHookResult {
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const configuredIntegrations = useSelector((state: RootState) => state.integrations.integrations);
  const isLoading = useSelector((state: RootState) => state.integrations.isLoading);
  const {
    stripeSyncOnlyProductCatalog,
    stripeSecretBilling: stripeSecretBillingEnabled,
    auth0Integration: auth0IntegrationEnabled,
    openFgaIntegration: openFgaIntegrationEnabled,
  } = useFlags<FeatureFlags>();

  useEffect(() => {
    if (currentEnvironmentId && isUndefined(configuredIntegrations) && !isLoading) {
      void dispatch(
        fetchIntegrationsAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId, configuredIntegrations, isLoading]);

  return useMemo(() => {
    if (!configuredIntegrations) {
      return {
        isLoading: isLoading || !currentEnvironmentId,
        configuredIntegrations: [],
        availableIntegrations: [],
        availableIntegrationsByVendorIdentifier: {},
        hasStripeIntegration: false,
        hasBillingIntegration: false,
        defaultBillingIntegration: null,
        hasMultipleBillingIntegrations: false,
        billingIntegrations: [],
        hasCRMIntegration: false,
        isSyncingCustomerAndSubscriptions: false,
      };
    }

    const hasStripeIntegration =
      !stripeSecretBillingEnabled &&
      configuredIntegrations.filter((integration) => integration.vendorType === VendorType.Billing).length > 1;

    const hasBillingIntegration =
      !stripeSecretBillingEnabled &&
      !!configuredIntegrations.some((integration) => integration.vendorType === VendorType.Billing);

    const hasMultipleBillingIntegrations =
      !stripeSecretBillingEnabled &&
      configuredIntegrations.filter((integration) => integration.vendorType === VendorType.Billing).length > 1;

    const defaultBillingIntegration = configuredIntegrations.find(
      (integration) => integration.vendorType === VendorType.Billing && integration.isDefault,
    );

    const billingIntegrations = configuredIntegrations.filter(
      (integration) => integration.vendorType === VendorType.Billing,
    );

    const availableIntegrations = getAvailableIntegrations(hasStripeIntegration, {
      stripeSecretBillingEnabled,
      auth0IntegrationEnabled,
      openFgaIntegrationEnabled,
    });

    return {
      isLoading: isLoading || !currentEnvironmentId,
      configuredIntegrations,
      availableIntegrations,
      availableIntegrationsByVendorIdentifier: keyBy(availableIntegrations, 'vendorIdentifier'),
      hasStripeIntegration,
      hasBillingIntegration,
      defaultBillingIntegration,
      hasMultipleBillingIntegrations,
      billingIntegrations,
      hasCRMIntegration: !!configuredIntegrations.find((integration) => integration.vendorType === VendorType.Crm),
      isSyncingCustomerAndSubscriptions: !stripeSyncOnlyProductCatalog,
    };
  }, [
    currentEnvironmentId,
    configuredIntegrations,
    isLoading,
    stripeSecretBillingEnabled,
    auth0IntegrationEnabled,
    openFgaIntegrationEnabled,
    stripeSyncOnlyProductCatalog,
  ]);
}
