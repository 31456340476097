import { t } from 'i18next';
import { PackageStatus } from '@stigg-types/apiTypes';
import { Chip } from '@stigg-components';
import { ChipColor, TextColor } from '../theme';

export function PackageChip({ status, hasPointer }: { status: PackageStatus; hasPointer?: boolean }) {
  const getChipParams = (): [string, ChipColor, TextColor] => {
    switch (status) {
      case PackageStatus.Draft:
        return [t('packages.draft'), 'draft', 'primary'];
      case PackageStatus.Published:
        return [t('packages.active'), 'primary', 'white'];
      case PackageStatus.Archived:
        return [t('packages.archived'), 'draft', 'primary'];
      default:
        throw new Error(`Unknown status: ${status}`);
    }
  };

  const [label, color, textColor] = getChipParams();

  return <Chip $hasPointer={hasPointer} size="small" label={label} color={color} textColor={textColor} />;
}
