import ZuoraSvg from '@assets/icons/Zuora.svg?react';
import SalesforceSvg from '@assets/icons/Salesforce.svg?react';
import SnowflakeSvg from '@assets/icons/snowflake.svg?react';
import BigQuerySvg from '@assets/icons/BigQuery.svg?react';
import styled from 'styled-components/macro';

export const StyledZuoraSvg = styled(ZuoraSvg)`
  width: 48px;
`;

export const StyledSnowflakeSvg = styled(SnowflakeSvg)`
  width: 18px;
  height: 18px;
`;

export const StyledBigQuerySvg = styled(BigQuerySvg)`
  width: 18px;
  height: 18px;
`;

export const StyledSalesforceSvg = styled(SalesforceSvg)`
  width: 18px;
  height: 18px;
`;
