import { EventEntityType, EventLog } from '@stigg-types/apiTypes';
import { appRoutes } from '../../navigation/useNavigation';

type EntityDetails = {
  primary?: string | null;
  secondary?: string | null;
  subtitle?: string | null;
  route?: string;
  containsPii?: boolean;
};

const route = (
  route: (refId: string, version?: string | number) => string,
  entityId: string | null | undefined,
  version?: string | number,
): string | undefined => {
  return entityId ? route(entityId, version) : undefined;
};

export const getEntityDetails = (event: EventLog): EntityDetails | undefined => {
  switch (event.entityType) {
    case EventEntityType.Customer:
      return {
        primary: event.payload.name || event.payload.email || event.entityId,
        secondary: event.payload.name ? event.payload.email : undefined,
        subtitle: event.payload.name || event.payload.email ? event.entityId : undefined,
        route: route(appRoutes.customerPage, event.entityId),
        containsPii: event.payload.name || event.payload.email,
      };
    case EventEntityType.Entitlement:
    case EventEntityType.PromotionalEntitlement:
      return {
        primary: event.payload.customer.name || event.payload.customer.email || event.payload.customer.id,
        secondary: event.payload.customer.name ? event.payload.customer.email : undefined,
        subtitle: event.payload.customer.name || event.payload.customer.email ? event.id : undefined,
        route: route(appRoutes.customerPage, event.payload.customer.id),
        containsPii: event.payload.customer.name || event.payload.customer.email,
      };
    case EventEntityType.Subscription:
      return {
        primary: event.entityId,
        // @ts-ignore FIX-ME
        route: route(appRoutes.subscriptionPage, event.entityId),
      };
    case EventEntityType.Product:
      return {
        primary: event.payload.displayName,
        subtitle: event.entityId,
        route: route(appRoutes.productPage, event.entityId),
      };
    case EventEntityType.Package:
      return {
        primary: event.payload.name,
        secondary: `v${event.payload.version}`,
        subtitle: event.entityId,
        route: route(
          event.payload.packageType === 'Plan' ? appRoutes.planPage : appRoutes.addonPage,
          event.entityId,
          event.payload.version,
        ),
      };

    case EventEntityType.Plan:
      return {
        primary: event.payload.name,
        secondary: `v${event.payload.version}`,
        subtitle: event.entityId,
        route: route(appRoutes.planPage, event.entityId, event.payload.version),
      };
    case EventEntityType.Addon:
      return {
        primary: event.payload.name,
        secondary: `v${event.payload.version}`,
        subtitle: event.entityId,
        route: route(appRoutes.addonPage, event.entityId, event.payload.version),
      };
    case EventEntityType.Feature:
      return {
        primary: event.payload.name,
        subtitle: event.entityId,
        route: route(appRoutes.featurePage, event.entityId),
      };
    case EventEntityType.Coupon:
      return {
        primary: event.payload.name,
        subtitle: event.entityId,
        route: route(appRoutes.couponPage, event.entityId),
      };
    default:
      return undefined;
  }
};
