import { useState } from 'react';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Icon, Button, GridFlex, Grid, Text, Tabs, TabProps, ConfirmationDialog } from '@stigg-components';
import { IntegrationListItemFragment, VendorIdentifier, VendorType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { styled } from '@stigg-theme';
import groupBy from 'lodash/groupBy';
import { clearSelectedIntegrationAction, deleteIntegrationByIdAction } from '../integrationsSlice';
import { useAppDispatch } from '../../../redux/store';
import { AddIntegrationModal } from './AddIntegrationModal';
import { ActiveIntegrationsList } from './ActiveIntegrationsList';
import { useUpdateIntegration } from '../mutations/useUpdateIntegration';

const MainContent = styled(GridFlex.Column)`
  background-color: ${({ theme }) => theme.itamar.palette.background.paper};
  border: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  flex: 1;
`;

export function ActiveIntegrations({
  integrations,
  onActiveIntegrationSelected,
}: {
  integrations: IntegrationListItemFragment[];
  onActiveIntegrationSelected: (integrationId: string) => void;
}) {
  const dispatch = useAppDispatch();
  const allowIntegrationWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);
  const [isOpenAddIntegration, setIsOpenAddIntegration] = useState(false);
  const [integrationToRemove, setIntegrationToRemove] = useState<IntegrationListItemFragment | null>(null);
  const [integrationForDefault, setIntegrationForDefault] = useState<IntegrationListItemFragment | undefined>(
    undefined,
  );
  const [selectedVendorIdentifierToAdd, setSelectedVendorIdentifierToAdd] = useState<VendorIdentifier | null>(null);
  const activeIntegrationsByVendorType = groupBy(integrations, 'vendorType');
  const { mutateAsync: updateIntegration } = useUpdateIntegration({ integrationId: integrationForDefault?.id });

  const closeAddIntegrationDialog = () => {
    if (selectedVendorIdentifierToAdd) {
      setSelectedVendorIdentifierToAdd(null);
    } else {
      setIsOpenAddIntegration(false);
      setSelectedVendorIdentifierToAdd(null);
      dispatch(clearSelectedIntegrationAction());
    }
  };

  const handleRemoveDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && integrationToRemove) {
      await dispatch(deleteIntegrationByIdAction(integrationToRemove.id));
    }
    setIntegrationToRemove(null);
  };

  const handleDefaultDialogClose = async (shouldSetDefault: boolean) => {
    if (shouldSetDefault && integrationForDefault) {
      await updateIntegration({ isDefault: true, vendorIdentifier: integrationForDefault.vendorIdentifier });
    }
    setIntegrationForDefault(undefined);
  };

  const onIntegrationClick = (integration: IntegrationListItemFragment) => {
    onActiveIntegrationSelected(integration.id);
  };

  const onAddIntegrationClick = (vendorIdentifier: VendorIdentifier) => {
    setSelectedVendorIdentifierToAdd(vendorIdentifier);
  };

  const onBack = () => {
    setSelectedVendorIdentifierToAdd(null);
  };

  const tabsByVendorType: TabProps[] = Object.entries(activeIntegrationsByVendorType).map(
    ([vendorType, integrations]) => ({
      title: `${t('integrations.vendorType', { context: vendorType })} (${integrations.length})`,
      url: `${t('integrations.vendorType', { context: vendorType })}`,
      content: (
        <ActiveIntegrationsList
          allowIntegrationWrite={allowIntegrationWrite}
          integrations={integrations}
          vendorType={vendorType as VendorType}
          onIntegrationClick={onIntegrationClick}
          onIntegrationRemove={(integration) => setIntegrationToRemove(integration)}
          onSetIntegrationAsDefault={(integration) => setIntegrationForDefault(integration)}
        />
      ),
    }),
  );

  return (
    <GridFlex.Column $fullHeight>
      <GridFlex.RowCenter mb={6} justifyContent="space-between" $fullWidth>
        <Grid item>
          <Text.H1 gutterBottom display="inline">
            {t('integrations.activeIntegrations')}
          </Text.H1>
        </Grid>

        {allowIntegrationWrite && (
          <Button
            size="large"
            variant="contained"
            startIcon={<Icon icon="Add" color="white" />}
            onClick={() => {
              setIsOpenAddIntegration(true);
            }}>
            <Text.B2 color="white">{t('integrations.addIntegration')}</Text.B2>
          </Button>
        )}
      </GridFlex.RowCenter>

      <MainContent $fullHeight $fullWidth>
        <Tabs
          orientation="vertical"
          wrapperStyle={{ minWidth: 222, pt: 4 }}
          data={[
            {
              title: `${t('integrations.allActiveIntegrationsTab')} (${integrations.length})`,
              url: `${t('integrations.allActiveIntegrationsTab')}`,
              content: (
                <ActiveIntegrationsList
                  allowIntegrationWrite={allowIntegrationWrite}
                  integrations={integrations}
                  onIntegrationClick={onIntegrationClick}
                  onIntegrationRemove={(integration) => setIntegrationToRemove(integration)}
                  onSetIntegrationAsDefault={(integration) => setIntegrationForDefault(integration)}
                />
              ),
            },
            ...tabsByVendorType,
          ]}
        />
      </MainContent>

      <AddIntegrationModal
        isOpen={!!selectedVendorIdentifierToAdd || isOpenAddIntegration}
        onCancel={closeAddIntegrationDialog}
        selectedVendorIdentifier={selectedVendorIdentifierToAdd}
        onIntegrationClick={onAddIntegrationClick}
        activeIntegrations={integrations}
        onBack={onBack}
      />

      <ConfirmationDialog
        open={!!integrationToRemove}
        handleClose={handleRemoveDialogClose}
        title={t('integrations.delete')}
        content={t('integrations.deleteConfirmation')}
      />

      <ConfirmationDialog
        open={!!integrationForDefault}
        handleClose={handleDefaultDialogClose}
        confirmButtonColor="primary"
        confirmationButtonText={t('integrations.setDefaultDialogCTA')}
        title={t('integrations.setDefaultDialogTitle')}
        content={t('integrations.setDefaultDialogContent')}
      />
    </GridFlex.Column>
  );
}
