import { PlanCompatibleAddonFragment, PlanCompatiblePackageGroupFragment } from '@stigg-types/apiTypes';
import { GridFlex, HeadCell, Icon, Link, LongText, OptionsDropdown, Text } from '@stigg-components';
import { t } from 'i18next';
import { Trash2 } from 'react-feather';
import React from 'react';
import toLower from 'lodash/toLower';
import head from 'lodash/head';
import { Trans } from 'react-i18next';
import { Navigation } from '../../../../../navigation/useNavigation';
import { AddonMissingCurrencyAlertWithTooltip } from './AddonMissingCurrencyAlertWithTooltip';
import { AddonOrAddonGroupOption } from './AddCompatibleAddonOrAddonGroupDialog';
import {
  isAddon,
  isCompatibleAddonSingleInstance,
  isCompatiblePackageGroup,
  isPackageGroup,
} from '../../../../common/packageUtils';
import { getBillingCadenceIconTooltip } from '../../../../pricing/components/SetPriceWizard/form/billingCadenceStep/BillingCadenceStep.utils';

function getAddonPackageCell(
  entity: PlanCompatibleAddonFragment,
  navigation: Navigation,
  addonsWithMissingCurrency?: PlanCompatibleAddonFragment[],
) {
  const isAddonWithMissingCurrency = addonsWithMissingCurrency?.some(({ id }) => entity.id === id);
  const billingCadence = isAddon(entity) ? head(entity.prices)?.billingCadence : undefined;

  return (
    <GridFlex.RowSpaceBetween>
      {billingCadence && getBillingCadenceIconTooltip({ billingCadence, selected: false })}
      <GridFlex.Column ml={2} $fullWidth>
        <Link
          onClick={() => {
            navigation.navigateTo(navigation.appRoutes.addonPage(entity.refId));
          }}>
          <LongText>{entity.displayName}</LongText>
        </Link>
        <LongText>{entity.refId}</LongText>
      </GridFlex.Column>
      {isAddonWithMissingCurrency && (
        <AddonMissingCurrencyAlertWithTooltip navigation={navigation} planAddon={entity} />
      )}
    </GridFlex.RowSpaceBetween>
  );
}

function getPackageGroupPackageCell(entity: PlanCompatiblePackageGroupFragment, navigation: Navigation) {
  return (
    <GridFlex.RowCenter>
      <Icon type="custom" icon="AddonGroup" />
      <GridFlex.Column ml={2} $fullWidth>
        <Link
          onClick={() => {
            navigation.navigateTo(navigation.appRoutes.addonGroupPage(entity.packageGroupId));
          }}>
          <LongText>{entity.displayName}</LongText>
        </Link>
        <LongText>{entity.packageGroupId}</LongText>
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}

export const createCompatibleAddonsHeadCells = (
  setCompatibleEntityToRevoke: ({ id, isAddon }: { id: string; isAddon: boolean }) => void,
  setDeleteDialogOpen: any,
  navigation: Navigation,
  readonly: boolean,
  addonsWithMissingCurrency?: PlanCompatibleAddonFragment[],
): Array<HeadCell<AddonOrAddonGroupOption, any>> => [
  {
    id: 'id',
    alignment: 'left',
    label: t('plans.addonOrAddonGroupName'),
    render: (entity: AddonOrAddonGroupOption) => {
      return isAddon(entity)
        ? getAddonPackageCell(entity as PlanCompatibleAddonFragment, navigation, addonsWithMissingCurrency)
        : getPackageGroupPackageCell(entity as PlanCompatiblePackageGroupFragment, navigation);
    },
  },
  {
    id: 'type',
    alignment: 'left',
    label: t('plans.addonOrAddonGroupType'),
    render: (entity: AddonOrAddonGroupOption) => (
      <Text.B2 color="primary">
        {isPackageGroup(entity)
          ? t('packages.addonGroupType')
          : isCompatibleAddonSingleInstance(entity as PlanCompatibleAddonFragment)
            ? `${t('packages.singleAddonType')} ${toLower(t('packages.addon'))}`
            : `${t('packages.multiAddonType')} ${toLower(t('packages.addon'))}`}
      </Text.B2>
    ),
  },
  {
    id: 'addon-options',
    alignment: 'left',
    label: 'Options',
    render: (entity: AddonOrAddonGroupOption) => {
      const packageGroup = isCompatiblePackageGroup(entity) ? entity : undefined;

      if (!packageGroup) return null;

      const {
        options: { minItems, freeItems },
      } = packageGroup;

      return (
        <GridFlex.Column>
          {minItems && (
            <Text.B2>
              <Trans t={t} i18nKey="plans.compatiblePackageGroupMinItems" values={{ minItems }} />
            </Text.B2>
          )}
          {freeItems && (
            <Text.B2>
              <Trans t={t} i18nKey="plans.compatiblePackageGroupFreeItems" values={{ freeItems }} />
            </Text.B2>
          )}
        </GridFlex.Column>
      );
    },
  },
  {
    id: 'options',
    alignment: 'right',
    width: 56,
    maxWidth: 36 + 8,
    label: '',
    visible: !readonly,
    render: (entity: AddonOrAddonGroupOption) => {
      const onRevoke = () => {
        const isAddonValue = isAddon(entity);
        const entityId = isAddonValue ? entity.id : isPackageGroup(entity) ? entity.packageGroupId : '';
        setCompatibleEntityToRevoke({ id: entityId, isAddon: isAddonValue });
        setDeleteDialogOpen(true);
      };
      return (
        <OptionsDropdown
          options={[
            {
              icon: Trash2,
              text: t('plans.removeAddon'),
              onClick: onRevoke,
            },
          ]}
        />
      );
    },
  },
];
