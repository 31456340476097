import { Link, Text } from '@stigg-components';
import { t } from 'i18next';
import React, { ReactElement } from 'react';
import {
  AddonPriceOverrideChangeVariables,
  PlanPriceOverrideChangeVariables,
  PriceOverrideChangeVariables,
  SubscriptionDataFragment,
} from '@stigg-types/apiTypes';
import { Trans } from 'react-i18next';
import { useNavigation } from '../../../../../../../navigation/useNavigation';
import { ChangeType, SubscriptionFutureUpdateRowContainer } from '../SubscriptionFutureUpdateRowContainer';
import { getFeatureDisplayName } from '../../../../../../../features/utils/getFeatureDisplayName';

export type PriceOverrideUpdateRowProps = {
  changeVariables: PriceOverrideChangeVariables | PlanPriceOverrideChangeVariables | AddonPriceOverrideChangeVariables;
  subscription: SubscriptionDataFragment;
  alertVariant?: boolean;
};

function parseChangeVariables(
  changeVariables: PriceOverrideChangeVariables | PlanPriceOverrideChangeVariables | AddonPriceOverrideChangeVariables,
) {
  let planRefId: string | undefined | null;
  let addonRefId: string | undefined | null;

  if (changeVariables.__typename === 'PriceOverrideChangeVariables') {
    ({ planRefId, addonRefId } = changeVariables);
  } else if (changeVariables.__typename === 'PlanPriceOverrideChangeVariables') {
    planRefId = changeVariables.planRefId;
  } else if (changeVariables.__typename === 'AddonPriceOverrideChangeVariables') {
    addonRefId = changeVariables.addonRefId;
  }

  const { featureId } = changeVariables;
  return { planRefId, addonRefId, featureId };
}

export function PriceOverrideUpdateRow({ changeVariables, subscription, alertVariant }: PriceOverrideUpdateRowProps) {
  const navigation = useNavigation();
  const { planRefId, addonRefId, featureId } = parseChangeVariables(changeVariables);

  let content: ReactElement;

  if (addonRefId) {
    const addon = subscription.plan.compatibleAddons?.find(({ refId }) => refId === addonRefId);
    if (!addon) {
      return null;
    }

    content = (
      <Trans
        i18nKey="subscriptions.schedules.overrideAddonPrice"
        values={{ addonName: addon.displayName }}
        components={[
          <Link
            variant="body2"
            display="inline"
            onClick={() => navigation.navigateTo(navigation.appRoutes.addonPage(addon.refId))}
          />,
        ]}
      />
    );
  } else if (planRefId && featureId) {
    const subscriptionPrice = subscription.prices?.find((p) => p.featureId === featureId);
    const feature = subscriptionPrice?.price?.feature;
    if (!feature) {
      return null;
    }

    content = (
      <Trans
        i18nKey="subscriptions.schedules.overrideUsageBasedPrice"
        values={{ featureName: getFeatureDisplayName(feature) }}
        components={[
          <Link
            variant="body2"
            display="inline"
            onClick={() => navigation.navigateTo(navigation.appRoutes.featurePage(feature.refId))}
          />,
        ]}
      />
    );
  } else if (planRefId) {
    // @ts-ignore FIX-ME
    content = t('subscriptions.schedules.overrideBaseChargePrice');
  } else {
    return null;
  }

  return (
    <SubscriptionFutureUpdateRowContainer changeType={ChangeType.OVERRIDE_PRICE}>
      <Text.B2 color={alertVariant ? 'warning.content' : undefined}>{content}</Text.B2>
    </SubscriptionFutureUpdateRowContainer>
  );
}
