import { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  DialogActionsButtons,
  Divider,
  Flex,
  Text,
  CustomDrawer,
  GridFlex,
} from '@stigg-components';
import { Environment } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { styled } from '@stigg-theme';
import { AccountPermissionActions, useAccountPermissionsCheck, useEnvironmentsPermissions } from '@stigg-permissions';
import { EntityTile } from './EntityTile';
import { RootState, useAppDispatch } from '../../../../redux/store';
import {
  ENVIRONMENT_SELECT_WIDTH,
  EnvironmentOptionType,
  EnvironmentsAutocompleteSearch,
} from './EnvironmentsAutocompleteSearch';
import { CompareEnvironmentsDialog } from './CompareEnvironmentsDialog';
import { mergeEnvironmentAction, resetMergeEnvironmentValidation } from '../../accountsSlice';
import { useEnvironmentDiff } from './hooks/useEnvironmentDiff';
import { ChangesAlert } from './ChangesAlert';
import { useNewEnvironmentForm } from './hooks/useNewEnvironmentForm';
import { useMergeEnvironmentValidation } from './hooks/useMergeEnvironmentValidation';
import { DEFAULT_INPUT_HEIGHT } from '../../../../components/input.utils';

const AlertBodyContainer = styled(Flex.Column)`
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(4)};
  max-height: 200px;
  overflow-y: auto;
  padding-right: ${({ theme }) => theme.spacing(2)};
`;

interface MergeEnvironmentsDrawerProps {
  open: boolean;
  onClose: () => void;
  environment: Environment | null;
}

type UseEnvironmentOptionsProps = {
  environment: MergeEnvironmentsDrawerProps['environment'];
};

const useEnvironmentOptions = ({ environment }: UseEnvironmentOptionsProps) => {
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const environmentPermissions = useEnvironmentsPermissions(environments);

  return useMemo<EnvironmentOptionType[]>(() => {
    const options: EnvironmentOptionType[] = environments
      .filter((env) => env.id !== environment?.id)
      .map(({ id, displayName, slug, type }) => ({
        displayName,
        slug,
        type,
        missingWritePermission: !environmentPermissions[id].WriteEnvironment,
      }));

    return options;
  }, [environments, environment?.id, environmentPermissions]);
};

export const MergeEnvironmentsDrawer = ({ open, onClose, environment }: MergeEnvironmentsDrawerProps) => {
  const dispatch = useAppDispatch();
  const [allowCreateNonProductionEnvironment, allowCreateProductionEnvironment] = useAccountPermissionsCheck([
    AccountPermissionActions.CreateNonProductionEnvironment,
    AccountPermissionActions.CreateProductionEnvironment,
  ]);
  const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentOptionType | null>(null);
  const [isCompareDialogOpen, setIsCompareDialogOpen] = useState(false);
  const { mergeEnvironmentValidation } = useSelector((state: RootState) => state.accountReducer);
  const { changes, isLoadingDiff, diff } = useEnvironmentDiff(
    environment?.slug,
    selectedEnvironment?.slug,
    mergeEnvironmentValidation.isLoaded && mergeEnvironmentValidation.isValid,
  );
  const isNewEnvironment = !selectedEnvironment?.slug;
  const showNewEnvironmentOptions = !!selectedEnvironment && isNewEnvironment;

  const {
    formComponent,
    formRenderProps: { values: newEnvironmentValues, resetForm },
  } = useNewEnvironmentForm({
    environment: { displayName: selectedEnvironment?.displayName },
    allowCreateProductionEnvironment,
    allowCreateNonProductionEnvironment,
  });

  const envOptions = useEnvironmentOptions({ environment });

  useEffect(() => {
    setSelectedEnvironment(null);
    resetForm();
  }, [environment, resetForm]);

  useMergeEnvironmentValidation({ environment, selectedEnvironment });

  const closeDrawer = () => {
    dispatch(resetMergeEnvironmentValidation());
    onClose();
  };

  const onEnvironmentSelected = (environment: EnvironmentOptionType | null) => {
    setSelectedEnvironment(environment);
  };

  const onClickMainCta = async () => {
    if (!isNewEnvironment) {
      if (changes) {
        setIsCompareDialogOpen(true);
        return;
      }
      await dispatch(
        mergeEnvironmentAction({
          sourceEnvironmentSlug: environment!.slug,
          destinationEnvironmentSlug: selectedEnvironment.slug,
        }),
      );
    } else {
      await dispatch(
        mergeEnvironmentAction({
          sourceEnvironmentSlug: environment!.slug,
          destinationEnvironmentName: newEnvironmentValues.displayName,
          destinationEnvironmentType: newEnvironmentValues.type,
        }),
      );
    }

    closeDrawer();
  };

  const isSaveDisabled =
    !selectedEnvironment ||
    !mergeEnvironmentValidation.isValid ||
    (!isNewEnvironment && !changes) ||
    (showNewEnvironmentOptions && (!newEnvironmentValues.displayName || !newEnvironmentValues.type));

  return (
    <>
      <CustomDrawer
        isOpen={open}
        title={t('accounts.copyMergeEnvironments.sidebar.header')}
        footer={
          <DialogActionsButtons
            onCancel={closeDrawer}
            saveText={
              selectedEnvironment?.slug && changes && mergeEnvironmentValidation.isValid
                ? t('accounts.copyMergeEnvironments.sidebar.compareButtonText')
                : t('accounts.copyMergeEnvironments.sidebar.copyButtonText')
            }
            saveDisabled={isSaveDisabled}
            onSave={onClickMainCta}
            isSaveLoading={mergeEnvironmentValidation.isLoading || isLoadingDiff}
          />
        }
        onClose={closeDrawer}
        withStickyHeaderAndFooter>
        {environment && (
          <Flex.Column $fullHeight>
            <Text.B2 mt={2}>
              <Trans
                i18nKey="accounts.copyMergeEnvironments.sidebar.text"
                values={{ environmentName: environment.displayName }}
                components={{ strong: <Text.B2 display="inline" $bold /> }}
              />
            </Text.B2>
            <Text.B2 mt={3}>{t('accounts.copyMergeEnvironments.sidebar.entitiesCopiedHeader')}</Text.B2>
            <Flex.Row sx={{ mt: 2, gap: 2 }}>
              <EntityTile
                icon="Catalog"
                title={t('accounts.copyMergeEnvironments.sidebar.productCatalogTile')}
                description={t('accounts.copyMergeEnvironments.sidebar.productCatalogDescription')}
              />
              <EntityTile
                icon="Colorpalette"
                title={t('accounts.copyMergeEnvironments.sidebar.widgetDesignerTile')}
                description={t('accounts.copyMergeEnvironments.sidebar.widgetDesignerDescription')}
              />
            </Flex.Row>
            <Divider my={4} />
            <GridFlex.Column gap={4}>
              <Flex.RowCenter height={DEFAULT_INPUT_HEIGHT}>
                <Text.B2 sx={{ flexGrow: 1 }}>{t('accounts.copyMergeEnvironments.sidebar.sourceEnv')}</Text.B2>
                <Text.B2 sx={{ width: ENVIRONMENT_SELECT_WIDTH }}>{environment.displayName}</Text.B2>
              </Flex.RowCenter>
              <Flex.RowCenter>
                <Text.B2 sx={{ flexGrow: 1 }}>{t('accounts.copyMergeEnvironments.CTA')}</Text.B2>
                <EnvironmentsAutocompleteSearch
                  envOptions={envOptions}
                  sourceEnvironmentName={environment.displayName}
                  selectedEnvironment={selectedEnvironment}
                  setSelectedEnvironment={onEnvironmentSelected}
                  allowCreatingNewEnvironment={allowCreateNonProductionEnvironment || allowCreateProductionEnvironment}
                />
              </Flex.RowCenter>
            </GridFlex.Column>
            {showNewEnvironmentOptions ? (
              <>
                <Divider my={4} />
                <GridFlex.Column gap={4}>{formComponent}</GridFlex.Column>
              </>
            ) : null}
            {selectedEnvironment && !mergeEnvironmentValidation.isLoading && !mergeEnvironmentValidation.isValid && (
              <Alert severity="error" sx={{ mt: 3, mb: 4 }}>
                <AlertTitle>
                  <Text.B2 color="error.main">
                    <Trans
                      i18nKey="accounts.copyMergeEnvironments.sidebar.validationError"
                      values={{
                        sourceEnvironment: environment.displayName,
                        destinationEnvironment: selectedEnvironment.displayName,
                      }}
                      components={{ strong: <Text.B2 display="inline" color="error.main" $bold /> }}
                    />
                  </Text.B2>
                </AlertTitle>
                <AlertBodyContainer>
                  {mergeEnvironmentValidation.errors.map((err) => (
                    <Text.B2 key={err}>{err}</Text.B2>
                  ))}
                </AlertBodyContainer>
              </Alert>
            )}
            {selectedEnvironment &&
              mergeEnvironmentValidation.isLoaded &&
              mergeEnvironmentValidation.isValid &&
              !isLoadingDiff &&
              !!diff && <ChangesAlert changes={changes} sx={{ mt: 3, mb: 4 }} />}
          </Flex.Column>
        )}
      </CustomDrawer>
      {isCompareDialogOpen && selectedEnvironment?.slug && environment && (
        <CompareEnvironmentsDialog
          sourceEnv={environment}
          destEnv={selectedEnvironment as Required<EnvironmentOptionType>}
          onClose={() => {
            setIsCompareDialogOpen(false);
          }}
          onMergeSuccess={closeDrawer}
        />
      )}
    </>
  );
};
