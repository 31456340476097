import { Text, Grid, GridFlex, ClipboardChip, Box } from '@stigg-components';
import { t } from 'i18next';
import { useStiggTheme } from '@stigg-theme';
import { Optional } from '@stigg-types/primitives';
import { IntegrationFragment, ZuoraCredentials } from '@stigg-types/apiTypes';
import { AVAILABLE_ZUORA_TENANTS } from './constants';
import { truncateApiKey as truncate } from '../../../accounts/utils/truncate';

export type ZuoraIntegrationDetailsProps = { integration: IntegrationFragment };

export function ZuoraIntegrationDetails({ integration }: ZuoraIntegrationDetailsProps) {
  const credentials = integration.credentials as Optional<ZuoraCredentials>;
  const { theme } = useStiggTheme();

  if (!credentials) {
    return null;
  }

  const { baseUrl, clientId, clientSecret, stripePublishableKey, stripeSecretKey, webhookSecret, paymentPageId } =
    credentials;
  const zuoraTenant = AVAILABLE_ZUORA_TENANTS.find((tenant) => tenant.baseUrl === baseUrl);

  return (
    <>
      <Grid item container flexDirection="column">
        <GridFlex.Column item>
          <Text.Sub2 mb={2}>{t('integrations.zuoraTenant')}</Text.Sub2>
          <Text.B2>
            {zuoraTenant?.name} ({baseUrl})
          </Text.B2>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.clientId')}</Text.Sub2>
          <Text.B2>{clientId}</Text.B2>
        </GridFlex.Column>
        <GridFlex.Column item>
          <Text.Sub2 my={2}>{t('integrations.clientSecret')}</Text.Sub2>
          <Text.B2>{clientSecret && truncate(clientSecret)}</Text.B2>
        </GridFlex.Column>
        {webhookSecret && (
          <GridFlex.Column item>
            <Text.Sub2 my={2}>{t('integrations.webhookSecret')}</Text.Sub2>
            <Box width="fit-content">
              <ClipboardChip
                copy={webhookSecret}
                text={truncate(webhookSecret)}
                variant="code"
                iconLocation="right"
                backgroundColor={theme.itamar.palette.action.hover}
              />
            </Box>
          </GridFlex.Column>
        )}
        {paymentPageId && (
          <GridFlex.Column item>
            <Text.Sub2 my={2}>{t('integrations.paymentPageId')}</Text.Sub2>
            <Text.B2>{paymentPageId}</Text.B2>
          </GridFlex.Column>
        )}
        {stripePublishableKey && (
          <GridFlex.Column item>
            <Text.Sub2 my={2}>{t('integrations.stripePublishableKey')}</Text.Sub2>
            <Text.B2>{truncate(stripePublishableKey)}</Text.B2>
          </GridFlex.Column>
        )}
        {stripeSecretKey && (
          <GridFlex.Column item>
            <Text.Sub2 my={2}>{t('integrations.stripeSecretKey')}</Text.Sub2>
            <Text.B2>{truncate(stripeSecretKey)}</Text.B2>
          </GridFlex.Column>
        )}
      </Grid>
    </>
  );
}
