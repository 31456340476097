const config = {
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN || '',
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID || '',
    audience: import.meta.env.VITE_AUTH0_AUDIENCE || '',
    scope: 'openid profile',
  },
  version: import.meta.env.VITE_APP_VERSION,
  graphQLUrl: import.meta.env.VITE_GRAPHQL_URL,
  restUrl: import.meta.env.VITE_REST_URL,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  devMode: process.env.NODE_ENV !== 'production',
  envName: import.meta.env.VITE_ENV_NAME || 'development',
  stripeClientId: import.meta.env.VITE_STRIPE_CLIENT_ID || '',
  hubspotClientId: import.meta.env.VITE_HUBSPOT_CLIENT_ID || '',
  stripeTestClientId: import.meta.env.VITE_STRIPE_TEST_CLIENT_ID || '',
  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '',
  stripeTestPublishableKey: import.meta.env.VITE_STRIPE_TEST_PUBLISHABLE_KEY || '',
  fullstoryOrgId: import.meta.env.VITE_FULLSTORY_ORG_ID || '',
  cubejsApiUrl: import.meta.env.VITE_CUBEJS_API_URL || '',
  stiggdServiceBaseUrl: import.meta.env.VITE_STIGGD_SERVICE_BASE_URL || 'http://localhost:8001',
  demoBaseUrl: import.meta.env.VITE_DEMO_BASE_URL || '',
  sandboxStripeAccountId: import.meta.env.VITE_SANDBOX_STRIPE_ACCOUNT_ID || '',
  launchDarklyKey: import.meta.env.VITE_LAUNCHDARKLY_SDK_CLIENT || '',
  segmentKey: import.meta.env.VITE_SEGMENT_KEY || '',
  hotjarId: import.meta.env.VITE_HOTJAR_ID || '',
  hotjarSnippetVersion: import.meta.env.VITE_HOTJAR_SNIPPET_VERSION || '',
  intercomId: import.meta.env.VITE_INTERCOM_ID || '',
  stiggDoggoBaseUrl: import.meta.env.VITE_STIGG_DOGGO_BASE_URL,
  stiggDoggoApiKey: import.meta.env.VITE_STIGG_DOGGO_API_KEY || '',
  stiggDoggoEnableEdge: import.meta.env.VITE_STIGG_DOGGO_ENABLE_EDGE === 'true',
  commandBarOrgId: import.meta.env.VITE_COMMAND_BAR_ORG_ID,
  salesforceAppVersionId: import.meta.env.VITE_SALESFORCE_APP_VERSION_ID,
  koalaApiKey: import.meta.env.VITE_KOALA_API_KEY,
  workflowsBaseUrl: import.meta.env.VITE_WORKFLOWS_BASE_URL || '',
};

export default config;
