export enum VendorIdentifier {
  Zuora = 'ZUORA',
  Stripe = 'STRIPE',
  Auth0 = 'AUTH0',
  Salesforce = 'SALESFORCE',
  Hubspot = 'HUBSPOT',
  AwsMarketplace = 'AWS_MARKETPLACE',
  Snowflake = 'SNOWFLAKE',
  BigQuery = 'BIG_QUERY',
  OpenFga = 'OPEN_FGA',
}
