import { t } from 'i18next';
import { GridFlex, Icon, InformationalTooltipIcon } from '@stigg-components';
import { CustomerResponseFragment, Customer } from '@stigg-types/apiTypes';

type DelegatedSubscriptionIconProps = {
  customer: CustomerResponseFragment;
  payingCustomer?: Pick<Customer, 'id'> | null;
};

export function DelegatedSubscriptionIcon({ customer, payingCustomer }: DelegatedSubscriptionIconProps) {
  if (!payingCustomer) {
    return null;
  }

  const isManagedIcon = customer.id === payingCustomer.id;

  return (
    <GridFlex.Column mr={2}>
      <InformationalTooltipIcon
        $padding={3}
        $maxWidth={250}
        icon={<Icon icon={isManagedIcon ? 'ManagingCustomer' : 'PayingCustomer'} />}
        text={isManagedIcon ? t('customers.subscriptionManagedByTooltip') : t('customers.subscriptionPaidByTooltip')}
        placement="top"
      />
    </GridFlex.Column>
  );
}
