import { t } from 'i18next';
import { styled } from '@stigg-theme';
import { Text, GridFlex, Icon } from '@stigg-components';
import { PlanCard } from './PlanOffering';

const StyledPlanCard = styled(PlanCard)`
  transition: background-color 50ms;
  :hover {
    background-color: ${({ theme }) => theme.itamar.palette.background.default};
  }
`;

export function AddPlanCard({ onClick }: { onClick: () => void }) {
  return (
    <StyledPlanCard onClick={onClick}>
      <GridFlex.ColumnMiddle height="100%" justifyContent="center">
        <Icon type="reactFeather" color="default" icon="Plus" size={50} />
        <Text.B2 color="disabled">{t('products.addPlan')}</Text.B2>
      </GridFlex.ColumnMiddle>
    </StyledPlanCard>
  );
}
