import {
  Alert,
  Field,
  FormRenderProps,
  GridFlex,
  Link,
  MenuItem,
  OutlinedFormFieldLabel,
  Select,
  Text,
} from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { useState } from 'react';
import { t } from 'i18next';
import { updateCustomerAction } from '../../../../../../customersSlice';
import { UpdateCustomerFormFields } from '../../../../CustomerUpdateForm';
import { useAppDispatch } from '../../../../../../../../redux/store';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { CustomerUpdateDialog } from '../../../../CustomerUpdateDialog';
import { AdditionalInputLayout } from '../AdditionalInputLayout';
import { SEARCH_COMPONENT_WIDTH } from '../coupon/consts';

const INVOICE_STATUSES = ['OPEN', 'PAID'] as const;

export function InvoiceCollectionMethod({
  customer,
  form,
}: {
  form: FormRenderProps<SubscriptionFormFields>;
  customer: CustomerResponseFragment;
}) {
  const dispatch = useAppDispatch();
  const [isEditCustomerDialogOpen, setIsEditCustomerDialogOpen] = useState(false);
  const { setFieldValue, values } = form;
  const invoiceStatus = values.isInvoicePaid ? 'PAID' : 'OPEN';

  const onUpdate = (updatePayload: UpdateCustomerFormFields) => {
    void dispatch(updateCustomerAction(updatePayload));
    setIsEditCustomerDialogOpen(false);
  };

  const invoiceFormFields: Field<SubscriptionFormFields>[] = [
    {
      type: 'custom',
      render: () => (
        <Select
          width={200}
          menuMaxWidth={610}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          value={invoiceStatus}
          onChange={(e) => setFieldValue('isInvoicePaid', e.target.value === 'PAID')}
          renderValue={(value: any) => (
            <Text.B2 color="secondary">{t(`subscriptionForm.invoice.status.${value}.name`)}</Text.B2>
          )}>
          {INVOICE_STATUSES.map((value) => (
            <MenuItem key={value} value={value}>
              <GridFlex.Column>
                <Text.B2 color="primary">{t(`subscriptionForm.invoice.status.${value}.name`)}</Text.B2>
                <Text.Caption color="secondary" sx={{ textWrap: 'wrap' }}>
                  {t(`subscriptionForm.invoice.status.${value}.description`)}
                </Text.Caption>
              </GridFlex.Column>
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      type: 'layout',
      layout: 'row',

      fields: [
        {
          type: 'custom',
          render: () => (
            <GridFlex.RowMiddle $fullWidth $fullHeight width="fit-content">
              <OutlinedFormFieldLabel
                label={t('subscriptionForm.invoice.daysUntilDue.label')}
                helpTooltipText={t('subscriptionForm.invoice.daysUntilDue.tooltip')}
                helpTooltipMaxWidth={300}
              />
            </GridFlex.RowMiddle>
          ),
        },
        {
          id: 'invoiceDaysUntilDue',
          type: 'text',
          gridProps: {
            minWidth: '150px',
          },
          placeholder: t('subscriptionForm.invoice.daysUntilDue.placeholder'),
          isNumberWithoutFraction: true,
          isNumberWithoutSigns: true,
          endAdornment: <Text.B2>{t('subscriptionForm.invoice.daysUntilDue.days')}</Text.B2>,
        },
      ],
    },
  ];

  const setEmailField: Field<SubscriptionFormFields> = {
    type: 'custom',
    render: () => (
      <>
        <Alert severity="error" icon={false} p={2} py={0} sx={{ width: SEARCH_COMPONENT_WIDTH }}>
          <GridFlex.RowMiddle gap={2}>
            <Text.B2 color="error.main" ml={2}>
              {t('subscriptionForm.invoice.addEmailRequired')}
            </Text.B2>
            <Link onClick={() => setIsEditCustomerDialogOpen(true)}>{t('subscriptionForm.invoice.addEmail')}</Link>
          </GridFlex.RowMiddle>
        </Alert>
        <CustomerUpdateDialog
          customer={customer}
          onUpdate={onUpdate}
          open={isEditCustomerDialogOpen}
          setOpen={setIsEditCustomerDialogOpen}
          editableFields={['email']}
        />
      </>
    ),
  };

  return customer.email ? (
    <AdditionalInputLayout
      isSet
      setIcon={{ icon: 'Invoice', type: 'custom', color: 'default' }}
      setLabel={t('subscriptionForm.invoice.invoiceStatus')}
      fields={invoiceFormFields}
      formRenderProps={form}
    />
  ) : (
    <AdditionalInputLayout isSet setLabel={''} fields={[setEmailField]} formRenderProps={form} />
  );
}
