import { t } from 'i18next';
import moment from 'moment/moment';
import { capitalize } from 'lodash';
import { EventLog } from '@stigg-types/apiTypes';
import { DateText, HeadCell, LongText } from '@stigg-components';
import { getEventName } from '../utils/eventName.utils';
import { ActorInformation } from './ActorInformation';
import { DEFAULT_COLUMNS, EventLogColumns } from './EventLog';
import EventEntity from './common/EventEntity';
import { getEntityDetails } from '../utils/entity.utils';

export const headCells: (columns?: EventLogColumns[]) => Array<HeadCell<EventLog, any>> = (
  columns: EventLogColumns[] = DEFAULT_COLUMNS,
) => {
  const headCells: Array<HeadCell<EventLog, any>> = [];

  if (columns.includes(EventLogColumns.Timestamp)) {
    headCells.push({
      id: 'timestamp',
      alignment: 'left',
      label: t('eventsLog.tableColumns.timestamp'),
      width: 210,
      render: (event) => (
        <DateText color="secondary" date={moment.utc(event.createdAt).toDate()} withTime formatType="longDate" />
      ),
    });
  }

  if (columns.includes(EventLogColumns.EventName)) {
    headCells.push({
      id: 'eventLogType',
      alignment: 'left',
      label: t('eventsLog.tableColumns.eventName'),
      width: 240,
      render: (event) => (
        <LongText variant="body2" color="primary" wordBreak>
          {getEventName(event.eventLogType)}
        </LongText>
      ),
    });
  }

  if (columns.includes(EventLogColumns.Entity)) {
    headCells.push({
      id: 'entity',
      alignment: 'left',
      label: t('eventsLog.tableColumns.entity'),
      render: (event: EventLog) => {
        const { primary } = getEntityDetails(event) || {};
        if (!primary) {
          return <LongText variant="body2">{event.entityType ? capitalize(event.entityType) : null}</LongText>;
        }

        return <EventEntity event={event} ignoreRoute />;
      },
    });
  }

  if (columns.includes(EventLogColumns.Actor)) {
    headCells.push({
      id: 'actor',
      alignment: 'left',
      label: t('eventsLog.tableColumns.actor'),
      width: 300,
      render: (event) => <ActorInformation actor={event.actor} />,
    });
  }

  return headCells;
};
