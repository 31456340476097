import { useState } from 'react';
import { TwitterPicker } from 'react-color';
import { Box, Button, Field, GridFlex, Icon, OutlinedFormFieldLabel, Popper, Text } from '@stigg-components';
import styled from 'styled-components/macro';
import { t } from 'i18next';
import { Environment, EnvironmentType } from '@stigg-types/apiTypes';
import { useStiggTheme } from '@stigg-theme';
import { AccountPermissionActions, useAccountPermissionsCheck } from '@stigg-permissions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { ENVIRONMENT_DEFAULT_COLOR } from './EnvironmentSwitch';
import { DEFAULT_INPUT_HEIGHT } from '../../../../components/input.utils';

const ENVIRONMENTS_COLORS = ['#4CAF50', '#FFB547', '#F88078', '#327EEE', '#784CAF', '#47DEFF', '#FFA37C', '#EE32D0'];

const ColorBox = styled(Box)<{ $color: string }>`
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  background: ${(props) => props.$color};
  transition: background 0.2s ease-in-out;
  border-radius: 2px;
`;

function findEnvironmentColor(environments: Environment[]) {
  const currentEnvironmentColors = new Set(environments.map((env) => env.color?.toLowerCase()));
  return ENVIRONMENTS_COLORS.find((color) => !currentEnvironmentColors.has(color.toLowerCase()));
}

export type EnvironmentFormFields = {
  color: string;
  displayName: string;
  description: string;
  type: EnvironmentType;
  copyFromExisting?: boolean;
  copyFromSlug?: string;
};

export function useEnvironmentFields<T>({
  environment,
  withColor = true,
  withDescription = true,
  isEdit,
}: {
  environment?: Partial<Environment>;
  withColor?: boolean;
  withDescription?: boolean;
  isEdit?: boolean;
}): Field<T>[] {
  const { theme } = useStiggTheme();
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [color, setColor] = useState(
    (environment ? environment?.color : findEnvironmentColor(environments)) || ENVIRONMENT_DEFAULT_COLOR,
  );
  const [allowCreateProductionEnvironment, allowCreateNonProductionEnvironment] = useAccountPermissionsCheck([
    AccountPermissionActions.CreateProductionEnvironment,
    AccountPermissionActions.CreateNonProductionEnvironment,
  ]);

  const onChange = (color: string) => {
    setColor(color);
  };

  const onClickAway = () => {
    setIsColorPickerVisible(false);
    setAnchorEl(null);
  };

  const onClickColorBox = (event: any) => {
    if (!isColorPickerVisible) {
      setIsColorPickerVisible(true);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const fullAccessRequiredText = ` (${t('permissions.fullAccessRequired')})`;

  return [
    {
      type: 'layout',
      contentGridProps: { alignItems: 'baseline' },
      fields: [
        {
          type: 'custom',
          render: () => {
            return <OutlinedFormFieldLabel label={t('accounts.environmentNameLabel')} />;
          },
        },
        {
          type: 'custom',
          gridProps: {
            flex: 2,
          },
          render: ({ renderTextField, setFieldValue }) => {
            return (
              <GridFlex.Row $fullWidth gap={2} alignItems="center">
                {renderTextField('displayName', undefined, {
                  placeholder: environment?.displayName || t('account.environmentNamePlaceholder'),
                  hideErrorText: true,
                })}
                {withColor ? (
                  <GridFlex.Row alignItems="center">
                    <Button
                      variant="outlined"
                      endIcon={<Icon type="materialIcons" icon="ArrowDropDown" color="active" />}
                      sx={{ borderColor: '#C4CBDD', p: 2, height: DEFAULT_INPUT_HEIGHT }}
                      onClick={onClickColorBox}>
                      <ColorBox $color={color} />
                    </Button>
                    {isColorPickerVisible ? (
                      <>
                        <Box
                          sx={{ position: 'fixed', zIndex: 2, top: 0, right: 0, bottom: 0, left: 0 }}
                          onClick={onClickAway}
                        />
                        <Popper
                          open={isColorPickerVisible}
                          disablePortal
                          sx={{ zIndex: 2 }}
                          anchorEl={anchorEl}
                          placement="bottom-start"
                          modifiers={[{ name: 'offset', options: { offset: [0, 6] } }]}>
                          <TwitterPicker
                            colors={ENVIRONMENTS_COLORS}
                            color={color}
                            onChange={(color) => {
                              onChange(color.hex);
                              setFieldValue('color', color.hex);
                            }}
                          />
                        </Popper>
                      </>
                    ) : null}
                  </GridFlex.Row>
                ) : null}
              </GridFlex.Row>
            );
          },
        },
      ],
    },
    {
      type: 'optional',
      label: t('common.description.add'),
      testId: 'add-description-button',
      hide: () => !withDescription,
      fields: [
        {
          type: 'layout',
          gridProps: { $fullWidth: true },
          contentGridProps: { alignItems: 'center' },
          fields: [
            {
              type: 'custom',
              render: () => {
                return <OutlinedFormFieldLabel label={t('sharedComponents.description')} />;
              },
            },
            {
              gridProps: {
                flex: 2,
              },
              type: 'text',
              id: 'description',
              placeholder: environment?.description || '',
            },
          ],
        },
      ],
    },

    {
      type: 'layout',
      contentGridProps: { alignItems: 'center' },
      fields: [
        {
          type: 'custom',
          render: () => {
            return <OutlinedFormFieldLabel label={t('accounts.environmentTypeLabel')} />;
          },
        },
        {
          type: 'select',
          id: 'type',
          gridProps: {
            flex: 2,
          },
          disabled: isEdit,
          values: [
            {
              value: EnvironmentType.Development,
              disabled: !allowCreateNonProductionEnvironment,
              overrideDisplayValue: (
                <GridFlex.RowCenter>
                  <Icon
                    icon="DevEnvironmentIcon"
                    type="custom"
                    color={theme.isLightTheme ? 'default' : 'primary.main'}
                    overrideStroke
                  />
                  <GridFlex.Column ml={2}>
                    <Text.B2>{t('accounts.devEnvironmentLabel')}</Text.B2>
                  </GridFlex.Column>
                </GridFlex.RowCenter>
              ),
              displayValue: (
                <GridFlex.RowCenter columnGap={3}>
                  <Icon
                    size={24}
                    icon="DevEnvironmentIcon"
                    type="custom"
                    color={theme.isLightTheme ? 'default' : 'primary.main'}
                    overrideStroke
                  />
                  <GridFlex.Column>
                    <Text.B2>
                      {t('accounts.devEnvironmentLabel')}{' '}
                      {!allowCreateNonProductionEnvironment && fullAccessRequiredText}
                    </Text.B2>
                  </GridFlex.Column>
                </GridFlex.RowCenter>
              ),
            },
            {
              value: EnvironmentType.Production,
              disabled: !allowCreateProductionEnvironment,
              overrideDisplayValue: (
                <GridFlex.RowCenter>
                  <Icon
                    icon="ProductionEnvironmentIcon"
                    type="custom"
                    color={theme.isLightTheme ? 'default' : 'primary.main'}
                    overrideStroke
                  />
                  <GridFlex.Column ml={2}>
                    <Text.B2>{t('accounts.prodEnvironmentLabel')}</Text.B2>
                  </GridFlex.Column>
                </GridFlex.RowCenter>
              ),
              displayValue: (
                <GridFlex.RowCenter columnGap={3}>
                  <Icon
                    size={24}
                    icon="ProductionEnvironmentIcon"
                    type="custom"
                    color={theme.isLightTheme ? 'default' : 'primary.main'}
                    overrideStroke
                  />
                  <GridFlex.Column>
                    <Text.B2>
                      {t('accounts.prodEnvironmentLabel')}
                      {!allowCreateProductionEnvironment && fullAccessRequiredText}
                    </Text.B2>
                  </GridFlex.Column>
                </GridFlex.RowCenter>
              ),
            },
          ],
        },
      ],
    },
  ];
}
