import { t } from 'i18next';
import { ExperimentFragment, ExperimentStatus } from '@stigg-types/apiTypes';
import moment from 'moment';
import { DatePeriod, LocalDate, Text } from '@stigg-components';
import PeriodIcon from '../../../../../../assets/icons/experiments/Period.svg?react';
import { DetailsRow } from './DetailsRow';

function calculateExperimentPeriodInDays(days: number) {
  if (days === 0) {
    return t('experiments.lessThanDay');
  }
  if (days === 1) {
    return t('experiments.singleDay');
  }
  return t('experiments.numberOfDays', { days });
}

function CompletedExperimentPeriod({ experiment }: { experiment: ExperimentFragment }) {
  const totalDays = moment(experiment.stoppedAt).diff(moment(experiment.startedAt), 'days');

  return (
    <DetailsRow iconComponent={<PeriodIcon width={50} />}>
      <Text.B2 color="secondary">
        {t('experiments.experimentPeriod')}:{' '}
        <Text.B2 color="primary" display="inline">
          {calculateExperimentPeriodInDays(totalDays)}
        </Text.B2>
      </Text.B2>
      <Text.B2 color="primary">
        <DatePeriod start={experiment.startedAt} end={experiment.stoppedAt} />
      </Text.B2>
    </DetailsRow>
  );
}

function InProgressExperimentPeriod({ experiment }: { experiment: ExperimentFragment }) {
  return (
    <DetailsRow iconComponent={<PeriodIcon width={50} />}>
      <Text.B2 color="secondary">{t('experiments.started')}</Text.B2>
      <Text.B2 color="primary">
        <LocalDate date={experiment.startedAt} showTimeSince formatOptions={{ withTime: true }} />
      </Text.B2>
    </DetailsRow>
  );
}

export function PeriodDetailsRow({ experiment }: { experiment: ExperimentFragment }) {
  switch (experiment.status) {
    case ExperimentStatus.Completed:
      return <CompletedExperimentPeriod experiment={experiment} />;
    case ExperimentStatus.InProgress:
      return <InProgressExperimentPeriod experiment={experiment} />;
    default:
      return null;
  }
}
