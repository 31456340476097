import { useEffect } from 'react';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setIsDrawerExpanded, setIsMobileDrawerOpen } from '../../modules/application/applicationSlice';
import { RootState, useAppDispatch } from '../../redux/store';

export const useAppDrawer = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDrawerExpandedState = useSelector((state: RootState) => state.application.isDrawerExpanded);
  const drawerExpanded = isDrawerExpandedState === 'true' || isNil(isDrawerExpandedState);
  const isRightDrawerOpen = useSelector((state: RootState) => state.application.isRightDrawerOpen);
  const rightDrawerWidth = useSelector((state: RootState) => state.application.rightDrawerWidth);
  const mobileDrawerOpen = useSelector((state: RootState) => state.application.isMobileDrawerOpen);
  const isTopBannerOpen = useSelector((state: RootState) => state.application.isTopBannerOpen);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isDrawerExpanded = isMdUp ? drawerExpanded : mobileDrawerOpen;

  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const isEnvironmentsLoaded = useSelector((state: RootState) => state.accountReducer.isEnvironmentsLoaded);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const currentEnvironment = environments.find((environment) => environment.id === currentEnvironmentId);
  const isSandboxBannerOpen = !!currentEnvironment?.isSandbox;
  const isNoAccessState = isEnvironmentsLoaded && environments.length === 0;

  const onDrawerToggle = () => {
    if (isMdUp) {
      dispatch(setIsDrawerExpanded(!isDrawerExpanded));
    } else {
      dispatch(setIsMobileDrawerOpen(!mobileDrawerOpen));
    }
  };

  useEffect(() => {
    if (isNoAccessState) {
      dispatch(setIsDrawerExpanded(false));
    }
  }, [dispatch, isNoAccessState]);

  return {
    isNoAccessState,
    isDrawerExpanded,
    isRightDrawerOpen,
    rightDrawerWidth,
    isTopBannerOpen,
    onDrawerToggle,
    isSandboxBannerOpen,
  };
};
