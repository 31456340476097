import { MenuItem, Select, Button, LoadingButton, Grid, TextField, GridFlex, Text, Icon } from '@stigg-components';
import { Formik } from 'formik';
import { t } from 'i18next';
import { Save } from 'react-feather';
import map from 'lodash/map';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { WidgetType, Product, ProductListItemFragment } from '@stigg-types/apiTypes';
import { refIdValidation, AwsChip } from '@stigg-common';
import { DisplayNameTextField } from '../../../../components/DisplayNameTextField';
import Loader from '../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchPlansAction } from '../../plans/plansSlice';
import { fetchProductsAction } from '../../../products/productsSlice';
import { usePaywallVisibilityControlFeature } from './usePaywallVisibilityControlFeature';
import { AddonType, AddonTypeValue } from '../../addons/components/AddonTypeValue';
import { PackagePaywallCustomerVisibilityFormField } from './hiddenFromWidgets/PackagePaywallCustomerVisibilityFormField';

export type CreatePackageFormFields = {
  displayName: string;
  refId: string;
  description: string;
  productId: string;
  hiddenFromWidgets: WidgetType[];
  addonType?: AddonType;
};

const validationSchema = () =>
  Yup.object().shape({
    displayName: Yup.string().required(t('products.yup.required')),
    refId: refIdValidation(),
    productId: Yup.string().required(t('products.yup.required')),
    description: Yup.string().nullable(true),
  });

export function CreatePackageForm({
  selectedProduct,
  onSubmit,
  onCancel,
  refIdPrefix,
  packageType,
  simplifiedForm,
}: {
  selectedProduct?: ProductListItemFragment;
  onSubmit: (values: CreatePackageFormFields) => void;
  onCancel: () => void;
  refIdPrefix: string;
  packageType: 'Plan' | 'Add-on';
  simplifiedForm?: boolean;
}) {
  const isLoadingProducts = useSelector((state: RootState) => state.productReducer.isLoading);
  // first created product is the default one. fetchProductsAction is ordered by creation date.
  const products = useSelector((state: RootState) => state.productReducer.products);
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  const showPaywallVisibilityControl = usePaywallVisibilityControlFeature(packageType);

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(fetchProductsAction({ environmentId: currentEnvironmentId, silentFetch: true }));

      if (packageType === 'Plan') {
        void dispatch(fetchPlansAction({ environmentId: currentEnvironmentId, paging: { first: 100 } }));
      }
    }
  }, [dispatch, currentEnvironmentId, packageType]);

  // check if product was prefilled in qs
  const searchQs = useLocation().search;
  const qsProductId = new URLSearchParams(searchQs).get('productId');
  if (isLoadingProducts) {
    return <Loader />;
  }

  const handleSubmit = (values: CreatePackageFormFields) => {
    onSubmit(values);
  };

  // we want to prefill product only if we have only one product
  const prefilledProductId = selectedProduct?.id ?? (products?.length === 1 ? products[0].id : '');
  const initialValues = {
    displayName: '',
    refId: '',
    description: '',
    productId: qsProductId || prefilledProductId,
    hiddenFromWidgets: [],
    addonType: packageType === 'Add-on' ? AddonType.MULTI : undefined,
  } as CreatePackageFormFields;

  return (
    <Formik validationSchema={validationSchema()} initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        errors,
        isValid,
        isSubmitting,
        dirty,
        values,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => {
        const isPaywallHidden = (values?.hiddenFromWidgets || []).some((value) => value === WidgetType.Paywall);

        return (
          <form onSubmit={handleSubmit}>
            <GridFlex.Column container rowSpacing={2}>
              {!simplifiedForm && (
                <GridFlex.Column pt={2}>
                  <Select
                    fullWidth
                    value={values.productId}
                    name="productId"
                    labelId="package-product"
                    placeholder={t('packages.selectProductPlaceholder')}
                    renderValue={(value) => (
                      <GridFlex.RowCenter>
                        <Icon icon="Grid" color="active" />
                        <Text.B2 ml={2}>{products.find((v: any) => v.id === value)?.displayName}</Text.B2>
                      </GridFlex.RowCenter>
                    )}
                    label={t('packages.chooseProduct')}
                    onChange={handleChange}>
                    {map(products, (product: Product) => (
                      <MenuItem
                        key={product.id}
                        value={product.id}
                        disabled={packageType === 'Add-on' && !!product.awsMarketplaceProductId}>
                        <Grid gap={2}>
                          <Text.B2 color="primary">{product.displayName}</Text.B2>
                          {product.awsMarketplaceProductId ? <AwsChip logoOnly /> : null}
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </GridFlex.Column>
              )}
              <Grid item>
                <DisplayNameTextField
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                  handleBlur={handleBlur}
                  label={t('packages.name')}
                  productName={products.find((x) => x.id === values.productId)?.displayName || ''}
                  prefix={refIdPrefix}
                  dataTestId="package-name"
                />
              </Grid>
              <GridFlex.Column item>
                <TextField
                  name="refId"
                  label={t('packages.packageId')}
                  value={values.refId}
                  touched={!!touched.refId}
                  error={!!errors.refId}
                  fullWidth
                  errorText={errors.refId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  captionText={t('packages.packageIdText', { packageType: packageType === 'Plan' ? 'plan' : 'add-on' })}
                />
              </GridFlex.Column>
              <Grid item>
                <TextField
                  maxRows={4}
                  name="description"
                  label={t('packages.descriptionForm')}
                  value={values.description}
                  touched={!!touched.description}
                  error={!!errors.description}
                  fullWidth
                  errorText={errors.description}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  optional
                />
              </Grid>
              {packageType === 'Add-on' && (
                <GridFlex.Column item>
                  <Select
                    fullWidth
                    value={values.addonType}
                    name="addonType"
                    labelId="addon-type"
                    renderValue={(value) => <AddonTypeValue value={value} />}
                    label={t('packages.addonTypeLabel')}
                    onChange={handleChange}>
                    {map(Object.values(AddonType), (maxQuantity: AddonType) => (
                      <MenuItem key={maxQuantity} value={maxQuantity}>
                        <AddonTypeValue value={maxQuantity} withExample />
                      </MenuItem>
                    ))}
                  </Select>
                </GridFlex.Column>
              )}
              {showPaywallVisibilityControl && (
                <PackagePaywallCustomerVisibilityFormField
                  isPaywallHidden={isPaywallHidden}
                  onToggle={(hiddenFromWidgets) => setFieldValue('hiddenFromWidgets', hiddenFromWidgets)}
                />
              )}
              <Grid mt={4} justifyContent="flex-end" alignItems="center">
                <Button onClick={onCancel} disabled={isSubmitting} sx={{ mr: 3 }} $outlined color="primary">
                  {t('packages.cancelButton')}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  data-testid="submit-create-package"
                  loadingPosition="start"
                  loading={isSubmitting}
                  startIcon={isSubmitting ? <Save /> : null}
                  disabled={!dirty || !isValid}>
                  {t('packages.saveButton')}
                </LoadingButton>
              </Grid>
            </GridFlex.Column>
          </form>
        );
      }}
    </Formik>
  );
}
