import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useActiveSubscriptions, useStiggContext } from '@stigg/react-sdk';
import { AccountAccessRole, EnvironmentAccessRole } from '@stigg-types/apiTypes';
import { filter, includes, isEmpty } from 'lodash';
import * as Yup from 'yup';
import { Form, CustomDrawer, Button, Chip, GridFlex, Text, Link } from '@stigg-components';
import { FormikHelpers } from 'formik';
import { t } from 'i18next';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { inviteNewMembersAction } from '../../accountsSlice';
import { useMeteredEntitlement } from '../../../../doggo/hooks/useMeteredEntitlement';
import { StiggFeature } from '../../../../doggo/StiggFeature';
import { MembersLimitNotification } from './MembersLimitNotification';
import { disableInvites } from './MembersLimitNotification.utils';
import { setRolesFields } from '../setRolesFields';
import { useBooleanEntitlement } from '../../../../doggo/hooks/useBooleanEntitlement';
import { TooltipFields } from '../../../../components/InformationTooltip';
import { useNavigation } from '../../../navigation/useNavigation';
import { SettingsPageTabs } from '../../types/settingsPageTabs';

interface InviteMembersDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string().min(1).email(),
  });
type InviteMembersFormFields = {
  email: string;
  accountRole?: AccountAccessRole;
  productionAccess?: EnvironmentAccessRole;
  nonProductionAccess?: EnvironmentAccessRole;
};

const initialValues = {
  email: '',
} as InviteMembersFormFields;

export function InviteMembersDrawer({ onCancel, isOpen, onSubmit }: InviteMembersDialogProps) {
  const { rbacRollout } = useFlags<FeatureFlags>();
  const navigation = useNavigation();
  const { hasAccess: hasRbacEntitlement } = useBooleanEntitlement(StiggFeature.RBAC);
  const dispatch = useAppDispatch();
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const [invites, setInvites] = useState<string[]>([]);
  const entitlement = useMeteredEntitlement({ feature: StiggFeature.SEATS, requestedUsage: invites.length });

  const { activeSubscriptions } = useActiveSubscriptions();
  const stigg = useStiggContext();

  const addInvite = (invite: string) => {
    if (!includes(invites, invite)) {
      if (validationSchema().isValidSync({ email: invite })) {
        setInvites([...invites, invite]);
      }
    }
  };

  const sendInvites = async (
    values: InviteMembersFormFields,
    formikHelpers: FormikHelpers<InviteMembersFormFields>,
  ) => {
    const { accountRole, productionAccess, nonProductionAccess } = values;
    await dispatch(
      inviteNewMembersAction({
        invites,
        accountRole,
        nonProductionRole: nonProductionAccess,
        productionRole: productionAccess,
      }),
    );
    onSubmit();
    setInvites([]);
    formikHelpers.resetForm();
    await stigg?.refreshData();
  };

  function handleChipDelete(deletedInvite: string) {
    setInvites(filter(invites, (invite) => invite !== deletedInvite));
  }

  const handleKeyDown = (event: any, setFieldValue: FormikHelpers<InviteMembersFormFields>['setFieldValue']) => {
    const { value } = event.target;
    if (event.key === t('accounts.enter') && value) {
      event.preventDefault();
      addInvite(value);
      setFieldValue('email', '');
    }
  };
  const settingsPageUrl = navigation.appRoutes.settingsPage({ selectedTab: SettingsPageTabs.Billing });
  const disableTooltip: TooltipFields | undefined = {
    placement: 'top',
    title: (
      <Text.B2 color="primary">
        <Trans
          i18nKey="accounts.rbacUpgradePlan"
          components={[<Link onClick={() => navigation.navigateTo(settingsPageUrl, { isGlobal: true })} />]}
        />
      </Text.B2>
    ),
  };

  const showRolesSelection = rbacRollout && !isEmpty(invites);

  const content = (
    <Form
      withStickyFooter
      withFooterSpacing
      onCancel={onCancel}
      initialValues={initialValues}
      submitButtonText={t('accounts.invite')}
      validationSchema={validationSchema()}
      onSubmit={sendInvites}
      overrideIsValid={(values) =>
        !isEmpty(invites) &&
        (!rbacRollout || (!!values.accountRole && !!values.productionAccess && !!values.nonProductionAccess))
      }
      fields={({ values, errors, setFieldValue }) => [
        {
          type: 'custom',
          render: () => (
            <MembersLimitNotification entitlement={entitlement} activeSubscriptions={activeSubscriptions} />
          ),
        },
        {
          type: 'text',
          autoFocus: true,
          id: 'email',
          textFieldType: 'email',
          label: t('accounts.email'),
          onKeyDown: (e: any) => {
            handleKeyDown(e, setFieldValue);
          },
          captionText: t('accounts.enterPrompt'),
          disabled: disableInvites(entitlement, activeSubscriptions, invites),
          endAdornment: !!values.email && !errors.email && (
            <Button
              sx={{ borderRadius: '6px' }}
              variant="contained"
              onClick={() => {
                addInvite(values.email);
                setFieldValue('email', '');
              }}>
              <Text.B2 color="white">{t('accounts.add')}</Text.B2>
            </Button>
          ),
          inputProps: { style: { paddingRight: 0 } },
          textFieldProps: {
            maxRows: 4,
          },
        },
        {
          type: 'custom',
          render: () => (
            <GridFlex.Row rowGap={2} columnGap={2} flexWrap="wrap" my={4}>
              {invites.map((invite) => (
                <Chip color="primary" label={invite} key={invite} onDelete={() => handleChipDelete(invite)} />
              ))}
            </GridFlex.Row>
          ),
        },
        ...setRolesFields<InviteMembersFormFields>({
          hide: !showRolesSelection,
          accountRoleDisabled: !hasRbacEntitlement,
          environmentRoleDisabled: !hasRbacEntitlement,
          accountTooltip: !hasRbacEntitlement ? disableTooltip : undefined,
          environmentTooltip: !hasRbacEntitlement ? disableTooltip : undefined,
        }),
      ]}
    />
  );

  return (
    <CustomDrawer
      title={t('accounts.inviteWithEmail', { accountName: account?.displayName })}
      onClose={onCancel}
      isOpen={isOpen}
      variant="persistent"
      BackdropProps={{ invisible: true }}>
      {content}
    </CustomDrawer>
  );
}
