import { WizardAccordionStep } from '@stigg-components';
import { BillingCadence, PricingType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import capitalize from 'lodash/capitalize';
import { ReactElement, ReactNode } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { PriceTypeStep } from './priceTypeStep/PriceTypeStep';
import { PriceTypeStepSummary } from './priceTypeStep/PriceTypeStepSummary';
import { FreeTrialStep } from './freeTrialStep/FreeTrialStep';
import { FreeTrialStepSummary } from './freeTrialStep/FreeTrialStepSummary';
import { BillingPeriodStepSummary } from './billingPeriodStep/BillingPeriodStepSummary';
import { BillingPeriodStep } from './billingPeriodStep/BillingPeriodStep';
import { ChargesStep } from './chargesStep/ChargesStep';
import { ChargesStepSummary } from './chargesStep/ChargesStepSummary';
import { getPackageTypeDisplay } from '../../../../common/getPackageTypeDisplay';
import { PriceLocalizationStep } from './priceLocalization/PriceLocalizationStep';
import { PriceLocalizationStepSummary } from './priceLocalization/PriceLocalizationStepSummary';
import { useSetPriceWizardFormContext } from './SetPriceWizardForm.context';
import { getInitialFocusedChargeUUID } from './utils/getInitialFocusedChargeUUID';
import { getInitialFocusedCountryUUID } from './utils/getInitialFocusedCountryUUID';
import { useSubStepsState } from './utils/useSubStepsState';
import { OverageChargesStep } from './chargesStep/OverageChargesStep';
import { OverageChargesStepSummary } from './chargesStep/OverageChargesStepSummary';
import { BillingCadenceStep } from './billingCadenceStep/BillingCadenceStep';
import { BillingCadenceStepSummary } from './billingCadenceStep/BillingCadenceStepSummary';
import { MinimumSpendStep } from './minimumSpendStep/minimumSpendStep';
import { MinimumSpendSummary } from './minimumSpendStep/minimumSpendSummary';

export type StepIdentifier =
  | 'priceType'
  | 'billingPeriod'
  | 'billingCadence'
  | 'charges'
  | 'overageCharges'
  | 'minimumSpend'
  | 'freeTrial'
  | 'priceLocalization';

export type SectionIdentifier = {
  stepId: StepIdentifier;
  priceGroupId?: string;
  billingCountryCode?: string;
};

export function useSetPricingWizardSteps(): {
  steps: WizardAccordionStep<StepIdentifier>[];
  additionalSteps: WizardAccordionStep<StepIdentifier>[];
} {
  const {
    overagePricing: isOveragePricingEnabled,
    minimumSpend: isMinimumSpendEnabled,
    oneOffAddOnRollout: isOneOffAddonsEnabled,
  } = useFlags<FeatureFlags>();

  const { aPackage, formRenderProps, initialFocusedSection } = useSetPriceWizardFormContext();
  const { values, errors } = formRenderProps;
  const { pricingType, billingCadence, charges, overageCharges, priceLocalization } = values;

  const chargesState = useSubStepsState(getInitialFocusedChargeUUID('charges', charges, initialFocusedSection));
  const countriesState = useSubStepsState(
    getInitialFocusedCountryUUID(priceLocalization.countries, initialFocusedSection),
  );

  const isAddon = aPackage.type === 'Addon';

  const overageChargesState = useSubStepsState(
    getInitialFocusedChargeUUID('overageCharges', overageCharges.charges, initialFocusedSection),
  );

  const steps: WizardAccordionStep<StepIdentifier>[] = [
    {
      id: 'priceType',
      title: capitalize(t('pricing.pricingTypeTitle', { packageType: getPackageTypeDisplay(aPackage) })),
      content: <PriceTypeStep />,
      summary: <PriceTypeStepSummary />,
      hasErrors: !!errors.pricingType,
    },
    {
      id: 'billingCadence',
      hide: !isAddon || pricingType !== PricingType.Paid || !isOneOffAddonsEnabled,
      title: capitalize(t('pricing.billingCadence')),
      content: <BillingCadenceStep />,
      summary: <BillingCadenceStepSummary />,
    },
    {
      id: 'billingPeriod',
      hide: pricingType !== PricingType.Paid || billingCadence === BillingCadence.OneOff,
      title: t('pricing.billingPeriod'),
      content: <BillingPeriodStep />,
      summary: <BillingPeriodStepSummary />,
      hasErrors: !!errors.billingPeriods,
    },
    {
      id: 'charges',
      hide: pricingType !== PricingType.Paid,
      title: t('pricing.chargesTitle'),
      // @ts-ignore FIX-ME
      content: (renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactElement) => (
        <ChargesStep chargesState={chargesState} renderStepActionButtons={renderStepActionButtons} />
      ),
      summary: <ChargesStepSummary />,
      hasErrors: !!errors.charges,
      hideStepActionsButtons: charges.some((charge) => !charge.isConfirmed),
    },
    {
      id: 'overageCharges',
      hide: isAddon || pricingType !== PricingType.Paid || !isOveragePricingEnabled,
      title: t('pricing.overageCharges.title'),
      // @ts-ignore FIX-ME
      content: (renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactElement) => (
        <OverageChargesStep chargesState={overageChargesState} renderStepActionButtons={renderStepActionButtons} />
      ),
      summary: <OverageChargesStepSummary />,
      hasErrors: !!errors.overageCharges?.enabled && !!errors.overageCharges?.charges,
      hideStepActionsButtons:
        !!errors.overageCharges?.enabled && overageCharges.charges.some((charge) => !charge.isConfirmed),
    },
    {
      id: 'freeTrial',
      hide: aPackage.type !== 'Plan' || !pricingType || pricingType === PricingType.Free,
      title: t('pricing.trials.freeTrialTitle'),
      content: <FreeTrialStep />,
      summary: <FreeTrialStepSummary />,
      hasErrors: !!errors.freeTrial,
    },
  ];

  const additionalSteps: WizardAccordionStep<StepIdentifier>[] = [];

  if (pricingType === PricingType.Paid) {
    additionalSteps.push({
      id: 'minimumSpend',
      hide: isAddon || !isMinimumSpendEnabled,
      title: t('pricing.minimumSpend.title'),
      // @ts-ignore FIX-ME
      content: (renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactElement) => (
        <MinimumSpendStep renderStepActionButtons={renderStepActionButtons} />
      ),
      summary: <MinimumSpendSummary />,
      hasErrors: !!errors.minimumSpend?.enabled && !!errors.minimumSpend?.minimums,
    });
    additionalSteps.push({
      id: 'priceLocalization',
      title: t('pricing.priceLocalization.title'),
      // @ts-ignore FIX-ME
      content: (renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactElement) => (
        <PriceLocalizationStep countriesState={countriesState} renderStepActionButtons={renderStepActionButtons} />
      ),
      summary: <PriceLocalizationStepSummary />,
      hasErrors: !!errors.priceLocalization,
      hideStepActionsButtons: priceLocalization.countries.some((country) => !country.isConfirmed),
    });
  }

  return {
    steps,
    additionalSteps,
  };
}
