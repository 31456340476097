import styled, { css } from 'styled-components/macro';
import { ItamarTheme as Theme } from '@stigg-theme';
import {
  tooltipClasses,
  MenuItem as BaseMenuItem,
  MenuDropdown,
  Text,
  GridFlex,
  InformationTooltip,
  Icon,
} from '@stigg-components';
import { matchPath } from 'react-router-dom';
import { SidebarItemsType } from '../../../types/sidebar';
import SidebarNavListItem from './SidebarNavListItem';
import SidebarNavList from './SidebarNavList';
import { Navigation } from '../../../modules/navigation/useNavigation';

type ReduceChildRoutesProps = {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[];
  currentRoute: string;
  navigation: Navigation;
  isSidenavOpen: boolean;
};

const SidenavTooltip = styled(InformationTooltip)`
  .${tooltipClasses.tooltipPlacementRight} {
    left: -20px;
  }
`;

const MenuItem = styled(BaseMenuItem)<{ $isSelected?: boolean }>`
  color: ${({ theme }) => theme.itamar.palette.text.disabled};
  opacity: 1 !important;
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.itamar.palette.environmentSwitch.hoverBackground};
    `}
  &:hover {
    background-color: ${({ theme }) => theme.itamar.palette.environmentSwitch.hoverBackground} !important;
  }
`;

const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const { isSidenavOpen, items, page, depth, currentRoute, navigation } = props;
  const path = page.requiresEnvironment ? navigation.getEnvPath(page.href) : page.href;
  const isChildPageOpened = page.children
    ?.map((child) => (child.requiresEnvironment ? navigation.getEnvPath(child.href) : child.href))
    .some((path) => matchPath(currentRoute, { path }));

  if (page.children && isSidenavOpen) {
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={isChildPageOpened || page.open}
        isChildPageOpened={isChildPageOpened}
        title={page.title}
        href={path}
        isSidenavOpen={isSidenavOpen}
        dataTestId={page.dataTestId}>
        <SidebarNavList depth={depth + 1} pages={page.children} isSidenavOpen={isSidenavOpen} />
      </SidebarNavListItem>,
    );
  } else {
    items.push(
      !page.children ? (
        <SidenavTooltip
          key={page.title}
          arrow
          placement="right"
          title={!isSidenavOpen ? <Text.B2>{page.title}</Text.B2> : ''}
          $padding={2}>
          <div>
            <SidebarNavListItem
              depth={depth}
              href={path}
              icon={page.icon}
              key={page.title}
              badge={page.badge}
              title={page.title}
              externalLink={page.externalLink}
              isSidenavOpen={isSidenavOpen}
              dataTestId={page.dataTestId}
            />
          </div>
        </SidenavTooltip>
      ) : (
        <MenuDropdown
          key={page.title}
          isMenuWidthBasedOnTriggerWidth={false}
          minWidth={200}
          backgroundColor={(theme: Theme) =>
            theme.isLightTheme
              ? theme.itamar.palette.background.fadedBackground
              : theme.itamar.palette.background.darkBackground
          }
          borderColor="rgba(229, 242, 255, 0.3)"
          menuMarginTop={isSidenavOpen ? '-8px' : '-8px'}
          menuMarginLeft={isSidenavOpen ? '0' : '8px'}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          dropdownTrigger={
            <SidenavTooltip
              arrow
              placement="right"
              title={
                !isSidenavOpen ? (
                  <GridFlex.RowCenter>
                    <Text.B2>{page.title}</Text.B2>
                    <Icon type="materialIcons" icon="ChevronRight" color="primary.main" />
                  </GridFlex.RowCenter>
                ) : (
                  ''
                )
              }
              $padding={2}>
              <div>
                <SidebarNavListItem
                  isChildPageOpened={isChildPageOpened}
                  depth={depth}
                  href={path}
                  icon={page.icon}
                  key={page.title}
                  badge={page.badge}
                  title={page.title}
                  externalLink={page.externalLink}
                  isSidenavOpen={isSidenavOpen}
                  dataTestId={page.dataTestId}
                />
              </div>
            </SidenavTooltip>
          }
          menuItems={
            <GridFlex.Column>
              <MenuItem disabled>
                <Text.B2 color="disabled">{page.title}</Text.B2>
              </MenuItem>
              {page.children.map((childItem) => (
                <MenuItem
                  $isSelected={
                    !!matchPath(currentRoute, {
                      path: childItem.requiresEnvironment ? navigation.getEnvPath(childItem.href) : childItem.href,
                    })
                  }
                  onClick={() => navigation.navigateTo(childItem.href, { isGlobal: !childItem.requiresEnvironment })}>
                  <Text.B2 color="white">{childItem.title}</Text.B2>
                </MenuItem>
              ))}
            </GridFlex.Column>
          }
        />
      ),
    );
  }

  return items;
};

export default reduceChildRoutes;
