import type { FC } from 'react';
import { useState } from 'react';
import { t } from 'i18next';
import head from 'lodash/head';
import { useSelector } from 'react-redux';
import { GridFlex, Icon, Link, Text, Button } from '@stigg-components';
import { BillingModel, EnvironmentType } from '@stigg-types/apiTypes';
import LightFigmaLogoSVG from '@assets/images/logos/figma.light.svg?react';
import DarkFigmaLogoSVG from '@assets/images/logos/figma.dark.svg?react';
import LightNetflixLogoSVG from '@assets/images/logos/netflix.light.svg?react';
import DarkNetflixLogoSVG from '@assets/images/logos/netflix.dark.svg?react';
import LightSlackLogoSVG from '@assets/images/logos/slack.light.svg?react';
import DarkSlackLogoSVG from '@assets/images/logos/slack.dark.svg?react';
import LightSnowflakeLogoSVG from '@assets/images/logos/snowflake.light.svg?react';
import DarkSnowflakeLogoSVG from '@assets/images/logos/snowflake.dark.svg?react';
import LightSpotifyLogoSVG from '@assets/images/logos/spotify.light.svg?react';
import DarkSpotifyLogoSVG from '@assets/images/logos/spotify.dark.svg?react';
import LightTrelloLogoSVG from '@assets/images/logos/trello.light.svg?react';
import DarkTrelloLogoSVG from '@assets/images/logos/trello.dark.svg?react';
import LightTwilioLogoSVG from '@assets/images/logos/twilio.light.svg?react';
import DarkTwilioLogoSVG from '@assets/images/logos/twilio.dark.svg?react';
import LightZoomLogoSVG from '@assets/images/logos/zoom.light.svg?react';
import DarkZoomLogoSVG from '@assets/images/logos/zoom.dark.svg?react';
import { BrandIcon } from '../../../../../components/layout/sidebar/Sidebar.style';
import {
  Container,
  SandboxOptionDescriptionExtraWrapper,
  SandboxOptionDescriptionLogosWrapper,
  SandboxOptionDescriptionPriceSection,
  SandboxOptionDescriptionPriceSubtitleWrapper,
  SandboxOptionDescriptionSection,
  SandboxOptionPriceSection,
  SandboxOptionWrapper,
  UnderlineSketch,
} from './GenerateSandboxPage.style';
import type { SandboxOption, SandboxOptionComponentProps } from './GenerateSandboxPage.types';
import Loader from '../../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { addEnvironmentAction, fetchEnvironmentsAction, provisionSandboxAction } from '../../../accountsSlice';
import { useSwitchEnvironment } from '../../../../navigation/useSwitchEnvironment';
import { useNavigation } from '../../../../navigation/useNavigation';
import { useAnalytics } from '../../../../common/useAnalytics';
import { fetchProductsAction } from '../../../../products/productsSlice';
import { getStiggTheme } from '../../../../../theme';

const generateSandboxEnvironmentName = ({ displayName }: SandboxOption): string => `Sandbox - ${displayName}`;
// changes of the colors that are made for the dark the
const SANDBOX_OPTIONS: () => SandboxOption[] = () => {
  const { theme } = getStiggTheme();
  const isLight = theme.isLightTheme;
  return [
    {
      billingModel: BillingModel.FlatFee,
      priceBackgroundColor: '#88B333',
      priceTileBackgroundColor: '#212121',
      priceTitle: t('sandbox.generate.flatFee.priceTitle'),
      priceTitleColor: '#EFEFEF',
      priceSubtitle: t('sandbox.generate.flatFee.priceSubtitle'),
      priceSubtitleColor: '#EFEFEF',
      displayName: t('sandbox.generate.flatFee.displayName'),
      likeLogos: isLight ? [LightSpotifyLogoSVG, LightNetflixLogoSVG] : [DarkSpotifyLogoSVG, DarkNetflixLogoSVG],
      extraText: t('sandbox.generate.flatFee.extraText'),
    },
    {
      billingModel: BillingModel.PerUnit,
      priceBackgroundColor: '#1A5E2A',
      priceTileBackgroundColor: '#212121',
      priceTitle: t('sandbox.generate.perUnit.priceTitle'),
      priceTitleColor: '#EFEFEF',
      priceSubtitle: t('sandbox.generate.perUnit.priceSubtitle'),
      priceSubtitleColor: '#EFEFEF',
      displayName: t('sandbox.generate.perUnit.displayName'),
      likeLogos: isLight
        ? [LightSlackLogoSVG, LightTrelloLogoSVG, LightFigmaLogoSVG, LightZoomLogoSVG]
        : [DarkSlackLogoSVG, DarkTrelloLogoSVG, DarkFigmaLogoSVG, DarkZoomLogoSVG],
      extraText: t('sandbox.generate.perUnit.extraText'),
    },
    {
      billingModel: BillingModel.UsageBased,
      priceBackgroundColor: '#5C9D61',
      priceTileBackgroundColor: '#212121',
      priceTitle: t('sandbox.generate.usageBased.priceTitle'),
      priceTitleColor: '#EFEFEF',
      priceSubtitle: t('sandbox.generate.usageBased.priceSubtitle'),
      priceSubtitleColor: '#EFEFEF',
      displayName: t('sandbox.generate.usageBased.displayName'),
      likeLogos: isLight ? [LightSnowflakeLogoSVG, LightTwilioLogoSVG] : [DarkSnowflakeLogoSVG, DarkTwilioLogoSVG],
      extraText: t('sandbox.generate.usageBased.extraText'),
    },
  ];
};

export const SandboxOptionComponent: FC<SandboxOptionComponentProps> = ({ data, onClick }) => {
  const {
    priceBackgroundColor,
    priceTileBackgroundColor,
    priceTitle,
    priceTitleColor,
    priceSubtitle,
    priceSubtitleColor,
    displayName,
    likeLogos,
    extraText,
  } = data;

  return (
    <SandboxOptionWrapper onClick={onClick}>
      <SandboxOptionPriceSection color={priceBackgroundColor}>
        <SandboxOptionDescriptionPriceSection color={priceTileBackgroundColor}>
          <Text.H3 overrideColor={priceTitleColor} align="center" letterSpacing="-0.5px" lineHeight="36px">
            {priceTitle}
          </Text.H3>
          <SandboxOptionDescriptionPriceSubtitleWrapper>
            <Text.H6 overrideColor={priceSubtitleColor} align="center">
              {priceSubtitle}
            </Text.H6>
            <UnderlineSketch color={priceSubtitleColor} />
          </SandboxOptionDescriptionPriceSubtitleWrapper>
        </SandboxOptionDescriptionPriceSection>
      </SandboxOptionPriceSection>
      <SandboxOptionDescriptionSection>
        <Text.H3 color="primary" align="center" mt={11} mb={6}>
          {displayName}
        </Text.H3>
        <SandboxOptionDescriptionLogosWrapper>
          <GridFlex.Row alignItems="center" justifyContent="center">
            <Text.B1 color="primary" mr={4}>
              like
            </Text.B1>
            <GridFlex.Row alignItems="center" justifyContent="center" gap={3}>
              {likeLogos.map((Logo, index) => (
                <Logo key={index} />
              ))}
            </GridFlex.Row>
          </GridFlex.Row>
        </SandboxOptionDescriptionLogosWrapper>
        <SandboxOptionDescriptionExtraWrapper>
          <Text.B1 color="secondary" align="center" mb={5.5}>
            {extraText}
          </Text.B1>
          <Button variant="contained" endIcon={<Icon icon="ArrowForward" />} size="large">
            {t('sandbox.generate.explore')}
          </Button>
        </SandboxOptionDescriptionExtraWrapper>
      </SandboxOptionDescriptionSection>
    </SandboxOptionWrapper>
  );
};

export const GenerateSandboxPage: FC = () => {
  const { track } = useAnalytics();
  const { navigateTo } = useNavigation();
  const switchEnvironment = useSwitchEnvironment();
  const dispatch = useAppDispatch();
  const [isCreatingEnvironment, setIsCreatingEnvironment] = useState(false);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);

  const createDevelopmentEnvironment = () =>
    dispatch(
      addEnvironmentAction({
        noSuccessMessage: true,
        noPostFetch: true,
        environment: { displayName: 'Development', color: '#4CAF50' },
      }),
    ).unwrap();
  const createProductionEnvironment = () =>
    dispatch(
      addEnvironmentAction({
        noSuccessMessage: true,
        noPostFetch: true,
        environment: { displayName: 'Production', color: '#F88078', type: EnvironmentType.Production },
      }),
    ).unwrap();

  const headNotSandboxEnvironment = head((environments ?? []).filter(({ isSandbox }) => !isSandbox));

  const provisionSandbox = async (option: SandboxOption) => {
    setIsCreatingEnvironment(true);
    track('Sandbox selected', { billingModel: option.billingModel });

    try {
      if (!headNotSandboxEnvironment) {
        await createDevelopmentEnvironment();
        await createProductionEnvironment();
      }

      const displayName = generateSandboxEnvironmentName(option);
      const newEnvironment =
        head((environments ?? []).filter((env) => env.isSandbox && env.displayName === displayName)) ??
        (await dispatch(
          provisionSandboxAction({
            noSuccessMessage: true,
            noPostFetch: true,
            input: { billingModel: option.billingModel, displayName },
          }),
        ).unwrap());

      const products = await dispatch(
        fetchProductsAction({
          environmentId: newEnvironment.id,
          silentFetch: true,
        }),
      ).unwrap();

      await dispatch(fetchEnvironmentsAction({ silentFetch: true }));

      switchEnvironment(newEnvironment.id);
      navigateTo(`/${products?.[0] ? `products/${products[0].refId}` : ''}`);
    } catch (e) {
      console.error('GenerateSandboxPage.provisionSandbox failure', e);
    }
    setIsCreatingEnvironment(false);
  };

  const handleSkip = async () => {
    setIsCreatingEnvironment(true);
    track('Sandbox skipped');

    try {
      if (!headNotSandboxEnvironment) {
        await createProductionEnvironment();
      }
      const newEnvironment = headNotSandboxEnvironment ?? (await createDevelopmentEnvironment());
      await dispatch(fetchEnvironmentsAction({ silentFetch: true }));

      switchEnvironment(newEnvironment.id);
      navigateTo('/');
    } catch (e) {
      console.error('GenerateSandboxPage.handleSkip failure', e);
    }
    setIsCreatingEnvironment(false);
  };

  if (isCreatingEnvironment) {
    return <Loader useAnimationLoader />;
  }

  return (
    <Container>
      <BrandIcon $floating $open />

      <Text.H1 color="primary" align="center" mb={2}>
        {t('sandbox.generate.title')}
      </Text.H1>
      <Text.B1 color="primary" align="center" mb={15}>
        {t('sandbox.generate.subtitle')}
      </Text.B1>

      <GridFlex.Row justifyContent="center" gap={10} mb={24}>
        {SANDBOX_OPTIONS().map((option) => (
          <SandboxOptionComponent key={option.billingModel} data={option} onClick={() => provisionSandbox(option)} />
        ))}
      </GridFlex.Row>

      <Text.B1 color="secondary">
        {t('sandbox.generate.skip')}
        {', '}
        <Link onClick={handleSkip} underline="always" color="secondary">
          {t('sandbox.generate.skipLink')}
        </Link>
      </Text.B1>
    </Container>
  );
};
