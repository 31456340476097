import { Chip, GridFlex, PageCard, Text } from '@stigg-components';
import { t } from 'i18next';
import { ReactElement } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import * as S from './ExperimentsEmptyState.style';
import ExperimentCustomerJourneySlimIcon from '../../../assets/icons/experiments/ExperimentCustomerJourneySlim.svg?react';
import ExperimentPlanEntitlementsSlimIcon from '../../../assets/icons/experiments/ExperimentPlanEntitlementsSlim.svg?react';
import { ExperimentType } from './commom/ExperimentType';

type ExperimentUseCaseCardProps = {
  title: string;
  subtitle: string;
  icon: ReactElement;
  comingSoon?: boolean;
  onClick?: () => void;
};

function ExperimentUseCaseCard({ title, subtitle, icon, comingSoon, onClick }: ExperimentUseCaseCardProps) {
  return (
    <S.UseCaseCard p={8} $isDisabled={comingSoon} onClick={onClick}>
      <GridFlex.RowCenter columnGap={6} $fullWidth justifyContent="center">
        <S.IconGrid>{icon}</S.IconGrid>
        <GridFlex.ColumnCenter flex={1} alignItems="flex-start">
          {comingSoon && (
            <Chip
              label={t('sharedComponents.comingSoon')}
              color="action.disabledBackground"
              textColor="secondary"
              sx={{ marginTop: -8, marginLeft: 'auto' }}
            />
          )}
          <Text.B1 align="left" $bold mb={2} color={comingSoon ? 'secondary' : 'primary'}>
            {title}
          </Text.B1>
          <Text.B2 align="left" color="secondary">
            {subtitle}
          </Text.B2>
        </GridFlex.ColumnCenter>
      </GridFlex.RowCenter>
    </S.UseCaseCard>
  );
}

function ExperimentCustomerJourneyUseCaseCard({
  onCreateClick,
}: {
  onCreateClick: (experimentType?: ExperimentType) => void;
}) {
  return (
    <ExperimentUseCaseCard
      title={t('experiments.useCases.customerJourney.title')}
      subtitle={t('experiments.useCases.customerJourney.subtitle')}
      icon={<ExperimentCustomerJourneySlimIcon />}
      onClick={() => onCreateClick(ExperimentType.CUSTOMER_JOURNEY)}
    />
  );
}

function ExperimentPlanFeaturesUseCaseCard({
  onCreateClick,
}: {
  onCreateClick: (experimentType?: ExperimentType) => void;
}) {
  const { experimentEntitlements: experimentEntitlementsFeatureEnabled } = useFlags<FeatureFlags>();
  return (
    <ExperimentUseCaseCard
      title={t('experiments.useCases.planFeatures.title')}
      subtitle={t('experiments.useCases.planFeatures.subtitle')}
      comingSoon={!experimentEntitlementsFeatureEnabled}
      icon={<ExperimentPlanEntitlementsSlimIcon />}
      onClick={() => experimentEntitlementsFeatureEnabled && onCreateClick(ExperimentType.PLAN_ENTITLEMENTS)}
    />
  );
}

export function ExperimentsEmptyState({ onCreateClick }: { onCreateClick: (experimentType?: ExperimentType) => void }) {
  return (
    <PageCard>
      <GridFlex.Column rowGap={6}>
        <Text.H3>{t('experiments.createNewExperiment')}</Text.H3>
        <Text.B1 color="primary">{t('experiments.whatWouldYouLikeToTest')}</Text.B1>
        <GridFlex.Row container rowGap={6} columnGap={6} m={0}>
          <ExperimentCustomerJourneyUseCaseCard onCreateClick={onCreateClick} />
          <ExperimentPlanFeaturesUseCaseCard onCreateClick={onCreateClick} />
        </GridFlex.Row>
      </GridFlex.Column>
    </PageCard>
  );
}
