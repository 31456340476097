import {
  AddonFragment,
  BillingCadence,
  BillingModel,
  BillingPeriod,
  EntitlementResetPeriod,
  MonthlyResetPeriodConfig,
  OverageBillingPeriod,
  PlanFragment,
  PriceFragment,
  PriceTierFragment,
  ResetPeriodConfiguration as ApiResetPeriodConfiguration,
  TiersMode,
  TrialEndBehavior,
  TrialPeriodUnits,
  WeeklyResetPeriodConfig,
  YearlyResetPeriodConfig,
} from '@stigg-types/apiTypes';
import compact from 'lodash/compact';
import { groupBy, keyBy, mapValues, uniq } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { generateRandomSlug } from '@stigg-common';
import isNil from 'lodash/isNil';
import { ResetPeriodConfiguration } from '@stigg-common/types';
import {
  BasePriceChargeType,
  Charge,
  ChargeType,
  CountryPriceLocalization,
  FreeTrial,
  MinimumSpend,
  OverageCharge,
  OverageCharges,
  PriceLocalization,
  PricePeriods,
  PriceTier,
  PriceTiersPeriods,
  SetPriceWizardFormFields,
  TiersSchema,
  UsageBasedChargeType,
  UsageBasedInAdvanceCommitmentChargeType,
} from './SetPriceWizardForm.types';
import { convertToFrontendAmount } from './utils/priceConversions';
import { getChargeGroupId, getPriceGroupId, toPriceGroups } from '../../../utils/priceGroups';
import { DEFAULT_CURRENCY } from '../../currency/currencyUtils';
import { getPrice } from '../../../utils/getPrice';
import { ALL_BILLING_PERIODS } from './chargesStep/utils/getPricePeriodFields';
import { splitPricesByPricingType } from './SetPriceWizardForm.utils';
import { hasTierWithUnitPrice } from '../../../utils/priceTiersUtils';
import { isPackagePlan } from '../../../../common/packageUtils';

export const DEFAULT_PRICE_TIER: PriceTier = {
  endUnit: 1,
  unitPrice: null,
  tierPrice: null,
};

function getFreeTrialInitialValues(aPackage: PlanFragment | AddonFragment): FreeTrial {
  let enabled = false;
  let durationDays = 30;
  let spendLimit: undefined | number;
  let trialEndBehavior: TrialEndBehavior = TrialEndBehavior.CancelSubscription;

  if (aPackage.type === 'Plan') {
    const plan = aPackage as PlanFragment;

    if (plan.defaultTrialConfig) {
      enabled = true;
      durationDays = plan.defaultTrialConfig.duration;

      // for backward compatability
      if (plan.defaultTrialConfig.units === TrialPeriodUnits.Month) {
        durationDays *= 30;
      }
      if (plan.defaultTrialConfig.budget) {
        spendLimit = plan.defaultTrialConfig.budget.limit;
      }
      if (plan.defaultTrialConfig.trialEndBehavior) {
        trialEndBehavior = plan.defaultTrialConfig.trialEndBehavior;
      }
    }
  }

  return { enabled, durationDays, trialEndBehavior, spendLimit };
}

function getBillingPeriodsInitialValues(aPackage: PlanFragment | AddonFragment): BillingPeriod[] {
  const billingPeriods = uniq(compact(aPackage.prices?.map((price) => price.billingPeriod)));
  return isEmpty(billingPeriods) ? [BillingPeriod.Monthly] : billingPeriods;
}

function getBillingCadenceInitialValues(aPackage: PlanFragment | AddonFragment): BillingCadence {
  return aPackage.prices?.[0]?.billingCadence || BillingCadence.Recurring;
}

function getPricePeriods(
  prices: PriceFragment[],
  billingPeriods: BillingPeriod[],
  convertAmount: boolean,
): PricePeriods {
  return Object.fromEntries(
    prices.map((price) => [
      price.billingPeriod,
      {
        amount: convertAmount
          ? convertToFrontendAmount(getPrice(price).amount, price.billingPeriod, billingPeriods)
          : getPrice(price).amount,
        blockSize: price.blockSize,
      },
    ]),
  );
}

function getPricePeriodsTierStep(
  hasBillingPeriod: boolean,
  billingPeriod: BillingPeriod,
  billingPeriods: BillingPeriod[],
  tier: PriceTierFragment,
) {
  const { unitPrice, flatPrice, upTo } = tier;
  if (hasBillingPeriod) {
    // If there is a unit price it means that the tiers mode is either tiered or graduated
    if (unitPrice) {
      return {
        endUnit: upTo,
        unitPrice: convertToFrontendAmount(unitPrice.amount, billingPeriod, billingPeriods),
        tierPrice: flatPrice ? convertToFrontendAmount(flatPrice.amount, billingPeriod, billingPeriods) : null,
      };
    }
    if (flatPrice) {
      return {
        endUnit: upTo,
        unitPrice: isNil(upTo) ? null : convertToFrontendAmount(flatPrice.amount / upTo, billingPeriod, billingPeriods),
        tierPrice: convertToFrontendAmount(flatPrice.amount, billingPeriod, billingPeriods),
      };
    }
  }

  return { endUnit: upTo, unitPrice: null, tierPrice: null };
}

function getPricePeriodsTiers(
  prices: PriceFragment[],
  billingPeriods: BillingPeriod[],
  tiersMode: TiersMode | null | undefined,
): PriceTiersPeriods {
  // return default tiers
  if (!tiersMode) {
    return {
      [BillingPeriod.Monthly]: [DEFAULT_PRICE_TIER],
      [BillingPeriod.Annually]: [DEFAULT_PRICE_TIER],
    };
  }
  const priceByBillingPeriod = keyBy(prices, 'billingPeriod');
  const firstTiers = prices[0].tiers!;
  return Object.fromEntries(
    ALL_BILLING_PERIODS.map((billingPeriod) => {
      const hasBillingPeriod = !!priceByBillingPeriod[billingPeriod];
      return [
        billingPeriod,
        // We set all billing periods to have the same tiers even if the billing period doesn't exists
        (hasBillingPeriod ? priceByBillingPeriod[billingPeriod].tiers! : firstTiers).map((tier) =>
          getPricePeriodsTierStep(hasBillingPeriod, billingPeriod, billingPeriods, tier),
        ),
      ];
    }),
  );
}

function getBasePriceChargeInitialValue(prices: PriceFragment[], billingPeriods: BillingPeriod[]): BasePriceChargeType {
  const pricePeriods = getPricePeriods(prices, billingPeriods, true);
  const isBlockPricing = !!prices[0].blockSize;
  return {
    uuid: generateRandomSlug(),
    type: ChargeType.BasePrice,
    billingModel: BillingModel.FlatFee,
    pricePeriods,
    isBlockPricing,
    isConfirmed: true,
  };
}

export function toTiersSchema(
  tiersMode: TiersMode | null | undefined,
  tiers: PriceTierFragment[] | null | undefined,
): TiersSchema {
  switch (tiersMode) {
    case TiersMode.Volume:
      return hasTierWithUnitPrice(tiers) ? TiersSchema.VolumePerUnit : TiersSchema.VolumeBulkOfUnits;
    case TiersMode.Graduated:
      return TiersSchema.GraduatedPerUnit;
    default:
      return TiersSchema.Standard;
  }
}

function extractResetPeriodConfiguration(
  resetPeriod?: EntitlementResetPeriod | null,
  resetPeriodConfiguration?: ApiResetPeriodConfiguration | null,
): ResetPeriodConfiguration | null | undefined {
  switch (resetPeriod) {
    case EntitlementResetPeriod.Year:
      return (resetPeriodConfiguration as YearlyResetPeriodConfig)?.yearlyAccordingTo;
    case EntitlementResetPeriod.Month:
      return (resetPeriodConfiguration as MonthlyResetPeriodConfig)?.monthlyAccordingTo;
    case EntitlementResetPeriod.Week:
      return (resetPeriodConfiguration as WeeklyResetPeriodConfig)?.weeklyAccordingTo;
    default:
      return null;
  }
}

function getUsageBasedChargeInitialValue(
  billingModel: BillingModel.PerUnit | BillingModel.UsageBased,
  prices: PriceFragment[],
  billingPeriods: BillingPeriod[],
): UsageBasedChargeType | null {
  // @ts-ignore FIX-ME
  const { feature, tiersMode, tiers, resetPeriod, resetPeriodConfiguration } = prices[0];

  if (!feature) {
    return null;
  }

  const additionalFields: Partial<UsageBasedInAdvanceCommitmentChargeType> = {};

  if (billingModel === BillingModel.PerUnit) {
    const { minUnitQuantity, maxUnitQuantity } = prices[0];

    additionalFields.minUnitQuantity = minUnitQuantity;
    additionalFields.maxUnitQuantity = maxUnitQuantity;
  }

  const isBlockPricing = !!prices[0].blockSize;

  return {
    uuid: generateRandomSlug(),
    type: ChargeType.UsageBased,
    billingModel,
    feature,
    pricePeriods: !tiersMode ? getPricePeriods(prices, billingPeriods, true) : {},
    isBlockPricing,
    tiers: getPricePeriodsTiers(prices, billingPeriods, tiersMode),
    isConfirmed: true,
    tiersSchema: prices[0].blockSize ? TiersSchema.StandardPerBlock : toTiersSchema(tiersMode, tiers),
    resetPeriod: resetPeriod || undefined,
    resetPeriodConfiguration: extractResetPeriodConfiguration(resetPeriod, resetPeriodConfiguration),
    ...additionalFields,
  };
}

function getChargesInitialValues(
  priceGroups: PriceFragment[][],
  packageType: string,
  billingPeriods: BillingPeriod[],
): Charge[] {
  const charges = compact(
    priceGroups.map((prices) => {
      const { billingModel } = prices[0];
      const defaultPrices = prices.filter((price) => !price.billingCountryCode);

      switch (billingModel) {
        case BillingModel.FlatFee:
          return getBasePriceChargeInitialValue(defaultPrices, billingPeriods);
        case BillingModel.UsageBased:
        case BillingModel.PerUnit:
          return getUsageBasedChargeInitialValue(billingModel, defaultPrices, billingPeriods);
        default:
          return undefined;
      }
    }),
  );

  // Addons always have a base price
  if (packageType === 'Addon' && charges.length === 0) {
    charges.push({
      uuid: generateRandomSlug(),
      type: ChargeType.BasePrice,
      billingModel: BillingModel.FlatFee,
      pricePeriods: {},
      isConfirmed: true,
    });
  }

  return charges;
}

function getOverageChargeInitialValue(
  prices: PriceFragment[],
  billingPeriods: BillingPeriod[],
  aPackage: PlanFragment | AddonFragment,
): OverageCharge | null {
  const { feature } = prices[0];
  if (!feature) {
    return null;
  }

  const currentPackageEntitlement = aPackage.entitlements?.find(
    (entitlement) => entitlement.feature?.id === feature?.id,
  );

  const pricePeriods = getPricePeriods(prices, billingPeriods, false);
  const isBlockPricing = !!prices[0].blockSize;

  return {
    uuid: generateRandomSlug(),
    type: ChargeType.UsageBased,
    billingModel: BillingModel.PerUnit,
    feature,
    pricePeriods,
    isBlockPricing,
    isConfirmed: true,
    entitlement: currentPackageEntitlement
      ? {
          featureId: feature.id,
          usageLimit: currentPackageEntitlement.usageLimit!,
          resetPeriod: currentPackageEntitlement.resetPeriod!,
          resetPeriodConfiguration: extractResetPeriodConfiguration(
            currentPackageEntitlement.resetPeriod,
            currentPackageEntitlement.resetPeriodConfiguration,
          ),
        }
      : undefined,
  };
}

function getOverageChargesInitialValues(
  priceGroups: PriceFragment[][],
  billingPeriods: BillingPeriod[],
  aPackage: PlanFragment | AddonFragment,
): OverageCharges {
  const overageCharges = compact(
    priceGroups.map((prices) => {
      const defaultPrices = prices.filter((price) => !price.billingCountryCode);
      return getOverageChargeInitialValue(defaultPrices, billingPeriods, aPackage);
    }),
  );

  return {
    enabled: !isEmpty(overageCharges),
    charges: overageCharges,
  };
}

function getMinimumSpendInitialValues(aPackage: PlanFragment | AddonFragment): MinimumSpend {
  let enabled = false;
  const minimums = {};

  if (aPackage.type === 'Plan') {
    const plan = aPackage as PlanFragment;
    plan.minimumSpend?.forEach((minimum) => {
      if (minimum.minimum.currency === DEFAULT_CURRENCY) {
        minimums[minimum.billingPeriod] = minimum.minimum.amount;
      }
      enabled = true;
    });
  }
  return { enabled, minimums };
}

function getPriceLocalizationInitialValues(
  priceGroups: PriceFragment[][],
  billingPeriods: BillingPeriod[],
  charges: Charge[],
): PriceLocalization {
  if (isEmpty(priceGroups)) {
    return {
      enabled: false,
      countries: [],
    };
  }

  // first construct a list of all price localizations
  const countriesData = new Map<string, CountryPriceLocalization>();
  const countries: CountryPriceLocalization[] = [];

  priceGroups[0].forEach((price) => {
    if (!price.billingCountryCode || countriesData.has(price.billingCountryCode)) {
      return;
    }

    const item: CountryPriceLocalization = {
      uuid: generateRandomSlug(),
      billingCountryCode: price.billingCountryCode,
      currency: getPrice(price).currency,
      countryAndCurrencyConfirmed: true,
      isConfirmed: true,
      chargesPricePeriods: {},
      chargesTieredPricePeriods: {},
      openedCharges: {},
    };

    countriesData.set(price.billingCountryCode, item);
    countries.push(item);
  });

  if (countries.length === 0) {
    return {
      enabled: false,
      countries,
    };
  }

  // then populate the price localizations with the charges
  const priceGroupIdToChargeUuid = mapValues(keyBy(charges, getChargeGroupId), (value) => value.uuid);

  priceGroups.forEach((priceGroup) => {
    const pricesWithCountryCode = priceGroup.filter(({ billingCountryCode }) => billingCountryCode);
    const pricesGroupedByCountry = groupBy(pricesWithCountryCode, 'billingCountryCode');

    Object.entries(pricesGroupedByCountry).forEach(([billingCountryCode, prices]) => {
      const usedInSubscriptions = prices.some(({ usedInSubscriptions }) => usedInSubscriptions);
      const chargeUuid = priceGroupIdToChargeUuid[getPriceGroupId(prices)];
      const countryData = countriesData.get(billingCountryCode);
      if (countryData) {
        const { tieredPrices, otherPrices } = splitPricesByPricingType(prices);

        countryData.chargesPricePeriods[chargeUuid] = getPricePeriods(otherPrices, billingPeriods, true);
        countryData.chargesTieredPricePeriods[chargeUuid] = getPricePeriodsTiers(
          tieredPrices,
          billingPeriods,
          tieredPrices?.[0]?.tiersMode,
        );
        countryData.usedInSubscriptions = usedInSubscriptions;
      }
    });
  });

  return {
    enabled: true,
    countries,
  };
}

export function getInitialValues(aPackage: PlanFragment | AddonFragment): SetPriceWizardFormFields {
  const pricingType = aPackage.pricingType || null;
  const freeTrial = getFreeTrialInitialValues(aPackage);
  const billingPeriods = getBillingPeriodsInitialValues(aPackage);
  const billingCadence = getBillingCadenceInitialValues(aPackage);

  const priceGroups = toPriceGroups(aPackage.prices);
  const charges = getChargesInitialValues(priceGroups, aPackage.type, billingPeriods);
  const priceLocalization = getPriceLocalizationInitialValues(priceGroups, billingPeriods, charges);

  const overagePriceGroups = toPriceGroups(isPackagePlan(aPackage) ? aPackage.overagePrices : null);
  const overageCharges = getOverageChargesInitialValues(overagePriceGroups, billingPeriods, aPackage);

  const overageBillingPeriod = aPackage.overageBillingPeriod || OverageBillingPeriod.Monthly;

  const minimumSpend = getMinimumSpendInitialValues(aPackage);

  return {
    pricingType,
    defaultCurrency: DEFAULT_CURRENCY,
    freeTrial,
    billingPeriods,
    billingCadence,
    charges,
    overageCharges,
    priceLocalization,
    overageBillingPeriod,
    minimumSpend,
  };
}
