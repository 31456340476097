import { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Loader from '../../../components/Loader';
import { RootState, useAppDispatch } from '../../../redux/store';
import { clearSelectedIntegrationAction } from '../integrationsSlice';
import { AddIntegration } from './AddIntegration';
import { ActiveIntegrations } from './ActiveIntegrations';
import { VendorIdentifier } from '../types';
import { IntegrationPageModal } from './IntegrationPageModal';
import { useQueryParam } from '../../common';

export const INTEGRATION_VENDOR_ID_SEARCH_PARAM = 'integration';

function IntegrationsPage() {
  const dispatch = useAppDispatch();
  const {
    value: vendorIdentifierQueryParam,
    setQueryParam,
    removeQueryParam,
  } = useQueryParam(INTEGRATION_VENDOR_ID_SEARCH_PARAM);
  const selectedVendorIdentifier = vendorIdentifierQueryParam?.toUpperCase() as VendorIdentifier;
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string | null>(null);
  const integrations = useSelector((state: RootState) => state.integrations.integrations);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoading = useSelector((state: RootState) => state.integrations.isLoading);
  const isModalOpen = !!selectedVendorIdentifier || !!selectedIntegrationId;

  const hasActiveIntegrations = (integrations?.length || 0) > 0;

  const onAddIntegration = (vendorIdentifier: VendorIdentifier) => {
    setQueryParam(vendorIdentifier);
  };

  const onActiveIntegrationSelected = (integrationId: string) => {
    setSelectedIntegrationId(integrationId);
  };

  const closeIntegrationSetupDialog = () => {
    setSelectedIntegrationId(null);
    dispatch(clearSelectedIntegrationAction());
    removeQueryParam();
  };

  if ((isLoading && !isModalOpen && isEmpty(integrations)) || !currentEnvironmentId) {
    return <Loader />;
  }

  return (
    <>
      {hasActiveIntegrations ? (
        <ActiveIntegrations
          integrations={integrations || []}
          onActiveIntegrationSelected={onActiveIntegrationSelected}
        />
      ) : (
        <AddIntegration title={t('integrations.addFirstIntegrationTitle')} onAddIntegration={onAddIntegration} />
      )}
      <IntegrationPageModal
        isOpen={!!selectedVendorIdentifier || !!selectedIntegrationId}
        onClose={closeIntegrationSetupDialog}
        vendorIdentifier={selectedVendorIdentifier}
        integrationId={selectedIntegrationId}
      />
    </>
  );
}

export default IntegrationsPage;
