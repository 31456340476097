import { t } from 'i18next';
import { Clock, CornerRightUp, Edit2, Trash2 } from 'react-feather';
import { Restore } from '@mui/icons-material';
import { Icon, OptionsDropdown, Text } from '@stigg-components';
import { OptionsDropdownType } from '@stigg-components/types';
import {
  canCancelSubscription,
  hasOutdatedPackagesUtil,
  hasPayAsYouGoPrice,
  isActiveSubscriptionUtil,
  useIntegrations,
} from '@stigg-common';
import { SubscriptionType } from '@stigg-common/types';
import { PricingType, SubscriptionScheduleStatus, SubscriptionStatus } from '@stigg-types/apiTypes';
import { hasFutureUpdates } from '../../modules/customers/components/subscriptions/utils/futureUpdates.utils';
import { getBillingIntegration, getReadableVendorIdentifier } from '../../utils/integrationUtils';

const SyncUsageIcon = () => <Icon icon="SyncUsage" size={20} />;

export function SubscriptionsOptionsDropdown({
  subscription,
  isAwsMarketplaceManagedSubscription,
  onCancelSubscriptionClick,
  onUpdateSubscriptionClick,
  onEndTrialSubscriptionClick,
  onMigrateSubscriptionToLatest,
  onCancelSubscriptionScheduleClick,
  onTriggerSubscriptionUsageSync,
}: {
  subscription: SubscriptionType;
  isAwsMarketplaceManagedSubscription?: boolean;
  onCancelSubscriptionClick: () => void;
  onUpdateSubscriptionClick?: () => void;
  onEndTrialSubscriptionClick: () => void;
  onMigrateSubscriptionToLatest?: () => void;
  onCancelSubscriptionScheduleClick?: () => void;
  onTriggerSubscriptionUsageSync: () => void;
}) {
  const { hasBillingIntegration, configuredIntegrations } = useIntegrations();
  // TODO: this needs to be the integration the subscription is synced to
  const billingIntegration = getBillingIntegration(configuredIntegrations);
  const vendorIdentifier = billingIntegration?.vendorIdentifier;
  const readableVendorIdentifier = getReadableVendorIdentifier(vendorIdentifier || '');

  const options: OptionsDropdownType = [];
  const tooltipProp: OptionsDropdownType[0]['tooltip'] = isAwsMarketplaceManagedSubscription
    ? {
        placement: 'left',
        title: <Text.B2 color="primary">{t('integrations.awsMarketplace.managedByAWSTooltipText')}</Text.B2>,
      }
    : undefined;

  if (onCancelSubscriptionScheduleClick && hasFutureUpdates(subscription, SubscriptionScheduleStatus.Scheduled)) {
    options.push({
      icon: Restore,
      disabled: isAwsMarketplaceManagedSubscription,
      tooltip: tooltipProp,
      text: t('customers.cancelSchedules'),
      onClick: () => onCancelSubscriptionScheduleClick(),
    });
  }

  if (
    onUpdateSubscriptionClick &&
    ![SubscriptionStatus.Expired, SubscriptionStatus.Canceled].includes(subscription.status)
  ) {
    options.push({
      icon: Edit2,
      disabled: isAwsMarketplaceManagedSubscription,
      tooltip: tooltipProp,
      text: t('customers.updateSubscription'),
      onClick: () => onUpdateSubscriptionClick(),
      dataTestId: 'update-subscription-option',
    });
  }

  if (onMigrateSubscriptionToLatest) {
    const isActiveSubscription = isActiveSubscriptionUtil(subscription.status);
    const { hasOutdatedPackages, hasOutdatedPrice, outdatedPackageDescription } = hasOutdatedPackagesUtil(subscription);

    if (isActiveSubscription && (hasOutdatedPackages || hasOutdatedPrice)) {
      options.push({
        disabled: isAwsMarketplaceManagedSubscription,
        tooltip: tooltipProp,
        icon: CornerRightUp,
        text: t('subscriptions.migrateToLatestMenuItem', {
          outdatedPackages: outdatedPackageDescription,
        }),
        onClick: () => onMigrateSubscriptionToLatest(),
      });
    }
  }

  if (
    hasBillingIntegration &&
    vendorIdentifier &&
    !isAwsMarketplaceManagedSubscription &&
    subscription.pricingType === PricingType.Paid &&
    subscription.status === SubscriptionStatus.Active &&
    hasPayAsYouGoPrice(subscription)
  ) {
    options.push({
      icon: SyncUsageIcon,
      text: t('subscriptions.syncUsageToBillingIntegration', { vendorIdentifier: readableVendorIdentifier }),
      onClick: () => onTriggerSubscriptionUsageSync(),
      dataTestId: 'sync-usage-to-stripe-option',
    });
  }

  if (subscription.status === SubscriptionStatus.InTrial) {
    options.push({
      icon: Clock,
      text: t('customers.trials.endTrial'),
      onClick: () => onEndTrialSubscriptionClick(),
      tooltip: tooltipProp,
    });
  }

  options.push({
    icon: Trash2,
    text: t('customers.cancelSubscription'),
    onClick: () => onCancelSubscriptionClick(),
    disabled: isAwsMarketplaceManagedSubscription || !canCancelSubscription(subscription.status),
    tooltip: tooltipProp,
    dataTestId: 'cancel-subscription-option',
  });

  return <OptionsDropdown dataTestId="subscription-card-dropdown" options={options} />;
}
