import { t } from 'i18next';
import { GridFlex, Text } from '@stigg-components';
import { IntegrationFragment } from '@stigg-types/apiTypes';
import { Check } from 'react-feather';
import { getIconColor } from '@stigg-theme';
import { StripeFooterTax } from './StripeFooterTax';
import { StripeFooterActions } from './StripeFooterActions';

type StripeFooterProps = { integration: IntegrationFragment | null; isReadOnly: boolean };

const STRIPE_USAGES = () => [t('integrations.billCustomersUsingStripe'), t('integrations.keepStripeAndStiggSynced')];

export function StripeFooter({ integration, isReadOnly }: StripeFooterProps) {
  if (!integration) {
    return (
      <GridFlex.Column mt={12} px={10}>
        <Text.H6 mb={5}>{t('integrations.stripeIntegrationUsages')}</Text.H6>
        {STRIPE_USAGES().map((usage, index) => (
          <GridFlex.RowCenter key={index} mb={4}>
            <Check color={getIconColor('active')} />
            <Text.B2 ml={5} color="secondary">
              {usage}
            </Text.B2>
          </GridFlex.RowCenter>
        ))}
      </GridFlex.Column>
    );
  }

  if (isReadOnly) {
    return null;
  }

  return (
    <GridFlex.Column>
      <StripeFooterTax integration={integration} />
      <StripeFooterActions integration={integration} />
    </GridFlex.Column>
  );
}
