import React from 'react';
import styled from 'styled-components/macro';
import { PageCard, Divider } from '@stigg-components';
import { IntegrationFragment } from '@stigg-types/apiTypes';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IntegrationContactUsState } from '../IntegrationContactUsState';
import { HubspotConnect } from './HubspotConnect';
import { DetailsBox } from '../IntegrationPage';
import { HubspotIntegrationDetails } from '../HubspotIntegrationDetails';
import { VendorIdentifier } from '../../types';

type HubSpotIntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
  isReadOnly: boolean;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function HubSpotIntegrationPage({ integration, headerComponent, isReadOnly }: HubSpotIntegrationPageProps) {
  const { hubspotIntegration: isHubspotIntegrationEnabled } = useFlags<FeatureFlags>();

  if (!integration && !isHubspotIntegrationEnabled) {
    return (
      <IntegrationContactUsState
        headerComponent={headerComponent}
        // @ts-ignore FIX-ME
        integrationType={VendorIdentifier.Hubspot}
        betaAccess
      />
    );
  }

  return (
    <>
      <StyledPageCard cardProps={{ p: 4 }}>
        {headerComponent}

        {integration ? (
          <>
            <Divider my={4} />
            <DetailsBox p={4} position="relative">
              <HubspotIntegrationDetails integration={integration} />
            </DetailsBox>
          </>
        ) : !isReadOnly ? (
          <>
            <Divider my={4} />
            <HubspotConnect />
          </>
        ) : null}
      </StyledPageCard>
    </>
  );
}
