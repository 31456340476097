import { drawFormFields, Field, GridFlex, InformationTooltip, Text } from '@stigg-components';
import { t } from 'i18next';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { BillingModel, BillingPeriod, Feature, VendorIdentifier } from '@stigg-types/apiTypes';
import { get } from 'lodash';
import { useIntegrations } from '@stigg-common';
import { useSetPriceWizardFormContext } from '../../SetPriceWizardForm.context';
import {
  ChargeType,
  SetPriceWizardFormFields,
  TIERS_SCHEMA_PER_UNIT,
  TiersSchema,
  UsageBasedChargeType,
} from '../../SetPriceWizardForm.types';
import { getPricePeriodUnit } from '../../utils/priceConversions';
import { FeatureAutocomplete } from './FeatureAutocomplete';
import { UsageBasedBillingTypeSelection } from './UsageBasedBillingTypeSelection';
import { getBlockPricePeriodFields, getPricePeriodFields } from '../utils/getPricePeriodFields';
import { PricingModelSelectItem } from './PricingModelSelectItem';
import { PricingTiersTable } from './PricingTiersTable';
import { DEFAULT_PRICE_TIER } from '../../SetPriceWizardForm.initialValues';
import { ChargeResetPeriodConfiguration } from './ChargeResetPeriodConfiguration';
import { getBillingIntegration } from '../../../../../../../../utils/integrationUtils';

const DEFAULT_TIERS = {
  [BillingPeriod.Monthly]: [DEFAULT_PRICE_TIER],
  [BillingPeriod.Annually]: [DEFAULT_PRICE_TIER],
};

const DEFAULT_TIERS_PER_UNIT_TIERS = [
  DEFAULT_PRICE_TIER,
  {
    endUnit: 3,
    unitPrice: null,
    tierPrice: null,
  },
];

const DEFAULT_TIERS_PER_UNIT_BILLING_PERIODS = {
  [BillingPeriod.Monthly]: DEFAULT_TIERS_PER_UNIT_TIERS,
  [BillingPeriod.Annually]: DEFAULT_TIERS_PER_UNIT_TIERS,
};

type UsageBasedPriceProps = {
  index: number;
};

export function UsageBasedPrice({ index }: UsageBasedPriceProps) {
  const {
    volumePerUnit,
    graduatedPricingModel,
    packagePricingModel,
    addOverageToCharge: addOverageToChargeEnabled,
    shouldUseNewPaygoMechanism,
  } = useFlags<FeatureFlags>();
  const { formRenderProps } = useSetPriceWizardFormContext();
  const { errors, setFieldValue, setFieldTouched } = formRenderProps;
  const { billingPeriods, charges, overageCharges, defaultCurrency, billingCadence } = formRenderProps.values;
  const pricePeriodUnit = getPricePeriodUnit(billingPeriods);
  const hasErrorsInAdvancedSection =
    !!get(errors, `charges[${index}].minUnitQuantity`) || !!get(errors, `charges[${index}].maxUnitQuantity`);
  const currentCharge = charges[index];

  const initialAdvancedSectionOpen =
    currentCharge.billingModel === BillingModel.PerUnit &&
    (!!currentCharge.minUnitQuantity || !!currentCharge.maxUnitQuantity);
  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(initialAdvancedSectionOpen);
  const [resetPeriodConfigurationOpen, setResetPeriodConfigurationOpen] = useState(false);

  const { configuredIntegrations } = useIntegrations();
  const billingIntegration = getBillingIntegration(configuredIntegrations);
  const vendorIdentifier = billingIntegration?.vendorIdentifier;

  const isFeatureDisabled = (feature: Feature) => {
    if (charges.some((charge) => charge.type === ChargeType.UsageBased && charge.feature?.id === feature.id)) {
      return {
        isDisabled: true,
        disabledReasonText: t('sharedComponents.alreadyAdded'),
      };
    }

    const overageChargeWithFeature = overageCharges?.charges.find(
      (overageCharge) => overageCharge.feature?.id === feature.id,
    );
    if (overageChargeWithFeature && !addOverageToChargeEnabled) {
      return {
        isDisabled: true,
        disabledReasonText: t('pricing.charges.usageBased.canNotAddUsageForFeatureWithOverage'),
      };
    }

    return { isDisabled: false };
  };

  const chooseFeature = (feature: Feature) => {
    const id = `charges[${index}].feature`;
    setFieldValue(id, feature);
    setFieldTouched(id);
  };

  if (currentCharge.type !== ChargeType.UsageBased) {
    return null;
  }

  const isVolumePricingDisabled = currentCharge.billingModel === BillingModel.UsageBased && shouldUseNewPaygoMechanism;
  const isPackagePricingDisabled = vendorIdentifier === VendorIdentifier.Zuora;

  const fields: Field<SetPriceWizardFormFields>[] = [
    {
      type: 'idle',
      hide: () => !!currentCharge.feature,
      component: <Text.B2 color="secondary">{t('pricing.selectMeteredFeature')}</Text.B2>,
    },
    {
      type: 'idle',
      hide: () => !!currentCharge.feature,
      component: <FeatureAutocomplete isFeatureDisabled={isFeatureDisabled} chooseFeature={chooseFeature} />,
      gridProps: {
        mt: 2,
      },
    },
    {
      type: 'idle',
      hide: () => !currentCharge.feature,
      component: <UsageBasedBillingTypeSelection index={index} />,
    },
    {
      type: 'select',
      label: t('pricing.pricingModelSelectLabel'),
      hide: () => !currentCharge.feature,
      id: `charges[${index}].tiersSchema`,
      dataTestId: `pricing-model-select`,
      handleChange: ({ values, setFieldValue, handleChange }) => {
        return (e: React.ChangeEvent<HTMLSelectElement>) => {
          const tiersSchemaInput = e.target.value as TiersSchema;
          const currentValue = values.charges[index] as UsageBasedChargeType;

          handleChange(e);

          if (tiersSchemaInput !== currentValue.tiersSchema) {
            if (TIERS_SCHEMA_PER_UNIT.includes(tiersSchemaInput)) {
              setFieldValue(`charges[${index}].tiers`, DEFAULT_TIERS_PER_UNIT_BILLING_PERIODS);
            } else {
              setFieldValue(`charges[${index}].tiers`, DEFAULT_TIERS);
            }
            setFieldTouched(`charges[${index}].tiers`, false);
          }

          setFieldValue(`charges[${index}].isBlockPricing`, tiersSchemaInput === TiersSchema.StandardPerBlock);
        };
      },
      values: [
        {
          value: TiersSchema.Standard,
          overrideDisplayValue: t('pricing.tiersSchema.STANDARD'),
          displayValue: (
            <PricingModelSelectItem
              tiersSchema={TiersSchema.Standard}
              title={t('pricing.tiersSchema.STANDARD')}
              subtitle={t('pricing.tiersSchemaSubtitle.STANDARD')}
            />
          ),
        },
        ...(packagePricingModel
          ? [
              {
                value: TiersSchema.StandardPerBlock,
                overrideDisplayValue: t('pricing.tiersSchema.STANDARD_PER_BLOCK'),
                disabled: isPackagePricingDisabled,
                displayValue: (
                  <InformationTooltip
                    arrow
                    placement="top"
                    title={
                      vendorIdentifier === VendorIdentifier.Zuora ? (
                        <Text.B2>{t('pricing.notSupportedInZuora')}</Text.B2>
                      ) : (
                        ''
                      )
                    }>
                    <div>
                      <PricingModelSelectItem
                        tiersSchema={TiersSchema.StandardPerBlock}
                        title={t('pricing.tiersSchema.STANDARD_PER_BLOCK')}
                        subtitle={t('pricing.tiersSchemaSubtitle.STANDARD_PER_BLOCK')}
                      />
                    </div>
                  </InformationTooltip>
                ),
              },
            ]
          : []),
        ...(graduatedPricingModel
          ? [
              {
                value: TiersSchema.GraduatedPerUnit,
                overrideDisplayValue: t('pricing.tiersSchema.GRADUATED_PER_UNIT'),
                disabled: isVolumePricingDisabled,
                displayValue: (
                  <InformationTooltip
                    arrow
                    placement="top"
                    title={isVolumePricingDisabled ? <Text.B2>{t('pricing.comingSoonTiersUsageBased')}</Text.B2> : ''}>
                    <div>
                      <PricingModelSelectItem
                        tiersSchema={TiersSchema.GraduatedPerUnit}
                        title={t('pricing.tiersSchema.GRADUATED_PER_UNIT')}
                        subtitle={t('pricing.tiersSchemaSubtitle.GRADUATED_PER_UNIT')}
                      />
                    </div>
                  </InformationTooltip>
                ),
              },
            ]
          : []),
        ...(volumePerUnit
          ? [
              {
                value: TiersSchema.VolumePerUnit,
                overrideDisplayValue: t('pricing.tiersSchema.VOLUME_PER_UNIT'),
                disabled: isVolumePricingDisabled,
                displayValue: (
                  <InformationTooltip
                    arrow
                    placement="top"
                    title={isVolumePricingDisabled ? <Text.B2>{t('pricing.comingSoonTiersUsageBased')}</Text.B2> : ''}>
                    <div>
                      <PricingModelSelectItem
                        tiersSchema={TiersSchema.VolumePerUnit}
                        title={t('pricing.tiersSchema.VOLUME_PER_UNIT')}
                        subtitle={t('pricing.tiersSchemaSubtitle.VOLUME_PER_UNIT')}
                      />
                    </div>
                  </InformationTooltip>
                ),
              },
            ]
          : []),
        {
          value: TiersSchema.VolumeBulkOfUnits,
          overrideDisplayValue: t('pricing.tiersSchema.VOLUME_BULK_OF_UNITS'),
          disabled: currentCharge.billingModel === BillingModel.UsageBased,
          displayValue: (
            <InformationTooltip
              arrow
              placement="top"
              title={
                currentCharge.billingModel === BillingModel.UsageBased ? (
                  <Text.B2>{t('pricing.comingSoonStairstepUsageBased')}</Text.B2>
                ) : (
                  ''
                )
              }>
              <div>
                <PricingModelSelectItem
                  tiersSchema={TiersSchema.VolumeBulkOfUnits}
                  title={t('pricing.tiersSchema.VOLUME_BULK_OF_UNITS')}
                  subtitle={t('pricing.tiersSchemaSubtitle.VOLUME_BULK_OF_UNITS')}
                />
              </div>
            </InformationTooltip>
          ),
        },
      ],
    },
    {
      type: 'custom',
      gridProps: {
        mt: 4,
      },
      hide: () =>
        !currentCharge.billingModel ||
        !currentCharge.feature ||
        [TiersSchema.Standard, TiersSchema.StandardPerBlock].includes(currentCharge.tiersSchema!),
      render: () => (
        <PricingTiersTable
          key={currentCharge.tiersSchema}
          priceTiersId={`charges[${index}].tiers`}
          tiers={currentCharge.tiers!}
          tiersSchema={currentCharge.tiersSchema!}
          currency={defaultCurrency}
          pricePeriodUnit={pricePeriodUnit}
        />
      ),
    },
    {
      type: 'layout',
      hide: () =>
        !currentCharge.billingModel || !currentCharge.feature || currentCharge.tiersSchema !== TiersSchema.Standard,
      gridProps: {
        mt: 4,
        alignItems: 'baseline',
      },
      fields: getPricePeriodFields({
        pricePeriodsId: `charges[${index}].pricePeriods`,
        billingModel: currentCharge.billingModel,
        pricePeriodUnit,
        currency: defaultCurrency,
        billingCadence,
      }),
    },
    {
      type: 'layout',
      hide: () =>
        !currentCharge.billingModel ||
        !currentCharge.feature ||
        currentCharge.tiersSchema !== TiersSchema.StandardPerBlock,
      gridProps: {
        mt: 4,
        flexDirection: 'column',
        alignSelf: 'stretch',
      },
      contentGridProps: {
        flexDirection: 'column',
      },
      fields: getBlockPricePeriodFields({
        pricePeriodsId: `charges[${index}].pricePeriods`,
        billingModel: currentCharge.billingModel,
        pricePeriodUnit,
        currency: defaultCurrency,
        billingCadence,
      }),
    },
    {
      type: 'custom',
      hide: () => !currentCharge.billingModel || !currentCharge.feature,
      gridProps: {
        mt: 4,
        flexDirection: 'column',
        alignSelf: 'stretch',
      },
      render: (formRenderProps) => (
        <GridFlex.Column container>
          <ChargeResetPeriodConfiguration
            index={index}
            formRenderProps={formRenderProps}
            resetPeriodConfigurationOpen={resetPeriodConfigurationOpen}
            setResetPeriodConfigurationOpen={setResetPeriodConfigurationOpen}
          />
        </GridFlex.Column>
      ),
    },
    {
      type: 'collapse',
      title: (
        <Text.B2 color={hasErrorsInAdvancedSection && !advancedSectionOpen ? 'error' : 'primary'}>
          {t('pricing.advanced')}
        </Text.B2>
      ),
      hide: () =>
        !currentCharge.feature ||
        currentCharge.billingModel !== BillingModel.PerUnit ||
        currentCharge.tiersSchema !== TiersSchema.Standard,
      open: advancedSectionOpen,
      onClick: () => setAdvancedSectionOpen((prevState) => !prevState),
      fields: [
        {
          type: 'layout',
          gridProps: {
            alignItems: 'baseline',
          },
          fields: [
            {
              type: 'text',
              textFieldType: 'number',
              isNumberWithoutSigns: true,
              isNumberWithoutFraction: true,
              placeholder: '1',
              id: `charges[${index}].minUnitQuantity`,
              label: t(`pricing.minQuantity`),
              handleChange:
                ({ setFieldValue, setFieldTouched }) =>
                (e) => {
                  setFieldValue(`charges[${index}].minUnitQuantity`, e.target.value ? Number(e.target.value) : null);
                  // touching the max quantity to trigger validation
                  setFieldTouched(`charges[${index}].maxUnitQuantity`);
                },
            },
            {
              type: 'text',
              textFieldType: 'number',
              isNumberWithoutSigns: true,
              isNumberWithoutFraction: true,
              placeholder: t('sharedComponents.unlimited'),
              id: `charges[${index}].maxUnitQuantity`,
              label: t(`pricing.maxQuantity`),
            },
          ],
        },
      ],
      gridProps: {
        mt: 4,
      },
    },
  ];

  return <>{drawFormFields(fields, formRenderProps)}</>;
}
