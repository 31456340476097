import { useStiggTheme } from '@stigg-theme';
import StiggLogoDark from './layout/sidebar/logo.dark.svg?react';
import StiggLogoLight from './layout/sidebar/logo.light.svg?react';

export function StiggLogo(props: any) {
  const { theme } = useStiggTheme();
  const StiggLogoComponent = theme.isLightTheme ? StiggLogoLight : StiggLogoDark;

  return <StiggLogoComponent {...props} />;
}
