/* eslint-disable no-restricted-imports */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Theme } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DottedText,
  Grid,
  GridFlex,
  IconButton,
  MenuItem,
  Select,
  Text,
} from '@stigg-components';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { PackageEntitlement, PackageStatus, PlanFragment } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useStiggTheme } from '@stigg-theme';
import Table from '../../../../../../../components/table/Table';
import { BasePlanProps, PlanEntitlement } from '../../PackageGrantedEntitlements.types';
import { createHeadCells } from './createHeadCells';
import Loader from '../../../../../../../components/Loader';
import { RootState, useAppDispatch } from '../../../../../../../redux/store';
import {
  clearDialogPlans,
  fetchDialogPlansAction,
  setIsLoadingDialogPlans,
  updatePlanAction,
} from '../../../../../plans/plansSlice';

export type BasePlanGrantedEntitlementsProps = {
  packageEntitlements: PackageEntitlement[];
  childPlanEntitlementsByFeatureId: Map<string, PlanEntitlement>;
  currentPlan: PlanFragment;
  readonly: boolean;
  triggerOverrideEntitlement: (entitlement: PlanEntitlement) => void;
  triggerDeleteEntitlement: (entitlement: PlanEntitlement) => void;
  withReordering?: boolean;
};

export type BasePlanSwitchProps = Pick<
  BasePlanGrantedEntitlementsProps,
  'packageEntitlements' | 'currentPlan' | 'readonly'
>;

export function BasePlanSwitch({ packageEntitlements, currentPlan, readonly }: BasePlanSwitchProps) {
  const noBasePlanValue: BasePlanProps = {
    status: PackageStatus.Published,
    refId: 'stigg__no-plan',
    displayName: t('packages.emptyBasePlan'),
  };
  const dispatch = useAppDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const existingPlans = useSelector((state: RootState) => state.plansReducer.dialogPlans);
  const currentBasePlan = currentPlan.basePlan || noBasePlanValue;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(fetchDialogPlansAction({ environmentId: currentEnvironmentId, paging: { first: 100 } }));
    }

    return () => {
      dispatch(clearDialogPlans());
    };
  }, [dispatch, currentEnvironmentId]);

  const availablePlans = existingPlans?.edges
    ?.filter((plan) => plan.node.refId !== currentPlan.refId)
    ?.filter((plan) => plan.node.product.refId === currentPlan.product.refId)
    .map((plan) => plan.node);

  const onBasePlanChanged = async (event: any) => {
    event.stopPropagation();

    const selectedPlanRefId = event.target.value;
    if (selectedPlanRefId !== currentBasePlan.refId) {
      const parentPlanId = selectedPlanRefId === noBasePlanValue.refId ? null : selectedPlanRefId;

      setIsLoading(true);
      dispatch(setIsLoadingDialogPlans(true));

      await dispatch(
        updatePlanAction({
          planRefId: currentPlan.refId,
          updatePayload: { id: currentPlan.id, parentPlanId },
          silentFetch: true,
        }),
      );

      if (currentEnvironmentId) {
        await dispatch(fetchDialogPlansAction({ environmentId: currentEnvironmentId, paging: { first: 100 } }));
      }

      dispatch(setIsLoadingDialogPlans(false));
      setIsLoading(false);
    }
  };

  const checkIfCircularDependency = (parentPlanRefId?: string) => {
    let isCircularParent;
    let countPlans = 0;
    while (parentPlanRefId) {
      if (parentPlanRefId === currentPlan.refId) {
        isCircularParent = true;
        break;
      }
      parentPlanRefId = availablePlans.find(({ refId }) => refId === parentPlanRefId)?.basePlan?.refId;

      // avoid indefinite loop
      countPlans += 1;
      if (countPlans > availablePlans.length) {
        break;
      }
    }

    return isCircularParent;
  };

  return (
    <GridFlex.RowCenter container item>
      <Grid item mr={1}>
        <Text.B2>{t('entitlements.basePlanEntitlementsTitle')}</Text.B2>
      </Grid>
      <Grid item>
        {readonly && <Text.B2 color="primary">{currentBasePlan.displayName}</Text.B2>}
        {!readonly && (
          <Select
            variant="inline"
            onChange={onBasePlanChanged}
            labelId="basePlan"
            placeholder={currentBasePlan.displayName}
            IconComponent={isLoading ? () => null : undefined}
            renderValue={(value: any) => {
              const currentParentPlan =
                value === noBasePlanValue.refId
                  ? noBasePlanValue
                  : (availablePlans?.find(({ refId }) => refId === value) ?? noBasePlanValue);

              if (isLoading) {
                return (
                  <Box p={1}>
                    <Loader size={16} />
                  </Box>
                );
              }

              return (
                <DottedText pl={1} color={currentParentPlan.refId === noBasePlanValue.refId ? 'disabled' : 'primary'}>
                  {currentParentPlan.status === PackageStatus.Draft
                    ? t('sharedComponents.draftSuffix', { planName: currentParentPlan.displayName })
                    : currentParentPlan.displayName}
                </DottedText>
              );
            }}
            value={currentBasePlan.refId}>
            <MenuItem value={noBasePlanValue.refId} onClick={(e) => e.stopPropagation()}>
              <Text.B2 color="primary">{noBasePlanValue.displayName}</Text.B2>
            </MenuItem>
            {!isEmpty(availablePlans) &&
              availablePlans.map((plan) => {
                const isCircularParent = checkIfCircularDependency(plan?.basePlan?.refId);

                return (
                  <MenuItem
                    key={plan.id}
                    value={plan.refId}
                    disabled={isCircularParent}
                    onClick={(e) => e.stopPropagation()}>
                    <GridFlex.Column>
                      <Text.B2 color="primary">
                        {plan.status === PackageStatus.Draft
                          ? t('sharedComponents.draftSuffix', { planName: plan.displayName })
                          : plan.displayName}
                      </Text.B2>
                      <Text.Caption>
                        {(plan.entitlements?.length || 0) + (plan.inheritedEntitlements?.length || 0)}{' '}
                        {t('entitlements.features')}
                        {isCircularParent ? ` (${t('entitlements.circularDependency')})` : ''}
                      </Text.Caption>
                    </GridFlex.Column>
                  </MenuItem>
                );
              })}
          </Select>
        )}
      </Grid>
      {!isLoading && currentBasePlan.refId !== noBasePlanValue.refId && (
        <Grid item ml={2}>
          <Text.Sub2>
            {t('entitlements.basePlanEntitlementsSubtitle', {
              numberOfEntitlements: packageEntitlements.length.toString(),
            })}
          </Text.Sub2>
        </Grid>
      )}
    </GridFlex.RowCenter>
  );
}

export function BasePlanGrantedEntitlements({
  packageEntitlements,
  childPlanEntitlementsByFeatureId,
  currentPlan,
  readonly,
  triggerOverrideEntitlement,
  triggerDeleteEntitlement,
  withReordering,
}: BasePlanGrantedEntitlementsProps) {
  const { theme } = useStiggTheme();
  const [open, setOpen] = useState(false);
  const isLoadingPlans = useSelector((state: RootState) => state.plansReducer.isLoadingDialogPlans);
  const hasBasePlan = !!currentPlan?.basePlan;

  return (
    <GridFlex.Column container aria-label="expand row" onClick={() => setOpen(!open)}>
      <Grid item onClick={(e) => e.stopPropagation()}>
        <Accordion
          disableGutters
          square
          expanded={open}
          sx={{ width: '100%', padding: 0, backgroundColor: 'transparent' }}
          onClick={() => setOpen((prev) => !prev)}>
          <AccordionSummary sx={{ width: '100%', height: 50, padding: 0, backgroundColor: 'transparent' }}>
            <GridFlex.RowCenter container item wrap="nowrap" {...(withReordering ? { pl: '34px' } : { pl: 4, pr: 2 })}>
              <BasePlanSwitch currentPlan={currentPlan} packageEntitlements={packageEntitlements} readonly={readonly} />

              {hasBasePlan && packageEntitlements?.length > 0 && (
                <Grid item mr={7}>
                  <IconButton size="small">{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</IconButton>
                </Grid>
              )}
            </GridFlex.RowCenter>
          </AccordionSummary>
          <AccordionDetails sx={{ width: '100%', padding: 0, backgroundColor: 'transparent' }}>
            <Table
              hasBorder={false}
              withDragHandle={withReordering}
              isLoading={isLoadingPlans && open}
              skeletonOptions={{ backgroundColor: theme.itamar.palette.action.focus }}
              headlessTable
              backgroundColor={(theme: Theme) => theme.itamar.palette.background.default}
              tableLayout="auto"
              label=""
              headCells={createHeadCells(
                triggerOverrideEntitlement,
                triggerDeleteEntitlement,
                childPlanEntitlementsByFeatureId,
                readonly,
                currentPlan,
              )}
              data={isLoadingPlans ? [] : packageEntitlements}
              isHeaderComponentChild
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </GridFlex.Column>
  );
}
