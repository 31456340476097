import styled from 'styled-components/macro';
import FadedGridSVG from '@assets/images/fadedGrid.svg?react';

export const Container = styled.div`
  background: ${({ theme }) =>
    theme.isLightTheme
      ? theme.itamar.palette.background.lightBackground
      : theme.itamar.palette.background.darkBackground};
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const FadedGrid = styled(FadedGridSVG)`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const Rectangle = styled.div`
  position: absolute;
  top: 76%;
  left: -8.6%;
  width: 267px;
  height: 102px;
  background: linear-gradient(118.61deg, rgba(169, 193, 255, 0.408) 5.88%, rgba(169, 193, 255, 0) 89.53%);
  filter: blur(4.8px);
  border-radius: 1.94px;
`;

export const FormWrapper = styled.div`
  width: 496px;
  background: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground2};
  box-shadow: ${({ theme }) => theme.itamar.palette.shadow.paper};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: 80px;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
