import { gql } from '@apollo/client';
import { FetchCustomerSyncStatesQuery, FetchCustomerSyncStatesQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { SYNC_STATE_FRAGMENT } from '../../packages/plans/queries/syncStateFragment';
const QUERY = gql`
  query FetchCustomerSyncStates($filter: CustomerFilter!) {
    customers(filter: $filter) {
      edges {
        node {
          billingId
          defaultPaymentMethodId
          defaultPaymentMethodType
          defaultPaymentMethodLast4Digits
          defaultPaymentExpirationMonth
          defaultPaymentExpirationYear
          billingLinkUrl
          crmHubspotCompanyId
          crmHubspotCompanyUrl
          syncStates {
            ...SyncStateFragment
          }
        }
      }
    }
  }
  ${SYNC_STATE_FRAGMENT}
`;

export function watchCustomerSyncStates(customerId: string, pollInterval: number) {
  return apolloClient.watchQuery<FetchCustomerSyncStatesQuery, FetchCustomerSyncStatesQueryVariables>({
    query: QUERY,
    fetchPolicy: 'no-cache',
    pollInterval,
    variables: {
      filter: {
        id: {
          eq: customerId,
        },
      },
    },
  });
}
