import { GridFlex, Card, OptionsDropdown, Chip, Grid, Text, ConfirmationDialog, LongText } from '@stigg-components';
import { t } from 'i18next';
import { useState } from 'react';
import { Trash2 } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { IntegrationListItemFragment } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../redux/store';
import { deleteIntegrationByIdAction } from '../integrationsSlice';
import { AvailableIntegration } from '../models';
import { VendorIdentifier } from '../types';

const CardWrapper = styled(Card)<{ $isComingSoon?: boolean; $isDisabled?: boolean }>`
  position: relative;
  padding: 16px;
  min-height: 140px;
  cursor: auto;
  transition: all 0.3s;

  ${({ $isComingSoon, $isDisabled, theme }) =>
    $isComingSoon
      ? css`
          border-color: ${theme.itamar.palette.action.disabledBackground} !important;
          background-color: ${theme.itamar.palette.action.disabled};
        `
      : $isDisabled
        ? css`
            cursor: default;
            background-color: ${theme.itamar.palette.action.disabled};
          `
        : css`
            cursor: pointer;

            &:hover {
              box-shadow: ${theme.itamar.palette.shadow.lightShadow};
              background-color: ${theme.itamar.palette.background.default};
            }
          `}
`;

export const IntegrationIcon = styled.img`
  width: 50px;
  height: 50px;
`;

type IntegrationCardProps = {
  availableIntegration: AvailableIntegration;
  integration?: IntegrationListItemFragment;
  hideOptions?: boolean;
  onAddIntegration?: (vendorIdentifier: VendorIdentifier) => void;
  isDisabled?: boolean;
};

export function IntegrationCard({
  availableIntegration: { getDescription, icon, isComingSoon, name, beta, recommended, vendorIdentifier, vendorType },
  integration,
  hideOptions,
  onAddIntegration,
  isDisabled,
}: IntegrationCardProps) {
  const dispatch = useAppDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (!shouldDelete) {
      setDeleteDialogOpen(false);
      return;
    }
    if (integration) {
      await dispatch(deleteIntegrationByIdAction(integration.id));
    }

    setDeleteDialogOpen(false);
  };

  return (
    <CardWrapper
      onClick={() => !isDisabled && !isComingSoon && onAddIntegration?.(vendorIdentifier)}
      $isDisabled={isDisabled}
      $isComingSoon={isComingSoon}>
      <Grid container alignItems="flex-start" justifyContent="space-between" wrap="nowrap" mb={2}>
        <GridFlex.RowCenter>
          <Grid item mr={2}>
            <IntegrationIcon src={icon} alt={name} />
          </Grid>

          <GridFlex.Column rowGap={1}>
            <GridFlex.Row alignItems="flex-end">
              <Text.H6 fontSize={18} color="primary" $bold>
                {name}
              </Text.H6>
              {beta && (
                <Text.Caption fontSize={10} color="secondary" ml={1}>
                  {t('integrations.beta')}
                </Text.Caption>
              )}
            </GridFlex.Row>
            <Text.Caption>{t('integrations.vendorType', { context: vendorType })}</Text.Caption>
          </GridFlex.Column>
        </GridFlex.RowCenter>

        <Grid item>
          <Grid container alignItems="center">
            <Grid item sx={{ position: 'absolute', top: 8, right: 8 }}>
              {isDisabled ? (
                <Chip size="small" label={t('integrations.installed')} color="disabled" textColor="white" />
              ) : isComingSoon ? (
                <Chip size="small" label={t('sharedComponents.comingSoon')} color="disabled" textColor="white" />
              ) : recommended ? (
                <Chip size="small" label={t('integrations.recommended')} color="#E7F8E8" textColor="success.main" />
              ) : null}
            </Grid>

            {!hideOptions && !isDisabled && integration && (
              <Grid item>
                <OptionsDropdown
                  options={[
                    {
                      icon: Trash2,
                      text: t('integrations.deleteButtonText'),
                      onClick: () => setDeleteDialogOpen(true),
                    },
                  ]}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <LongText variant="body2" maxLines={3} tooltipPlacement="bottom">
        {getDescription()}
      </LongText>

      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDialogClose}
        title={t('integrations.delete')}
        content={t('integrations.deleteConfirmation')}
      />
    </CardWrapper>
  );
}
