import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import remove from 'lodash/remove';
import { ProductForEditFragment, ProductFragment, ProductListItemFragment } from '@stigg-types/apiTypes';
import {
  createProduct,
  deleteProductById,
  updateProduct,
  createOrUpdateAwsMarketplaceProduct,
  duplicateProduct,
} from './mutations';
import { fetchProducts, fetchProductByRefId } from './queries';
import { fetchProductForEditByRefId } from './queries/fetchProductForEditByRefId';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';

export interface ProductsState {
  isLoading: boolean;
  products: ProductListItemFragment[];
  product: ProductFragment;
  edit: {
    isLoading: boolean;
    product: ProductForEditFragment;
  };
}

const initialState: ProductsState = {
  isLoading: false,
  products: [] as ProductListItemFragment[],
  product: {} as ProductFragment,
  edit: {
    isLoading: false,
    product: {} as ProductForEditFragment,
  },
};

const fetchProductsAction = createAppAsyncThunk('fetchProducts', fetchProducts);
const createProductAction = createAppAsyncThunk('createProduct', createProduct);
const deleteProductByIdAction = createAppAsyncThunk('deleteProductById', deleteProductById);
const updateProductAction = createAppAsyncThunk('updateProduct', updateProduct);
const fetchProductByRefIdAction = createAppAsyncThunk('fetchProductsById', fetchProductByRefId);
const fetchProductForEditByRefIdAction = createAppAsyncThunk('fetchProductForEditByRefId', fetchProductForEditByRefId);

const createOrUpdateAwsMarketplaceProductAction = createAppAsyncThunk(
  'createOrUpdateAwsMarketplaceProduct',
  createOrUpdateAwsMarketplaceProduct,
);

const duplicateProductAction = createAppAsyncThunk('duplicateProduct', duplicateProduct);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetProductsState: () => initialState,
    resetProduct: (state) => {
      state.product = {} as ProductFragment;
    },
    resetProductForEdit: (state) => {
      state.edit.product = {} as ProductForEditFragment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsAction.fulfilled, (state, action) => {
        state.products = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchProductsAction.pending, (state, action) => {
        if (!action?.meta?.arg?.silentFetch) {
          state.isLoading = true;
        }
      })
      .addCase(fetchProductsAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(createProductAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProductAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createProductAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchProductByRefIdAction.pending, (state, action) => {
        if (!action?.meta?.arg?.silentFetch) {
          state.isLoading = true;
        }
      })
      .addCase(fetchProductByRefIdAction.fulfilled, (state, { payload }) => {
        state.product = payload || ({} as ProductFragment);
        state.isLoading = false;
      })
      .addCase(fetchProductByRefIdAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchProductForEditByRefIdAction.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(fetchProductForEditByRefIdAction.fulfilled, (state, { payload }) => {
        state.edit.product = payload || ({} as ProductForEditFragment);
        state.edit.isLoading = false;
      })
      .addCase(fetchProductForEditByRefIdAction.rejected, (state) => {
        state.edit.isLoading = false;
      });

    builder.addCase(updateProductAction.fulfilled, (state, { payload }) => {
      state.product = merge(state.product, payload);
      state.products = state.products.map((product) => {
        if (product.id === payload?.id) {
          return merge(product, payload);
        }

        return product;
      });
    });

    builder.addCase(deleteProductByIdAction.fulfilled, (state, { payload }) => {
      remove(state.products, (product) => product.id === payload?.id);
    });
  },
});

const { resetProductsState, resetProduct, resetProductForEdit } = productsSlice.actions;
export {
  resetProductsState,
  resetProduct,
  resetProductForEdit,
  fetchProductsAction,
  createProductAction,
  fetchProductByRefIdAction,
  fetchProductForEditByRefIdAction,
  deleteProductByIdAction,
  updateProductAction,
  createOrUpdateAwsMarketplaceProductAction,
  duplicateProductAction,
};
export default productsSlice.reducer;
