import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

export function useStiggProviderTheme() {
  const theme = useTheme();

  const stiggThemeProps = useMemo(
    () =>
      theme.isLightTheme
        ? {}
        : {
            theme: {
              border: { radius: theme.itamar.border.radius },
              palette: {
                backgroundPaper: theme.itamar.palette.background.default,
                backgroundSection: theme.itamar.palette.background.paper,
                backgroundHighlight: theme.itamar.palette.success.background,
                primary: theme.itamar.palette.primary.main,
                primaryLight: theme.itamar.palette.primary.light,
                primaryDark: theme.itamar.palette.primary.dark,
                error: theme.itamar.palette.error.main,
                errorDark: theme.itamar.palette.error.dark,
                success: theme.itamar.palette.success.main,
                successDark: theme.itamar.palette.success.dark,
                warning: theme.itamar.palette.warning.main,
                switchBorder: theme.itamar.palette.other.outlineBorder,
                outlinedBorder: theme.itamar.palette.other.outlineBorder,
                outlinedHoverBackground: theme.itamar.palette.primary.outlinedHoverBackground,
                white: theme.itamar.palette.background.darkBackground,
                backgroundButton: theme.itamar.palette.background.paper,
                outlinedRestingBorder: theme.itamar.palette.other.outlineBorder,
                text: {
                  primary: theme.itamar.palette.text.primary,
                  secondary: theme.itamar.palette.text.secondary,
                  disabled: theme.itamar.palette.text.disabled,
                  tertiary: theme.itamar.palette.text.tertiary,
                },
              },
            },
          },
    [theme],
  );

  return stiggThemeProps;
}
