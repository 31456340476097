import { Grid, Chip } from '@stigg-components';
import { t } from 'i18next';
import { IntegrationListItemFragment } from '@stigg-types/apiTypes';
import { isIntegrationActive } from './isIntegrationActive';

export function IntegrationStatus({ integration }: { integration: IntegrationListItemFragment }) {
  return (
    <Grid item>
      {isIntegrationActive(integration) ? (
        <Chip size="small" variant="filled" label={t('integrations.active')} color="primary" textColor="white" />
      ) : (
        <Chip size="small" label={t('integrations.incomplete')} color="warning" textColor="white" />
      )}
    </Grid>
  );
}
