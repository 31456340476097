import { ReactNode } from 'react';
import { Grid, GridFlex, Text, LongText } from '@stigg-components';

export type BaseEntity = {
  displayName: string;
  refId: string;
};

export type EntityValueCellProps = {
  entity: BaseEntity;
  icon?: ReactNode;
  suffix?: ReactNode;
  disabledText?: string;
  dataTestId?: string;
};

export const EntityValueCell = ({ entity, icon, disabledText, suffix, dataTestId }: EntityValueCellProps) => {
  const { displayName, refId } = entity;

  return (
    <GridFlex.RowSpaceBetween $fullWidth alignItems="flex-start">
      <GridFlex.RowMiddle container gap={4} wrap="nowrap">
        {icon ? <GridFlex.Column item>{icon}</GridFlex.Column> : null}
        <GridFlex.Column container item>
          <Grid item gap={2}>
            <LongText wordBreak>
              <Text.B2 data-testid={dataTestId}>{displayName}</Text.B2>
              {disabledText ? <Text.B2>{disabledText}</Text.B2> : null}
            </LongText>
          </Grid>
          <Grid item>
            <LongText wordBreak>
              <Text.B2 color="secondary">{refId}</Text.B2>
            </LongText>
          </Grid>
        </GridFlex.Column>
      </GridFlex.RowMiddle>
      {suffix}
    </GridFlex.RowSpaceBetween>
  );
};
