import { MenuItem, Select, Text } from '@stigg-components';
import map from 'lodash/map';
import { EntitlementResetPeriod } from '@stigg-types/apiTypes';
import { resetPeriods, ResetPeriodValue, ENTITLEMENT_NO_RESET_VALUE } from './EntitlementsResetPeriods.utils';

export type EntitlementsResetPeriodsSelectOptions = EntitlementResetPeriod | typeof ENTITLEMENT_NO_RESET_VALUE;

type EntitlementsResetPeriodsSelectProps = {
  label?: string;
  name: string;
  labelId?: string;
  onChange: (event: any) => void;
  value: EntitlementsResetPeriodsSelectOptions | null;
  defaultSelection?: EntitlementsResetPeriodsSelectOptions;
  i18nPath: string;
  withNoResetOption?: boolean;
  resetPeriodsFilter?: (resetPeriod: ResetPeriodValue) => boolean;
};

export function EntitlementsResetPeriodsSelect({
  label,
  name,
  labelId,
  onChange,
  value,
  i18nPath,
  withNoResetOption,
  defaultSelection,
  resetPeriodsFilter = () => true,
}: EntitlementsResetPeriodsSelectProps) {
  const allResetPeriods = resetPeriods(i18nPath, withNoResetOption).filter(resetPeriodsFilter);

  return (
    <Select
      label={label}
      name={name}
      fullWidth
      labelId={labelId}
      onChange={onChange}
      value={value ?? defaultSelection}
      dataTestId="reset-period-select-input"
      renderValue={(value: any) => {
        const resetPeriod = allResetPeriods.find((v) => v.value === value);
        return <Text.B2>{resetPeriod?.text}</Text.B2>;
      }}>
      {map(allResetPeriods, (resetPeriod: { text: string; value: EntitlementResetPeriod }) => (
        <MenuItem key={resetPeriod.value} value={resetPeriod.value}>
          <Text.B2 color="primary" data-testid={`reset-period-select-${resetPeriod.text.toLowerCase()}`}>
            {resetPeriod.text}
          </Text.B2>
        </MenuItem>
      ))}
    </Select>
  );
}
