/* eslint-disable */
import { SyncStateFragment, SyncStatus, VendorIdentifier, VendorType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useIntegrations } from '@stigg-common';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import { AlertCircle, CheckCircle } from 'react-feather';
import { getIconColor, border } from '@stigg-theme';
import {
  Box,
  CircularProgress,
  CodeTypographyText,
  ExternalLink,
  Grid,
  GridFlex,
  InformationTooltip,
  Link,
  Text,
} from '.';
import { ClipboardChip } from './clipboard/ClipboardChip';
import StripeIcon from '../assets/icons/StripeLetter.svg?react';
import ZuoraIcon from '../assets/icons/Zuora.svg?react';
import HubspotIcon from '../assets/icons/Hubspot1.svg?react';
import AWSMarketplaceIcon from '../assets/icons/AWSMarketplace.svg?react';
import SalesforceIcon from '../assets/icons/Salesforce.svg?react';
import cloneDeep from 'lodash/cloneDeep';
import { getReadableVendorIdentifier, useIntegrationSyncStateLabel } from '../utils/integrationUtils';
import { MultipleIntegrationIdLink } from './MultipleIntegrationIdLink';

const DOCS_BASE_URL = 'https://docs.stigg.io/docs';

const formatContactSupportLink = (vendorName: string, entityType: string, refId?: string) => {
  let subject = encodeURIComponent(`${vendorName} sync error in ${entityType} ${refId}`);
  let body = encodeURIComponent(
    `Hi, I noticed that syncing ${entityType} ${refId} to ${vendorName} failed. Id appreciate your assistance in looking into the issue. Thanks`,
  );
  return `mailto:support@stigg.io?subject=${subject}&body=${body}`;
};

const StyledStripeIcon = styled(StripeIcon)`
  width: 24px;
  height: 24px;
`;

const StyledZuoraIcon = styled(ZuoraIcon)`
  width: 32px;
  height: 32px;
`;

const StyledHubspotIcon = styled(HubspotIcon)`
  width: 40px;
  height: 40px;
`;

const StyledAWSMarketplaceIcon = styled(AWSMarketplaceIcon)`
  width: 24px;
  height: 24px;
`;

const StyledSalesforceIcon = styled(SalesforceIcon)`
  width: 24px;
  height: 24px;
`;

const HighlightOnHoverRow = styled(GridFlex.RowCenter)`
  height: 24px;
  border-radius: 4px;
  &:hover {
    --hoverHighlight: 100%;
  }
  color: ${({ theme }) => theme.itamar.palette.primary.main};
  background:
    linear-gradient(#0000 50%, #fff 0) 0 var(--hoverHighlight, 0) / 100% 200% no-repeat,
    linear-gradient(#0000 50%, #327eee14 0) 0 var(--hoverHighlight, 0) / 100% 200% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.2s;
`;

const CodeTypographyCard = styled(Box)`
  background-color: ${({ theme }) => theme.itamar.palette.grey[50]};
  border-radius: ${border.radius};
`;

const VendorIcon = styled.span<{ $syncStatus?: SyncStatus }>`
  margin-right: 4px;
  @keyframes sync-animation-keyframes {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  display: flex;
  ${({ $syncStatus }) =>
    $syncStatus !== SyncStatus.Success &&
    css`
      path {
        fill: #99a5c4;
      }
    `}
  ${({ $syncStatus }) =>
    $syncStatus === SyncStatus.Pending &&
    css`
      animation: sync-animation-keyframes 1.5s infinite;
    `}
`;

const getSyncStatusIcon = (syncState?: SyncStateFragment) => {
  switch (syncState?.status) {
    case SyncStatus.Pending:
      return <CircularProgress size={16} sx={{ color: getIconColor('active') }} />;
    case SyncStatus.Success:
      return <CheckCircle color={getIconColor('success')} size={16} />;
    case SyncStatus.Error:
      return <AlertCircle color={getIconColor('error')} size={16} />;
    default:
      return null;
  }
};

const getIntegrationIcon = (syncState?: SyncStateFragment) => {
  switch (syncState?.integration?.vendorIdentifier) {
    case VendorIdentifier.Stripe:
      return StyledStripeIcon;
    case VendorIdentifier.Zuora:
      return StyledZuoraIcon;
    case VendorIdentifier.Hubspot:
      return StyledHubspotIcon;
    default:
      return null;
  }
};

const getIntegrationIconByVendorIdentifier = (vendorIdentifier: VendorIdentifier) => {
  switch (vendorIdentifier) {
    case VendorIdentifier.Stripe:
      return StyledStripeIcon;
    case VendorIdentifier.Zuora:
      return StyledZuoraIcon;
    case VendorIdentifier.Hubspot:
      return StyledHubspotIcon;
    case VendorIdentifier.AwsMarketplace:
      return StyledAWSMarketplaceIcon;
    case VendorIdentifier.Salesforce:
      return StyledSalesforceIcon;
    default:
      return null;
  }
};

const getNotSyncedTooltipLinkUrl = (vendorIdentifier: VendorIdentifier) => {
  switch (vendorIdentifier) {
    case VendorIdentifier.Hubspot:
      return DOCS_BASE_URL + '/hubspot#entities-that-are-not-synced-to-hubspot';
    case VendorIdentifier.Stripe:
      return DOCS_BASE_URL + '/stripe#entities-that-are-not-synced-to-stripe';
    case VendorIdentifier.Zuora: // TODO: Update Zuora Docs
      return DOCS_BASE_URL + '/zuora#entities-that-are-not-synced-to-zuora';
    default:
      return undefined;
  }
};

const getIntegrationIdState = (
  entityType: IntegrationIdEntityType,
  isSyncingCustomerAndSubscriptions: boolean,
  syncState?: SyncStateFragment,
  refId?: string,
): {
  error?: string | null;
  status: string;
  tooltipText?: string | React.ReactNode;
  tooltipLinkText?: string;
  tooltipLinkUrl?: string;
} => {
  if (!syncState) {
    return {
      status: 'Not synced',
    };
  }
  const {
    integration: { vendorIdentifier },
  } = syncState;
  const readableEntityType = entityType.toLowerCase();
  const readableVendorIdentifier = getReadableVendorIdentifier(vendorIdentifier);

  switch (syncState.status) {
    case SyncStatus.NoSyncRequired:
      let tooltipText = `This ${readableEntityType} won't be synced to ${readableVendorIdentifier}`;
      if (entityType === 'CUSTOMER') {
        tooltipText = isSyncingCustomerAndSubscriptions
          ? t('integrations.customerNotSynced')
          : t('integrations.customerNotSyncedContactSupport');
      } else if (entityType === 'SUBSCRIPTION') {
        tooltipText = isSyncingCustomerAndSubscriptions
          ? t('integrations.subscriptionNotSynced', { vendorIdentifier: readableVendorIdentifier })
          : t('integrations.subscriptionNotSyncedContactSupport', { vendorIdentifier: readableVendorIdentifier });
      }

      return {
        status: 'Not synced',
        tooltipText,
        // tooltipLinkText: 'Learn more',
        // tooltipLinkUrl: getNotSyncedTooltipLinkUrl(vendorIdentifier),
      };
    case SyncStatus.Pending:
      return {
        status: 'Sync in progress...',
      };
    case SyncStatus.Success:
      return {
        status: 'In sync',
      };
    default:
      return {
        error: syncState?.error,
        status: 'Sync failed',
        tooltipLinkText: 'Contact support',
        tooltipLinkUrl: formatContactSupportLink(readableVendorIdentifier, readableEntityType, refId),
      };
  }
};

type IntegrationIdEntityType = 'CUSTOMER' | 'PLAN' | 'ADDON' | 'SUBSCRIPTION' | 'COUPON';
export const MULTIPLE_BILLING_INTEGRATIONS_ENTITIES: IntegrationIdEntityType[] = ['PLAN', 'ADDON', 'COUPON'];

type IntegrationIdLinkProps = {
  vendorCategory: 'BILLING' | 'CRM';
  entityType: IntegrationIdEntityType;
  syncStates?: SyncStateFragment[] | null;
  refId?: string;
  to: {
    integrationId?: string | null;
    integrationLinkUrl?: string | null;
  };
};

const IntegrationId = ({
  integrationId,
  syncState,
  entityType,
  refId,
  isSyncingCustomerAndSubscriptions,
}: {
  integrationId?: string | null;
  syncState?: SyncStateFragment;
  entityType: IntegrationIdEntityType;
  refId?: string;
  isSyncingCustomerAndSubscriptions: boolean;
}) => {
  if (syncState && syncState.status === SyncStatus.Success && !integrationId) {
    syncState = cloneDeep(syncState);
    syncState.status = SyncStatus.NoSyncRequired;
  }

  const { error, status, tooltipText, tooltipLinkText, tooltipLinkUrl } = getIntegrationIdState(
    entityType,
    isSyncingCustomerAndSubscriptions,
    syncState,
    refId,
  );
  const IntegrationIcon = getIntegrationIcon(syncState);
  const readableVendorIdentifier = syncState ? useIntegrationSyncStateLabel(syncState) : undefined;
  const noSyncRequired = syncState && syncState.status === SyncStatus.NoSyncRequired;

  return (
    <InformationTooltip
      style={{ cursor: 'pointer' }}
      arrow
      placement={integrationId ? 'top' : 'right'}
      $padding={integrationId ? 2 : 4}
      $maxWidth={400}
      title={
        <>
          <GridFlex.RowSpaceBetween>
            <GridFlex.RowCenter>
              {(integrationId || tooltipText) && (
                <Text.B2 mr={4}>
                  {integrationId && isSyncingCustomerAndSubscriptions
                    ? `Open in ${readableVendorIdentifier}`
                    : tooltipText}
                </Text.B2>
              )}
              {syncState && syncState.status !== SyncStatus.NoSyncRequired && (
                <>
                  {getSyncStatusIcon(syncState)}
                  <Text.B2 ml={2} color="secondary">
                    {status}
                  </Text.B2>
                </>
              )}
            </GridFlex.RowCenter>
            {syncState?.status === SyncStatus.Error && error && (
              <GridFlex.Row alignItems="flex-end">
                <ClipboardChip copy={error} />
              </GridFlex.Row>
            )}
          </GridFlex.RowSpaceBetween>
          {error && (
            <Text.B2 my={2}>
              <CodeTypographyCard p={2}>
                <CodeTypographyText color="secondary">{error}</CodeTypographyText>
              </CodeTypographyCard>
            </Text.B2>
          )}
          {!integrationId && tooltipLinkText && (
            <Box mt={2}>
              <ExternalLink label={tooltipLinkText} url={tooltipLinkUrl!} />
            </Box>
          )}
        </>
      }>
      <HighlightOnHoverRow data-testid={`sync-state-${syncState?.integration?.vendorIdentifier}-${syncState?.status}`}>
        {!noSyncRequired && IntegrationIcon && (
          <VendorIcon $syncStatus={syncState?.status}>
            <IntegrationIcon />
          </VendorIcon>
        )}

        <Text.B2 color={integrationId ? 'inherit' : 'disabled'} ml={noSyncRequired ? 2 : 0}>
          {integrationId || status}
        </Text.B2>

        {syncState?.status === SyncStatus.Error && (
          <Grid item ml={2}>
            {getSyncStatusIcon(syncState)}
          </Grid>
        )}
      </HighlightOnHoverRow>
    </InformationTooltip>
  );
};

export function IntegrationIdWithIcon({
  integrationId,
  integrationType,
  tooltipTitle,
  integrationLinkUrl,
}: {
  integrationId?: string | null;
  integrationType?: VendorIdentifier;
  tooltipTitle?: ReactNode;
  integrationLinkUrl?: string;
}) {
  const IntegrationIcon = integrationType ? getIntegrationIconByVendorIdentifier(integrationType) : undefined;

  const comp = (
    <InformationTooltip
      arrow
      placement={integrationId ? 'top' : 'right'}
      $padding={integrationId ? 2 : 4}
      $maxWidth={400}
      title={tooltipTitle ? <Text.B2 color="primary">{tooltipTitle}</Text.B2> : undefined}>
      <HighlightOnHoverRow>
        <Text.B2 color={integrationLinkUrl ? 'primary.main' : integrationId ? 'primary' : 'disabled'}>
          {integrationId || 'Not synced'}
        </Text.B2>

        {IntegrationIcon ? (
          <VendorIcon $syncStatus={integrationId ? SyncStatus.Success : undefined}>
            <IntegrationIcon />
          </VendorIcon>
        ) : null}
      </HighlightOnHoverRow>
    </InformationTooltip>
  );

  if (integrationLinkUrl) {
    return (
      <Link href={integrationLinkUrl} target="_blank" onClick={(e) => e.stopPropagation()} display="inline-block">
        {comp}
      </Link>
    );
  }

  return comp;
}

export function IntegrationIdLink({
  vendorCategory,
  entityType,
  syncStates,
  refId,
  to: { integrationId, integrationLinkUrl },
}: IntegrationIdLinkProps) {
  const { isSyncingCustomerAndSubscriptions } = useIntegrations();
  const vendorType = vendorCategory === 'BILLING' ? VendorType.Billing : VendorType.Crm;
  const vendorSyncStates = syncStates?.filter((integration) => integration.integration?.vendorType === vendorType);

  if (MULTIPLE_BILLING_INTEGRATIONS_ENTITIES.includes(entityType) && vendorSyncStates && vendorSyncStates.length > 1) {
    return <MultipleIntegrationIdLink syncStates={vendorSyncStates} />;
  }

  const syncState = vendorSyncStates?.[0];

  const integrationIdElement = (
    <IntegrationId
      integrationId={integrationId}
      entityType={entityType}
      syncState={syncState}
      refId={refId}
      isSyncingCustomerAndSubscriptions={isSyncingCustomerAndSubscriptions}
    />
  );

  if (!integrationId) {
    return integrationIdElement;
  }

  return (
    <Link
      href={integrationLinkUrl || undefined}
      target="_billing_tab"
      onClick={(e) => e.stopPropagation()}
      display="inline-block">
      {integrationIdElement}
    </Link>
  );
}
