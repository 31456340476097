import { useState } from 'react';
import { Checkbox, Form, Text } from '@stigg-components';
import * as Yup from 'yup';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { urlFriendlyValidation } from '@stigg-common';
import { Environment, EnvironmentType } from '@stigg-types/apiTypes';
import { AccountPermissionActions, useAccountPermissionCheck } from '@stigg-permissions';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Trans } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../redux/store';
import Loader from '../../../../components/Loader';
import { ENVIRONMENT_DEFAULT_COLOR } from './EnvironmentSwitch';
import { mergeEnvironmentAction } from '../../accountsSlice';
import { useEnvironmentFields } from './useEnvironmentFields';
import { EnvironmentSelectItem } from './EnvironmentSelectItem';

const ENVIRONMENTS_MENU_MAX_HEIGHT = 280;
const ENVIRONMENTS_COLORS = ['#4CAF50', '#FFB547', '#F88078', '#327EEE', '#784CAF', '#47DEFF', '#FFA37C', '#EE32D0'];

const validationSchema = () =>
  Yup.object().shape({
    displayName: urlFriendlyValidation(),
    description: Yup.string().nullable(true),
    copyFromExisting: Yup.boolean().required(t('fieldValidationMessages.required')),
    copyFromSlug: Yup.string().when(['copyFromExisting'], {
      is: (copyFromExisting: boolean) => copyFromExisting,
      then: (schema: any) => schema.required(t('fieldValidationMessages.required')),
      otherwise: (schema: any) => schema.nullable(true),
    }),
  });

function findEnvironmentColor(environments: Environment[]) {
  const currentEnvironmentColors = new Set(environments.map((env) => env.color?.toLowerCase()));
  return ENVIRONMENTS_COLORS.find((color) => !currentEnvironmentColors.has(color.toLowerCase()));
}

export type EnvironmentFormFields = {
  color: string;
  displayName: string;
  description: string;
  type: EnvironmentType;
  copyFromExisting?: boolean;
  copyFromSlug?: string;
};

export default function EnvironmentDetailsForm({
  onSubmit,
  onCancel,
  environment,
  copyFromSlug,
}: {
  onSubmit: (values: EnvironmentFormFields) => Promise<void>;
  onCancel: () => void;
  environment?: Environment;
  copyFromSlug?: string;
}) {
  const dispatch = useAppDispatch();
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const isLoadingEnvironments = useSelector((state: RootState) => state.accountReducer.isLoadingEnvironments);
  const [isCopyingEnv, setIsCopyingEnv] = useState(false);
  const [sourceAndDestinationNames, setSourceAndDestinationNames] = useState(['', '']);

  const allowCreateNonProductionEnvironment = useAccountPermissionCheck(
    AccountPermissionActions.CreateNonProductionEnvironment,
  );
  const isEdit = !!environment;

  const initialValues: EnvironmentFormFields = {
    displayName: environment?.displayName || '',
    description: environment?.description || '',
    copyFromExisting: !!copyFromSlug,
    type:
      environment?.type || allowCreateNonProductionEnvironment
        ? EnvironmentType.Development
        : EnvironmentType.Production,
    color: (environment ? environment?.color : findEnvironmentColor(environments)) || ENVIRONMENT_DEFAULT_COLOR,
    copyFromSlug,
  };

  const fields = useEnvironmentFields<EnvironmentFormFields>({
    environment: initialValues,
    isEdit,
  });

  if (initialValues.copyFromExisting && isLoadingEnvironments) {
    return <Loader />;
  }

  if (isCopyingEnv) {
    return (
      <>
        <Text.B2>
          <Trans
            t={t}
            i18nKey="accounts.copyToNewEnvAnimationDescription"
            values={{
              sourceEnvironmentName: sourceAndDestinationNames[0],
              destinationEnvironmentName: sourceAndDestinationNames[1],
            }}
            components={[<Text.B2 $bold display="inline" color="primary" />]}
          />
        </Text.B2>
        <Loader useAnimationLoader />
      </>
    );
  }
  const handleSubmit = async (values: EnvironmentFormFields) => {
    if (values.copyFromExisting && values.copyFromSlug) {
      const sourceEnv = environments.find((x) => x.slug === values.copyFromSlug);
      if (sourceEnv) {
        const sourceEnvSlug = sourceEnv?.slug || '';
        setSourceAndDestinationNames([sourceEnv?.displayName || '', values.displayName]);
        setIsCopyingEnv(true);

        const result = await dispatch(
          mergeEnvironmentAction({
            sourceEnvironmentSlug: sourceEnvSlug,
            destinationEnvironmentName: values.displayName,
            destinationEnvironmentType: values.type,
          }),
        ).unwrap();

        window.location.href = `/${result.environmentSlug}`;
      }
    } else {
      await onSubmit(omit(values, 'copyFromExisting'));
    }
  };

  if (!isEdit) {
    fields.push(
      {
        type: 'custom',
        render: ({ values, setFieldValue }) => {
          return (
            <Checkbox
              label={<Text.B2 color="secondary">{t('accounts.copyFromLabel')}</Text.B2>}
              checked={values.copyFromExisting}
              onChange={(_event, checked) => setFieldValue('copyFromExisting', checked)}
            />
          );
        },
      },
      {
        id: 'copyFromSlug',
        type: 'select',
        disabled: (values: EnvironmentFormFields) => !values.copyFromExisting,
        hide: (values: EnvironmentFormFields) =>
          isLoadingEnvironments || isEmpty(environments) || !values.copyFromExisting,
        restProps: {
          placeholder: t('accounts.copyToNewEnvCopyFromSlugPlaceholder'),
          menuMaxHeight: ENVIRONMENTS_MENU_MAX_HEIGHT,
        },
        values: environments.map((environment: Environment) => ({
          value: environment.slug,
          displayValue: <EnvironmentSelectItem displayName={environment.displayName} type={environment.type} />,
        })),
      },
    );
  }

  return (
    <Form
      withStickyFooter
      initialValues={initialValues}
      validationSchema={validationSchema()}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      fields={fields}
      submitButtonText={
        copyFromSlug
          ? t('accounts.copyEnvironmentModalCTA')
          : environment
            ? t('sharedComponents.editSaveButton')
            : undefined
      }
    />
  );
}
