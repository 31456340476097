import React, { useState } from 'react';
import { GridFlex, Icon, TextField, IconButton } from '@stigg-components';
import { t } from 'i18next';
import styled from 'styled-components/macro';
import { HiddenButton } from '../../../../components/buttons';
import { useConfirmationDialog } from '../../../common/useConfirmationDialog';
import { IntegrationFragment } from '@stigg-types/apiTypes';
import { ClipboardChip } from '@stigg-components';
import { Text } from '@stigg-components';
import { useUpdateIntegration } from '../../mutations/useUpdateIntegration';
import { IntegrationDetailsRow, ROW_HEIGHT } from './IntegrationDetailsRow';
import { useIntegrations } from '../../../common/useIntegrations';

const EditAliasButton = styled(IconButton)`
  border-radius: 4px;
  &:hover {
    background-color: #e2eeff;
    opacity: 1;
    svg {
      path {
        stroke: ${({ theme }) => theme.itamar.palette.primary.main};
      }
    }
  }
`;

function AliasEditForm({
  initialValue,
  onSave,
  onCancel,
  currentIntegrationId,
}: {
  initialValue: string | null | undefined;
  onSave: (alias: string) => void;
  onCancel: () => void;
  currentIntegrationId: string;
}) {
  const [value, setValue] = useState(initialValue || '');
  const [error, setError] = useState<string | boolean>(false);
  const { configuredIntegrations } = useIntegrations();

  const validateAlias = (alias: string): boolean => {
    if (alias.length < 3) {
      setError(t('integrations.alias.validation.tooShort'));
      return false;
    }

    // Check for valid characters: only English letters, numbers, hyphens, and underscores
    const validCharactersRegex = /^[a-zA-Z0-9_-]+$/;
    if (!validCharactersRegex.test(alias)) {
      setError(t('integrations.alias.validation.invalidCharacters'));
      return false;
    }

    // Check for duplicate aliases
    const isDuplicate = configuredIntegrations.some(
      (integration) => integration.integrationId === alias && integration.id !== currentIntegrationId,
    );

    if (isDuplicate) {
      setError(t('integrations.alias.validation.duplicateAlias'));
      return false;
    }

    setError(false);
    return true;
  };

  const handleSave = () => {
    if (validateAlias(value)) {
      onSave(value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);

    // Clear error when user starts typing again
    if (error) {
      validateAlias(newValue);
    }
  };

  return (
    <GridFlex.Row gap={1}>
      <TextField
        value={value}
        onChange={handleChange}
        placeholder={t('integrations.alias.inputPlaceholder')}
        onKeyDown={handleKeyDown}
        autoFocus
        touched={!!error}
        error={error}
      />
      <GridFlex.RowCenter gap={1} maxHeight={ROW_HEIGHT}>
        <EditAliasButton onClick={handleSave}>
          <Icon type="custom" icon="Check" size={16} color="primary.main" overrideStroke />
        </EditAliasButton>
        <EditAliasButton onClick={onCancel}>
          <Icon type="custom" icon="Close" size={12} color="primary.main" overrideStroke />
        </EditAliasButton>
      </GridFlex.RowCenter>
    </GridFlex.Row>
  );
}

interface EditAliasProps {
  integration: IntegrationFragment;
}

function EditAlias({ integration }: EditAliasProps) {
  const [newAlias, setNewAlias] = useState<string | undefined>(undefined);
  const [isEditingAlias, setIsEditingAlias] = useState(false);
  const [hovering, setHovering] = useState(false);

  const { integrationId } = integration;

  const { mutate: updateIntegration } = useUpdateIntegration({ integrationId: integration.id });

  const handleSaveAlias = (alias: string) => {
    if (alias === integrationId) {
      setIsEditingAlias(false);
      return;
    }
    setNewAlias(alias);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmUpdate = async () => {
    updateIntegration({
      vendorIdentifier: integration.vendorIdentifier,
      integrationId: newAlias,
    });
  };

  const [ConfirmationDialog, setConfirmationDialogOpen, confirmationDialogProps] = useConfirmationDialog({
    title: t('integrations.alias.editAliasConfirmationTitle'),
    content: t('integrations.alias.editAliasConfirmationContent'),
    confirmButtonText: t('integrations.alias.approve'),
    cancelButtonText: t('sharedComponents.cancel'),
    confirmButtonColor: 'error',
    handleConfirm: handleConfirmUpdate,
  });

  const displayComponent = integrationId ? (
    <ClipboardChip text={integrationId} copy={integrationId} iconLocation="right" variant="code" />
  ) : (
    <Text.B2>{t('integrations.integrationAliasNotSet')}</Text.B2>
  );

  return (
    <>
      {isEditingAlias ? (
        <AliasEditForm
          initialValue={integrationId}
          onSave={handleSaveAlias}
          onCancel={() => setIsEditingAlias(false)}
          currentIntegrationId={integration.id}
        />
      ) : (
        <GridFlex.Row onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} gap={6}>
          {displayComponent}
          <HiddenButton
            variant="text"
            textColor="primary"
            hoverColor="disabled"
            $forceVisibility={hovering}
            onClick={() => setIsEditingAlias(true)}
            startIcon={<Icon type="custom" icon="Pencil" size={14} color="primary.main" overrideStroke />}>
            {t('integrations.alias.edit')}
          </HiddenButton>
        </GridFlex.Row>
      )}
      <ConfirmationDialog {...confirmationDialogProps} />
    </>
  );
}

export function EditAliasRow({ integration }: EditAliasProps) {
  // const hasMultipleBillingIntegrations = useSelector(
  //   (state: RootState) => state.integrations.hasMultipleBillingIntegrations,
  // );

  return (
    <IntegrationDetailsRow
      alignLabel="flex-start"
      label={
        <GridFlex.RowCenter height={ROW_HEIGHT}>
          <Text.Sub2>{t('integrations.stripe.integrationAlias')}</Text.Sub2>
        </GridFlex.RowCenter>
      }
      value={<EditAlias integration={integration} />}
    />
  );
}
