import { ChipColor } from '@stigg-theme';
import { FeatureFragment, FeatureStatus } from '@stigg-types/apiTypes';

const featureStatusLabelMap: Record<FeatureStatus, string> = {
  [FeatureStatus.New]: 'Active',
  [FeatureStatus.Active]: 'Active',
  [FeatureStatus.Suspended]: 'Draft',
};
const featureStatusColorMap: Record<FeatureStatus, ChipColor> = {
  [FeatureStatus.New]: 'primary',
  [FeatureStatus.Active]: 'primary',
  [FeatureStatus.Suspended]: 'success',
};

export function getFeatureLabel(feature: FeatureFragment): { label: string; color: ChipColor } {
  return {
    label: featureStatusLabelMap[feature.featureStatus],
    color: featureStatusColorMap[feature.featureStatus],
  };
}
