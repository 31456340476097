import { t } from 'i18next';
import {
  TriggerSubscriptionUsageSyncMutation,
  TriggerSubscriptionUsageSyncMutationVariables,
} from '@stigg-types/apiTypes';
import { gql } from '@apollo/client';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { getBillingIntegration, getReadableVendorIdentifier } from '../../../utils/integrationUtils';

const TRIGGER_SUBSCRIPTION_USAGE_SYNC = gql`
  mutation TriggerSubscriptionUsageSync($input: TriggerSubscriptionUsageSyncInput!) {
    triggerSubscriptionUsageSync(input: $input)
  }
`;

export type TriggerSubscriptionUsageSyncProps = {
  customerId: string;
  resourceId?: string;
};

async function triggerSubscriptionUsageSync(
  { customerId, resourceId }: TriggerSubscriptionUsageSyncProps,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
) {
  const {
    integrations: { integrations },
  } = getState();
  const billingIntegration = getBillingIntegration(integrations || []);
  const vendorIdentifier = billingIntegration?.vendorIdentifier;
  const readableVendorIdentifier = getReadableVendorIdentifier(vendorIdentifier || '');

  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      await apolloClient.mutate<TriggerSubscriptionUsageSyncMutation, TriggerSubscriptionUsageSyncMutationVariables>({
        mutation: TRIGGER_SUBSCRIPTION_USAGE_SYNC,
        variables: {
          input: {
            environmentId: accountReducer.currentEnvironmentId,
            customerId,
            resourceId,
          },
        },
      });
    },
    {
      successMessage: t('subscriptions.api.triggerSubscriptionUsageSyncSuccess', {
        vendorIdentifier: readableVendorIdentifier,
      }),
      failureMessageHandler: () =>
        t('subscriptions.api.triggerSubscriptionUsageSyncFailed', { vendorIdentifier: readableVendorIdentifier }),
    },
    dispatch,
  );
}

export { triggerSubscriptionUsageSync };
