import { t } from 'i18next';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { BillingAnchor, Account, ProrationBehavior } from '@stigg-types/apiTypes';
import { Text, GridFlex, TextField, Autocomplete, Paper, Box, Form } from '@stigg-components';
import { formatTimezone } from '@stigg-common';
import styled from 'styled-components';

type TimezoneOption = {
  label: string;
  value: string;
};

const StyledPaper = styled(Paper)`
  box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  max-height: 220px;
  overflow: auto;
`;

const validationSchema = () =>
  Yup.object().shape({
    displayName: Yup.string().min(3).required(t('accounts.yup.name')),
    id: Yup.string(),
    timezone: Yup.string(),
  });

const timezonesOptions = moment.tz
  .names()
  .map<TimezoneOption>((timezone) => ({ label: formatTimezone(timezone), value: timezone }));

export function EditAccountSettings({
  account,
  onCancel,
  onSubmit,
  showProrationBehavior,
}: {
  account: Account;
  onCancel: () => void;
  onSubmit: (account: Account) => void;
  showProrationBehavior: boolean;
}) {
  const initialValues: Account = {
    id: account.id,
    displayName: account.displayName,
    timezone: account.timezone || moment.tz.guess(),
    subscriptionBillingAnchor: account.subscriptionBillingAnchor,
    subscriptionProrationBehavior: account.subscriptionProrationBehavior,
  };

  return (
    <GridFlex.Column container $fullWidth>
      <Form
        disableSaveOnNoChanges
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitButtonText={t('sharedComponents.editSaveButton')}
        validationSchema={validationSchema()}
        initialValues={initialValues}
        fields={[
          {
            type: 'text',
            id: 'displayName',
            label: 'Name',
          },
          {
            type: 'text',
            id: 'id',
            label: t('accounts.accountId'),

            disabled: true,
          },
          {
            type: 'custom',
            render: ({ values, touched, errors, handleBlur, setFieldValue }) => {
              return (
                <Autocomplete
                  PaperComponent={({ children }) => <StyledPaper>{children}</StyledPaper>}
                  id="timezone"
                  value={timezonesOptions.find((option) => option.value === values.timezone)}
                  options={timezonesOptions}
                  fullWidth
                  autoHighlight
                  disableClearable
                  onBlur={handleBlur}
                  onChange={(_, option) => setFieldValue('timezone', option.value)}
                  renderOption={(props: any, option: TimezoneOption) => (
                    <Box component="li" {...props} key={option.value}>
                      <Text.B2>{option.label}</Text.B2>
                    </Box>
                  )}
                  isOptionEqualToValue={(option: TimezoneOption, value: TimezoneOption) => option.value === value.value}
                  renderInput={(props) => (
                    <TextField
                      name="timezone"
                      label={t('accounts.timezone')}
                      value={values.timezone}
                      error={!!errors.timezone}
                      touched={!!touched.timezone}
                      errorText={errors.timezone}
                      textFieldProps={props}
                      InputProps={props.InputProps}
                    />
                  )}
                />
              );
            },
          },
          {
            id: 'subscriptionBillingAnchor',
            type: 'select',
            label: t('accounts.subscriptionBillingAnchor'),
            values: [
              {
                value: BillingAnchor.SubscriptionStart,
                displayValue: t('accounts.subscriptionBillingAnchorDescription.SUBSCRIPTION_START'),
              },
              {
                value: BillingAnchor.StartOfTheMonth,
                displayValue: t('accounts.subscriptionBillingAnchorDescription.START_OF_THE_MONTH'),
              },
              {
                value: BillingAnchor.SubscriptionsConsolidateBilling,
                displayValue: t('accounts.subscriptionBillingAnchorDescription.SUBSCRIPTIONS_CONSOLIDATE_BILLING'),
              },
            ],
          },
          {
            id: 'subscriptionProrationBehavior',
            type: 'select',
            label: t('accounts.subscriptionUpdateProrationBehavior'),
            hide: () => !showProrationBehavior,
            values: [
              {
                value: ProrationBehavior.InvoiceImmediately,
                displayValue: t('accounts.subscriptionProrationBehaviorDescription.INVOICE_IMMEDIATELY'),
              },
              {
                value: ProrationBehavior.CreateProrations,
                displayValue: t('accounts.subscriptionProrationBehaviorDescription.CREATE_PRORATIONS'),
              },
            ],
          },
        ]}
      />
    </GridFlex.Column>
  );
}
