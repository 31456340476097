import { capitalize } from 'lodash';
import { useIntegrations } from '@stigg-common';
import { IntegrationListItemFragment, SyncStateFragment, VendorType } from '@stigg-types/apiTypes';

// TODO: do this by the synced entity integration
export function getBillingIntegration(integrations: IntegrationListItemFragment[]) {
  const billingIntegrations = integrations.filter((integration) => integration.vendorType === VendorType.Billing);
  if (!billingIntegrations.length) {
    return null;
  }
  if (billingIntegrations.length > 1) {
    return null;
  }
  return billingIntegrations[0];
}

export function getReadableVendorIdentifier(vendorIdentifier: string) {
  return capitalize(vendorIdentifier);
}

export function useIntegrationSyncStateLabel(syncState: SyncStateFragment) {
  const { hasMultipleBillingIntegrations } = useIntegrations();

  if (!hasMultipleBillingIntegrations) {
    return getReadableVendorIdentifier(syncState.integration.vendorIdentifier);
  }

  if (syncState.integration.credentials?.__typename === 'StripeCredentials') {
    return `${getReadableVendorIdentifier(syncState.integration.vendorIdentifier)} • ${syncState.integration.credentials.accountDisplayName}`;
  }

  return getReadableVendorIdentifier(syncState.integration.vendorIdentifier);
}
