import { t } from 'i18next';
import { FeatureStatus, FeatureType, PackageEntitlement, PlanFragment, WidgetType } from '@stigg-types/apiTypes';
import { InformationTooltip, EMPTY_CHAR, Box, OptionsDropdown, Text, Icon } from '@stigg-components';

import { OptionsDropdownType } from '@stigg-components/types';
import { StatusChip } from '@stigg-common';
import { HeadCell } from '../../../../../../../components/table/Table';
import { Value } from '../Value';
import { PlanEntitlement } from '../../PackageGrantedEntitlements.types';
import { TooltipFields } from '../../../../../../../components/InformationTooltip';
import { HiddenIcon } from '../../../hiddenFromWidgets/HiddenIcon';
import { getFeatureTypeIcon } from '../../../../../../../components/getFeatureTypeIcon';
import { FeatureDisplayNameAndId } from '../../../../../../features/components/FeatureDisplayNameAndId';
import { getConnectedEntityByFeatureId } from '../../../../overageCharges.utils';

const HEAD_CELLS_FIXED_WIDTH = 220;

export const createHeadCells = (
  triggerOverrideEntitlement: (entitlement: PlanEntitlement) => void,
  triggerDeleteEntitlement: (entitlement: PlanEntitlement) => void,
  childPlanEntitlementsByFeatureId: Map<string, PlanEntitlement>,
  readonly: boolean,
  currentPlan: PlanFragment,
): Array<HeadCell<PackageEntitlement, any>> => {
  const cells: Array<HeadCell<PackageEntitlement, any>> = [
    {
      disablePadding: true,
      width: 35,
      maxWidth: 35,
      id: 'feature_type',
      alignment: 'center',
      label: t('entitlements.featureType'),
      render: (entity: PlanEntitlement) => {
        return (
          <Box px={4} display="flex" alignItems="center" justifyContent="center">
            {entity.price ? (
              <InformationTooltip
                arrow
                $padding={2}
                placement="top"
                title={<Text.B2>{t('packages.pricingMetric')}</Text.B2>}>
                <div>
                  <Icon type="reactFeather" icon="DollarSign" color="active" size={20} />
                </div>
              </InformationTooltip>
            ) : (
              getFeatureTypeIcon(entity.feature, { entitlementsTooltip: true })
            )}
          </Box>
        );
      },
    },
    {
      width: HEAD_CELLS_FIXED_WIDTH,
      id: 'id',
      alignment: 'left',
      label: t('entitlements.feature'),
      render: (entity: PackageEntitlement) => <FeatureDisplayNameAndId feature={entity.feature} />,
    },
    {
      width: HEAD_CELLS_FIXED_WIDTH,
      id: 'value',
      alignment: 'left',
      label: t('entitlements.featureValue'),
      render: (entity: PlanEntitlement) => {
        return (
          <Value
            entity={entity}
            overridingEntity={childPlanEntitlementsByFeatureId.get(entity.feature.id)}
            hasOverageCharge={
              !!(
                getConnectedEntityByFeatureId(entity.featureId, currentPlan.overagePrices) ||
                (currentPlan.basePlan &&
                  !!getConnectedEntityByFeatureId(entity.featureId, currentPlan.basePlan.overagePrices))
              )
            }
          />
        );
      },
    },
    {
      width: HEAD_CELLS_FIXED_WIDTH,
      maxWidth: HEAD_CELLS_FIXED_WIDTH,
      id: 'feature-widget-display-name-override',
      alignment: 'left' as any,
      label: t('widgets.customTextAndVisibilityColumn'),
      render: (entity: PlanEntitlement) => {
        return <Text.B2>{entity.displayNameOverride || EMPTY_CHAR}</Text.B2>;
      },
    },
    {
      width: readonly ? 60 : 30,
      maxWidth: readonly ? 60 : 30,
      disablePadding: true,
      id: 'feature-archive-status',
      alignment: 'right' as any,
      render: (entity: PlanEntitlement) => {
        if (entity.feature.featureStatus !== FeatureStatus.Suspended) {
          return null;
        }
        return (
          <StatusChip
            textColor="primary"
            color="disabled"
            variant="outlined"
            label={t('features.archived')}
            size="small"
          />
        );
      },
    },
    {
      width: readonly ? 50 : 25,
      maxWidth: readonly ? 50 : 25,
      disablePadding: true,
      id: 'feature-widget-visibility',
      alignment: 'right' as any,
      render: (entity: PlanEntitlement) => {
        const isHidden = (entity.hiddenFromWidgets || []).includes(WidgetType.Paywall);
        return <HiddenIcon isHidden={isHidden} tooltipPlacement="left" />;
      },
    },
    {
      id: 'options',
      alignment: 'center',
      label: '',
      width: 32,
      maxWidth: 32,
      disablePadding: true,
      visible: !readonly,
      render: (entity: PlanEntitlement) => {
        let extendDisabledTooltip: TooltipFields | undefined;

        if (entity.feature.featureType === FeatureType.Boolean) {
          extendDisabledTooltip = {
            title: <Text.B2>{t('entitlements.booleanFeatureCantBeOverridden')}</Text.B2>,
            placement: 'left',
          };
        }

        const hasOverrideEntitlement = childPlanEntitlementsByFeatureId.has(entity.feature.id);

        const options: OptionsDropdownType = [
          {
            icon: () => <Icon icon="Override" size={20} />,
            text: t('entitlements.overrideEntitlement'),
            onClick: () => triggerOverrideEntitlement(entity),
            disabled: !!extendDisabledTooltip,
            tooltip: extendDisabledTooltip,
            hide: hasOverrideEntitlement,
          },
          {
            icon: () => <Icon icon="RefreshCcw" size={20} />,
            text: t('entitlements.revokeOverride'),
            onClick: () => triggerDeleteEntitlement(childPlanEntitlementsByFeatureId.get(entity.feature.id)!),
            hide: !hasOverrideEntitlement,
          },
        ];

        return <OptionsDropdown options={options} />;
      },
    },
  ];

  return cells;
};
