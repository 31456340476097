import styled from 'styled-components';
import { IntegrationListItemFragment } from '@stigg-types/apiTypes';
import { GridFlex, Text } from '@stigg-components';
import { useIntegrationDisplayParameters } from './utils';
import { DefaultIntegrationChip } from '../DefaultIntegrationChip';

const IntegrationIcon = styled.img<{ size?: number }>`
  width: ${({ size }) => size || 50}px;
  height: ${({ size }) => size || 50}px;
`;

interface IntegrationRowProps {
  integration: IntegrationListItemFragment;
  hideIntegrationId?: boolean;
  iconSize?: number;
  showDefault?: 'none' | 'short' | 'long';
}

export function IntegrationRow({
  integration,
  hideIntegrationId,
  iconSize,
  showDefault = 'long',
}: IntegrationRowProps) {
  const { icon, integrationName, displayName, integrationId } = useIntegrationDisplayParameters(integration);

  return (
    <GridFlex.RowCenter $fullWidth gap={2}>
      <IntegrationIcon src={icon} alt={integrationName} size={iconSize} />
      <GridFlex.RowSpaceBetween $fullWidth>
        <GridFlex.Column gap={1}>
          <GridFlex.Row gap={1}>
            <Text.B2 color="primary">{integrationName}</Text.B2>
            {displayName && (
              <>
                <Text.B2>•</Text.B2>
                <Text.B2 color="primary">{displayName}</Text.B2>
              </>
            )}
          </GridFlex.Row>
          {!hideIntegrationId && integrationId && <Text.B2 color="secondary">{integrationId}</Text.B2>}
        </GridFlex.Column>
        {showDefault !== 'none' && integration.isDefault && (
          <DefaultIntegrationChip
            vendorType={integration.vendorType}
            showTooltip={false}
            showShortDefault={showDefault === 'short'}
          />
        )}
      </GridFlex.RowSpaceBetween>
    </GridFlex.RowCenter>
  );
}
