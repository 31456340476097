import { Box, Button, Field, GridFlex, HighlightText, Icon, Text, SearchableSelectOption } from '@stigg-components';
import { t } from 'i18next';
import { map } from 'lodash';
import countryList from 'country-list';
import { Currency } from '@stigg-types/apiTypes';
import { CountryFlagWithCurrency } from '../../../../currency/CountryWithFlagByCode';
import { SetPriceWizardFormFields } from '../../SetPriceWizardForm.types';
import { getCurrencyText } from '../../../../currency/currencyUtils';

const COUNTRY_OPTIONS: SearchableSelectOption[] = map(countryList.getCodeList(), (countryName, countryCode) => ({
  id: countryCode,
  render: (search?: string, disabled?: boolean) => (
    <CountryFlagWithCurrency
      countryCode={countryCode}
      highlight={search}
      suffix={disabled ? ` - ${t('sharedComponents.alreadySelected')}` : undefined}
    />
  ),
  isInSearch: (search) => countryName.toLowerCase().includes(search.toLowerCase()),
}));

const CURRENCY_OPTIONS: SearchableSelectOption[] = Object.values(Currency).map((currency) => ({
  id: currency,
  render: (search?: string) => (
    <Text.B2>
      <HighlightText text={getCurrencyText(currency)} highlight={search} />
    </Text.B2>
  ),
  isInSearch: (search) => currency.toLowerCase().includes(search.toLowerCase()),
}));

const MAX_OPTIONS_TO_SHOW = 20;

export function getCountryAndCurrencySelectionFields(
  index: number,
  billingCountryCode: string | undefined,
  currency: Currency | undefined,
  onConfirmCountryAndCurrency: () => void,
  onRemove: () => void,
  hasSingleCountry: boolean,
  isCountryInUse: (countryCode: string) => boolean,
): Field<SetPriceWizardFormFields>[] {
  return [
    {
      type: 'layout',
      gridProps: {
        columnGap: 8,
      },
      fields: [
        {
          type: 'searchableSelect',
          id: `priceLocalization.countries[${index}].billingCountryCode`,
          label: t('pricing.priceLocalization.country'),
          placeholder: t('pricing.priceLocalization.selectCountry'),
          maxOptionsToShow: MAX_OPTIONS_TO_SHOW,
          options: COUNTRY_OPTIONS,
          dataTestId: 'country-select',
          isOptionDisabled: (countryCode) => isCountryInUse(countryCode),
        },
        {
          type: 'searchableSelect',
          id: `priceLocalization.countries[${index}].currency`,
          label: t('pricing.priceLocalization.currency'),
          placeholder: t('pricing.priceLocalization.selectCurrency'),
          maxOptionsToShow: MAX_OPTIONS_TO_SHOW,
          options: CURRENCY_OPTIONS,
          dataTestId: 'currency-select',
        },
      ],
    },
    {
      type: 'idle',
      component: (
        <Box flex={1}>
          <GridFlex.Row justifyContent="flex-end" gap={2} mt={6}>
            {!hasSingleCountry && (
              <Button $outlined color="primary" onClick={onRemove}>
                {t('sharedComponents.cancel')}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirmCountryAndCurrency}
              disabled={!billingCountryCode || !currency}
              endIcon={<Icon icon="ArrowForward" />}
              data-testid="button-continue">
              {t('subscriptions.schedules.continue')}
            </Button>
          </GridFlex.Row>
        </Box>
      ),
    },
  ];
}
