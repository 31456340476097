import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import {
  CustomerResponseFragment,
  SubscriptionDataFragment,
  UsageMeasurementDataFragment,
  EntitlementDataFragment,
  Customer,
  FetchCustomerSyncStatesQuery,
  FetchSubscriptionSyncStatesQuery,
  CustomerSubscriptionConnection,
  ActiveSubscriptionsFragment,
  FetchCustomerSubscriptionsResourcesQuery,
  SubscriptionPreviewV2Fragment,
  PreviewNextInvoiceFragment,
  SubscriptionMaximumSpendFragment,
} from '@stigg-types/apiTypes';
import { fetchCustomerByCustomerId, fetchSubscriptionByRefId } from './queries';
import {
  migrateSubscriptionToLatest,
  createSubscription,
  createCustomer,
  archiveCustomerByRefId,
  updateCustomer,
  previewSubscription,
} from './mutations';
import { fetchEntitlementsByCustomerId } from './queries/fetchEntitlementsByCustomerId';
import { fetchUsageMeasurementsByFeatureRefId } from './queries/fetchUsageMeasurementsByFeatureRefId';
import { createPromotionalEntitlements } from './mutations/createPromotionalEntitlements';
import { deletePromotionalEntitlement } from './mutations/deletePromotionalEntitlement';
import { updatePromotionalEntitlement } from './mutations/updatePromotionalEntitlement';
import { cancelSubscription } from './mutations/cancelSubscription';
import { updateSubscription } from './mutations/updateSubscription';
import { fetchCustomerSubscriptions } from './queries/fetchCustomerSubscriptions';
import { fetchNonActiveSubscriptionsCount } from './queries/fetchNonActiveSubscriptionsCount';
import { cancelSubscriptionUpdates } from './mutations/cancelSubscriptionUpdates';
import { fetchActiveSubscriptions } from './queries/fetchActiveSubscriptions';
import { fetchCustomerSubscriptionsResources } from './queries/fetchCustomerSubscriptionsResources';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';
import { reportUsage } from './mutations/reportUsage';
import { triggerSubscriptionUsageSync } from './mutations/triggerSubscriptionUsageSync';
import { previewNextInvoice } from './queries/previewNextInvoice';
import { markInvoiceAsPaid } from './mutations/markInvoiceAsPaid';
import { previewSubscriptionMaximumSpend } from './queries/previewSubscriptionMaximumSpend';
import { deletePaymentMethod } from './mutations/deletePaymentMethod';

type FeatureMeasurements = {
  isLoading: boolean;
  measurements: UsageMeasurementDataFragment[];
};

type NextInvoiceBySubscriptionMap = Record<
  string,
  {
    invoice: PreviewNextInvoiceFragment | undefined;
    isLoading: boolean;
  }
>;

type MaximumSpendBySubscriptionMap = Record<
  string,
  {
    maximumSpend?: SubscriptionMaximumSpendFragment | null;
    isLoading: boolean;
  }
>;

export interface CustomersState {
  isLoading: boolean;
  isLoadingEntitlements: boolean;
  isLoadingSubscriptions: boolean;
  isLoadingDelegatedSubscriptions: boolean;
  isLoadingNonActiveSubscriptionsCount: boolean;
  isLoadingNonActiveDelegatedSubscriptionsCount: boolean;
  isSavingPromotionalEntitlements: boolean;
  isLoadingPreviewSubscriptionAction: boolean;
  customer: CustomerResponseFragment | null;
  customerSubscriptions: CustomerSubscriptionConnection;
  customerDelegatedSubscriptions: CustomerSubscriptionConnection;
  subscription: SubscriptionDataFragment;

  nextInvoiceBySubscription: NextInvoiceBySubscriptionMap;
  maximumSpendBySubscription: MaximumSpendBySubscriptionMap;
  previewSubscriptionResult?: SubscriptionPreviewV2Fragment | null;
  entitlements: EntitlementDataFragment[];
  usageMeasurements: Record<string, FeatureMeasurements>;

  entitlementsLastUpdated?: Date | null;
  lastReportedUsage: Record<string, Date | null>;

  isLoadingActiveSubscriptions: boolean;
  activeSubscriptions: ActiveSubscriptionsFragment[];

  isLoadingCustomerSubscriptionsResources: boolean;
  customerSubscriptionsResources: FetchCustomerSubscriptionsResourcesQuery['customerSubscriptions'];

  nonActiveSubscriptionsCount: number | null;
  nonActiveDelegatedSubscriptionsCount: number | null;
}

const initialState: CustomersState = {
  isLoading: false,
  isLoadingEntitlements: false,
  isSavingPromotionalEntitlements: false,
  isLoadingSubscriptions: false,
  isLoadingDelegatedSubscriptions: false,
  isLoadingNonActiveSubscriptionsCount: false,
  isLoadingNonActiveDelegatedSubscriptionsCount: false,
  isLoadingPreviewSubscriptionAction: false,
  customer: null,
  customerSubscriptions: {} as CustomerSubscriptionConnection,
  customerDelegatedSubscriptions: {} as CustomerSubscriptionConnection,
  subscription: {} as SubscriptionDataFragment,
  maximumSpendBySubscription: {},
  nextInvoiceBySubscription: {},
  previewSubscriptionResult: null,
  entitlements: [],
  usageMeasurements: {},

  entitlementsLastUpdated: null,
  lastReportedUsage: {},

  isLoadingActiveSubscriptions: false,
  activeSubscriptions: [],

  isLoadingCustomerSubscriptionsResources: false,
  customerSubscriptionsResources: {} as CustomerSubscriptionConnection,

  nonActiveSubscriptionsCount: null,
  nonActiveDelegatedSubscriptionsCount: null,
};

const createCustomerAction = createAppAsyncThunk('createCustomer', createCustomer);
const fetchCustomerByRefIdAction = createAppAsyncThunk('fetchCustomerById', fetchCustomerByCustomerId);
const fetchCustomerSubscriptionsAction = createAppAsyncThunk('fetchCustomerSubscriptions', fetchCustomerSubscriptions);
const fetchNonActiveSubscriptionsCountAction = createAppAsyncThunk(
  'fetchNonActiveSubscriptionsCount',
  fetchNonActiveSubscriptionsCount,
);

const fetchCustomerSubscriptionsResourcesAction = createAppAsyncThunk(
  'fetchCustomerSubscriptionsResources',
  fetchCustomerSubscriptionsResources,
);
const fetchSubscriptionByRefIdAction = createAppAsyncThunk('fetchSubscriptionByRefId', fetchSubscriptionByRefId);
const previewNextInvoiceAction = createAppAsyncThunk('previewNextInvoice', previewNextInvoice);
const previewSubscriptionMaximumSpendAction = createAppAsyncThunk(
  'previewSubscriptionMaximumSpend',
  previewSubscriptionMaximumSpend,
);
const updateSubscriptionAction = createAppAsyncThunk('updateSubscription', updateSubscription);
const fetchEntitlementsByRefIdAction = createAppAsyncThunk('fetchEntitlementsByRefId', fetchEntitlementsByCustomerId);
const fetchActiveSubscriptionsAction = createAppAsyncThunk('fetchActiveSubscriptions', fetchActiveSubscriptions);
const triggerSubscriptionUsageSyncAction = createAppAsyncThunk(
  'triggerSubscriptionUsageSync',
  triggerSubscriptionUsageSync,
);
const createSubscriptionAction = createAppAsyncThunk('createSubscription', createSubscription);
const cancelSubscriptionAction = createAppAsyncThunk('cancelSubscription', cancelSubscription);
const cancelSubscriptionUpdatesAction = createAppAsyncThunk('cancelSubscriptionUpdates', cancelSubscriptionUpdates);
const createPromotionalEntitlementsAction = createAppAsyncThunk(
  'createPromotionalEntitlements',
  createPromotionalEntitlements,
);
const deletePromotionalEntitlementAction = createAppAsyncThunk(
  'deletePromotionalEntitlement',
  deletePromotionalEntitlement,
);
const updatePromotionalEntitlementAction = createAppAsyncThunk(
  'updatePromotionalEntitlement',
  updatePromotionalEntitlement,
);
const fetchUsageMeasurementsByFeatureRefIdAction = createAppAsyncThunk(
  'fetchUsageMeasurementsByFeatureId',
  fetchUsageMeasurementsByFeatureRefId,
);
const archiveCustomerByIdAction = createAppAsyncThunk('deleteCustomerById', archiveCustomerByRefId);
const updateCustomerAction = createAppAsyncThunk('updateCustomer', updateCustomer);
const markInvoiceAsPaidAction = createAppAsyncThunk('markInvoiceAsPaid', markInvoiceAsPaid);
const migrateSubscriptionToLatestAction = createAppAsyncThunk(
  'migrateSubscriptionToLatest',
  migrateSubscriptionToLatest,
);
const previewSubscriptionAction = createAppAsyncThunk('previewSubscription', previewSubscription);

const detachCustomerPaymentMethodAction = createAppAsyncThunk('detachCustomerPaymentMethod', deletePaymentMethod);

const reportUsageAction = createAppAsyncThunk('reportUsage', reportUsage);

const updateCustomerOnFullFilled = (state: CustomersState, payload?: Partial<Customer>) => {
  state.customer = merge(state.customer, payload);
  if (payload?.additionalMetaData) {
    state.customer.additionalMetaData = payload?.additionalMetaData;
  }
};

type SubscriptionSyncState = FetchSubscriptionSyncStatesQuery['customerSubscriptions']['edges'][number]['node'];

type CustomerSyncState = FetchCustomerSyncStatesQuery['customers']['edges'][number]['node'];

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetCustomer: (state) => {
      state.customer = null;
      state.subscription = {} as SubscriptionDataFragment;
      state.nextInvoiceBySubscription = {};
      state.maximumSpendBySubscription = {};
      state.customerSubscriptions = {} as CustomerSubscriptionConnection;
      state.isLoadingSubscriptions = false;
      state.isLoadingNonActiveSubscriptionsCount = false;
      state.nonActiveSubscriptionsCount = null;
      state.customerDelegatedSubscriptions = {} as CustomerSubscriptionConnection;
      state.isLoadingDelegatedSubscriptions = false;
      state.isLoadingNonActiveDelegatedSubscriptionsCount = false;
      state.nonActiveDelegatedSubscriptionsCount = null;
      state.activeSubscriptions = [];
      state.customerSubscriptionsResources = {} as CustomerSubscriptionConnection;
    },
    resetSubscription: (state) => {
      state.subscription = {} as SubscriptionDataFragment;
    },
    resetEntitlements: (state) => {
      state.entitlements = [];
      state.entitlementsLastUpdated = null;
    },
    updateSubscriptionSyncState: (state, action: { payload: SubscriptionSyncState }) => {
      state.subscription = merge(state.subscription, action.payload);
    },
    updateCustomerSyncState: (state, action: { payload: CustomerSyncState }) => {
      state.customer = merge(state.customer, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(previewNextInvoiceAction.pending, (state, action) => {
        const { subscriptionId } = action.meta.arg;
        state.nextInvoiceBySubscription[subscriptionId] = {
          ...state.nextInvoiceBySubscription[subscriptionId],
          isLoading: true,
        };
      })
      .addCase(previewNextInvoiceAction.fulfilled, (state, action) => {
        const { subscriptionId } = action.meta.arg;
        state.nextInvoiceBySubscription[subscriptionId] = {
          invoice: action.payload,
          isLoading: false,
        };
      })
      .addCase(previewNextInvoiceAction.rejected, (state, action) => {
        const { subscriptionId } = action.meta.arg;
        state.nextInvoiceBySubscription[subscriptionId] = {
          ...state.nextInvoiceBySubscription[subscriptionId],
          isLoading: false,
        };
      });

    builder
      .addCase(previewSubscriptionMaximumSpendAction.pending, (state, action) => {
        const { subscriptionId } = action.meta.arg;
        state.maximumSpendBySubscription[subscriptionId] = {
          ...state.maximumSpendBySubscription[subscriptionId],
          isLoading: true,
        };
      })
      .addCase(previewSubscriptionMaximumSpendAction.fulfilled, (state, action) => {
        const { subscriptionId } = action.meta.arg;
        state.maximumSpendBySubscription[subscriptionId] = {
          maximumSpend: action.payload,
          isLoading: false,
        };
      })
      .addCase(previewSubscriptionMaximumSpendAction.rejected, (state, action) => {
        const { subscriptionId } = action.meta.arg;
        state.maximumSpendBySubscription[subscriptionId] = {
          ...state.maximumSpendBySubscription[subscriptionId],
          isLoading: false,
        };
      });

    builder
      .addCase(cancelSubscriptionUpdatesAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelSubscriptionUpdatesAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(cancelSubscriptionUpdatesAction.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(createPromotionalEntitlementsAction.pending, (state) => {
        state.isSavingPromotionalEntitlements = true;
      })
      .addCase(createPromotionalEntitlementsAction.fulfilled, (state) => {
        state.isSavingPromotionalEntitlements = false;
      })
      .addCase(createPromotionalEntitlementsAction.rejected, (state) => {
        state.isSavingPromotionalEntitlements = false;
      });
    builder
      .addCase(updatePromotionalEntitlementAction.pending, (state) => {
        state.isSavingPromotionalEntitlements = true;
      })
      .addCase(updatePromotionalEntitlementAction.fulfilled, (state) => {
        state.isSavingPromotionalEntitlements = false;
      })
      .addCase(updatePromotionalEntitlementAction.rejected, (state) => {
        state.isSavingPromotionalEntitlements = false;
      });
    builder
      .addCase(deletePromotionalEntitlementAction.pending, (state) => {
        state.isSavingPromotionalEntitlements = true;
      })
      .addCase(deletePromotionalEntitlementAction.fulfilled, (state) => {
        state.isSavingPromotionalEntitlements = false;
      })
      .addCase(deletePromotionalEntitlementAction.rejected, (state) => {
        state.isSavingPromotionalEntitlements = false;
      });
    builder
      .addCase(fetchCustomerByRefIdAction.pending, (state, action) => {
        state.isLoading = !action.meta.arg.isSilentLoading;
      })
      .addCase(fetchCustomerByRefIdAction.fulfilled, (state, { payload }) => {
        state.customer = payload;
        state.isLoading = false;
      })
      .addCase(fetchCustomerByRefIdAction.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(fetchCustomerSubscriptionsAction.pending, (state, { meta }) => {
        const isLoading = !meta.arg.isSilentLoading;
        if (meta.arg.payingCustomerId) {
          state.isLoadingDelegatedSubscriptions = isLoading;
        } else {
          state.isLoadingSubscriptions = isLoading;
        }
      })
      .addCase(fetchCustomerSubscriptionsAction.fulfilled, (state, { meta, payload }) => {
        if (meta.arg.payingCustomerId) {
          state.customerDelegatedSubscriptions = payload.customerSubscriptions as CustomerSubscriptionConnection;
          state.isLoadingDelegatedSubscriptions = false;
        } else {
          state.customerSubscriptions = payload.customerSubscriptions as CustomerSubscriptionConnection;
          state.isLoadingSubscriptions = false;
        }
      })
      .addCase(fetchCustomerSubscriptionsAction.rejected, (state, { meta }) => {
        if (meta.arg.payingCustomerId) {
          state.isLoadingDelegatedSubscriptions = false;
        } else {
          state.isLoadingSubscriptions = false;
        }
      });

    builder
      .addCase(fetchNonActiveSubscriptionsCountAction.pending, (state, { meta }) => {
        const isLoading = !meta.arg.isSilentLoading;
        if (meta.arg.payingCustomerId) {
          state.isLoadingNonActiveDelegatedSubscriptionsCount = isLoading;
        } else {
          state.isLoadingNonActiveSubscriptionsCount = isLoading;
        }
      })
      .addCase(fetchNonActiveSubscriptionsCountAction.fulfilled, (state, { meta, payload }) => {
        if (meta.arg.payingCustomerId) {
          state.nonActiveDelegatedSubscriptionsCount = payload.totalCount;
          state.isLoadingNonActiveDelegatedSubscriptionsCount = false;
        } else {
          state.nonActiveSubscriptionsCount = payload.totalCount;
          state.isLoadingNonActiveSubscriptionsCount = false;
        }
      })
      .addCase(fetchNonActiveSubscriptionsCountAction.rejected, (state, { meta }) => {
        if (meta.arg.payingCustomerId) {
          state.isLoadingNonActiveDelegatedSubscriptionsCount = false;
        } else {
          state.isLoadingNonActiveSubscriptionsCount = false;
        }
      });

    builder
      .addCase(fetchCustomerSubscriptionsResourcesAction.pending, (state, { meta }) => {
        state.isLoadingCustomerSubscriptionsResources = !meta.arg.isSilentLoading;
      })
      .addCase(fetchCustomerSubscriptionsResourcesAction.fulfilled, (state, { payload }) => {
        state.customerSubscriptionsResources = payload as CustomerSubscriptionConnection;
        state.isLoadingCustomerSubscriptionsResources = false;
      })
      .addCase(fetchCustomerSubscriptionsResourcesAction.rejected, (state) => {
        state.isLoadingCustomerSubscriptionsResources = false;
      });

    builder
      .addCase(fetchSubscriptionByRefIdAction.pending, (state, action) => {
        state.isLoading = !action.meta.arg.isSilentLoading;
      })
      .addCase(fetchSubscriptionByRefIdAction.fulfilled, (state, { payload }) => {
        state.subscription = payload || ({} as SubscriptionDataFragment);
        state.isLoading = false;
      })
      .addCase(fetchSubscriptionByRefIdAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(updateSubscriptionAction.fulfilled, (state, { payload }) => {
      state.subscription.additionalMetaData = payload;
    });

    builder
      .addCase(fetchEntitlementsByRefIdAction.pending, (state) => {
        state.isLoadingEntitlements = true;
      })
      .addCase(fetchEntitlementsByRefIdAction.fulfilled, (state, { payload }) => {
        state.entitlements = payload;
        state.isLoadingEntitlements = false;
        state.entitlementsLastUpdated = new Date();
      })
      .addCase(fetchEntitlementsByRefIdAction.rejected, (state) => {
        state.isLoadingEntitlements = false;
      });

    builder
      .addCase(fetchActiveSubscriptionsAction.pending, (state) => {
        state.isLoadingActiveSubscriptions = true;
      })
      .addCase(fetchActiveSubscriptionsAction.fulfilled, (state, { payload }) => {
        state.activeSubscriptions = payload;
        state.isLoadingActiveSubscriptions = false;
      })
      .addCase(fetchActiveSubscriptionsAction.rejected, (state) => {
        state.isLoadingActiveSubscriptions = false;
      });

    builder
      .addCase(fetchUsageMeasurementsByFeatureRefIdAction.pending, (state, { meta }) => {
        state.usageMeasurements[meta.arg.featureRefId] = { isLoading: false, measurements: [] };
      })
      .addCase(fetchUsageMeasurementsByFeatureRefIdAction.fulfilled, (state, { payload, meta }) => {
        state.usageMeasurements[meta.arg.featureRefId] = { isLoading: false, measurements: payload };
      })
      .addCase(fetchUsageMeasurementsByFeatureRefIdAction.rejected, (state, { meta }) => {
        state.usageMeasurements[meta.arg.featureRefId] = { isLoading: false, measurements: [] };
      });

    builder.addCase(updateCustomerAction.fulfilled, (state, { payload }) => {
      updateCustomerOnFullFilled(state, payload || undefined);
    });

    builder.addCase(detachCustomerPaymentMethodAction.fulfilled, (state, { payload }) => {
      updateCustomerOnFullFilled(state, payload);
    });

    builder
      .addCase(previewSubscriptionAction.pending, (state) => {
        state.isLoadingPreviewSubscriptionAction = true;
      })
      .addCase(previewSubscriptionAction.fulfilled, (state, { payload }) => {
        state.isLoadingPreviewSubscriptionAction = false;
        state.previewSubscriptionResult = payload;
      })
      .addCase(previewSubscriptionAction.rejected, (state) => {
        state.isLoadingPreviewSubscriptionAction = false;
      });

    builder.addCase(reportUsageAction.fulfilled, (state, { meta }) => {
      state.lastReportedUsage = { [meta.arg.featureId]: new Date() };
    });
  },
});

const { resetSubscription, resetCustomer, resetEntitlements, updateSubscriptionSyncState, updateCustomerSyncState } =
  customersSlice.actions;

export {
  createCustomerAction,
  fetchCustomerByRefIdAction,
  archiveCustomerByIdAction,
  updateCustomerAction,
  markInvoiceAsPaidAction,
  fetchSubscriptionByRefIdAction,
  resetSubscription,
  resetCustomer,
  resetEntitlements,
  fetchEntitlementsByRefIdAction,
  fetchActiveSubscriptionsAction,
  fetchUsageMeasurementsByFeatureRefIdAction,
  createSubscriptionAction,
  cancelSubscriptionAction,
  cancelSubscriptionUpdatesAction,
  createPromotionalEntitlementsAction,
  deletePromotionalEntitlementAction,
  updatePromotionalEntitlementAction,
  migrateSubscriptionToLatestAction,
  updateSubscriptionSyncState,
  updateCustomerSyncState,
  updateSubscriptionAction,
  detachCustomerPaymentMethodAction,
  reportUsageAction,
  previewSubscriptionAction,
  fetchCustomerSubscriptionsAction,
  fetchNonActiveSubscriptionsCountAction,
  fetchCustomerSubscriptionsResourcesAction,
  triggerSubscriptionUsageSyncAction,
  previewNextInvoiceAction,
  previewSubscriptionMaximumSpendAction,
};

export default customersSlice.reducer;
