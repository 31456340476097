import get from 'lodash/get';
import { drawFormFields, FormRenderProps, Grid, GridFlex, Text, TextField } from '@stigg-components';
import { BillingPeriod, Currency } from '@stigg-types/apiTypes';
import getSymbolFromCurrency from 'currency-symbol-map';
import { t } from 'i18next';
import { HeadCell } from '../../../../../../../../components/table/Table';
import { roundMaximumFractionDigits } from '../../../../../utils/priceFormatUtils';
import {
  PriceTier,
  SetPriceWizardFormFields,
  TIERS_SCHEMA_PER_UNIT,
  TiersSchema,
} from '../../SetPriceWizardForm.types';
import { CellTextField } from './CellTextField';
import { SyncPriceIcon } from './SyncPriceIcon';
import { FallbackTierIcon } from '../../../../../../common/components/pricingTypeViews/FallbackTierIcon';

type HeadCellsProps = {
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
  currency: Currency;
  priceTiersId: string;
  billingPeriod: BillingPeriod;
  pricePeriodUnit: BillingPeriod;
  lastIndex: number;
  tiersSchema: TiersSchema;
  tiers: PriceTier[];
  disableUnitChanges?: boolean;
};

function EndUnitCell({
  priceTier,
  entityIndex,
  billingPeriod,
  disableUnitChanges,
  formRenderProps,
  priceTiersId,
  endAdornment,
  shouldUpdateTierPrice = true,
}: {
  priceTier: PriceTier;
  entityIndex: number;
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
  priceTiersId: string;
  billingPeriod: BillingPeriod;
  disableUnitChanges?: boolean;
  endAdornment?: React.ReactNode;
  shouldUpdateTierPrice?: boolean;
}) {
  const currentTierEndUnitId = `${priceTiersId}.${billingPeriod}[${entityIndex}].endUnit`;
  const tierPriceUnitId = `${priceTiersId}.${billingPeriod}[${entityIndex}].tierPrice`;
  const monthlyTierEndUnitId = `${priceTiersId}.${BillingPeriod.Monthly}[${entityIndex}].endUnit`;
  const annuallyTierEndUnitId = `${priceTiersId}.${BillingPeriod.Annually}[${entityIndex}].endUnit`;

  return (
    <CellTextField errors={formRenderProps.errors} touched={formRenderProps.touched} id={currentTierEndUnitId}>
      <Grid key={entityIndex} item>
        <TextField
          type="number"
          isNumberWithoutSigns
          disabled={disableUnitChanges}
          name={currentTierEndUnitId}
          touched={!!get(formRenderProps.touched, currentTierEndUnitId)}
          error={!!get(formRenderProps.errors, currentTierEndUnitId)}
          fullWidth
          endAdornment={endAdornment}
          label=""
          value={priceTier.endUnit}
          InputProps={!disableUnitChanges ? {} : { style: { borderRadius: 0 } }}
          dataTestId={`tier-${entityIndex}-end-unit`}
          onChange={(e: any) => {
            const endUnitValue = e.target.value ? Number(e.target.value) : undefined;
            formRenderProps.setFieldValue(monthlyTierEndUnitId, endUnitValue);
            formRenderProps.setFieldTouched(monthlyTierEndUnitId, true);
            formRenderProps.setFieldValue(annuallyTierEndUnitId, endUnitValue);
            formRenderProps.setFieldTouched(annuallyTierEndUnitId, true);

            if (priceTier.unitPrice && endUnitValue && shouldUpdateTierPrice) {
              formRenderProps.setFieldValue(tierPriceUnitId, endUnitValue * priceTier.unitPrice);
              formRenderProps.setFieldTouched(tierPriceUnitId, true);
            }
          }}
        />
      </Grid>
    </CellTextField>
  );
}

function getUnitTierHeadCells({
  formRenderProps,
  billingPeriod,
  currency,
  lastIndex,
  pricePeriodUnit,
  priceTiersId,
  tiers,
  disableUnitChanges,
  tiersSchema,
}: HeadCellsProps): Array<HeadCell<PriceTier, any>> {
  return [
    {
      id: 'startUnit',
      alignment: 'left',
      label: (
        <Text.B2 $medium ml={4}>
          {t('pricing.tieredStartUnit')}
        </Text.B2>
      ),
      disablePadding: true,
      render: (_, entityIndex) => {
        const currentTierStartUnitId = `${priceTiersId}.${billingPeriod}[${entityIndex}].startUnit`;
        let startValue: number | null = 1;
        if (entityIndex !== 0) {
          const lastTierEndUnit = tiers[entityIndex - 1].endUnit;
          if (lastTierEndUnit) {
            startValue = lastTierEndUnit + 1;
          } else {
            startValue = null;
          }
        }

        return (
          <CellTextField errors={formRenderProps.errors} touched={formRenderProps.touched} id={currentTierStartUnitId}>
            <Grid key={entityIndex} item>
              <TextField
                type="text"
                fullWidth
                error={false}
                touched={false}
                disabled
                label=""
                value={startValue}
                InputProps={{ style: { borderRadius: 0 } }}
              />
            </Grid>
          </CellTextField>
        );
      },
    },
    {
      id: 'endUnit',
      alignment: 'left',
      label: (
        <Text.B2 $medium ml={4}>
          {t('pricing.tieredEndUnit')}
        </Text.B2>
      ),
      disablePadding: true,
      render: (priceTier, entityIndex) => {
        if (TIERS_SCHEMA_PER_UNIT.includes(tiersSchema) && entityIndex === lastIndex) {
          return (
            <GridFlex.RowCenter columnGap={2} pl={4}>
              <FallbackTierIcon iconColor="other.outlineBorder" textColor="disabled" />
            </GridFlex.RowCenter>
          );
        }
        return (
          <EndUnitCell
            priceTier={priceTier}
            entityIndex={entityIndex}
            billingPeriod={billingPeriod}
            disableUnitChanges={disableUnitChanges}
            formRenderProps={formRenderProps}
            priceTiersId={priceTiersId}
            endAdornment={entityIndex === lastIndex ? <Text.Caption>or more</Text.Caption> : undefined}
            shouldUpdateTierPrice={false}
          />
        );
      },
    },
    {
      id: 'unitPrice',
      alignment: 'left',
      label: (
        <GridFlex.RowCenter ml={4}>
          <Text.B2 $medium mr={1}>
            {t('pricing.unitPrice')}
          </Text.B2>
          <Text.B2 color="disabled">/ {t(`pricing.shortBillingPeriodPriceDescription.${pricePeriodUnit}`)}</Text.B2>
        </GridFlex.RowCenter>
      ),
      disablePadding: true,
      render: (_, entityIndex) => {
        const tierId = `${priceTiersId}.${billingPeriod}[${entityIndex}]`;
        const tierUnitPriceId = `${tierId}.unitPrice`;

        return (
          <CellTextField errors={formRenderProps.errors} touched={formRenderProps.touched} id={tierUnitPriceId}>
            {drawFormFields(
              [
                {
                  id: tierUnitPriceId,
                  type: 'text',
                  textFieldType: 'number',
                  isNumberWithoutSigns: true,
                  hideErrorText: true,
                  placeholder: t('pricing.priceAmountPlaceholder'),
                  startAdornment: getSymbolFromCurrency(currency),
                  dataTestId: `tier-${entityIndex}-unit-price`,
                  handleChange:
                    ({ setFieldValue, setFieldTouched }) =>
                    (e: any) => {
                      const unitPrice = e.target.value ? Number(e.target.value) : undefined;
                      setFieldValue(tierUnitPriceId, unitPrice);
                      setFieldTouched(tierUnitPriceId, true);
                    },
                },
              ],
              formRenderProps,
            )}
          </CellTextField>
        );
      },
    },
    {
      id: 'flatPrice',
      alignment: 'left',
      label: (
        <GridFlex.RowCenter ml={4}>
          <Text.B2 $medium mr={1}>
            {t('pricing.tierFlatFee')}
          </Text.B2>
          <Text.B2 color="disabled">/ {t(`pricing.shortBillingPeriodPriceDescription.${pricePeriodUnit}`)}</Text.B2>
        </GridFlex.RowCenter>
      ),
      disablePadding: true,
      render: (_, entityIndex) => {
        const tierId = `${priceTiersId}.${billingPeriod}[${entityIndex}]`;
        const tierUnitPriceId = `${tierId}.tierPrice`;

        return (
          <CellTextField errors={formRenderProps.errors} touched={formRenderProps.touched} id={tierUnitPriceId}>
            {drawFormFields(
              [
                {
                  id: tierUnitPriceId,
                  type: 'text',
                  textFieldType: 'number',
                  isNumberWithoutSigns: true,
                  hideErrorText: true,
                  placeholder: t('pricing.priceAmountPlaceholder'),
                  startAdornment: getSymbolFromCurrency(currency),
                  dataTestId: `tier-${entityIndex}-flat-price`,
                  handleChange:
                    ({ setFieldValue, setFieldTouched }) =>
                    (e: any) => {
                      const unitPrice = e.target.value ? Number(e.target.value) : undefined;
                      setFieldValue(tierUnitPriceId, unitPrice);
                      setFieldTouched(tierUnitPriceId, true);
                    },
                },
              ],
              formRenderProps,
            )}
          </CellTextField>
        );
      },
    },
  ];
}

function getEntireTierHeadCells({
  formRenderProps,
  billingPeriod,
  currency,
  pricePeriodUnit,
  priceTiersId,
  disableUnitChanges,
}: HeadCellsProps): Array<HeadCell<PriceTier, any>> {
  return [
    {
      id: 'endUnit',
      alignment: 'left',
      label: (
        <Text.B2 $medium ml={4}>
          {t('pricing.tieredNumberOfUnit')}
        </Text.B2>
      ),
      disablePadding: true,
      render: (priceTier, entityIndex) => {
        return (
          <EndUnitCell
            priceTier={priceTier}
            entityIndex={entityIndex}
            billingPeriod={billingPeriod}
            disableUnitChanges={disableUnitChanges}
            formRenderProps={formRenderProps}
            priceTiersId={priceTiersId}
          />
        );
      },
    },
    {
      id: 'unitPrice',
      alignment: 'left',
      label: (
        <GridFlex.RowCenter ml={4}>
          <Text.B2 $medium mr={1}>
            {t('pricing.unitPrice')}
          </Text.B2>
          <Text.B2 color="disabled">/ {t(`pricing.shortBillingPeriodPriceDescription.${pricePeriodUnit}`)}</Text.B2>
        </GridFlex.RowCenter>
      ),
      disablePadding: true,
      render: (priceTier, entityIndex) => {
        const tierId = `${priceTiersId}.${billingPeriod}[${entityIndex}]`;
        const tierUnitPriceId = `${tierId}.unitPrice`;
        const tierPriceUnitId = `${tierId}.tierPrice`;

        return (
          <CellTextField errors={formRenderProps.errors} touched={formRenderProps.touched} id={tierUnitPriceId}>
            {drawFormFields(
              [
                {
                  id: tierUnitPriceId,
                  type: 'text',
                  textFieldType: 'number',
                  isNumberWithoutSigns: true,
                  hideErrorText: true,
                  placeholder: t('pricing.priceAmountPlaceholder'),
                  startAdornment: getSymbolFromCurrency(currency),
                  endAdornment: <SyncPriceIcon placement="right" />,
                  dataTestId: `tier-${entityIndex}-unit-price`,
                  handleChange:
                    ({ setFieldValue, setFieldTouched }) =>
                    (e: any) => {
                      const unitPrice = e.target.value ? Number(e.target.value) : undefined;
                      setFieldValue(tierUnitPriceId, unitPrice);
                      setFieldTouched(tierUnitPriceId, true);
                      setFieldValue(
                        tierPriceUnitId,
                        unitPrice && priceTier.endUnit
                          ? roundMaximumFractionDigits(unitPrice * priceTier.endUnit)
                          : undefined,
                      );
                      setFieldTouched(tierPriceUnitId, true);
                    },
                },
              ],
              formRenderProps,
            )}
          </CellTextField>
        );
      },
    },
    {
      id: 'tierPrice',
      alignment: 'left',
      label: (
        <GridFlex.RowCenter ml={4}>
          <Text.B2 $medium mr={1}>
            {t('pricing.tierPrice')}
          </Text.B2>
          <Text.B2 color="disabled">/ {t(`pricing.shortBillingPeriodPriceDescription.${pricePeriodUnit}`)}</Text.B2>
        </GridFlex.RowCenter>
      ),
      disablePadding: true,
      render: (priceTier, entityIndex) => {
        const tierId = `${priceTiersId}.${billingPeriod}[${entityIndex}]`;
        const tierUnitPriceId = `${tierId}.unitPrice`;
        const tierPriceUnitId = `${tierId}.tierPrice`;

        return (
          <CellTextField errors={formRenderProps.errors} touched={formRenderProps.touched} id={tierPriceUnitId}>
            {drawFormFields(
              [
                {
                  id: tierPriceUnitId,
                  type: 'text',
                  textFieldType: 'number',
                  isNumberWithoutSigns: true,
                  hideErrorText: true,
                  placeholder: t('pricing.priceAmountPlaceholder'),
                  startAdornment: getSymbolFromCurrency(currency),
                  endAdornment: <SyncPriceIcon placement="left" />,
                  dataTestId: `tier-${entityIndex}-tier-price`,
                  handleChange:
                    ({ setFieldTouched, setFieldValue }) =>
                    (e: any) => {
                      const tierPrice = e.target.value ? Number(e.target.value) : undefined;
                      setFieldValue(
                        tierUnitPriceId,
                        tierPrice && priceTier.endUnit
                          ? roundMaximumFractionDigits(tierPrice / priceTier.endUnit)
                          : undefined,
                      );
                      setFieldTouched(tierUnitPriceId, true);
                      setFieldValue(tierPriceUnitId, tierPrice);
                      setFieldTouched(tierPriceUnitId, true);
                    },
                },
              ],
              formRenderProps,
            )}
          </CellTextField>
        );
      },
    },
  ];
}

export const headCells = ({
  formRenderProps,
  billingPeriod,
  currency,
  lastIndex,
  pricePeriodUnit,
  priceTiersId,
  tiersSchema,
  tiers,
  disableUnitChanges,
}: HeadCellsProps): Array<HeadCell<PriceTier, any>> => {
  if (TIERS_SCHEMA_PER_UNIT.includes(tiersSchema)) {
    return getUnitTierHeadCells({
      formRenderProps,
      billingPeriod,
      currency,
      lastIndex,
      pricePeriodUnit,
      priceTiersId,
      tiersSchema,
      tiers,
      disableUnitChanges,
    });
  }

  return getEntireTierHeadCells({
    formRenderProps,
    billingPeriod,
    currency,
    lastIndex,
    pricePeriodUnit,
    priceTiersId,
    tiersSchema,
    tiers,
    disableUnitChanges,
  });
};
