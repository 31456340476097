export const MAX_FRACTION_DIGITS = 7;

/** This function will only work for scientific notation numbers (containing "e") */
export function getAmountOfDecimalDigitsInScientificNotation(numberAsString: string) {
  if (numberAsString.includes('e+')) return 0;

  if (!numberAsString.includes('e-')) throw new Error('Provided number is not in scientific notation');

  const extractScientificNotationNumberDecimalDigits = new RegExp(
    `(?<numberBeforeE>(\\d\\.)?[\\d]+)[eE]-?(?<decimalAmountAfterE>\\d+)`,
  );
  const { decimalAmountAfterE, numberBeforeE } =
    numberAsString.match(extractScientificNotationNumberDecimalDigits)?.groups || {};

  const lengthOfStringBeforeE = numberBeforeE?.replace('.', '')?.length || 0;
  const amountBeforeE = lengthOfStringBeforeE - 1;

  return Number(decimalAmountAfterE || 0) + amountBeforeE;
}

/** This function won't be accurate for large numbers that JS limit the amount of decimal digits amount for (for example 100000000000.42) */
export function validateMaximumFractionDigits(
  val: number | undefined,
  numberOfDecimalDigits: number = MAX_FRACTION_DIGITS,
) {
  if (val === undefined) return true;

  const numberAsString = val.toString();

  if (numberAsString.includes('e-')) {
    return getAmountOfDecimalDigitsInScientificNotation(numberAsString) <= numberOfDecimalDigits;
  }

  const maximumFractionDigitsRegex = new RegExp(`^\\d+(\\.\\d{0,${numberOfDecimalDigits}})?$`);
  return maximumFractionDigitsRegex.test(numberAsString);
}
