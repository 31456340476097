import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { GridFlex, Divider, Text, Alert, AlertProps, Icon } from '@stigg-components';
import { SubscriptionDataFragment, SubscriptionMaximumSpendFragment } from '@stigg-types/apiTypes';
import { ChargesBreakdown, SubTotalSection, DiscountsSection, AmountDueSection, MaximumSpendSection } from '.';

type MaximumSpendBreakdownProps = {
  maxSpend: SubscriptionMaximumSpendFragment;
  subscription?: SubscriptionDataFragment;
};

function getMaximumSpendAlertProps(usedPercentage: number): Pick<AlertProps, 'severity' | 'icon'> {
  if (usedPercentage < 80) {
    return { severity: 'info', icon: <Icon icon="InfoOutlined" color="default" /> };
  }
  if (usedPercentage >= 80 && usedPercentage < 100) {
    return { severity: 'warning', icon: <Icon icon="AlertTriangle" color="warning" /> };
  }

  return { severity: 'error', icon: <Icon icon="BudgetExceeded" color="error" /> };
}

export function MaximumSpendBreakdown({ maxSpend, subscription }: MaximumSpendBreakdownProps) {
  const { lines, total, maximumSpend, subTotal, discount, discountDetails } = maxSpend;
  const budgetUsed = Math.floor((total.amount / maximumSpend.amount) * 100);
  const alertProps = getMaximumSpendAlertProps(budgetUsed);

  return (
    <GridFlex.Column container>
      {/* @ts-ignore FIX-ME */}
      <ChargesBreakdown plan={subscription?.plan} lines={lines || []} />

      <Divider my={4} />

      <SubTotalSection subTotal={subTotal} />

      <DiscountsSection discount={discount} discountDetails={discountDetails} />

      <Divider mt={4} />

      <AmountDueSection description={t('subscriptions.maxSpend.currentSpend')} amountDue={total} />

      <MaximumSpendSection maximumSpend={maximumSpend} />

      <Alert {...alertProps} mt={4}>
        <Text.B2 color="primary">
          <Trans
            i18nKey="subscriptions.maxSpend.breakdownAlert"
            values={{ usedPercentage: budgetUsed }}
            components={[<Text.B2 $bold display="inline" />]}
          />
        </Text.B2>
      </Alert>
    </GridFlex.Column>
  );
}
