import { ExternalLink, InformationTooltip, Grid, Box, Text, GridFlex, LongText } from '@stigg-components';
import { BillingPeriod, PackageStatus, PricingType, ProductPlanFragment, WidgetType } from '@stigg-types/apiTypes';
import { styled } from '@stigg-theme';
import { t } from 'i18next';
import React from 'react';
import { getPriceAndPeriodFormat } from '../../../packages/pricing/utils/priceFormatUtils';
import { PlanOfferingHeaderIcons } from './PlanOfferingHeaderIcons';
import {
  PriceBreakdown,
  useTotalPrice,
} from '../../../customers/components/customerPage/customerSubscriptions/priceBreakdown';

const Header = styled(Grid)<{ $isHidden?: boolean }>`
  height: 102px;
  padding: 16px;
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  background-color: ${({ theme, $isHidden }) => {
    if ($isHidden) {
      return theme.itamar.palette.background.default;
    }
    if (theme.isLightTheme) {
      return theme.itamar.palette.white;
    }
    return theme.itamar.palette.background.default;
  }};
  transition: all 1s ease-in-out;
`;

const ContentBox = styled(Box)<{ $isDraft?: boolean }>`
  flex: 1;
  transition: background-color 50ms;
  opacity: ${({ $isDraft }) => ($isDraft ? 0.5 : 1)};
  border-top: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
`;

export const PlanCard = styled(Box)<{ $isHidden?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ theme }) => (theme.isLightTheme ? '' : `background-color: ${theme.itamar.palette.background.default}`)};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};

  ${({ $isHidden, theme }) => ($isHidden ? `background-color: ${theme.itamar.palette.background.default};` : '')}

  cursor: pointer;
  height: 100%;
  width: 100%;

  &:hover {
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};

    ${ContentBox} {
      background-color: ${({ theme }) => theme.itamar.palette.background.default};
    }
  }
`;

type PlanOfferingProps = {
  plan: ProductPlanFragment;
  showPriceByMonth: boolean;
  priceBreakdown: PriceBreakdown | null;
  selectedBillingPeriod: BillingPeriod;
  onPlanClick: () => void;
  hasPendingChanges?: boolean;
  children: React.ReactNode;
};

export function PlanOffering({
  plan,
  onPlanClick,
  selectedBillingPeriod,
  priceBreakdown,
  showPriceByMonth,
  children,
  hasPendingChanges,
}: PlanOfferingProps) {
  const title = plan.displayName;
  const billingPeriod = priceBreakdown?.billingPeriod;
  const isDraft = plan.status === PackageStatus.Draft;
  const trialConfig = plan.defaultTrialConfig;
  const isHiddenInPaywall = (plan.hiddenFromWidgets || []).includes(WidgetType.Paywall);

  const { totalValue, startingAtPrefix } = useTotalPrice({ priceBreakdown });

  const getPriceTitle = () => {
    if (!plan.pricingType) {
      return t('pricing.pricingNotSet');
    }
    if (plan.pricingType === PricingType.Custom) {
      return t('pricing.customPrice');
    }
    if (plan.pricingType === PricingType.Free) {
      return t('pricing.freePricingType');
    }
    // Price not set for the selected billing period
    if (!priceBreakdown?.planBreakdown?.prices?.length) {
      return t('pricing.pricingNotSet');
    }

    let formattedPrice = '';
    if (priceBreakdown.planBreakdown.prices.length === 1) {
      formattedPrice = getPriceAndPeriodFormat(priceBreakdown.planBreakdown.prices[0].price, {
        shortFormat: true,
        reversedPeriod: showPriceByMonth,
        totalPrice: true,
      });
    } else {
      formattedPrice = getPriceAndPeriodFormat(
        {
          price: { amount: totalValue || 0, currency: priceBreakdown.currency },
          billingPeriod: selectedBillingPeriod,
          feature: null,
          // @ts-ignore
          billingModel: null,
          // @ts-ignore
          id: null,
        },
        { shortFormat: true, reversedPeriod: showPriceByMonth },
      );
    }

    return startingAtPrefix ? `${startingAtPrefix} ${formattedPrice}` : formattedPrice;
  };

  return (
    <InformationTooltip
      arrow
      placement="top"
      title={
        isDraft ? (
          <>
            <Text.B2 mb={2}>{t('products.draftChipTooltipText')}</Text.B2>
            <ExternalLink label={t('sharedComponents.learnMore')} url={t('products.draftChipTooltipDocsLink')} />
          </>
        ) : (
          ''
        )
      }>
      <PlanCard onClick={onPlanClick} $isHidden={isHiddenInPaywall}>
        <Header container flexDirection="column" wrap="nowrap" $isHidden={isHiddenInPaywall}>
          <GridFlex.RowSpaceBetweenTop container wrap="nowrap" flex={1}>
            <GridFlex.Column justifyContent="flex-start" flex={1}>
              <GridFlex.RowSpaceBetween item container wrap="nowrap" gap={2} mb={1}>
                <Grid item>
                  <LongText variant="h6">{title}</LongText>
                </Grid>

                <Grid item>
                  <PlanOfferingHeaderIcons
                    isHidden={isHiddenInPaywall}
                    isDraft={isDraft}
                    hasPendingChanges={hasPendingChanges}
                    trialConfig={trialConfig}
                  />
                </Grid>
              </GridFlex.RowSpaceBetween>

              <LongText variant="body1" color="primary" sx={{ wordBreak: 'break-all' }}>
                {getPriceTitle()}
              </LongText>

              {billingPeriod && (
                <Text.B2 color="secondary" sx={{ whiteSpace: 'nowrap' }}>
                  {t(`pricing.billedPeriod.${billingPeriod}`)}
                </Text.B2>
              )}
            </GridFlex.Column>
          </GridFlex.RowSpaceBetweenTop>
        </Header>

        <ContentBox pt={2} $isDraft={isDraft}>
          {children}
        </ContentBox>
      </PlanCard>
    </InformationTooltip>
  );
}
