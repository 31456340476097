import { ExternalLink, GridFlex, Text } from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { IntegrationFragment, StripeCredentialsFragment } from '@stigg-types/apiTypes';
import { RootState } from '../../../redux/store';
import { Optional } from '../../../types/primitives';
import { ClipboardChip } from '@stigg-components';
import { EditAliasRow } from './common';
import { IntegrationDetailsRow } from './common/IntegrationDetailsRow';

export type StripeIntegrationDetailsProps = { integration: IntegrationFragment };

export function StripeIntegrationDetails({ integration }: StripeIntegrationDetailsProps) {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const isStiggUser = user?.email?.endsWith('@stigg.io');
  const credentials = integration.credentials as Optional<StripeCredentialsFragment>;
  if (!credentials) {
    return null;
  }
  const { accountDisplayName, isTestMode, accountId } = credentials;

  const stripeConsoleUrl = isStiggUser
    ? `https://dashboard.stripe.com${isTestMode ? '/test' : ''}/connect/accounts/${accountId}/activity`
    : `https://dashboard.stripe.com/${accountId}`;

  return (
    <GridFlex.Column gap={4}>
      <IntegrationDetailsRow
        label={<Text.Sub2>{t('integrations.stripe.accountName')}</Text.Sub2>}
        value={
          <ExternalLink url={stripeConsoleUrl}>
            {accountDisplayName}
            {isTestMode && ' (Test)'}
          </ExternalLink>
        }
      />

      <IntegrationDetailsRow
        label={<Text.Sub2>{t('integrations.stripe.accountId')}</Text.Sub2>}
        value={<ClipboardChip text={accountId} copy={accountId} iconLocation="right" variant="code" />}
      />

      <EditAliasRow integration={integration} />
    </GridFlex.Column>
  );
}
