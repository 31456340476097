import React from 'react';
import {
  Grid,
  TextField,
  Text,
  GridFlex,
  InformationTooltip,
  Box,
  FormRenderProps,
  Icon,
  Switch,
  FormControlLabel,
  drawFormFields,
  CollapsableSection,
  SearchableSelect,
  SelectField,
  SelectFieldProps,
} from '@stigg-components';
import { FormikHandlers } from 'formik';
import { t } from 'i18next';
import { get, isFunction } from 'lodash';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { getDateRemainingDays } from '@stigg-common';
import { Field, renderTextFieldFn } from './Form.types';
import { DynamicDataSearchableSelect } from '../DynamicDataSearchableSelect';
import { OptionalField } from '../optionalField/OptionalField';

export function handleFormField<FormValues extends Record<string, any>>(
  field: Field<FormValues>,
  formRenderProps: FormRenderProps<FormValues>,
): React.ReactNode {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = formRenderProps;

  const onChange: FormikHandlers['handleChange'] = (e: React.ChangeEvent<any>) => {
    const handleChangeAction = field?.handleChange?.(formRenderProps) ?? handleChange;

    return handleChangeAction(e);
  };

  switch (field.type) {
    case 'idle': {
      return field.component;
    }
    case 'text': {
      const isOutlinedVariant = field.variant === 'outlined';

      return (
        <TextField
          autoFocus={field.autoFocus}
          type={field.textFieldType || 'text'}
          tooltip={field.tooltip}
          InputProps={field.inputProps}
          onKeyDown={field.onKeyDown}
          name={field.id}
          label={isOutlinedVariant ? '' : field.label}
          value={get(values, field.id)}
          touched={!!get(touched, field.id)}
          error={!!get(errors, field.id)}
          fullWidth
          errorText={!field.hideErrorText ? (get(errors, field.id) as string) : null}
          onBlur={handleBlur}
          onChange={onChange}
          placeholder={field.placeholder}
          disabled={!!field.disabled}
          helpTooltipText={field.helpTooltipText}
          helpTooltipMaxWidth={field.helpTooltipMaxWidth}
          optional={field.optional}
          captionText={field.captionText}
          isNumberWithoutSigns={field.isNumberWithoutSigns}
          isNumberWithoutFraction={field.isNumberWithoutFraction}
          startAdornment={field.startAdornment}
          endAdornment={field.endAdornment}
          dataTestId={field.dataTestId}
          {...field.textFieldProps}
        />
      );
    }
    case 'optional': {
      return <OptionalField field={field} formRenderProps={formRenderProps} />;
    }
    case 'select': {
      return <SelectField field={field} values={values} handleChange={onChange} />;
    }
    case 'searchableSelect': {
      return (
        <SearchableSelect
          options={field.options}
          variant={field.variant}
          searchPlaceholder={field.searchPlaceholder}
          maxWidth={field.maxWidth}
          menuMaxHeight={field.menuMaxHeight}
          selectedOptionId={get(values, field.id)}
          placeholder={field.placeholder}
          maxOptionsToShow={field.maxOptionsToShow}
          isOptionDisabled={field.isOptionDisabled}
          onChange={field.onChange ? (id) => field.onChange!(id) : (itemKey) => setFieldValue(field.id, itemKey)}
          label={field.label}
          dataTestId={field.dataTestId}
          disable={field.disable}
          tooltip={field.tooltip}
        />
      );
    }
    case 'dynamicDataSearchableSelect': {
      return (
        <DynamicDataSearchableSelect
          label={field.label}
          placeholder={field.placeholder}
          useDataLoader={field.useDataLoader}
          selectedOptionId={get(values, field.id)}
          onChange={(option) => {
            if (field.onChange) {
              field.onChange(option);
            } else {
              void setFieldValue(field.id, option.id);
            }
          }}
          dataTestId={field.dataTestId}
          formRenderProps={formRenderProps}
          footerComponent={field.footerComponent}
        />
      );
    }
    case 'switch': {
      const getActualValue = (value: boolean) => (field.negateValue ? !value : value);
      const checked: boolean = getActualValue(get(values, field.id));
      const isDisabled = isFunction(field.disabled) ? field.disabled(values) : field.disabled;
      const onSwitchChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const newValue = getActualValue(checked);
        void setFieldValue(field.id, newValue);
        field.onChange?.(newValue);
      };

      return (
        <FormControlLabel
          name={field.id}
          value={checked}
          label={<Text.B2>{field.label}</Text.B2>}
          control={
            field.disabledTooltipProps?.title ? (
              <InformationTooltip
                arrow
                placement="top"
                $maxWidth={field.disabledTooltipProps?.maxWidth}
                title={isDisabled && <Text.B2>{field.disabledTooltipProps?.title}</Text.B2>}>
                <Grid>
                  <Switch
                    disabled={isDisabled}
                    checked={checked}
                    onChange={onSwitchChange}
                    data-testid={field.dataTestId}
                  />
                </Grid>
              </InformationTooltip>
            ) : (
              <Switch
                disabled={isDisabled}
                checked={checked}
                onChange={onSwitchChange}
                data-testid={field.dataTestId}
              />
            )
          }
        />
      );
    }
    case 'datePicker': {
      const { remainingDays, isInRange, isLessThenOneDay } = getDateRemainingDays(values[field.id]);
      const isDisabled = isFunction(field.disabled) ? field.disabled(values) : field.disabled;

      return (
        <Grid container>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={values[field.id]}
              disabled={isDisabled}
              disableFuture={field.disableFuture}
              InputAdornmentProps={{
                sx: { pr: 2 },
              }}
              inputFormat="DD/MM/YYYY"
              onChange={(value) => {
                void setFieldTouched(field.id);
                void setFieldValue(field.id, value);
              }}
              PaperProps={{
                sx: {
                  boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow,
                },
              }}
              components={{
                OpenPickerIcon: () => (
                  <Icon icon="DateRange" type="materialIcons" color={isDisabled ? 'disabled' : 'active'} />
                ),
              }}
              renderInput={(params) => (
                <TextField
                  name="datePick"
                  fullWidth
                  {...params}
                  label={field.label}
                  onBlur={handleBlur}
                  errorText={t('fieldValidationMessages.invalidDate')}
                  error={!!errors[field.id]}
                  touched={!!touched[field.id]}
                  captionText={
                    (field.showRemainingDays &&
                      isInRange &&
                      (isLessThenOneDay
                        ? t('subscriptions.inDaysZero')
                        : t('subscriptions.inDays_other', { count: remainingDays }))) ||
                    undefined
                  }
                />
              )}
              minDate={field.minDate}
            />
          </LocalizationProvider>
        </Grid>
      );
    }
    case 'custom': {
      const renderTextField: renderTextFieldFn = (id, label, props) => {
        const { hideErrorText, ...restProps } = props || {};
        return (
          <TextField
            type="text"
            name={id}
            label={label}
            value={get(values, id)}
            touched={!!get(touched, id)}
            error={!!get(errors, id)}
            errorText={hideErrorText ? null : get(touched, id) && (get(errors, id) as string)}
            fullWidth
            onBlur={handleBlur}
            onChange={onChange}
            {...restProps}
          />
        );
      };

      const renderSelect = (
        id: string,
        label: string,
        selectValues: Array<{ value: any; displayValue?: string; subtitle?: string }>,
        restProps?: SelectFieldProps<FormValues>['restProps'],
      ) => {
        return (
          <SelectField
            field={{ id, label, values: selectValues }}
            values={formRenderProps.values}
            handleChange={onChange}
            restProps={restProps}
          />
        );
      };
      return field.render({
        ...formRenderProps,
        renderSelect,
        renderTextField,
      });
    }

    case 'layout': {
      return (
        <GridFlex.Row $fullWidth gap={4} {...(field.contentGridProps || {})}>
          {field.label && (
            <GridFlex.Row mr={10} width={85} {...(field.labelGridProps || {})}>
              <Text.B2 color="primary">{field.label}</Text.B2>
            </GridFlex.Row>
          )}
          {field.fields.map(
            (subField, index) =>
              !subField.hide?.(values) && (
                <GridFlex.Item key={index} flex={1} {...(subField.gridProps || {})}>
                  {handleFormField(subField, formRenderProps)}
                </GridFlex.Item>
              ),
          )}
        </GridFlex.Row>
      );
    }

    case 'inline': {
      return (
        <Box display="inline">
          {field.fields.map(
            (subField, index) =>
              !subField.hide?.(values) && (
                <Box key={index} display="inline">
                  {handleFormField(subField, formRenderProps)}
                </Box>
              ),
          )}
        </Box>
      );
    }

    case 'collapse': {
      return (
        <CollapsableSection
          title={field.title}
          titleTextProps={field.titleTextProps}
          isSectionOpen={field.open}
          onClick={(isOpen) => field.onClick?.(isOpen, formRenderProps)}
          titleGridSx={field.titleGridSx}
          content={drawFormFields(field.fields, formRenderProps)}
          $fullWidth
          hideIcon={field.hideIcon}
          gap={field.gap}
          iconSize={field.iconSize}
          dataTestId={field.dataTestId}
        />
      );
    }

    default:
      return null;
  }
}
