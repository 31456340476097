import get from 'lodash/get';
import { ReactNode } from 'react';
import { GridProps } from '@stigg-components/types';
import {
  drawFormFields,
  FormProps,
  FormRenderProps,
  GridFlex,
  Text,
  Icon,
  IconButton,
  Icons,
  IconProps,
  Button,
  IconType,
} from '@stigg-components';
import { FormikHelpers } from 'formik';
import { SubscriptionFormFields } from '../SubscriptionForm.types';

export type AdditionalInputActionParams = Pick<
  FormikHelpers<SubscriptionFormFields>,
  'setFieldValue' | 'setFieldTouched'
>;

export type AdditionalInputLayoutProps = {
  title?: string;
  subtitle?: string;
  isSet?: boolean;
  setIcon?: { icon: Icons; type?: IconType } & Omit<IconProps, 'icon'>;
  unsetIcon?: { icon?: Icons; type?: IconType } & Omit<IconProps, 'icon'>;
  setLabel: string;
  unsetLabel?: string;
  buttonLabel?: string;
  fields?: FormProps<SubscriptionFormFields>['fields'];
  inputComponent?: ReactNode;
  onSet?: (args: AdditionalInputActionParams) => void;
  onUnset?: (args: AdditionalInputActionParams) => void;
  formRenderProps: FormRenderProps<SubscriptionFormFields>;
  gridProps?: Partial<GridProps>;
  inputGridProps?: Partial<GridProps>;
  hideErrorText?: boolean;
};

function FormLabel({
  isSet,
  setIcon,
  unsetIcon,
  setLabel,
  unsetLabel,
}: Pick<AdditionalInputLayoutProps, 'isSet' | 'setIcon' | 'unsetIcon' | 'setLabel' | 'unsetLabel'>) {
  return (
    <GridFlex.RowCenter gap={2} width={165} pl={setIcon ? 0 : 8}>
      {setIcon && (
        <Icon
          {...(isSet ? setIcon : unsetIcon || setIcon)}
          icon={(isSet ? setIcon.icon : unsetIcon?.icon) || setIcon.icon}
          color={(isSet ? setIcon.color : unsetIcon?.color) || 'default'}
          type={isSet ? setIcon.type : unsetIcon?.type}
        />
      )}
      <Text.B2 color="primary">{isSet ? setLabel : unsetLabel || setLabel}</Text.B2>
    </GridFlex.RowCenter>
  );
}

function FormInput({
  onClick,
  fields,
  formRenderProps,
  inputComponent,
  inputGridProps,
  hideErrorText,
}: Pick<
  AdditionalInputLayoutProps,
  'fields' | 'inputComponent' | 'formRenderProps' | 'inputGridProps' | 'hideErrorText'
> & {
  onClick?: () => void;
}) {
  const { errors, touched } = formRenderProps;
  const fieldId = fields?.[0].id;
  const fieldError = get(errors, fieldId);
  const fieldTouched = get(touched, fieldId);

  return (
    <GridFlex.RowMiddle gap={1} {...inputGridProps}>
      {fields ? drawFormFields(fields, formRenderProps) : null}
      {inputComponent}
      {!!onClick && (
        <IconButton onClick={onClick}>
          <Icon icon="Close" />
        </IconButton>
      )}
      {!hideErrorText && fieldError && fieldTouched ? <Text.B2 color="error">{fieldError}</Text.B2> : null}
    </GridFlex.RowMiddle>
  );
}

function FormSetButton({ label, onClick }: { label?: string; onClick?: () => void }) {
  return (
    <GridFlex.Row>
      <Button
        variant="outlined"
        sx={{ justifyContent: 'left' }}
        fullWidth
        onClick={onClick}
        disabled={!onClick}
        startIcon={<Icon icon="Plus" />}>
        {label}
      </Button>
    </GridFlex.Row>
  );
}

export function AdditionalInputLayout({
  title,
  subtitle,
  isSet,
  setIcon,
  unsetIcon,
  setLabel,
  unsetLabel,
  buttonLabel,
  fields,
  inputComponent,
  onSet,
  onUnset,
  formRenderProps,
  gridProps,
  inputGridProps,
  hideErrorText,
}: AdditionalInputLayoutProps) {
  const { setFieldValue, setFieldTouched } = formRenderProps;
  const handleUnset = !onUnset
    ? undefined
    : () => {
        onUnset?.({ setFieldValue, setFieldTouched });
      };

  const handleSet = !onSet
    ? undefined
    : () => {
        onSet?.({ setFieldValue, setFieldTouched });
      };

  return (
    <GridFlex.Column gap={2}>
      {title && <Text.H6>{title}</Text.H6>}
      {subtitle && <Text.B2>{subtitle}</Text.B2>}
      <GridFlex.RowCenter minHeight={40} {...gridProps}>
        <FormLabel isSet={isSet} setIcon={setIcon} unsetIcon={unsetIcon} setLabel={setLabel} unsetLabel={unsetLabel} />
        {isSet ? (
          <FormInput
            onClick={handleUnset}
            fields={fields}
            inputComponent={inputComponent}
            formRenderProps={formRenderProps}
            inputGridProps={inputGridProps}
            hideErrorText={hideErrorText}
          />
        ) : (
          <FormSetButton label={buttonLabel} onClick={handleSet} />
        )}
      </GridFlex.RowCenter>
    </GridFlex.Column>
  );
}
