import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { Auth0ApplicationType } from '@stigg-types/apiTypes';
import styled from 'styled-components/macro';
import {
  Icon,
  Box,
  Text,
  GridFlex,
  HighlightText,
  FormRenderProps,
  drawFormFields,
  Icons,
  Collapse,
} from '@stigg-components';
import { Auth0IntegrationFormFields } from './useAuth0Form';
import { useGetAuth0Applications } from '../../queries/fetchAuth0Applications';

type Auth0ApplicationsProps = {
  formRenderProps: FormRenderProps<Auth0IntegrationFormFields>;
  isReadOnly: boolean;
};

type ApplicationTypeCardProps = {
  icon: Icons;
  title: string;
  description: string;
  selected?: boolean;
};

const ApplicationTypeCardBox = styled(GridFlex.Column)<{ $selected: boolean }>`
  padding: ${({ theme }) => theme.spacing(2)};
  border: ${({ theme, $selected }) =>
    `1px solid ${$selected ? theme.itamar.palette.primary.main : theme.itamar.border.borderColor}`};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border-width: 2px;
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.itamar.palette.background.lightBackground2 : undefined};
  transition: all 0.5s;
  height: 100%;
  flex: 1;
`;

function ApplicationTypeCard({ icon, title, description, selected }: ApplicationTypeCardProps) {
  return (
    <ApplicationTypeCardBox $selected={!!selected} alignItems="center" justifyContent="center">
      <GridFlex.Column flex={1} alignItems="center" justifyContent="center">
        <Icon type="custom" icon={icon} size={70} color={selected ? 'primary.main' : 'disabled'} overrideStroke />
      </GridFlex.Column>
      <GridFlex.Column alignItems="center" flex={2}>
        <Text.B1 mt={4} $bold color={selected ? 'primary' : 'disabled'}>
          {title}
        </Text.B1>
        <Text.B2 textAlign="center" color={selected ? 'secondary' : 'disabled'}>
          {description}
        </Text.B2>
      </GridFlex.Column>
    </ApplicationTypeCardBox>
  );
}

export function Auth0Applications({ formRenderProps, isReadOnly }: Auth0ApplicationsProps) {
  const { values } = formRenderProps;

  const { data: applications } = useGetAuth0Applications({
    clientId: values.clientId,
    clientSecret: values.clientSecret,
    clientDomain: values.clientDomain,
  });

  const filteredApps = useMemo(() => applications?.filter((app) => !!app.type), [applications]);

  const selectedApp = useMemo(
    () => applications?.find((app) => app.appId === values.applicationId),
    [applications, values.applicationId],
  );

  useEffect(() => {
    if (selectedApp) {
      void formRenderProps.setFieldValue('applicationType', selectedApp.type);
      void formRenderProps.setFieldValue('applicationName', selectedApp.name);
      void formRenderProps.setFieldTouched('applicationType', true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp]);

  return (
    <Box>
      <Text.B2 mb={4}>{t('integrations.auth0.steps.application.subtitle')}</Text.B2>

      {drawFormFields(
        [
          {
            type: 'searchableSelect',
            id: 'applicationId',
            menuMaxHeight: 200,
            disable: isReadOnly,
            placeholder: t('integrations.auth0.steps.application.appPlaceholder'),
            options: (filteredApps || []).map((app) => ({
              id: app.appId,
              render: (search?: string) => (
                <Text.B2>
                  <HighlightText text={app.name} highlight={search} />
                </Text.B2>
              ),
              isInSearch: (search) => app.name.toLowerCase().includes(search.toLowerCase()),
            })),
          },
        ],
        formRenderProps,
      )}

      <Collapse in={!!selectedApp}>
        <GridFlex.Column mt={6}>
          <Text.B2 mb={1}>Type of users</Text.B2>

          <GridFlex.RowCenter height={219} columnGap={4} justifyContent="stretch">
            <ApplicationTypeCard
              icon="Auth0Individual"
              title={t('integrations.auth0.steps.application.individualTitle')}
              description={t('integrations.auth0.steps.application.individualDescription')}
              selected={selectedApp?.type === Auth0ApplicationType.Individual}
            />
            <ApplicationTypeCard
              icon="Auth0Business"
              title={t('integrations.auth0.steps.application.organizationTitle')}
              description={t('integrations.auth0.steps.application.organizationDescription')}
              selected={selectedApp?.type === Auth0ApplicationType.Organization}
            />
            <ApplicationTypeCard
              icon="Auth0Both"
              title={t('integrations.auth0.steps.application.bothTitle')}
              description={t('integrations.auth0.steps.application.bothDescription')}
              selected={selectedApp?.type === Auth0ApplicationType.Both}
            />
          </GridFlex.RowCenter>
        </GridFlex.Column>
      </Collapse>
    </Box>
  );
}
