import { ReactNode, useEffect } from 'react';
import { Button, FormRenderProps, GridFlex, Icon } from '@stigg-components';
import { t } from 'i18next';
import { ArrayHelpers } from 'formik';
import { BillingModel, BillingPeriod } from '@stigg-types/apiTypes';
import isEmpty from 'lodash/isEmpty';
import { generateRandomSlug } from '@stigg-common';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import {
  AbstractUsageBasedChargeType,
  BasePriceChargeType,
  ChargeType,
  SetPriceWizardFormFields,
  TiersSchema,
} from '../../SetPriceWizardForm.types';
import { ALL_BILLING_PERIODS } from '../utils/getPricePeriodFields';
import { StepsManagement } from '../../utils/useSubStepsState';
import { DEFAULT_PRICE_TIER } from '../../SetPriceWizardForm.initialValues';
import { OverageChargeAccordion } from '../OverageChargeAccordion';

export type ChargesStepProps = {
  chargesState: StepsManagement;
  renderStepActionButtons: (additionalActionButtons: ReactNode) => ReactNode;
  formRenderProps: FormRenderProps<SetPriceWizardFormFields>;
} & Pick<ArrayHelpers, 'push' | 'remove'>;

export function OverageChargesList({
  formRenderProps,
  renderStepActionButtons,
  chargesState,
  push,
  remove,
}: ChargesStepProps) {
  const { values, touched, setTouched, setFieldValue } = formRenderProps;
  const { overageCharges } = values;
  const hasCharges = !isEmpty(overageCharges.charges);
  const isEnabled = !!overageCharges.enabled;

  const updatePriceLocalizationForCharge = (charge: BasePriceChargeType | AbstractUsageBasedChargeType) => {
    const newTouched = cloneDeep(touched);
    const isUsageBasedCharge = charge.type === ChargeType.UsageBased;

    values.priceLocalization.countries.forEach((_, index) => {
      ALL_BILLING_PERIODS.forEach((billingPeriod) => {
        set(
          newTouched,
          `priceLocalization.countries[${index}].chargesPricePeriods[${charge.uuid}][${billingPeriod}]`,
          true,
        );
        if (isUsageBasedCharge) {
          set(
            newTouched,
            `priceLocalization.countries[${index}].chargesTieredPricePeriods[${charge.uuid}][${billingPeriod}]`,
            true,
          );
        }
      });
    });

    // setting touched for all price periods for all countries
    // in one action to prevent redundant rendering
    setTouched(newTouched);
  };

  const pushNewCharge = (item: BasePriceChargeType | AbstractUsageBasedChargeType) => {
    push(item);
    updatePriceLocalizationForCharge(item);
    chargesState.setFocused(item.uuid);
  };

  const createNewUsageBasedPrice = () =>
    pushNewCharge({
      uuid: generateRandomSlug(),
      type: ChargeType.UsageBased,
      billingModel: BillingModel.PerUnit, // this is default model
      tiersSchema: TiersSchema.Standard,
      feature: null,
      pricePeriods: {},
      tiers: {
        [BillingPeriod.Monthly]: [DEFAULT_PRICE_TIER],
        [BillingPeriod.Annually]: [DEFAULT_PRICE_TIER],
      },
      isConfirmed: false,
    } as AbstractUsageBasedChargeType);

  const removeCharge = (index: number) => {
    remove(index);
    if (overageCharges.charges.length === 1) {
      setFieldValue('overageCharges.enabled', false);
    }
  };

  const addChargeButtons: ReactNode = isEnabled && hasCharges && (
    <GridFlex.Row gap={4}>
      <Button variant="outlined" startIcon={<Icon icon="Plus" size={20} />} onClick={createNewUsageBasedPrice}>
        {t('pricing.overageCharges.addOverageCharge')}
      </Button>
    </GridFlex.Row>
  );

  useEffect(() => {
    if (isEnabled && !hasCharges) {
      createNewUsageBasedPrice();
    }
    if (!isEnabled) {
      overageCharges.charges.forEach((charge, index) => {
        if (!charge.isConfirmed) {
          removeCharge(index);
        }
      });
    }
    // Only create new charge when overage charges are changed to "enabled" and there are no charges
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);

  return (
    <>
      {isEnabled
        ? values.overageCharges.charges.map((charge, index) => (
            <OverageChargeAccordion
              key={charge.uuid}
              index={index}
              isFocused={chargesState.isExpanded(charge.uuid)}
              onRemove={() => removeCharge(index)}
              permanent={!charge.isConfirmed}
              onConfirm={() => chargesState.setFocused(undefined)}
              onToggle={() => chargesState.toggle(charge.uuid)}
            />
          ))
        : null}

      {renderStepActionButtons(addChargeButtons)}
    </>
  );
}
