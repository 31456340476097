import { Grid, Link, GridFlex, Text } from '@stigg-components';
import { EntitlementSummaryFragment, SubscriptionStatus } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useNavigation } from '../../../../../../navigation/useNavigation';
import { getPolicyTypeIcon } from '../../../../../../../components/getPolicyTypeIcon';
import { PackageVersionNumber } from '../../../../../../packages/common/components/PackageVersionNumber';
import { generateTitle } from './SummarySourceBox.utils';
import { EntitlementMethod, getEntitlementMethod, isOriginatedFromPlan } from '../EntitlementMethod';
import { isBasePlan } from '../entitlementSummary.utils';

type EntitlementSummarySourceProps = {
  title: string | null;
  overriddenPackage: boolean;
  entitlementMethod: EntitlementMethod;
  isEntitlementMethodOriginatedFromPlan: boolean;
  summary: EntitlementSummaryFragment;
};

function EntitlementSummarySource({
  title,
  overriddenPackage,
  entitlementMethod,
  isEntitlementMethodOriginatedFromPlan,
  summary,
}: EntitlementSummarySourceProps) {
  const navigation = useNavigation();

  return (
    <GridFlex.Column item textAlign="left">
      <Text.B2
        sx={{
          display: 'inline',
          textDecoration: overriddenPackage ? 'line-through' : 'none',
        }}>
        {title}
      </Text.B2>
      {entitlementMethod === EntitlementMethod.ADDON && !summary.featurePackageEntitlement?.package?.isLatest && (
        <PackageVersionNumber versionNumber={summary.featurePackageEntitlement?.package?.versionNumber} size="small" />
      )}
      {(isEntitlementMethodOriginatedFromPlan || entitlementMethod === EntitlementMethod.PRICE) && (
        <Grid
          item
          sx={{
            textDecoration: overriddenPackage ? 'line-through' : 'none',
          }}>
          <Link
            mr={2}
            underline="hover"
            variant="caption"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              if (summary.plan?.refId) {
                navigation.navigateTo(navigation.appRoutes.planPage(summary.plan.refId, summary.plan.versionNumber));
              }
            }}>
            {summary.plan?.product.displayName} / {summary.plan?.displayName}
          </Link>
          {!summary.plan?.isLatest && (
            <PackageVersionNumber
              versionNumber={summary.plan?.versionNumber}
              isLatest={summary.plan?.isLatest}
              size="small"
            />
          )}
        </Grid>
      )}
      {entitlementMethod === EntitlementMethod.UNKNOWN && <Text.B2>{t('customers.summaryUnknown')}</Text.B2>}
    </GridFlex.Column>
  );
}

export function SummarySourceBox({
  summary,
  focusedProductId,
  activePlanIds,
  trialPlanIds,
  shouldPrintIcons,
}: {
  summary: EntitlementSummaryFragment | null;
  focusedProductId?: string;
  activePlanIds: string[];
  trialPlanIds: string[];
  shouldPrintIcons?: boolean;
}) {
  if (!summary) {
    return null;
  }

  const entitlementMethod: EntitlementMethod = getEntitlementMethod(
    summary,
    activePlanIds,
    trialPlanIds,
    focusedProductId,
  );

  const isTrial = summary?.subscription?.status === SubscriptionStatus.InTrial;
  const isEntitlementMethodOriginatedFromPlan = isOriginatedFromPlan(entitlementMethod);
  const originatedFromBasePlan =
    isEntitlementMethodOriginatedFromPlan && isBasePlan(isTrial, summary?.plan?.refId, activePlanIds, trialPlanIds);

  const title = generateTitle(
    entitlementMethod,
    originatedFromBasePlan,
    isTrial,
    summary.featurePackageEntitlement?.package?.displayName,
  );

  // could be null in case there is no winning policy (e.g just sum of addons)
  const overriddenPackage = !summary.isEffectiveEntitlement;

  if (shouldPrintIcons) {
    return (
      <GridFlex.Row flexWrap="nowrap" flexDirection="row-reverse" gap={4}>
        {getPolicyTypeIcon(entitlementMethod, overriddenPackage)}
      </GridFlex.Row>
    );
  }

  return (
    <EntitlementSummarySource
      title={title}
      overriddenPackage={overriddenPackage}
      entitlementMethod={entitlementMethod}
      isEntitlementMethodOriginatedFromPlan={isEntitlementMethodOriginatedFromPlan}
      summary={summary}
    />
  );
}
