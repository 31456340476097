import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Feature, FeatureInspectorProductFilterFragment, Plan } from '@stigg-types/apiTypes';
import { isEmpty, sortBy } from 'lodash';
import { fetchFeatureInspectorFilters } from './queries/fetchFilters';
import { hasEntitlement } from './helpers/plan.helper';

import { createAppAsyncThunk } from '../../../redux/createAppAsyncThunk';

export interface FeatureInspectorSlice {
  isLoading: boolean;
  availablePlans: Plan[];
  filteredPlans: Plan[];
  selectedFeature: Feature | undefined;
  selectedPlan: Plan | undefined;
  showUncompletedPeriod: boolean;
  allowOverridePeriod: boolean;
  availableProducts: FeatureInspectorProductFilterFragment[];
  selectedProduct: FeatureInspectorProductFilterFragment | undefined;
}

const initialState: FeatureInspectorSlice = {
  isLoading: false,
  availablePlans: [],
  filteredPlans: [],
  selectedFeature: undefined,
  selectedPlan: undefined,
  showUncompletedPeriod: false,
  allowOverridePeriod: true,
  availableProducts: [],
  selectedProduct: undefined,
};

const fetchFeatureInspectorFiltersAction = createAppAsyncThunk(
  'fetchFeatureInspectorFilters',
  fetchFeatureInspectorFilters,
);

function selectItemFromArray<T>(arr: T[], selector: (item: T) => boolean): T | undefined {
  return !isEmpty(arr) ? arr.find(selector) || arr[0] : undefined;
}

function allowOverridePeriod(state: Draft<FeatureInspectorSlice>) {
  state.allowOverridePeriod = true;
}

function updateSelectedPlan(state: Draft<FeatureInspectorSlice>): void {
  state.selectedPlan = selectItemFromArray(
    state.filteredPlans.filter((plan) => state.selectedFeature && hasEntitlement(plan, state.selectedFeature)),
    (plan) => plan.refId === state.selectedPlan?.refId,
  );
}

function updateFilterPlans(state: Draft<FeatureInspectorSlice>): void {
  state.filteredPlans = state.selectedProduct
    ? state.availablePlans.filter((plan) => plan.productId === state.selectedProduct?.id)
    : [];
}

const featureInspectorSlice = createSlice({
  name: 'featureInspector',
  initialState,
  reducers: {
    selectFeature: (state, action: PayloadAction<Feature>) => {
      state.selectedFeature = action.payload;
      updateSelectedPlan(state);
      allowOverridePeriod(state);
    },
    selectPlan: (state, action: PayloadAction<string>) => {
      state.selectedPlan = state.filteredPlans.find((plan) => plan.refId === action.payload);
      allowOverridePeriod(state);
    },
    selectProduct: (state, action: PayloadAction<string>) => {
      state.selectedProduct = state.availableProducts.find((plan) => plan.id === action.payload);
      updateFilterPlans(state);
      updateSelectedPlan(state);
      allowOverridePeriod(state);
    },
    setShowUncompletedPeriod: (state, action: PayloadAction<boolean>) => {
      state.allowOverridePeriod = false;
      state.showUncompletedPeriod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeatureInspectorFiltersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFeatureInspectorFiltersAction.fulfilled, (state, { payload }) => {
        state.availablePlans = payload.plans;
        // Keeping non-multiple subscriptions products first so the default product
        // that will be chosen is not a multiple subscription one.
        // TODO: remove after adding support for multiple subscriptions in feature inspector
        state.availableProducts = sortBy(payload.products, (product) => product.multipleSubscriptions);

        state.selectedProduct = selectItemFromArray(
          state.availableProducts,
          (product) => product.refId === state.selectedProduct?.refId,
        );

        updateFilterPlans(state);
        updateSelectedPlan(state);
        allowOverridePeriod(state);

        state.isLoading = false;
      })
      .addCase(fetchFeatureInspectorFiltersAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

const { selectFeature, selectPlan, selectProduct, setShowUncompletedPeriod } = featureInspectorSlice.actions;

export { fetchFeatureInspectorFiltersAction, selectFeature, selectProduct, selectPlan, setShowUncompletedPeriod };

export default featureInspectorSlice.reducer;
