import React, { useCallback } from 'react';
import { SubscriptionCancellationTime, SubscriptionDataFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';

import * as Yup from 'yup';
import { capitalize, first, includes } from 'lodash';
import { calculateTrialEndDate, downgradeProductTimeFormat } from '@stigg-common';
import { Form, GridFlex, SelectFields, Text } from '@stigg-components';
import { useAppDispatch } from '../../../../../redux/store';

import { updateSubscriptionAction } from '../../../customersSlice';
import {
  getAfterTrialEndText,
  getBeforeTrialEndText,
} from '../../subscriptions/subscriptionInfo/components/inTrialStatus/InTrialStatus';
import { useNavigation } from '../../../../navigation/useNavigation';

const getAllowedCancellationTimes = (defaultCancelAction?: SubscriptionCancellationTime) => {
  const cancellationTimes: SelectFields['values'] = [
    {
      value: SubscriptionCancellationTime.Immediate,
      displayValue: capitalize(downgradeProductTimeFormat(SubscriptionCancellationTime.Immediate, true, true)),
      subtitle: defaultCancelAction === SubscriptionCancellationTime.Immediate ? t('subscriptions.defaultSetup') : '',
      subtitlePosition: 'right',
    },
    {
      value: 'LATER_DATE',
      displayValue: t('subscriptions.laterDate'),
    },
  ];

  return cancellationTimes;
};

export type EndTrialSubscriptionFormProps = {
  subscription: SubscriptionDataFragment;
  onClose: () => void;
};

export const EndTrialSubscriptionForm = ({ subscription, onClose }: EndTrialSubscriptionFormProps) => {
  const dispatch = useAppDispatch();
  const defaultCancelAction = subscription?.plan.product.productSettings.subscriptionCancellationTime;
  const navigation = useNavigation();

  const validationSchema = Yup.object().shape({
    endDate: Yup.date().when('subscriptionCancelTime', {
      is: 'LATER_DATE',
      then: Yup.date().required(t('products.yup.required')),
      otherwise: Yup.date().notRequired().nullable(true),
    }),
  });

  const handleSubmit = useCallback(
    async ({ endDate, subscriptionCancelTime }) => {
      if (subscriptionCancelTime === SubscriptionCancellationTime.Immediate) {
        endDate = calculateTrialEndDate(new Date().toString());
      }
      await dispatch(
        updateSubscriptionAction({
          updateSubscriptionData: {
            subscriptionId: subscription.subscriptionId,
            trialEndDate: endDate,
          },
          blockNavigate: true,
        }),
      );
      onClose();
    },
    [dispatch, subscription, onClose],
  );

  const cancellationTimes = getAllowedCancellationTimes(defaultCancelAction);
  const hasDefaultCancelAction = includes(
    cancellationTimes.map((x) => x.value),
    defaultCancelAction,
  );

  const initialValues = {
    endDate: null,
    subscriptionCancelTime:
      defaultCancelAction && hasDefaultCancelAction ? defaultCancelAction : first(cancellationTimes)?.value,
  };

  return (
    <>
      <GridFlex.Column gap={2}>
        <Text.B2>
          {getBeforeTrialEndText(subscription)}{' '}
          {getAfterTrialEndText(
            navigation,
            subscription.trialConfiguration?.trialEndBehavior,
            subscription.plan.product.productSettings?.subscriptionEndSetup,
            subscription.plan.product.productSettings?.downgradePlan,
          )}
          .
        </Text.B2>
      </GridFlex.Column>

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        submitButtonText={(values) => {
          return values.subscriptionCancelTime === 'LATER_DATE'
            ? t('subscriptions.endTrialSubscriptionLaterCTA')
            : t('subscriptions.endTrialSubscriptionImmediateCTA');
        }}
        cancelButtonText={t('sharedComponents.dismiss')}
        saveButtonColor="error"
        validationSchema={validationSchema}
        fields={[
          {
            type: 'select',
            id: 'subscriptionCancelTime',
            values: cancellationTimes,
            label: t('customers.trials.endTrialDialogChoose'),
          },
          {
            type: 'datePicker',
            hide: (values: any) => values.subscriptionCancelTime !== 'LATER_DATE',
            id: 'endDate',
          },
        ]}
        onCancel={onClose}
      />
    </>
  );
};
