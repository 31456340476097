import React, { useEffect, useState } from 'react';
import { Action, Location } from 'history';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { browserHistory } from '../../browserHistory';
import { ExitConfirmationDialog, NOOP, replayNavigationAction } from './useExitConfirmationDialog.utils';

export type ExitConfirmationDialogProps = {
  onClose: () => void;
  shouldConfirm: boolean;
};

export function useExitConfirmationDialog({
  onClose: onCloseExternal,
  shouldConfirm: shouldConfirmExternal,
}: ExitConfirmationDialogProps) {
  const { wizardExitConfirmation: wizardExitConfirmationEnabled } = useFlags<FeatureFlags>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [releaseNavigationBlock, setReleaseNavigationBlock] = useState<() => void>(NOOP);

  const [navigationAction, setNavigationAction] = useState<{ location: Location; action: Action } | null>(null);

  const shouldConfirm = shouldConfirmExternal && hasUnsavedChanges;

  const onClose = () => {
    setHasUnsavedChanges(false);
    onCloseExternal();
  };

  const onExit = () => {
    if (shouldConfirm && wizardExitConfirmationEnabled) {
      setDialogOpen(true);
    } else {
      onClose();
    }
  };

  const onDialogClose = (isConfirmed: boolean) => {
    setDialogOpen(false);
    setNavigationAction(null);

    if (!isConfirmed) {
      return;
    }

    releaseNavigationBlock();

    if (navigationAction) {
      replayNavigationAction(navigationAction);
      setNavigationAction(null);
    } else {
      onClose();
    }
  };

  // prompt before in-app browser navigation
  useEffect(() => {
    let release = NOOP;

    if (shouldConfirm && wizardExitConfirmationEnabled) {
      release = browserHistory.block((location, action) => {
        setNavigationAction({ location, action });
        setDialogOpen(true);
        return false;
      });
    }

    // saving the release function for use when confirm exit
    setReleaseNavigationBlock(() => release);

    return release;
  }, [shouldConfirm, wizardExitConfirmationEnabled]);

  // prompt before exiting the app
  useEffect(() => {
    if (!shouldConfirm || !wizardExitConfirmationEnabled) {
      return undefined;
    }

    // creating a new reference everytime to make sure
    // no interference between different instances
    const alertUser = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', alertUser);
    return () => window.removeEventListener('beforeunload', alertUser);
  }, [shouldConfirm, wizardExitConfirmationEnabled]);

  const exitConfirmationDialog = <ExitConfirmationDialog open={dialogOpen} onDialogClose={onDialogClose} />;

  return { exitConfirmationDialog, onExit, setHasUnsavedChanges };
}
