import React from 'react';
import { IntegrationFragment } from '@stigg-types/apiTypes';
import { VendorIdentifier } from '../types.tsx';
import { ZuoraIntegrationForm, ZuoraIntegrationFormProps } from './zuora/ZuoraIntegrationForm';
import { AWSMarketplaceIntegrationPage } from './awsMarketPlace/AWSMarketplaceIntegrationPage';
import { ZuoraIntegrationDetails, ZuoraIntegrationDetailsProps } from './zuora/ZuoraIntegrationDetails';
import { StripeIntegrationDetails, StripeIntegrationDetailsProps } from './StripeIntegrationDetails';
import { StripeConnect } from './StripeConnect';
import { StripeFooter } from './stripe/StripeFooter';
import { SnowflakeIntegrationPage } from './snowflake/SnowflakeIntegrationPage';
import { SalesforceIntegrationPage } from './salesforce/SalesforceIntegrationPage';
import { BigQueryIntegrationPage } from './bigQuery/BigQueryIntegrationPage';
import { OpenFGAIntegrationDetails, OpenFGAIntegrationDetailsProps } from './openFga/OpenFGAIntegrationDetails';
import { OpenFGAIntegrationForm, OpenFGAIntegrationFormProps } from './openFga/OpenFGAIntegrationForm';
import { HubSpotIntegrationPage } from './hubspotConnect/HubSpotIntegrationPage';
import { Auth0IntegrationPage } from './auth0/Auth0IntegrationPage';
import { AppDispatch } from '../../../redux/store.ts';

type IntegrationsComponents = {
  renderDetails?: (props: { integration: IntegrationFragment; isReadOnly: boolean }) => React.JSX.Element;
  renderForm?: (props: { onCancel: () => void; integration?: IntegrationFragment | null }) => React.JSX.Element;
  renderOAuthProvider?: () => React.JSX.Element;
  renderFooterSection?: (props: {
    integration: IntegrationFragment | null;
    isReadOnly: boolean;
    dispatch: AppDispatch;
  }) => React.JSX.Element;
  renderPage?: (props: {
    integration: IntegrationFragment | null;
    headerComponent: React.ReactNode;
    isReadOnly: boolean;
  }) => React.JSX.Element;
};

export const integrationsComponents: Record<VendorIdentifier, IntegrationsComponents> = {
  [VendorIdentifier.Zuora]: {
    renderDetails: (props: ZuoraIntegrationDetailsProps) => <ZuoraIntegrationDetails {...props} />,
    renderForm: (props: ZuoraIntegrationFormProps) => <ZuoraIntegrationForm {...props} />,
  },
  [VendorIdentifier.Stripe]: {
    renderDetails: (props: StripeIntegrationDetailsProps) => <StripeIntegrationDetails {...props} />,
    renderOAuthProvider: () => <StripeConnect />,
    renderFooterSection: (props) => <StripeFooter {...props} />,
  },
  [VendorIdentifier.AwsMarketplace]: {
    renderPage: ({ integration, headerComponent, isReadOnly }) => (
      <AWSMarketplaceIntegrationPage
        integration={integration}
        headerComponent={headerComponent}
        isReadOnly={isReadOnly}
      />
    ),
  },
  [VendorIdentifier.Hubspot]: {
    renderPage: ({ integration, headerComponent, isReadOnly }) => (
      <HubSpotIntegrationPage integration={integration} headerComponent={headerComponent} isReadOnly={isReadOnly} />
    ),
  },
  [VendorIdentifier.Salesforce]: {
    renderPage: ({ integration, headerComponent, isReadOnly }) => (
      <SalesforceIntegrationPage integration={integration} headerComponent={headerComponent} isReadOnly={isReadOnly} />
    ),
  },
  [VendorIdentifier.Snowflake]: {
    renderPage: ({ integration, headerComponent, isReadOnly }) => (
      <SnowflakeIntegrationPage integration={integration} headerComponent={headerComponent} isReadOnly={isReadOnly} />
    ),
  },
  [VendorIdentifier.BigQuery]: {
    renderPage: ({ integration, headerComponent, isReadOnly }) => (
      <BigQueryIntegrationPage integration={integration} headerComponent={headerComponent} isReadOnly={isReadOnly} />
    ),
  },
  [VendorIdentifier.Auth0]: {
    renderPage: ({ integration, headerComponent, isReadOnly }) => (
      <Auth0IntegrationPage integration={integration} headerComponent={headerComponent} isReadOnly={isReadOnly} />
    ),
  },
  [VendorIdentifier.OpenFga]: {
    renderDetails: (props: OpenFGAIntegrationDetailsProps) => <OpenFGAIntegrationDetails {...props} />,
    renderForm: (props: OpenFGAIntegrationFormProps) => <OpenFGAIntegrationForm {...props} />,
  },
};
