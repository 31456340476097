import { Currency, PlanListFragment } from '@stigg-types/apiTypes';
import { EMPTY_PLACEHOLDER } from '@stigg-components';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getPrice } from '../../utils/getPrice';
import { MAX_FRACTION_DIGITS } from '../../utils/priceValidationUtils';

export const DEFAULT_COUNTRY_CODE = 'default';
export const DEFAULT_CURRENCY = Currency.Usd;

export const extractCountryList = (
  plans: Pick<PlanListFragment, 'prices'>[],
  { currencyFilter, withDefaultCountry = true }: { currencyFilter?: Currency; withDefaultCountry?: boolean } = {},
) => {
  if (!plans) {
    return [];
  }

  const countrySet = new Set<string>();

  if (withDefaultCountry) {
    countrySet.add(DEFAULT_COUNTRY_CODE);
  }

  plans.forEach((plan) => {
    plan.prices?.forEach((price) => {
      const pricePoint = getPrice(price);
      if (price.billingCountryCode && (currencyFilter ? pricePoint.currency === currencyFilter : true)) {
        countrySet.add(price.billingCountryCode);
      }
    });
  });
  return Array.from(countrySet);
};

export const extractCountryWithCurrencyMap = (
  plans: Pick<PlanListFragment, 'prices'>[],
  { currencyFilter, withDefaultCountry = true }: { currencyFilter?: Currency; withDefaultCountry?: boolean } = {},
) => {
  if (!plans) {
    return new Map();
  }

  const countryMap = new Map<string, Currency>();

  if (withDefaultCountry) {
    countryMap.set(DEFAULT_COUNTRY_CODE, Currency.Usd);
  }

  plans.forEach((plan) => {
    plan.prices?.forEach((price) => {
      const pricePoint = getPrice(price);
      if (price.billingCountryCode && (currencyFilter ? pricePoint.currency === currencyFilter : true)) {
        countryMap.set(price.billingCountryCode, pricePoint.currency);
      }
    });
  });

  return countryMap;
};

export const currencyPriceFormatter = ({
  amount,
  currency = Currency.Usd,
  options = {},
}: {
  amount: number;
  currency?: Currency;
  options?: { withCodePostfix?: boolean };
}) => {
  const { withCodePostfix } = options;
  const currencyString = currency.toString();
  const currencySymbol = getSymbolFromCurrency(currencyString);
  let formattedPrice = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyString,
    maximumFractionDigits: MAX_FRACTION_DIGITS,
    ...(currencySymbol ? { currencyDisplay: 'code' } : {}),
  }).format(amount || 0);

  formattedPrice = formattedPrice.replace(EMPTY_PLACEHOLDER, '');

  if (currencySymbol) {
    formattedPrice = formattedPrice.replace(currencyString, currencySymbol);
  }

  if (withCodePostfix) {
    formattedPrice = `${formattedPrice} ${currencyString}`;
  }

  return formattedPrice;
};

export const getCurrencyText = (currency?: Currency | null) => {
  return `${getSymbolFromCurrency(currency || '') || ''} ${currency}`;
};

export const getCurrencySymbol = (currency?: Currency | null) => {
  return getSymbolFromCurrency(currency || DEFAULT_CURRENCY);
};
