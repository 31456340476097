import { gql } from '@apollo/client';
import { t } from 'i18next';
import { SetAccessRolesInput, SetAccessRolesMutation, SetAccessRolesMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';

const SET_ACCESS_ROLES = gql`
  mutation SetAccessRoles($input: SetAccessRolesInput!) {
    setAccessRoles(setAccessRolesInput: $input)
  }
`;

async function setAccessRoles(input: SetAccessRolesInput, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      await apolloClient.mutate<SetAccessRolesMutation, SetAccessRolesMutationVariables>({
        mutation: SET_ACCESS_ROLES,
        variables: { input },
      });
    },
    {
      successMessage: t('accounts.api.successSetAccessRoles'),
      failureMessageHandler: () => t('accounts.api.failSetAccessRoles'),
    },
    dispatch,
  );
}

export { setAccessRoles };
