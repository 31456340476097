import { useState } from 'react';
import { FormRenderProps, OptionalField as OptionalFieldType } from '../form/Form.types';
import { Icon } from '../icon';
import { Text } from '../Typography';
import { drawFormFields } from '../form';
import { Button } from '../buttons/Button';
import { DEFAULT_INPUT_HEIGHT } from '../input.utils';

type OptionalFieldProps<FormValues extends Record<string, any>> = {
  field: OptionalFieldType<FormValues>;
  formRenderProps: FormRenderProps<FormValues>;
};

export function OptionalField<FormValues extends Record<string, any>>({
  field,
  formRenderProps,
}: OptionalFieldProps<FormValues>) {
  const [isOpen, setIsOpen] = useState(false);

  if (!isOpen) {
    return (
      <Button
        data-testid={field.testId}
        variant="text"
        sx={{ height: DEFAULT_INPUT_HEIGHT }}
        onClick={() => setIsOpen(true)}
        startIcon={field.icon ?? <Icon type="reactFeather" icon="Plus" color="primary.main" size={16} />}>
        <Text.B2 color="primary.main">{field.label}</Text.B2>
      </Button>
    );
  }

  return <>{drawFormFields(field.fields, formRenderProps)}</>;
}
