import { t } from 'i18next';
import { forwardRef, PropsWithChildren } from 'react';
import { Button } from '@stigg-components';
import styled, { css, keyframes } from 'styled-components/macro';
import { Synchronize } from '../icon/CustomIcons';
import { ButtonProps } from './Button';

type ReloadButtonVariant = 'outlined' | 'inline';

type ReloadButtonProps = Omit<ButtonProps, 'variant'> & {
  isLoading?: boolean | undefined;
  text?: string;
  variant?: ReloadButtonVariant;
};

const rotate = keyframes`
  0% {
    transform: rotate(360deg);
  }
  80% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

const StyledReloadIcon = styled(Synchronize)<{ isLoading?: boolean }>`
  path {
    stroke: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: ${rotate} 2s cubic-bezier(0.28, 0.01, 0.72, 1.01) infinite;
    `};
`;

export const ReloadButton = forwardRef<HTMLButtonElement, PropsWithChildren<ReloadButtonProps>>(
  ({ isLoading = false, variant = 'outlined', text, ...props }, ref) => (
    <Button
      color={variant === 'outlined' ? 'primary' : undefined}
      $outlined={variant === 'outlined'}
      // @ts-ignore next line
      {...(variant === 'inline' ? { textColor: 'primary' } : {})}
      startIcon={<StyledReloadIcon isLoading={isLoading} width={16} height={16} />}
      fontSize={variant === 'outlined' ? '12px' : '14px'}
      sx={{ paddingX: 4 }}
      {...props}
      ref={ref}>
      {text || t('customers.entitlementsRefreshButton')}
    </Button>
  ),
);
