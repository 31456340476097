import { CouponType, Currency } from '@stigg-types/apiTypes';
import * as Yup from 'yup';
import { generateRandomSlug, refIdValidation } from '@stigg-common';
import { GridFlex, RadioGroup, RadioItem, OutlinedFormFieldLabel, Form, EMPTY_PLACEHOLDER } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { createCouponAction } from '../../couponsSlice';
import { getCouponTypes } from './getCouponTypes';
import { CouponDiscounts } from './CouponDiscounts';
import { DescriptionTitle } from '../../../../components/drawerComponents/DescriptionTitle';

export enum CouponDuration {
  Forever = 'forever',
  MultipleMonths = 'months',
}

export type CreateCouponFormFields = {
  refId: string;
  name: string;
  description: string;
  type: CouponType;
  amountsOff?: { amount: number; currency: Currency }[];
  duration: CouponDuration;
  durationInMonths?: number;
};

const validationSchema = () => {
  const currencySchema = Yup.string().required('Currency is required');
  const amountBaseSchema = Yup.number()
    .required(t('coupons.discountValueReqValidation'))
    .positive(t('coupons.discountPercentageValueMinValidation'));

  return Yup.object().shape({
    refId: refIdValidation(),
    name: Yup.string().required(t('coupons.nameReqValidation')).max(40, t('coupons.nameMaxValidation')),
    description: Yup.string(),
    durationInMonths: Yup.number().when('duration', {
      is: CouponDuration.MultipleMonths,
      then: Yup.number()
        .required(t('coupons.duration.durationInMonthsReqValidation'))
        .min(1, t('coupons.duration.durationInMonthsMinValidation')),
      otherwise: Yup.number().notRequired(),
    }),
    amountsOff: Yup.array()
      .when('type', {
        is: CouponType.Percentage,
        then: Yup.array().of(
          Yup.object().shape({
            amount: amountBaseSchema.max(100, t('coupons.discountPercentageValueMaxValidation')),
            currency: currencySchema,
          }),
        ),
        otherwise: Yup.array().of(
          Yup.object().shape({
            amount: amountBaseSchema,
            currency: currencySchema,
          }),
        ),
      })
      .required()
      .min(1),
  });
};

const getInitialValues = (): CreateCouponFormFields => ({
  name: '',
  description: '',
  refId: `coupon-${generateRandomSlug()}`,
  type: CouponType.Percentage,
  // @ts-ignore
  amountsOff: [{ currency: Currency.Usd }],
  duration: CouponDuration.Forever,
  durationInMonths: undefined,
});

export default function CreateCouponForm({ onCancel }: { onCancel: () => void }) {
  const initialValues = getInitialValues();

  const dispatch = useAppDispatch();
  const handleSubmit = async (values: CreateCouponFormFields) => {
    const { amountsOff, duration, durationInMonths, ...restValues } = values;
    const isPercentageCoupon = values.type === CouponType.Percentage;
    const percentageValue = isPercentageCoupon ? values.amountsOff?.[0].amount : undefined;
    await dispatch(
      createCouponAction({
        ...restValues,
        ...(duration === CouponDuration.MultipleMonths && durationInMonths ? { durationInMonths } : {}),
        ...(isPercentageCoupon ? { percentOff: percentageValue } : { amountsOff }),
      }),
    );
    onCancel();
  };

  return (
    <Form
      withStickyFooter
      withFooterSpacing
      onCancel={onCancel}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      fields={(formProps) => {
        const { values, setFieldValue } = formProps;

        return [
          {
            type: 'text',
            id: 'name',
            label: t('coupons.nameForm'),
            value: values.name,
            fullWidth: true,
            captionText: t('coupons.createCouponHelperText'),
          },
          {
            type: 'collapse',
            hideIcon: true,
            title: (isOpen: boolean) => <DescriptionTitle isOpen={isOpen} />,
            fields: [
              {
                id: 'description',
                type: 'text',
                optional: true,
                textFieldProps: { multiline: true, maxRows: 2 },
              },
            ],
          },
          {
            type: 'text',
            id: 'refId',
            label: t('coupons.refIdForm'),
            value: values.refId,
            fullWidth: true,
          },
          {
            type: 'custom',
            fullWidth: true,

            render: () => (
              <RadioGroup
                sx={{ width: '100%' }}
                value={values.type}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('amountsOff', [values.amountsOff?.[0] || {}]);
                  setFieldValue('type', (event.target as HTMLInputElement).value as CouponType);
                }}>
                <OutlinedFormFieldLabel label={t('coupons.discountType.label')} />
                <GridFlex.Row gap={2}>
                  {getCouponTypes().map((type) => (
                    <RadioItem
                      sx={{ p: 1 }}
                      key={type.value}
                      value={type.value}
                      label={type.label}
                      selected={values.type === type.value}
                    />
                  ))}
                </GridFlex.Row>
              </RadioGroup>
            ),
          },
          {
            type: 'custom',
            fullWidth: true,
            render: () => <CouponDiscounts values={values} formRenderProps={formProps} />,
          },
          {
            type: 'layout',
            fields: [
              {
                label: t('coupons.duration.label'),
                id: 'duration',
                type: 'select',
                values: [
                  {
                    value: CouponDuration.Forever,
                    displayValue: t('coupons.duration.options.forever'),
                  },
                  {
                    value: CouponDuration.MultipleMonths,
                    displayValue: t('coupons.duration.options.months'),
                  },
                ],
              },
              {
                label: EMPTY_PLACEHOLDER,
                id: 'durationInMonths',
                type: 'text',
                textFieldType: 'number',
                placeholder: '1',
                isNumberWithoutSigns: true,
                endAdornment: t('coupons.duration.postfix.months', { count: values.durationInMonths || 1 }),
                autoFocus: values.duration === CouponDuration.MultipleMonths,
                hide: () => values.duration !== CouponDuration.MultipleMonths,
              },
            ],
          },
        ];
      }}
    />
  );
}
