import { t } from 'i18next';
import {
  CustomerResponseFragment,
  PaymentCollectionMethod,
  PlanFragment,
  PlanListFragment,
  PricingType,
} from '@stigg-types/apiTypes';
import { Field, FormRenderProps, GridFlex, Text } from '@stigg-components';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { getPlanDefaultBillingData } from '../../SubscriptionForm.utils';
import { AdditionalInputLayout } from '../AdditionalInputLayout';
import { SEARCH_COMPONENT_WIDTH } from '../coupon/consts';
import { COLLECTION_METHOD_OPTIONS } from './CollectionMethodOptions';
import { useIntegrations } from '@stigg-common';
import { BillingProviderPicker } from '../../../../payment/BillingProviderPicker';

type SubscriptionPaymentProps = {
  isUpdatingSubscription: boolean;
  currentPlan: PlanListFragment | PlanFragment | undefined;
  customer: CustomerResponseFragment;
  formProps: FormRenderProps<SubscriptionFormFields>;
};

export const SubscriptionPayment = ({
  isUpdatingSubscription,
  currentPlan,
  customer,
  formProps,
}: SubscriptionPaymentProps) => {
  const { hasMultipleBillingIntegrations } = useIntegrations();
  const { setFieldValue, values } = formProps;
  const { paymentCollectionMethod } = values;
  const isCustomPlan = currentPlan?.pricingType === PricingType.Custom;

  // TODO: do we need this?
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubscriptionCustomPricingChange = (collectionMethod: PaymentCollectionMethod) => {
    const {
      paymentCollectionMethod: currentPaymentCollectionMethod,
      billingPeriod: currentBillingPeriod,
      billingCountryCode: currentBillingCountryCode,
    } = values;

    if (currentPaymentCollectionMethod === collectionMethod) {
      return;
    }

    if (currentPlan) {
      const { billingPeriod: defaultBillingPeriod, billingCountryCode: defaultBillingCountryCode } =
        getPlanDefaultBillingData({
          plan: currentPlan,
          customerBillingCurrency: customer.billingCurrency,
        });

      if (!currentBillingPeriod) {
        setFieldValue('billingPeriod', defaultBillingPeriod);
      }
      if (!currentBillingCountryCode) {
        setFieldValue('billingCountryCode', defaultBillingCountryCode);
      }
    }
  };

  const billingProviderSelector: Field<SubscriptionFormFields> = {
    type: 'select',
    id: 'billingIntegrationId',
    disabled: !!customer.defaultPaymentMethodId,
    tooltip: customer.defaultPaymentMethodId
      ? {
          title: <Text.B2>{t('subscriptionForm.paymentMethod.billingProviderDisabled')}</Text.B2>,
          placement: 'top',
        }
      : undefined,
    values: BillingProviderPicker(),
    gridProps: {
      minWidth: SEARCH_COMPONENT_WIDTH,
    },
  };

  const paymentMethodSelector: Field<SubscriptionFormFields> = {
    type: 'select',
    id: 'paymentCollectionMethod',
    disabled: isCustomPlan || isUpdatingSubscription,
    gridProps: {
      minWidth: SEARCH_COMPONENT_WIDTH,
    },
    values: Object.entries(COLLECTION_METHOD_OPTIONS).map(([method, options]) => ({
      disabled: options.isDisabled?.(values),
      label: method,
      value: method,
      displayValue: options.render(),
    })),
  };

  return (
    <GridFlex.Column gap={2} container $fullWidth>
      <Text.H6>{t('subscriptionForm.paymentSectionTitle')}</Text.H6>
      {hasMultipleBillingIntegrations && (
        <AdditionalInputLayout
          isSet
          setIcon={{ icon: 'PowerUp2', type: 'custom', color: 'default', overrideStroke: true }}
          setLabel={t('subscriptionForm.paymentMethod.billingProvider')}
          fields={[billingProviderSelector]}
          formRenderProps={formProps}
        />
      )}
      <AdditionalInputLayout
        isSet
        setIcon={{ icon: 'CreditCard', type: 'custom', color: 'default', overrideStroke: true }}
        setLabel={t('subscriptionForm.paymentMethod.paymentMethod')}
        fields={[paymentMethodSelector]}
        formRenderProps={formProps}
      />
      {paymentCollectionMethod &&
        COLLECTION_METHOD_OPTIONS[paymentCollectionMethod!].renderDetails?.({ form: formProps, customer })}
    </GridFlex.Column>
  );
};
