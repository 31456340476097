import { SyncStateFragment, StripeCredentialsFragment, SyncStatus, VendorIdentifier } from '@stigg-types/apiTypes';
import styled from 'styled-components/macro';
import {
  GridFlex,
  Text,
  LongText,
  Icon,
  Card,
  CircularProgress,
  Box,
  ClipboardChip,
  Link,
  InformationTooltip,
} from '@stigg-components';
import { t } from 'i18next';
import { useIntegrations } from '@stigg-common';

export const StyledIntegrationIcon = styled.img`
  width: 36px;
  height: 36px;
`;

function IntegrationIcon({ vendorIdentifier }: { vendorIdentifier: VendorIdentifier }) {
  const { availableIntegrations } = useIntegrations();
  const integration = availableIntegrations.find((integration) => integration.vendorIdentifier === vendorIdentifier);
  return <StyledIntegrationIcon src={integration?.icon} alt={integration?.name} />;
}

const StyledCard = styled(Card)`
  transition: all 0.2s ease-in-out;
  border: 1px solid ${({ theme }) => theme.itamar.palette.action.hover};
  &:hover {
    background-color: ${({ theme }) => theme.itamar.palette.action.hover};
    border-color: ${({ theme }) => theme.itamar.palette.action.selected};
    box-shadow: ${({ theme }) => theme.itamar.palette.shadow.lightShadow};
  }
`;

const getBillingLinkUrl = (syncState: SyncStateFragment) => {
  const { data } = syncState;
  if (data && 'billingLinkUrl' in data) {
    return data.billingLinkUrl;
  }

  return undefined;
};

const getIntegrationDisplayName = (syncState: SyncStateFragment) => {
  const { integration } = syncState;
  if (integration.vendorIdentifier === VendorIdentifier.Stripe) {
    return (integration.credentials as StripeCredentialsFragment).accountDisplayName;
  }

  return null;
};

function IntegrationDetails({ syncState }: { syncState: SyncStateFragment }) {
  const integrationDisplayName = getIntegrationDisplayName(syncState);
  const {
    integration: { vendorIdentifier, integrationId },
  } = syncState;

  return (
    <GridFlex.RowCenter>
      <IntegrationIcon vendorIdentifier={vendorIdentifier} />
      <GridFlex.Column ml={2} gap={1}>
        <GridFlex.RowCenter columnGap={1}>
          <Text.B2>{t('integrations.integrationNames', { context: vendorIdentifier })}</Text.B2>
          {integrationDisplayName && (
            <>
              <Text.B2 color="disabled">•</Text.B2>
              <LongText variant="body2">{integrationDisplayName}</LongText>
            </>
          )}
        </GridFlex.RowCenter>
        <GridFlex.RowCenter columnGap={2}>
          <LongText variant="caption" color="secondary">
            {integrationId}
          </LongText>
        </GridFlex.RowCenter>
      </GridFlex.Column>
    </GridFlex.RowCenter>
  );
}

function SyncStatusDisplay({ syncState }: { syncState: SyncStateFragment }) {
  const billingLinkUrl = getBillingLinkUrl(syncState);
  const {
    syncedEntityId,
    status,
    integration: { vendorIdentifier },
  } = syncState;

  return (
    <GridFlex.RowCenter columnGap={2}>
      {syncedEntityId ? (
        <InformationTooltip
          $padding={2}
          arrow
          placement="top"
          title={<Text.B2>{`Open in ${t('integrations.integrationNames', { context: vendorIdentifier })}`}</Text.B2>}>
          <GridFlex.RowCenter>
            <Link
              href={billingLinkUrl || undefined}
              target="_billing_tab"
              onClick={(e) => e.stopPropagation()}
              display="inline-block">
              {syncedEntityId}
            </Link>
          </GridFlex.RowCenter>
        </InformationTooltip>
      ) : status === SyncStatus.Pending ? (
        <Text.B2 color="disabled">{t('integrations.syncing')}</Text.B2>
      ) : null}
      <Box width={22} height={22}>
        {status === SyncStatus.Error ? (
          <Icon icon="Error" color="error" size={22} />
        ) : status === SyncStatus.Pending ? (
          <CircularProgress thickness={1.5} size="18px" sx={{ color: (theme) => theme.itamar.palette.action.active }} />
        ) : (
          <Icon type="reactFeather" icon="CheckCircle" color="success" size={22} />
        )}
      </Box>
    </GridFlex.RowCenter>
  );
}

const ErrorContainer = styled(GridFlex.RowCenter)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.itamar.palette.error.background};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const ErrorBox = styled(Box)`
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(2)}px`};
  background-color: ${({ theme }) => (theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.action)};
  border-radius: 10px;
`;

function ErrorDisplay({ syncState }: { syncState: SyncStateFragment }) {
  const { status, error } = syncState;
  if (status !== SyncStatus.Error || !error) {
    return null;
  }

  return (
    <ErrorContainer>
      <ErrorBox>
        <ClipboardChip
          text={error}
          copy={error}
          tooltipPlacement="top"
          backgroundColor="none"
          iconLocation="right"
          variant="code"
          maxLines={3}
        />
      </ErrorBox>
    </ErrorContainer>
  );
}

export function BillingIdCard({ syncState }: { syncState: SyncStateFragment }) {
  const { syncedEntityId } = syncState;

  return (
    <StyledCard key={syncedEntityId} p={4}>
      <GridFlex.Column gap={3}>
        <GridFlex.RowCenter justifyContent="space-between">
          <IntegrationDetails syncState={syncState} />
          <SyncStatusDisplay syncState={syncState} />
        </GridFlex.RowCenter>

        <ErrorDisplay syncState={syncState} />
      </GridFlex.Column>
    </StyledCard>
  );
}
