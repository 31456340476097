import { useMemo } from 'react';
import { drawFormFields } from '@stigg-components';
import { Environment, EnvironmentType } from '@stigg-types/apiTypes';
import { useFormik } from 'formik';
import { useEnvironmentFields } from '../../environments/useEnvironmentFields';

type NewEnvironmentFormFields = {
  displayName?: string;
  description?: string;
  type?: EnvironmentType;
};

export const useNewEnvironmentForm = ({
  environment,
  allowCreateProductionEnvironment,
  allowCreateNonProductionEnvironment,
}: {
  environment?: Partial<Environment>;
  allowCreateProductionEnvironment: boolean;
  allowCreateNonProductionEnvironment: boolean;
}) => {
  const initialValues: NewEnvironmentFormFields = useMemo(() => {
    return {
      displayName: environment?.displayName || '',
      description: environment?.description || '',
      type:
        environment?.type || allowCreateNonProductionEnvironment
          ? EnvironmentType.Development
          : allowCreateProductionEnvironment
            ? EnvironmentType.Production
            : undefined,
    };
  }, [environment, allowCreateProductionEnvironment, allowCreateNonProductionEnvironment]);

  const formRenderProps = useFormik<NewEnvironmentFormFields>({
    enableReinitialize: true,
    initialValues,
    onSubmit: () => Promise.resolve(),
  });

  const fields = useEnvironmentFields<NewEnvironmentFormFields>({
    environment,
    withColor: false,
    withDescription: false,
  });
  const formComponent = drawFormFields(fields, formRenderProps);

  return {
    formComponent,
    formRenderProps,
  };
};
