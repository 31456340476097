import { gql } from '@apollo/client';

export const SYNC_STATE_FRAGMENT = gql`
  fragment SyncStateFragment on SyncState {
    status
    error
    syncedEntityId
    integration {
      integrationId
      vendorType
      vendorIdentifier
      credentials {
        ... on StripeCredentials {
          accountId
          accountDisplayName
        }
      }
    }
    data {
      ... on SyncRevisionBillingData {
        billingId
        billingLinkUrl
      }
    }
  }
`;
