import React from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import { FormikHandlers } from 'formik';
import { Grid, MenuItem, Select, Text, GridFlex, InformationTooltip, DottedText } from '@stigg-components';
import { SelectFields } from '../form/Form.types';

type SelectProps = React.ComponentProps<typeof Select>;

export type SelectFieldProps<FormValues extends Record<string, any>> = {
  field: Omit<SelectFields<FormValues>, 'type'>;
  values: FormValues;
  handleChange: FormikHandlers['handleChange'];
  restProps?: Partial<SelectProps>;
};

export function SelectField<FormValues extends Record<string, any>>({
  field,
  values,
  handleChange,
  restProps,
}: SelectFieldProps<FormValues>) {
  const { id, label, values: selectValues } = field;
  const currentValue = get(values, id);
  const hasEmptyValue = selectValues.some(({ value }) => isNil(value));

  const renderValue = (value: any) => {
    const selectValue = selectValues.find((v: any) => v.value === value);
    if (!selectValue) {
      return null;
    }
    const v = selectValue?.overrideDisplayValue || selectValue?.displayValue;
    if (field.variant !== 'inline') {
      if (field.startAdornment) {
        return (
          <GridFlex.RowCenter columnGap={2}>
            {field.startAdornment}
            <Text.B2>{v}</Text.B2>
          </GridFlex.RowCenter>
        );
      }
      // optional prop. will be set to inline only when needed
      return <Text.B2>{v}</Text.B2>;
    }
    return field.hideDottedText ? <Text.B2>{v}</Text.B2> : <DottedText>{v}</DottedText>;
  };

  return (
    <Select
      withoutBorder={field.withoutBorder}
      withoutPadding={field.withoutPadding}
      variant={field.variant}
      helpTooltipText={field.helpTooltipText}
      optional={field.optional}
      renderValue={renderValue}
      hasEmptyValue={hasEmptyValue}
      labelId={id}
      displayEmpty={field.displayEmpty}
      disabled={isFunction(field.disabled) ? field.disabled(values) : Boolean(field.disabled)}
      disabledCursorNotAllowed={field.disabledCursorNotAllowed}
      hideSelectIcon={field.hideSelectIcon}
      tooltip={field.tooltip}
      fullWidth={!field.maxWidth}
      label={label}
      value={currentValue}
      name={id}
      maxWidth={field.maxWidth}
      onChange={handleChange}
      dataTestId={field.dataTestId}
      placeholder={field.placeholder}
      {...restProps}
      {...(field.restProps || {})}>
      {selectValues.map((selectValue) => {
        const displayValue = selectValue.displayValue || selectValue.value;
        const { subtitle, value, subtitlePosition, disabled, disabledText, tooltip, isCategory, key, divider } =
          selectValue;
        const selectSubtitlePosition = subtitlePosition || 'bottom';
        const isDisabled = isFunction(disabled) ? disabled(values) : Boolean(disabled);

        return (
          <MenuItem disabled={isCategory || isDisabled} key={key || value} value={value} divider={divider}>
            <InformationTooltip
              arrow
              placement={tooltip?.placement || 'right'}
              title={tooltip?.title || ''}
              $maxWidth={tooltip?.maxWidth}
              PopperProps={{
                onClick: (e) => (isCategory || isDisabled) && e.stopPropagation(),
              }}>
              <Grid
                onClick={(e) => (isCategory || isDisabled) && e.stopPropagation()}
                sx={{ pointerEvents: 'auto' }}
                container
                flexDirection={selectSubtitlePosition === 'bottom' ? 'column' : 'row'}
                justifyContent={selectSubtitlePosition === 'bottom' ? 'normal' : 'space-between'}>
                <Grid item>
                  <Text.B2 mr={2} $bold={isCategory} width="100%">
                    {displayValue}
                  </Text.B2>
                  <Text.B2>{isDisabled && disabledText ? disabledText : ''}</Text.B2>
                </Grid>
                {subtitle && (
                  <Grid item>
                    <Text.Sub2>{subtitle}</Text.Sub2>
                  </Grid>
                )}
              </Grid>
            </InformationTooltip>
          </MenuItem>
        );
      })}
    </Select>
  );
}
