import { t } from 'i18next';
import { useStiggTheme } from '@stigg-theme';
import { EnvironmentType } from '@stigg-types/apiTypes';
import { Text, GridFlex, Icon } from '@stigg-components';

export const ENVIRONMENT_SELECT_ITEM_HEIGHT = 44;

export type EnvironmentSelectItemProps = {
  displayName: string;
  type?: EnvironmentType;
  missingWritePermission?: boolean;
};

export function EnvironmentSelectItem({ displayName, type, missingWritePermission }: EnvironmentSelectItemProps) {
  const { theme } = useStiggTheme();

  return (
    <GridFlex.RowCenter gap={2} height={ENVIRONMENT_SELECT_ITEM_HEIGHT}>
      {type ? (
        <Icon
          icon={type === EnvironmentType.Production ? 'ProductionEnvironmentIcon' : 'DevEnvironmentIcon'}
          type="custom"
          color={theme.isLightTheme ? 'default' : 'primary.main'}
          overrideStroke
        />
      ) : null}

      <Text.B2 color="primary">{displayName}</Text.B2>

      {missingWritePermission ? <Text.B2 color="primary">({t('permissions.fullAccessRequired')})</Text.B2> : null}
    </GridFlex.RowCenter>
  );
}
