import { gql } from '@apollo/client';
import { t } from 'i18next';
import { UpdateOnePlanMutation, UpdateOnePlanMutationVariables, PlanUpdateInput } from '@stigg-types/apiTypes';
import { ExecuteOperationOptions } from '@stigg-common';
import { apolloClient } from '../../../../ApolloClient';
import { executeOperationSafely } from '../../../common/executeOperationSafely';
import { AppDispatch } from '../../../../redux/store';
import { fetchPlanByRefIdAction } from '../plansSlice';
import { SYNC_STATE_FRAGMENT } from '../../plans/queries/syncStateFragment';

const UPDATE_PLAN = gql`
  mutation UpdateOnePlan($input: PlanUpdateInput!) {
    updateOnePlan(input: $input) {
      id
      description
      displayName
      billingId
      billingLinkUrl
      updatedAt
      versionNumber
      hiddenFromWidgets
      prices {
        id
        crmLinkUrl
        crmId
      }
      syncStates {
        ...SyncStateFragment
      }
    }
  }
  ${SYNC_STATE_FRAGMENT}
`;

type UpdatePlanProps = {
  planRefId: string;
  updatePayload: PlanUpdateInput;
  reloadPlan?: boolean;
} & ExecuteOperationOptions;

async function updatePlan(
  { planRefId, updatePayload, reloadPlan = true, silentFetch }: UpdatePlanProps,
  { dispatch }: { dispatch: AppDispatch },
): Promise<UpdateOnePlanMutation['updateOnePlan'] | undefined> {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<UpdateOnePlanMutation, UpdateOnePlanMutationVariables>({
        mutation: UPDATE_PLAN,
        variables: {
          input: {
            ...updatePayload,
          },
        },
      });

      const responseData = response.data?.updateOnePlan;
      if (responseData && reloadPlan) {
        await dispatch(
          fetchPlanByRefIdAction({ refId: planRefId, versionNumber: responseData.versionNumber, silentFetch }),
        );
      }
      return responseData;
    },
    {
      failureMessageHandler: () => t('plans.api.failUpdate'),
    },
    dispatch,
  );
}

export { updatePlan };
