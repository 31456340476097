export function enumFindCaseInsensitive<T extends Record<string, string>>(
  enumObj: T,
  value?: string | null,
): T[keyof T] | undefined {
  if (!value) {
    return undefined;
  }
  const lowerCaseValue = value.toLowerCase();
  return Object.values(enumObj).find((enumValue) => enumValue.toLowerCase() === lowerCaseValue) as
    | T[keyof T]
    | undefined;
}
