import { t } from 'i18next';
import {
  UpdateIntegrationInput,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables,
  Integration,
  ErrorCode,
} from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchIntegrationByIdAction, fetchIntegrationsAction } from '../integrationsSlice';
import { UPDATE_INTEGRATION } from './updateIntegrationMutation';

async function updateIntegration(
  {
    integrationId,
    integrationData,
    skipFetch,
    noSuccessMessage,
    successMessage,
  }: {
    integrationId: string;
    integrationData: UpdateIntegrationInput;
    skipFetch?: boolean;
    noSuccessMessage?: boolean;
    successMessage?: string;
  },
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Integration> | undefined> {
  return executeOperationSafely(
    async () => {
      const integration = {
        ...integrationData,
      };

      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const response = await apolloClient.mutate<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>({
        mutation: UPDATE_INTEGRATION,
        variables: { input: { id: integrationId, update: integration } },
      });
      if (!response.data) {
        throw new Error('Invalid response');
      }
      const newIntegration = response.data.updateOneIntegration;
      if (!skipFetch) {
        await dispatch(fetchIntegrationByIdAction({ integrationId: newIntegration.id }));
        await dispatch(fetchIntegrationsAction({ environmentId: accountReducer.currentEnvironmentId }));
      }
      return newIntegration;
    },
    {
      successMessage: noSuccessMessage ? undefined : successMessage || t('integrations.api.successUpdate'),
      failureMessageHandler: (err) => {
        // TODO: change this string comparison to use explicit error code instead
        if (err.graphQLErrors[0]?.message === 'Zuora credentials are not valid') {
          return t('integrations.api.invalidCredentials');
        }
        return t('integrations.api.failUpdate');
      },
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('integrations.api.alreadyExists', {
          vendorIdentifier: integrationData.vendorIdentifier,
        }),
      },
    },
    dispatch,
  );
}

export { updateIntegration };
