import { DatePeriod, SplitRate, Text, GridFlex } from '@stigg-components';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { HeadCell } from '../../../components/table/Table';
import { ExperimentChip } from './commom/ExperimentChip';
import ExperimentCustomerJourneyIcon from '../../../assets/icons/experiments/ExperimentCustomerJourney.svg?react';

export const headCells = (): Array<HeadCell<ExperimentFragment, any>> => [
  {
    id: 'useCase',
    alignment: 'left',
    label: t('experiments.useCase'),
    // TODO: this is temporary hard-coded for the first experiment
    render: () => (
      <GridFlex.RowCenter columnGap={4}>
        <ExperimentCustomerJourneyIcon width={50} />
        <GridFlex.Column flex={1}>
          <Text.B2 color="secondary">{t('experiments.useCases.customerJourney.title')}</Text.B2>
        </GridFlex.Column>
      </GridFlex.RowCenter>
    ),
  },
  {
    id: 'name',
    alignment: 'left',
    label: t('experiments.name'),
    render: (experiment) => <Text.B2>{experiment.name}</Text.B2>,
  },
  {
    id: 'status',
    alignment: 'left',
    label: t('experiments.status'),
    width: 100,
    render: (experiment) => <ExperimentChip status={experiment.status} />,
  },
  {
    id: 'splitRatio',
    alignment: 'left',
    label: t('experiments.splitRatio'),
    render: (experiment) => <SplitRate percentage={experiment.variantPercentage} reverse variant="linearProgress" />,
  },
  {
    id: 'experimentPeriod',
    alignment: 'left',
    label: t('experiments.experimentPeriod'),
    render: (experiment) => <DatePeriod start={experiment.startedAt} end={experiment.stoppedAt} />,
  },
];
