import { Grid, Box, Text, GridFlex, DialogActionsButtons } from '@stigg-components';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { t } from 'i18next';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AddonFragment } from '@stigg-types/apiTypes';
import { useAppDispatch, RootState } from '../../../../redux/store';
import { fetchAddonsAction } from '../addonSlice';
import { AutocompleteSearch } from '../../plans/components/AutoCompleteSearch';
import { AddedAddonsList } from '../../plans/components/planPage/compatibleAddons/AddedAddonsList';

export type AddAddonsToGroupFormFields = {
  addons: AddonFragment[];
};

export function AddAddonsToAddonGroup({
  onCancel,
  productId,
  excludedAddonIds,
  onSubmit,
}: {
  onCancel: () => void;
  productId: string;
  excludedAddonIds: string[];
  onSubmit: (addonIds: string[]) => Promise<void>;
}) {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const addons = useSelector((state: RootState) => state.addonReducer.addons);
  const isFetchingAddons = useSelector((state: RootState) => state.addonReducer.isLoading);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const [searchOptions, setSearchOptions] = useState<readonly AddonFragment[]>([]);
  const onSearchKeyUp = useCallback(
    (event: any) => {
      setSearchTerm(event.target.value);
      if (event.target.value) {
        void dispatch(
          fetchAddonsAction({
            search: event.target.value,
            environmentId: currentEnvironmentId,
          }),
        );
      }
    },
    [currentEnvironmentId, dispatch],
  );

  useEffect(() => {
    if (currentEnvironmentId && !searchTerm) {
      void dispatch(fetchAddonsAction({ environmentId: currentEnvironmentId, productId }));
    }
  }, [currentEnvironmentId, dispatch, productId, searchTerm]);

  useEffect(() => {
    setSearchOptions(addons.edges ? addons.edges.map((edge) => edge.node) : []);
  }, [addons.edges]);

  const handleSubmit = async (values: AddAddonsToGroupFormFields) => {
    await onSubmit([...excludedAddonIds, ...values.addons.map((addon) => addon.refId)]);
    onCancel();
  };

  const initialValues: AddAddonsToGroupFormFields = {
    addons: [],
  };

  const chooseAddon = (push: any, option: AddonFragment | null) => {
    if (option) {
      push(option);
    }
  };

  const isOptionDisabled = (currentAddons: AddonFragment[], option: AddonFragment) => {
    const selectedAddonIds = currentAddons.map((newAddon: AddonFragment) => newAddon.refId);
    return includes([...selectedAddonIds, ...excludedAddonIds], option.refId);
  };

  const getDisabledDescription = () => {
    return `(${t('sharedComponents.alreadyAdded')})`;
  };

  const validationSchema = Yup.object().shape({
    addons: Yup.array().required().min(1),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {(formProps: FormikProps<AddAddonsToGroupFormFields>) => {
        const { isValid, values } = formProps;
        return (
          <Form>
            <FieldArray name="addons">
              {({ push, remove }) => (
                <GridFlex.Column container spacing={3}>
                  <Grid item>
                    <AutocompleteSearch
                      chooseOption={(option) => chooseAddon(push, option)}
                      isOptionDisabled={(option: AddonFragment) => isOptionDisabled(values.addons, option)}
                      searchOptions={searchOptions}
                      onSearchKeyUp={onSearchKeyUp}
                      isFetching={isFetchingAddons}
                      placeholder={t('addons.searchPlaceholder')}
                      getOptionLabel={(option) => `${option.displayName} (${option.refId})`}
                      renderOption={(props: any, option: AddonFragment) => (
                        <Box component="li" {...props} key={option.refId}>
                          <Grid alignItems="center" justifyContent="space-between" container spacing={6}>
                            <Grid item>
                              <Text.B2 mr={2}>{option.displayName} </Text.B2>
                              <Text.B2>{props['aria-disabled'] ? getDisabledDescription() : ''}</Text.B2>
                            </Grid>
                            <Grid item sx={{ color: (theme) => theme.itamar.palette.grey[700] }}>
                              <Text.B2>{option.refId}</Text.B2>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
                    />
                  </Grid>

                  <Grid item direction="column" sx={{ minHeight: 300 }} mb={4}>
                    {!isEmpty(values.addons) ? (
                      // @ts-ignore FIX-ME
                      <AddedAddonsList formProps={formProps} addons={values.addons} remove={remove} />
                    ) : null}
                  </Grid>

                  <Grid
                    container
                    item
                    alignItems="baseline"
                    justifyContent="space-between"
                    sx={{ position: 'absolute', bottom: 24, left: 0, px: 6 }}>
                    <Grid item pl={2}>
                      {values.addons.length > 0 ? (
                        <Text.Sub2>
                          {values.addons.length} add-on{values.addons.length > 1 ? 's' : ''} selected
                        </Text.Sub2>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <DialogActionsButtons
                        saveDisabled={!isValid || isEmpty(values.addons)}
                        saveText={t('sharedComponents.add')}
                        onCancel={onCancel}
                      />
                    </Grid>
                  </Grid>
                </GridFlex.Column>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
}
