import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import styled, { css } from 'styled-components/macro';
import { useIntercom } from 'react-use-intercom';
import {
  Plan,
  Checkout,
  CustomerPortal,
  OnManageSubscriptionFn,
  OnPlanSelectedCallbackFn,
  Paywall,
  useStiggContext,
  SubscribeIntentionType,
  OnCheckoutCompletedParams,
  useActiveSubscriptions,
} from '@stigg/react-sdk';
import { PricingType, BillingPeriod } from '@stigg-types/apiTypes';
import { useModal } from '../../common';

const StyledStiggCheckout = styled.div`
  ${({ theme }) =>
    theme.isLightTheme
      ? ''
      : css`
          .react-loading-skeleton {
            border-radius: ${theme.itamar.border.borderRadius};
            --highlight-color: ${theme.itamar.palette.background.darkBackground} !important;
            --base-color: ${theme.itamar.palette.background.paper} !important;
          }
        `}
  .stigg-checkout-layout {
    border-color: transparent;
    padding: ${({ theme }) => theme.spacing(4)};
    min-height: 600px;
  }
`;

const StyledStiggCustomerPortal = styled.div<{ $isPaidSubscription: boolean }>`
  ${({ theme }) =>
    theme.isLightTheme
      ? ''
      : css`
          // Skeleton
          .react-loading-skeleton {
            border-radius: ${theme.itamar.border.borderRadius};
            --highlight-color: ${theme.itamar.palette.background.darkBackground} !important;
            --base-color: ${theme.itamar.palette.background.paper} !important;
          }
          .stigg-skeleton-loader-billing-period {
            border-radius: ${theme.itamar.border.borderRadius};
            --highlight-color: ${theme.itamar.palette.background.darkBackground} !important;
            background-color: ${theme.itamar.palette.background.paper} !important;
          }
          // Customer portal
          .stigg-subscription-total-price-layout {
            // TODO: Change to use theme after it's updated with new tokens
            background-color: #242323;
            border-color: #414040;
          }
          .stigg-manage-subscription-button {
            border-color: ${({ theme }) => theme.itamar.palette.border.primary};
          }
          // Paywall
          .stigg-current-plan {
            border-color: ${({ theme }) => theme.itamar.palette.primary.main};
          }
          // Progress bar
          .stigg-feature-usage-progress-bar-standard {
            background-color: ${({ theme }) => theme.itamar.palette.primary.outlinedRestingBackground} !important;
            span {
              background-color: ${({ theme }) => theme.itamar.palette.primary.main} !important;
            }
          }
          .stigg-feature-usage-progress-bar-warning {
            background-color: ${({ theme }) => theme.itamar.palette.warning.outlinedRestingBackground} !important;
            span {
              background-color: ${({ theme }) => theme.itamar.palette.warning.main} !important;
            }
          }
          .stigg-feature-usage-progress-bar-error {
            background-color: ${({ theme }) => theme.itamar.palette.error.outlinedRestingBackground} !important;
            span {
              background-color: ${({ theme }) => theme.itamar.palette.error.main} !important;
            }
          }
        `}

  .stigg-plan-offering-container {
    min-width: 383px;
  }
  .stigg-entitlement-usage-feature-seats {
    order: 1;
  }
  .stigg-entitlement-usage-feature-paid-subscriptions-events {
    order: 2;
  }
  .stigg-entitlement-usage-feature-custom-subscriptions-events {
    order: 3;
  }
  .stigg-entitlement-usage-feature-free-subscriptions-events {
    order: 4;
  }
  .stigg-entitlement-usage-feature-products {
    order: 5;
  }
  .stigg-entitlement-usage-feature-development-environments {
    order: 6;
  }
  .stigg-entitlement-usage-feature-production-environments {
    order: 7;
  }

  ${({ $isPaidSubscription }) =>
    $isPaidSubscription &&
    css`
      .stigg-entitlement-usage-feature-seats .MuiLinearProgress-bar {
        background-color: rgba(50, 126, 238, 0.4);
      }
      .stigg-charge-list-item-feature-seats .MuiLinearProgress-bar {
        background-color: rgba(50, 126, 238, 0.4);
      }
      .stigg-entitlement-usage-feature-production-environments .MuiLinearProgress-bar {
        background-color: rgba(50, 126, 238, 0.4);
      }
    `}
`;

const HIDDEN_ENTITLEMENTS = ['feature-event-metering', 'feature-environments', 'feature-entitlements'];

export function BillingCustomerPortal() {
  const [CheckoutModal, setCheckoutModal] = useModal({ height: 800, width: 1000 });
  const intercom = useIntercom();
  const theme = useTheme();
  const stigg = useStiggContext();
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [currentBillingPeriod, setCurrentBillingPeriod] = useState(BillingPeriod.Annually);

  const onPaywallPlanSelected: OnPlanSelectedCallbackFn = (args) => {
    const { plan, intentionType } = args;
    if (intentionType === SubscribeIntentionType.REQUEST_CUSTOM_PLAN_ACCESS) {
      intercom.showNewMessage('I’d like to learn more about Stigg’s Scale plan');
    } else {
      setSelectedPlan(plan);
      setCheckoutModal(true);
    }
  };

  const onManageSubscription: OnManageSubscriptionFn = (args) => {
    const { customerSubscriptions } = args;
    const paidSubscription = customerSubscriptions.find((sub) => sub.pricingType === PricingType.Paid);
    if (paidSubscription) {
      setCheckoutModal(true);
      if (paidSubscription.prices[0].billingPeriod) {
        // We have an assumption that all prices in a subscription have the same billing period
        setCurrentBillingPeriod(paidSubscription.prices[0].billingPeriod);
      }
    } else {
      document
        .getElementsByClassName('stigg-customer-portal-paywall-section')[0]
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onCheckoutCompleted = async ({ success }: OnCheckoutCompletedParams) => {
    if (!success) {
      return;
    }

    setCheckoutModal(false);
    await stigg?.refreshData();
  };

  const onContactSupport = () => {
    intercom.showNewMessage();
  };

  const { activeSubscriptions } = useActiveSubscriptions();

  useEffect(() => {
    const paidSubscription = activeSubscriptions?.find((sub) => sub.pricingType === PricingType.Paid);
    if (paidSubscription) {
      setSelectedPlan(paidSubscription.plan);
    }
  }, [activeSubscriptions, stigg?.customerPortalUpdatedAt]);

  return (
    <>
      <CheckoutModal>
        {selectedPlan?.id && (
          <StyledStiggCheckout>
            <Checkout
              planId={selectedPlan.id}
              preferredBillingPeriod={currentBillingPeriod}
              onChangePlan={() => setCheckoutModal(false)}
              onCheckoutCompleted={onCheckoutCompleted}
              theme={{
                backgroundColor: theme.itamar.palette.background.darkBackground2,
                borderColor: theme.itamar.border.borderColor,
                primary: theme.itamar.palette.primary.main,
                textColor: theme.itamar.palette.text.primary,
                summaryBackgroundColor: theme.itamar.palette.background.fadedBackground,
                paymentInputBackgroundColor: theme.itamar.palette.background.default,
              }}
            />
          </StyledStiggCheckout>
        )}
      </CheckoutModal>
      <StyledStiggCustomerPortal $isPaidSubscription={selectedPlan?.pricingType === PricingType.Paid}>
        <CustomerPortal
          {...(theme.isLightTheme
            ? {}
            : {
                theme: {
                  backgroundColor: theme.itamar.palette.background.paper,
                  borderColor: theme.itamar.border.borderColor,
                  iconsColor: theme.itamar.palette.text.primary,
                  planNameColor: theme.itamar.palette.text.primary,
                  sectionTitleColor: theme.itamar.palette.text.primary,
                },
              })}
          filterEntitlements={(entitlements) =>
            entitlements.filter((entitlement) => !HIDDEN_ENTITLEMENTS.includes(entitlement.feature!.refId))
          }
          onManageSubscription={onManageSubscription}
          onContactSupport={onContactSupport}
          paywallComponent={
            <Paywall onBillingPeriodChange={setCurrentBillingPeriod} onPlanSelected={onPaywallPlanSelected} />
          }
        />
      </StyledStiggCustomerPortal>
    </>
  );
}
