import { t } from 'i18next';
import { ArticleCardProps } from '@stigg-components';
import TestingPriceChangesCTASvgLight from '@assets/images/testingPriceChangesCTA.light.svg?react';
import AddingTrialFlowCTASvgLight from '@assets/images/addingTrialFlowCTA.light.svg?react';
import GainVisibilityCTASvgLight from '@assets/images/gainVisibilityCTA.light.svg?react';
import RolloutPriceChangesCTASvgLight from '@assets/images/rolloutPriceChangesCTA.light.svg?react';

import TestingPriceChangesCTASvgDark from '@assets/images/testingPriceChangesCTA.dark.svg?react';
import AddingTrialFlowCTASvgDark from '@assets/images/addingTrialFlowCTA.dark.svg?react';
import GainVisibilityCTASvgDark from '@assets/images/gainVisibilityCTA.dark.svg?react';
import RolloutPriceChangesCTASvgDark from '@assets/images/rolloutPriceChangesCTA.dark.svg?react';

import { externalLinks } from '../../common';

export const getArticleCardsData = (isLightTheme: boolean): (ArticleCardProps & { id: string })[] => [
  {
    id: 'testing-price-changes',
    svg: isLightTheme ? <TestingPriceChangesCTASvgLight /> : <TestingPriceChangesCTASvgDark />,
    titleBoldText: t('gettingStarted.articleCards.testingPriceChangesCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.testingPriceChangesCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
  {
    id: 'adding-trial-flow',
    svg: isLightTheme ? <AddingTrialFlowCTASvgLight /> : <AddingTrialFlowCTASvgDark />,
    titleBoldText: t('gettingStarted.articleCards.addingTrialFlowCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.addingTrialFlowCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
  {
    id: 'gain-visibility',
    svg: isLightTheme ? <GainVisibilityCTASvgLight /> : <GainVisibilityCTASvgDark />,
    titleBoldText: t('gettingStarted.articleCards.gainVisibilityCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.gainVisibilityCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
  {
    id: 'rollout-price-changes',
    svg: isLightTheme ? <RolloutPriceChangesCTASvgLight /> : <RolloutPriceChangesCTASvgDark />,
    titleBoldText: t('gettingStarted.articleCards.rolloutPriceChangesCard.titleBoldText'),
    titleAfterBoldText: t('gettingStarted.articleCards.rolloutPriceChangesCard.titleAfterBoldText'),
    articleLink: externalLinks.gettingStartedDocsLink,
    buttonText: t('gettingStarted.learnHow'),
  },
];
