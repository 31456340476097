import { Grid, Box, GridFlex, CustomDrawer, DialogActionsButtons } from '@stigg-components';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { t } from 'i18next';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AddonFragment, PackageStatus, PricingType } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { fetchAddonsAction } from '../addonSlice';
import { AutocompleteSearch } from '../../plans/components/AutoCompleteSearch';
import { AddonDependenciesList } from './AddonDependenciesList';
import { AddonValueCell } from './AddonValueCell';

type AddDependentAddonsDialogProps = {
  currentAddon: AddonFragment;
  open: boolean;
  setDialogOpen: any;
  onSubmit: (values: DependentAddonsFormFields) => Promise<void>;
  excludedAddonIds: string[];
};
const getValidationSchema = () =>
  Yup.object().shape({
    addons: Yup.array().required().min(1),
  });

export type DependentAddonsFormFields = {
  addons: AddonFragment[];
};

export function AddDependentAddonsDialog({
  currentAddon,
  excludedAddonIds,
  onSubmit,
  open,
  setDialogOpen,
}: AddDependentAddonsDialogProps) {
  const dispatch = useAppDispatch();
  const { edges } = useSelector((state: RootState) => state.addonReducer.addons);
  const isFetchingAddons = useSelector((state: RootState) => state.addonReducer.isLoading);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;
  const [searchOptions, setSearchOptions] = useState<readonly AddonFragment[]>([]);
  const onSearchKeyUp = (event: any) => {
    void dispatch(
      fetchAddonsAction({
        search: event.target.value,
        environmentId: currentEnvironmentId,
      }),
    );
  };

  useEffect(() => {
    void dispatch(fetchAddonsAction({ environmentId: currentEnvironmentId, productId: currentAddon?.product?.id }));
  }, [dispatch, currentEnvironmentId, currentAddon?.product?.id]);

  useEffect(() => {
    const options = edges ? edges.map((edge) => edge.node) : [];
    setSearchOptions(options.filter((option) => option.refId !== currentAddon.refId));
  }, [edges, currentAddon]);

  const onCancel = () => setDialogOpen(false);
  const handleSubmit = async (values: DependentAddonsFormFields) => {
    try {
      await onSubmit(values);
      setDialogOpen(false);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const initialValues: DependentAddonsFormFields = {
    addons: currentAddon.dependencies || [],
  };

  const chooseAddon = (push: any, option: AddonFragment | null) => {
    if (option) {
      push(option);
    }
  };

  const isOptionDisabled = (currentAddons: AddonFragment[], option: AddonFragment) => {
    const notCompatibleWithPlanPricingType =
      currentAddon.pricingType === PricingType.Paid && option.pricingType === PricingType.Custom;
    const selectedAddonIds = currentAddons.map((newAddon: AddonFragment) => newAddon.id);
    return (
      includes([...selectedAddonIds, ...excludedAddonIds], option.id) ||
      option.status === PackageStatus.Draft ||
      notCompatibleWithPlanPricingType
    );
  };

  const getDisabledDescription = (addon: AddonFragment) => {
    if (addon.status === PackageStatus.Draft) {
      return `(${t('addons.draftAddon')})`;
    }
    if (currentAddon.pricingType === PricingType.Paid && addon.pricingType === PricingType.Custom) {
      return `(${t('addons.customAddon')})`;
    }
    return `(${t('sharedComponents.alreadyAdded')})`;
  };

  return (
    <CustomDrawer title={t('addons.addonDependencies.addonsDialog.title')} isOpen={open} onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}>
        {({ isValid, dirty, values }: FormikProps<DependentAddonsFormFields>) => (
          <Form>
            <FieldArray name="addons">
              {({ push, remove }) => (
                <GridFlex.Column container spacing={3}>
                  <Grid item>
                    <AutocompleteSearch
                      chooseOption={(option) => chooseAddon(push, option)}
                      isOptionDisabled={(option: AddonFragment) => isOptionDisabled(values.addons, option)}
                      searchOptions={searchOptions}
                      onSearchKeyUp={onSearchKeyUp}
                      isFetching={isFetchingAddons}
                      placeholder={t('addons.searchPlaceholder')}
                      getOptionLabel={(option) => `${option.displayName} (${option.refId})`}
                      renderOption={(props: any, option: AddonFragment) => (
                        <Box component="li" {...props} key={option.refId}>
                          <AddonValueCell
                            addon={option}
                            disabledText={props['aria-disabled'] ? getDisabledDescription(option) : undefined}
                          />
                        </Box>
                      )}
                      isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
                    />
                  </Grid>
                  <Grid item direction="column" mb={4} sx={{ minHeight: 300 }}>
                    {!isEmpty(values.addons) ? (
                      <AddonDependenciesList
                        addons={values.addons}
                        remove={remove}
                        height={480}
                        removeOptions={{ inOptions: true }}
                      />
                    ) : null}
                  </Grid>

                  <Grid
                    container
                    item
                    alignItems="baseline"
                    justifyContent="flex-end"
                    sx={{ position: 'absolute', bottom: 24, left: 0, px: 6 }}>
                    <Grid item>
                      <DialogActionsButtons
                        onSave={() => handleSubmit(values)}
                        saveDisabled={!dirty || !isValid || isEmpty(values.addons)}
                        saveText={t('sharedComponents.saveChanges')}
                        onCancel={onCancel}
                      />
                    </Grid>
                  </Grid>
                </GridFlex.Column>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </CustomDrawer>
  );
}
