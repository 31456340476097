import { PageCard, SplitRate, GridFlex, Text } from '@stigg-components';
import { ExperimentFragment } from '@stigg-types/apiTypes';
import * as React from 'react';
import { t } from 'i18next';
import { compact, uniqBy } from 'lodash';
import { CustomerJourneyExperimentDefinition } from './CustomerJourneyExperimentDefinition';
import { CustomerJourneyPlan } from '../../../products/components/productPage/customerJourney/types';

type ExperimentDetailsProps = {
  experiment: ExperimentFragment;
};

export function ExperimentDetails({ experiment }: ExperimentDetailsProps) {
  if (!experiment.product) {
    return null;
  }

  // At this stage we cannot relay on the product experiment.product at all,
  // as the experiment might have ended and its plans might have changed.

  const productId = experiment.product.id;
  const initialProductSettings = experiment.initialProductSettings
    ? experiment.initialProductSettings
    : experiment.product.productSettings;
  // @ts-ignore FIX-ME
  const plans: CustomerJourneyPlan[] = uniqBy(
    compact([
      initialProductSettings.downgradePlan,
      initialProductSettings.subscriptionStartPlan,
      experiment.productSettings.downgradePlan,
      experiment.productSettings.subscriptionStartPlan,
    ]),
    (item) => item.refId,
  );

  return (
    <PageCard>
      <Text.H3>{t('experiments.experimentGroups')}</Text.H3>
      <GridFlex.RowSpaceBetween mb={2} mt={6}>
        <Text.H6>{experiment.controlGroupName}</Text.H6>
        <Text.H6>{experiment.variantGroupName}</Text.H6>
      </GridFlex.RowSpaceBetween>
      <SplitRate percentage={experiment.variantPercentage} reverse variant="linearProgress" />
      <CustomerJourneyExperimentDefinition
        productId={productId}
        plans={plans}
        initialProductSettings={initialProductSettings}
        variantProductSettings={experiment.productSettings}
      />
    </PageCard>
  );
}
