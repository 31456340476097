import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAuth0, User } from '@auth0/auth0-react';
import { Trash2 } from 'react-feather';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  ExternalLink,
  Grid,
  GridFlex,
  InformationTooltip,
  LocalDate,
  Text,
  useScrollableAnchor,
  Box,
  EmptyList,
  Icon,
  OptionsDropdown,
  PageCard,
} from '@stigg-components';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useStiggContext } from '@stigg/react-sdk';
import { useConfirmationDialog } from '@stigg-common';
import { useAccountPermissionCheck, AccountPermissionActions } from '@stigg-permissions';
import { Member, MemberStatus } from '@stigg-types/apiTypes';
import { useTablePagination } from '../../../components/table/gqlTableHelper';
import Table, { HeadCell } from '../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchAccountMembersAction, removeMemberAction, setAccessRolesAction } from '../accountsSlice';
import { useInviteMembersDrawer } from './InviteMembersDrawer/useInviteMembersDrawer';
import { EditMemberDrawer, MemberFormFields } from './EditMemberDrawer';
import { MemberWithAvatar } from './MemberWithAvatar';
import Search from '../../../components/Search';
import { updateUserAction } from '../../auth/authSlice';
import { getListCursors } from '../../eventsLog/utils/getListCursors';
import useArrowKeyAction from '../../../components/hooks/useArrowKeyNavigation';

const headCells = (
  onRemove: (member: Member) => void,
  currentUser: User | undefined,
  rbacRollout: boolean,
): Array<HeadCell<Member, any>> => {
  const cells: Array<HeadCell<Member, any>> = [
    {
      id: 'name',
      alignment: 'left',
      label: t('accounts.userName'),
      width: rbacRollout ? 400 : undefined,
      render: (member) => {
        return <MemberWithAvatar member={member} isCurrentMember={currentUser?.email === member.email} />;
      },
    },
    {
      id: 'lastSeenAt',
      alignment: 'left',
      label: t('accounts.lastSeen'),
      render: (member) => (
        <Text.B2>
          {member.memberStatus === MemberStatus.Invited ? (
            <Text.B2 color="disabled">{t('accounts.pendingInvitation')} </Text.B2>
          ) : member.user && member.user.lastSeenAt ? (
            <LocalDate date={member.user.lastSeenAt} formatOptions={{ withTime: true, formatType: 'standard' }} />
          ) : (
            '-'
          )}
        </Text.B2>
      ),
    },
  ];

  if (rbacRollout) {
    const rbacCells: Array<HeadCell<Member, any>> = [
      {
        id: 'prodRole',
        alignment: 'left',
        label: t('accounts.productionAccess'),
        render: (member) => (
          <Text.B2>
            {member.accessRoles?.productionRole
              ? t(`accounts.environmentRole.${member.accessRoles?.productionRole}`)
              : '-'}
          </Text.B2>
        ),
      },
      {
        id: 'nonProdRole',
        alignment: 'left',
        label: t('accounts.nonProductionAccess'),
        render: (member) => (
          <Text.B2>
            {member.accessRoles?.nonProductionRole
              ? t(`accounts.environmentRole.${member.accessRoles?.nonProductionRole}`)
              : '-'}
          </Text.B2>
        ),
      },
    ];

    cells.splice(1, 0, ...rbacCells);
  }

  if (!rbacRollout) {
    cells.push({
      id: 'options',
      alignment: 'right',
      label: '',
      disableClick: true,
      width: 56,
      maxWidth: 36 + 8,
      render: (member) => (
        <OptionsDropdown
          options={[
            {
              icon: Trash2,
              text: t('sharedComponents.remove'),
              disabled: currentUser?.email === member.email,
              onClick: () => {
                onRemove(member);
              },
            },
          ]}
        />
      ),
    });
  }

  return cells;
};

function AccountMembers() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const { user } = useAuth0();
  const { rbacRollout } = useFlags<FeatureFlags>();
  const [pageNumber, setPageNumber] = useState(0);
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const stigg = useStiggContext();
  const allowInviteUser = useAccountPermissionCheck(AccountPermissionActions.InviteUser);

  const { inviteMembersDrawer, setIsInviteDrawerOpen } = useInviteMembersDrawer();
  const [teamMemberToDelete, setTeamMemberToDelete] = useState<Member | null>(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState<Member | null>(null);

  const [DeleteMemberDialog, showDeleteMemberDialog, deleteMemberDialogProps] = useConfirmationDialog({
    title: t('accounts.deleteAccountMemberDialogTitle'),
    content: t('accounts.deleteAccountMemberDialogContent', { teamMember: teamMemberToDelete?.email }),
    handleConfirm: async () => {
      if (teamMemberToDelete) {
        await dispatch(removeMemberAction(teamMemberToDelete.id));
        await stigg?.refreshData();
      }
    },
  });

  const { elementRef } = useScrollableAnchor({ anchor: 'accountMembers' });

  useEffect(() => {
    void dispatch(fetchAccountMembersAction({}));
  }, [dispatch]);

  const isLoadingAccountMembers = useSelector((state: RootState) => state.accountReducer.isLoadingAccountMembers);
  const { pageInfo, edges: members } = useSelector((state: RootState) => state.accountReducer.members);

  const { pageChangeFunc, refreshPageFunc } = useTablePagination({
    dispatch,
    pageNum: pageNumber,
    pageInfo,
    setPageNum: setPageNumber,
    fetchAction: fetchAccountMembersAction,
    dispatchArgs: { search },
  });

  const [currentMember, otherMembers] = partition(
    (members || []).map((item) => item.node),
    (member) => member.email === user?.email,
  );
  const orderedMembers = [...currentMember, ...otherMembers];

  const onMemberRemove = (member: Member) => {
    setTeamMemberToDelete(member);
    showDeleteMemberDialog(true);
    setEditDialogOpen(false);
  };

  const onCloseEdit = () => {
    setEditDialogOpen(false);
    setMemberToEdit(null);
  };

  const onMemberEdit = (member: Member) => {
    setMemberToEdit(member);
    setEditDialogOpen(true);
  };

  const handleEditSubmit = async (values: MemberFormFields) => {
    const { accountRole, nonProductionAccess, productionAccess, displayName } = values;
    const isCurrentUser = memberToEdit?.email === user?.email;

    if (memberToEdit) {
      const isNameChanged = displayName !== memberToEdit.user?.name;
      if (isNameChanged) {
        await dispatch(updateUserAction({ name: displayName }));
      }

      const hasRolesValues = accountRole && nonProductionAccess && productionAccess;
      const hasRolesChanges =
        accountRole !== memberToEdit.accessRoles?.accountRole ||
        nonProductionAccess !== memberToEdit.accessRoles?.nonProductionRole ||
        productionAccess !== memberToEdit.accessRoles?.productionRole;

      if (hasRolesValues && hasRolesChanges) {
        await dispatch(
          setAccessRolesAction({
            userId: memberToEdit.userId,
            accountRole,
            nonProductionRole: nonProductionAccess,
            productionRole: productionAccess,
          }),
        );

        await refreshPageFunc();

        // In case current user is the one being edited, refresh the page to refresh permissions and start the state from scratch
        if (isCurrentUser) {
          window.location.reload();
        }
      }
    }

    setEditDialogOpen(false);
  };

  const additionalTableProps = rbacRollout
    ? {
        onRowClick: (member: Member) => onMemberEdit(member),
      }
    : {};

  const { setPrevItem, setNextItem } = getListCursors(orderedMembers, memberToEdit, setMemberToEdit);

  const onLeftArrow = setPrevItem
    ? () => setPrevItem()
    : pageInfo?.hasPreviousPage
      ? () => pageChangeFunc(null, pageNumber - 1)
      : undefined;
  const onRightArrow = setNextItem
    ? () => setNextItem()
    : pageInfo?.hasNextPage
      ? () => pageChangeFunc(null, pageNumber + 1)
      : undefined;

  useArrowKeyAction({ onLeftArrow, onRightArrow });

  const ssoTooltip = (
    <GridFlex.Column>
      <Text.B2>{t('account.inviteMembersBlocked')}</Text.B2>
      <ExternalLink label={t('accounts.sso.learnMore')} url="https://docs.stigg.io/docs/single-sign-on" />
    </GridFlex.Column>
  );

  const noPermissionTooltip = <Text.B2>{t('permissions.inviteMemberDenied')}</Text.B2>;

  const inviteMembersDisabled = useMemo(
    () => !allowInviteUser || (account?.samlEnabled && !user?.email?.endsWith('@stigg.io')) || false,
    [allowInviteUser, account?.samlEnabled, user?.email],
  );

  return (
    <PageCard ref={elementRef}>
      <Grid wrap="nowrap" justifyContent="space-between" container spacing={12} mb={5}>
        <GridFlex.Column item xs={6}>
          <Text.H3 mb={2}>{t('accounts.members')}</Text.H3>
          <Text.Sub2>{t('accounts.inviteMembers')}</Text.Sub2>
        </GridFlex.Column>
        <Grid item container justifyContent="flex-end" alignItems="baseline" spacing={2}>
          <InformationTooltip
            arrow
            title={!allowInviteUser ? noPermissionTooltip : inviteMembersDisabled ? ssoTooltip : ''}>
            <Grid item>
              <Button
                sx={{ px: 4 }}
                $outlined
                color="primary"
                disabled={inviteMembersDisabled}
                onClick={() => setIsInviteDrawerOpen(true)}
                startIcon={
                  <Icon
                    type="custom"
                    icon="InviteMember"
                    color={allowInviteUser ? 'primary.main' : 'active'}
                    overrideStroke
                  />
                }>
                {t('accounts.invite')}
              </Button>
              {inviteMembersDrawer}
            </Grid>
          </InformationTooltip>
        </Grid>
      </Grid>

      <GridFlex.Column rowGap={4}>
        <Box maxWidth={400}>
          <Search
            variant="outlined"
            searchOnChange
            handleSearchFunc={(searchStr) => {
              setSearch(searchStr);
              if (searchStr) {
                setPageNumber(0);
              }
              void dispatch(fetchAccountMembersAction({ search: searchStr }));
            }}
            searchedStr={search}
            placeholder={t('accounts.searchPlaceholder')}
          />
        </Box>

        {isEmpty(orderedMembers) && !isLoadingAccountMembers ? (
          <EmptyList title={!isEmpty(search) ? t('accounts.emptySearchText') : t('accounts.emptyStateText')} />
        ) : (
          <Table
            isLoading={isLoadingAccountMembers}
            rowColor={(member) => (memberToEdit?.id === member?.id ? theme.itamar.palette.action.hover : undefined)}
            rowHeight={65}
            headCells={headCells(onMemberRemove, user, rbacRollout)}
            label={t('accounts.membersTable')}
            pageChangeFunc={pageChangeFunc}
            pageInfo={pageInfo}
            pageNum={pageNumber}
            data={orderedMembers}
            {...additionalTableProps}
          />
        )}
      </GridFlex.Column>

      <EditMemberDrawer
        open={editDialogOpen}
        member={memberToEdit}
        handleClose={onCloseEdit}
        handleSubmit={handleEditSubmit}
        isCurrentMember={user?.email === memberToEdit?.email}
        onRemove={onMemberRemove}
        onNext={onRightArrow}
        onPrevious={onLeftArrow}
      />

      <DeleteMemberDialog {...deleteMemberDialogProps} />
    </PageCard>
  );
}

export default AccountMembers;
