import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useWorkflowLogin } from '../mutations/useWorkflowLogin';
import Loader from '../../../components/Loader';
import { Box } from '../../../components';
import config from '../../../env.config';

export function WorkflowsPage() {
  const { billderRollout: showWorkflows } = useFlags<FeatureFlags>();
  const [isLoading, setIsLoading] = useState(true);
  const { data, isLoading: isLoginLoading } = useWorkflowLogin({ showWorkflows });
  const { projectId, token } = data || {};

  if (!showWorkflows) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  const src = `${config.workflowsBaseUrl}/projects/${projectId}/workflows?token=${token}`;

  return (
    <>
      {!isLoginLoading && !!projectId && !!token && (
        <iframe onLoad={() => setIsLoading(false)} src={src} style={{ width: '100%', height: '100%', border: 0 }} />
      )}
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Loader />
        </Box>
      )}
    </>
  );
}
