import { Button, ButtonProps } from '@stigg-components';
import styled from 'styled-components/macro';

/**
 * A button that is hidden by default and becomes visible on hover or when forceVisibility is true.
 * Used for actions that should only be visible when the user is interacting with the parent element.
 */
export const HiddenButton = styled(Button)<ButtonProps & { $forceVisibility?: boolean }>`
  padding: 0;
  opacity: ${({ $forceVisibility }) => ($forceVisibility ? 1 : 0)};
  transition: 0.2s ease-in-out;
  :hover,
  :active,
  :focus {
    opacity: 1;
  }
`;
