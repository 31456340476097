// Set a fixed height for table rows
import styled, { css } from 'styled-components/macro';
import {
  Collapse as MuiCollapsable,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableContainer as MuiTableContainer,
} from '@stigg-components';

export const SELECTION_COLUMN_WIDTH = 70;

export const TableContainer = styled(MuiTableContainer)<{ $withInnerDividers?: boolean }>`
  background: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.paper};

  ${({ $withInnerDividers }) =>
    $withInnerDividers &&
    css`
      .MuiTableCell-root:not(:last-child) {
        border-right: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};
      }
    `}
`;

export const TableRow = styled(MuiTableRow)<{
  $rowHeight?: number;
  $rowColor?: string | null;
  $isHeaderComponent?: boolean;
  $isHighlight?: boolean;
  $isLongBackgroundTransition?: boolean;
  $withRowHover?: boolean;
}>`
  height: ${(props) => (props.$rowHeight ? `${props.$rowHeight}px` : '53px')};
  transition: background-color 0.3s;

  ${({ $withRowHover, $isHeaderComponent }) =>
    $withRowHover &&
    !$isHeaderComponent &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.itamar.palette.action.hover} !important;
      }
    `}

  background: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.paper};

  ${({ $rowColor }) =>
    $rowColor &&
    css`
      background: ${$rowColor};
      th {
        background: ${$rowColor};
      }

      &:hover {
        background-color: ${$rowColor}cc !important;
      }
    `}

  ${({ $isHighlight }) =>
    $isHighlight &&
    css`
      background-color: ${({ theme }) => theme.itamar.palette.action.focus} !important;
    `}

  ${({ $isLongBackgroundTransition }) =>
    $isLongBackgroundTransition &&
    css`
      transition: background-color 1s !important;
    `}
`;

export const TableCell = styled(MuiTableCell)<{
  $disablePadding?: boolean;
  $disableVerticalPadding?: boolean;
  $disableBorder?: boolean;
  $disable?: boolean;
}>`
  padding-top: ${({ $disableVerticalPadding = true }) => ($disableVerticalPadding ? 0 : '16px')};
  padding-bottom: ${({ $disableVerticalPadding = true }) => ($disableVerticalPadding ? 0 : '16px')};
  padding-left: ${({ $disablePadding }) => ($disablePadding ? 0 : '16px')};
  padding-right: ${({ $disablePadding }) => ($disablePadding ? 0 : '16px')};

  ${({ $disableBorder }) =>
    $disableBorder &&
    css`
      border-bottom: none;
    `};

  border-bottom: ${({ theme }) => `1px solid ${theme.itamar.border.borderColor}`};

  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const TableDragHandleCell = styled(TableCell)`
  padding-left: 4px;
  padding-right: 0;
  width: 8px;
  max-width: 8px;
`;

export const TableBody = styled(MuiTableBody)<{
  $withBottomBorder?: boolean;
  $hasBorder?: boolean;
  $disable?: boolean;
}>`
  position: relative;
  background: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground};

  ${({ $withBottomBorder }) =>
    $withBottomBorder &&
    css`
      border-bottom: ${({ theme }) => theme.itamar.border.border};
      border-bottom-color: ${({ theme }) => theme.itamar.border.borderColor};
    `}

  ${({ $hasBorder }) =>
    $hasBorder &&
    css`
      & > *:last-child > * {
        border-bottom: none;
      }
    `}
  ${({ $disable }) =>
    $disable &&
    css`
      ${TableRow} {
        pointer-events: none;
        opacity: 0.5;
      }
    `}
`;

export const HeaderComponentRow = styled(TableRow)<{ $backgroundColorActive?: boolean }>`
  border-bottom: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.border.borderColor};
  cursor: pointer;
  height: unset;
  ${({ $backgroundColorActive }) =>
    $backgroundColorActive &&
    css`
      background-color: ${({ theme }) => theme.itamar.palette.background.paper};
    `};
`;

export const Collapse = styled(MuiCollapsable)`
  height: 100%;
`;

// This is used to hide double border when collapsed
export const CollapsableTableRow = styled(TableRow)<{ $rowColor?: string | null; $rowHeight?: number }>`
  cursor: pointer;
  border-bottom: none;

  height: ${(props) => (props.$rowHeight ? `${props.$rowHeight}px` : '53px')};
  ${({ $rowColor }) =>
    $rowColor &&
    css`
      background: ${$rowColor};
      th {
        background: ${$rowColor};
      }

      &:hover {
        background-color: ${$rowColor}cc !important;
      }
    `}
`;
