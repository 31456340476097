import { Chip, InformationTooltip, Text } from '@stigg-components';
import { VendorType } from '@stigg-types/apiTypes';
import { t } from 'i18next';

interface DefaultIntegrationChipProps {
  vendorType: VendorType;
  showTooltip?: boolean;
  showShortDefault?: boolean;
}

export function DefaultIntegrationChip({
  vendorType,
  showTooltip = true,
  showShortDefault = true,
}: DefaultIntegrationChipProps) {
  console.log('showShortDefault', showShortDefault);
  return (
    <InformationTooltip
      arrow
      placement="top"
      title={showTooltip ? <Text.B2>{t('integrations.defaultIntegrationTooltip')}</Text.B2> : undefined}>
      <Chip
        label={t('integrations.defaultIntegration', { context: showShortDefault ? undefined : vendorType })}
        color="disabled"
        textColor="disabled"
        variant="outlined"
        size="small"
      />
    </InformationTooltip>
  );
}
