import { PropsWithChildren } from 'react';
import { CarouselProps as ReactResponsiveCarouselProps } from 'react-responsive-carousel';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Box } from '../components.style';
import { StyledArrow, StyledCarousel, StyledIndicator } from './Carousel.style';

function CarouselSlide({ children, withPadding }: { withPadding?: boolean; children: React.ReactNode }) {
  return (
    <Box display="flex" height="100%" p={withPadding ? 11 : 0}>
      {children}
    </Box>
  );
}

export type CarouselProps = PropsWithChildren<
  Partial<ReactResponsiveCarouselProps> & { withPadding?: boolean; arrowPadding?: number }
>;

export function Carousel({
  emulateTouch = true,
  swipeable = true,
  useKeyboardArrows = true,
  showThumbs = false,
  showStatus = false,
  infiniteLoop = true,
  withPadding = true,
  arrowPadding = 16,
  renderItem = (item) => <CarouselSlide withPadding={withPadding}>{item}</CarouselSlide>,
  renderArrowNext = (clickHandler: () => void, hasNext: boolean) =>
    hasNext && (
      <StyledArrow $right $arrowPadding={arrowPadding} onClick={clickHandler}>
        <ArrowForwardIos fontSize="small" />
      </StyledArrow>
    ),
  renderArrowPrev = (clickHandler: () => void, hasPrev: boolean) =>
    hasPrev && (
      <StyledArrow $left $arrowPadding={arrowPadding} onClick={clickHandler}>
        <ArrowBackIosNew fontSize="small" />
      </StyledArrow>
    ),
  renderIndicator = (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean) => (
    <StyledIndicator onClick={clickHandler} $isSelected={isSelected} />
  ),
  ...props
}: CarouselProps) {
  return (
    <StyledCarousel
      emulateTouch={emulateTouch}
      swipeable={swipeable}
      useKeyboardArrows={useKeyboardArrows}
      showThumbs={showThumbs}
      showStatus={showStatus}
      infiniteLoop={infiniteLoop}
      renderItem={renderItem}
      renderArrowNext={renderArrowNext}
      renderArrowPrev={renderArrowPrev}
      renderIndicator={renderIndicator}
      {...props}
    />
  );
}
