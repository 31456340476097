import { useEffect } from 'react';
import { Environment } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../../redux/store';
import { resetMergeEnvironmentValidation, validateMergeEnvironmentAction } from '../../../accountsSlice';
import { EnvironmentOptionType } from '../EnvironmentsAutocompleteSearch';

type UseMergeEnvironmentValidationProps = {
  selectedEnvironment: EnvironmentOptionType | null;
  environment: Environment | null;
};

export const useMergeEnvironmentValidation = ({
  selectedEnvironment,
  environment,
}: UseMergeEnvironmentValidationProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetMergeEnvironmentValidation());
    if (selectedEnvironment?.slug && environment?.slug) {
      void dispatch(
        validateMergeEnvironmentAction({
          sourceEnvironmentSlug: environment.slug,
          destinationEnvironmentSlug: selectedEnvironment.slug,
        }),
      );
    }
  }, [selectedEnvironment, environment, dispatch]);
};
