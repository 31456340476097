import { drawFormFields, Field, FormRenderProps, GridFlex } from '@stigg-components';
import { t } from 'i18next';
import React from 'react';
import isEqual from 'lodash/isEqual';
import { pick } from 'lodash';
import * as S from './CustomerJourneyExperimentDiff.style';
import {
  SubscriptionStartSetupConfiguration,
  SubscriptionStartSetupFormFieldsNames,
} from '../../../../../products/components/productPage/customerJourney/SubscriptionStartSetupConfiguration';
import {
  SubscriptionEndSetupConfiguration,
  SubscriptionEndSetupFormFieldsNames,
} from '../../../../../products/components/productPage/customerJourney/SubscriptionEndSetupConfiguration';
import {
  SubscriptionCancellationTimeConfiguration,
  SubscriptionCancellationTimeFormFieldsNames,
} from '../../../../../products/components/productPage/customerJourney/SubscriptionCancellationTimeConfiguration';
import { ExperimentPreset } from '../../../editExperimentWizard/ExperimentWizard';
import {
  CustomerJourneyPlan,
  EditProductSettingsFormFields,
} from '../../../../../products/components/productPage/customerJourney/types';
import { CustomerJourneySectionTitle } from '../../../../../products/components/productPage/customerJourney/CustomerJourneySectionTitle';
import {
  SubscriptionUpdateSetupConfiguration,
  SubscriptionUpdateSetupFormFieldsNames,
} from '../../../../../products/components/productPage/customerJourney/SubscriptionUpdateSetupConfiguration';

const SECTION_GAP = 8;

export type CustomerJourneyExperimentDiffProps = {
  productId: string;
  plans: CustomerJourneyPlan[];
  controlFormRenderProps: FormRenderProps<EditProductSettingsFormFields>;
  variantRenderProps: FormRenderProps<EditProductSettingsFormFields>;
  variantReadOnly?: boolean;
  prefixFields?: {
    formRenderProps: FormRenderProps<ExperimentPreset>;
    controlField: Field<ExperimentPreset>[];
    variantField: Field<ExperimentPreset>[];
  };
};

export function CustomerJourneyExperimentDiff({
  productId,
  plans,
  prefixFields,
  controlFormRenderProps,
  variantRenderProps,
  variantReadOnly,
}: CustomerJourneyExperimentDiffProps) {
  const hasChangeInSubscriptionStartSetup = !isEqual(
    pick(controlFormRenderProps.values, SubscriptionStartSetupFormFieldsNames),
    pick(variantRenderProps.values, SubscriptionStartSetupFormFieldsNames),
  );

  const hasChangeInSubscriptionEndSetup = !isEqual(
    pick(controlFormRenderProps.values, SubscriptionEndSetupFormFieldsNames),
    pick(variantRenderProps.values, SubscriptionEndSetupFormFieldsNames),
  );

  const hasChangeInSubscriptionUpdateSetup = !isEqual(
    pick(controlFormRenderProps.values, SubscriptionUpdateSetupFormFieldsNames),
    pick(variantRenderProps.values, SubscriptionUpdateSetupFormFieldsNames),
  );

  const hasChangeInSubscriptionCancellationTime = !isEqual(
    pick(controlFormRenderProps.values, SubscriptionCancellationTimeFormFieldsNames),
    pick(variantRenderProps.values, SubscriptionCancellationTimeFormFieldsNames),
  );

  return (
    <GridFlex.Row mt={12} gap={10}>
      <GridFlex.Column xs={6}>
        <S.StyledBox $withReadOnly>
          {prefixFields && (
            <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
              {drawFormFields(prefixFields.controlField, prefixFields.formRenderProps)}
            </GridFlex.Item>
          )}
          <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
            <CustomerJourneySectionTitle
              readonly
              icon="JourneyStart"
              iconType="custom"
              iconSize={24}
              title={t('products.customerJourney.subscriptionStarts')}
              titleColor="secondary">
              <SubscriptionStartSetupConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={controlFormRenderProps}
                productPlans={plans}
                productId={productId}
                readonly
                readonlyHideDottedText
              />
            </CustomerJourneySectionTitle>
          </GridFlex.Item>

          <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
            <CustomerJourneySectionTitle
              readonly
              icon="RefreshIcon"
              iconType="custom"
              iconSize={24}
              title="Subscription update"
              titleColor="secondary">
              <SubscriptionUpdateSetupConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={controlFormRenderProps}
                readonly
                readonlyHideDottedText
              />
            </CustomerJourneySectionTitle>
          </GridFlex.Item>

          <GridFlex.Item flex={1} justifyContent="top">
            <CustomerJourneySectionTitle
              readonly
              icon="JourneyEnd"
              iconType="custom"
              iconSize={24}
              title={t('products.customerJourney.subscriptionEndsOrCanceled')}
              titleColor="secondary"
              lastChild>
              <SubscriptionCancellationTimeConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={controlFormRenderProps}
                readonly
                readonlyHideDottedText
              />
              <SubscriptionEndSetupConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={controlFormRenderProps}
                productPlans={plans}
                productId={productId}
                readonly
                readonlyHideDottedText
              />
            </CustomerJourneySectionTitle>
          </GridFlex.Item>
        </S.StyledBox>
      </GridFlex.Column>
      <GridFlex.Column xs={6}>
        <S.StyledBox>
          {prefixFields && (
            <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
              {drawFormFields(prefixFields.variantField, prefixFields.formRenderProps)}
            </GridFlex.Item>
          )}
          <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
            <CustomerJourneySectionTitle
              icon="JourneyStart"
              iconType="custom"
              iconSize={24}
              title={t('products.customerJourney.subscriptionStarts')}
              titleColor={variantReadOnly ? 'secondary' : undefined}
              readonly={variantReadOnly}>
              <SubscriptionStartSetupConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={variantRenderProps}
                productPlans={plans}
                productId={productId}
                readonly={variantReadOnly}
                readonlyHideDottedText={variantReadOnly}
                highlight={hasChangeInSubscriptionStartSetup}
              />
            </CustomerJourneySectionTitle>
          </GridFlex.Item>

          <GridFlex.Item flex={1} justifyContent="top" mb={SECTION_GAP}>
            <CustomerJourneySectionTitle icon="RefreshIcon" iconType="custom" iconSize={24} title="Subscription update">
              <SubscriptionUpdateSetupConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={variantRenderProps}
                readonly={variantReadOnly}
                readonlyHideDottedText={variantReadOnly}
                highlight={hasChangeInSubscriptionUpdateSetup}
              />
            </CustomerJourneySectionTitle>
          </GridFlex.Item>

          <GridFlex.Item flex={1} justifyContent="top">
            <CustomerJourneySectionTitle
              icon="JourneyEnd"
              iconType="custom"
              iconSize={24}
              title={t('products.customerJourney.subscriptionEndsOrCanceled')}
              titleColor={variantReadOnly ? 'secondary' : undefined}
              readonly={variantReadOnly}
              lastChild>
              <SubscriptionCancellationTimeConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={variantRenderProps}
                readonly={variantReadOnly}
                readonlyHideDottedText={variantReadOnly}
                highlight={hasChangeInSubscriptionCancellationTime}
              />
              <SubscriptionEndSetupConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={variantRenderProps}
                productPlans={plans}
                productId={productId}
                readonly={variantReadOnly}
                readonlyHideDottedText={variantReadOnly}
                highlight={hasChangeInSubscriptionEndSetup}
              />
            </CustomerJourneySectionTitle>
          </GridFlex.Item>
        </S.StyledBox>
      </GridFlex.Column>
    </GridFlex.Row>
  );
}
