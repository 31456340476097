import { styled, TextColor } from '@stigg-theme';
import { Flex, Skeleton, Text } from '@stigg-components';
import { useCubeQuery } from '@cubejs-client/react';
import { useSelector } from 'react-redux';
import { Filter, ResultSet, TimeDimensionGranularity } from '@cubejs-client/core';
import moment from 'moment';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import last from 'lodash/last';
import TrendNeutralIcon from '../../../../../assets/icons/TrendNeutral.svg?react';
import TrendUpIcon from '../../../../../assets/icons/TrendUp.svg?react';
import TrendDownIcon from '../../../../../assets/icons/TrendDown.svg?react';
import { formatPeriodDate, numberFullFormatter, percentFormatter } from '../../../common/formatters';
import { SparklineChartRenderer } from './charts';
import { RootState } from '../../../../../redux/store';
import { ProductFilter } from '../../subscriptionsOverviewSlice';
import { TIME_RANGES, TimeRangeLabel } from '../constants';
import {
  ChartAlternativeMessage,
  ChartErrorMessage,
  ChartPlaceholder,
} from '../../../featureInspector/components/widgets/ChartPlaceholders';

export type TimeRangePeriods = {
  label: TimeRangeLabel;
  currentPeriod: [string, string];
  previousPeriod: [string, string];
  daysBack: number;
  granularity?: TimeDimensionGranularity;
  duration?: {
    unit: string;
    amount: number;
  };
};

export const CountText = styled(Text.B2)`
  font-size: 22px;
`;

type TrendIndicatorDecoration = { color: TextColor; Icon: React.ComponentType; sign?: string };

function getTrendIndicatorDecoration(diffAmount: number) {
  const { color, Icon, sign }: TrendIndicatorDecoration =
    diffAmount === 0
      ? { color: 'primary.main', Icon: TrendNeutralIcon }
      : diffAmount > 0
        ? { color: 'success.main', Icon: TrendUpIcon, sign: '+' }
        : { color: 'error.main', Icon: TrendDownIcon, sign: '-' };
  return { color, Icon, sign };
}

export const TrendIndicator = ({
  diffRatio,
  diffAmount,
  decoration: { sign, color, Icon },
}: {
  diffRatio: number;
  diffAmount: number;
  decoration: TrendIndicatorDecoration;
}) => {
  // const hasInsufficientData = diffRatio === 0 && diffAmount !== 0;
  return (
    <Flex.RowCenter>
      {diffAmount === 0 ? (
        <Text.B1 color="primary">{t('dashboards.subscriptionsOverview.noChange')}</Text.B1>
      ) : (
        <>
          <Icon />
          <Text.B1 ml={2} color={color}>
            {numberFullFormatter(diffAmount)}
          </Text.B1>
          {diffRatio !== 0 && (
            <Text.B1 ml={16} color={color}>
              {sign}
              {percentFormatter(Math.abs(diffRatio))}
            </Text.B1>
          )}
        </>
      )}
    </Flex.RowCenter>
  );
};

export function useCurrentAndPreviousPeriods(): TimeRangePeriods {
  const { timeRangeFilter } = useSelector((state: RootState) => state.dashboards.subscriptionsOverview);

  return useMemo(() => {
    const { sinceTs, untilTs, durationMs, isRelativeFromNow, label } = timeRangeFilter!;
    const currentPeriod = [sinceTs, untilTs].map((x) => formatPeriodDate(x)) as [string, string];
    const previousPeriod = [moment(sinceTs).subtract(durationMs, 'milliseconds'), sinceTs].map((x) =>
      formatPeriodDate(x),
    ) as [string, string];
    const timeRange = TIME_RANGES.find((x) => x.durationMs === durationMs);

    return {
      label,
      currentPeriod,
      previousPeriod,
      daysBack: isRelativeFromNow ? moment.duration(durationMs).asDays() : 0,
      duration: timeRange?.duration,
      granularity: timeRange?.granularity,
    };
  }, [timeRangeFilter]);
}

export function calculateTrendRatio(previousValue?: number, currentValue = 0) {
  return previousValue ? (currentValue - previousValue) / previousValue : 0;
}

export const calculatePeriodValues = (resultSet?: ResultSet | null): [number, number] => {
  const [currentPeriodValue = 0, previousPeriodValue = 0] =
    resultSet?.series().map(({ series }) => last(series).value) ?? [];

  return [currentPeriodValue, previousPeriodValue];
};

export function useTotalAndTrendQuery({
  countMeasure,
  timeDimension,
  extraFilters = [],
  skipCubeQuery,
}: {
  countMeasure: string;
  timeDimension: string;
  extraFilters?: Filter[];
  skipCubeQuery?: boolean;
}) {
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId)!;
  const periods = useCurrentAndPreviousPeriods();
  const [entity] = countMeasure.split('.');
  const totalQuery = useCubeQuery(
    {
      measures: [countMeasure],
      timeDimensions: [
        {
          dimension: timeDimension,
          granularity: periods.granularity,
          compareDateRange: [periods.currentPeriod, periods.previousPeriod],
        },
      ],
      filters: [
        {
          member: `${entity}.environmentId`,
          operator: 'equals',
          values: [currentEnvironmentId],
        },
        ...extraFilters,
      ],
    },
    { skip: skipCubeQuery },
  );
  const chartQuery = useCubeQuery(
    {
      measures: [countMeasure],
      timeDimensions: [
        {
          dimension: timeDimension,
          dateRange: periods.currentPeriod,
          granularity: 'day',
        },
      ],
      order: [[timeDimension, 'asc']],
      filters: [
        {
          member: `${entity}.environmentId`,
          operator: 'equals',
          values: [currentEnvironmentId],
        },
        ...extraFilters,
      ],
    },
    { skip: skipCubeQuery },
  );

  const [currentPeriodValue, previousPeriodValue] = calculatePeriodValues(totalQuery?.resultSet);

  return {
    periods,
    isLoadingTotal: totalQuery.isLoading || !!totalQuery.error || !totalQuery.resultSet,
    totalQuery,
    isLoadingChart: chartQuery.isLoading || !!chartQuery.error || !chartQuery.resultSet,
    chartQuery,
    currentPeriodValue,
    previousPeriodValue,
  };
}

type WidgetTotalAndTrendProps = {
  isLoading: boolean;
  currentPeriodValue: number;
  previousPeriodValue: number;
  periods: TimeRangePeriods;
  chartQueryResultSet: ResultSet;
};

export function WidgetTotalAndTrend({
  isLoading,
  currentPeriodValue,
  previousPeriodValue,
  periods,
  chartQueryResultSet,
}: WidgetTotalAndTrendProps) {
  if (isLoading) {
    return (
      <>
        <Skeleton variant="text" animation="wave" width={170} />
        <Skeleton variant="text" animation="wave" width={100} sx={{ marginTop: 2 }} />
      </>
    );
  }

  const diffAmount = currentPeriodValue - previousPeriodValue;
  const diffRatio = calculateTrendRatio(previousPeriodValue, currentPeriodValue);
  const decoration = getTrendIndicatorDecoration(diffAmount);
  const duration = periods.duration || { unit: 'day', amount: periods.daysBack };

  return (
    <Flex.RowSpaceBetween alignItems="center">
      <Flex.Column minWidth={170} flex={1}>
        <Text.B2 mb={1} color="secondary">
          {t('dashboards.subscriptionsOverview.changeInLastPeriod', {
            count: duration.amount,
            unit: duration.unit,
          })}
        </Text.B2>
        <TrendIndicator diffRatio={diffRatio} diffAmount={diffAmount} decoration={decoration} />
      </Flex.Column>
      <SparklineChartRenderer resultSet={chartQueryResultSet} color={decoration.color} />
    </Flex.RowSpaceBetween>
  );
}

export const applyProductFilter = (filters: Filter[], productFilter: ProductFilter): Filter[] => {
  if (productFilter === 'ALL_PRODUCTS') {
    return filters;
  }

  return [
    ...filters,
    {
      member: 'Package.productId',
      operator: 'equals',
      values: [productFilter],
    },
  ];
};

const ChartNoDataMessage = () => (
  <ChartAlternativeMessage
    title={t('dashboards.subscriptionsOverview.noDataTitle')}
    subtitle={t('dashboards.subscriptionsOverview.noDataForFilterCriteria')}
  />
);

export const WidgetChartContainer: React.FC<{
  isLoading: boolean;
  error?: Error | null;
  resultSet?: ResultSet | null;
  children: (resultSet: ResultSet) => React.ReactElement;
}> = ({ isLoading, error, resultSet, children }) => {
  if (isLoading) {
    return <ChartPlaceholder repeat={5} />;
  }
  if (error) {
    return <ChartErrorMessage error={error} />;
  }
  if (!resultSet || !resultSet.series().length) {
    return <ChartNoDataMessage />;
  }
  return children(resultSet);
};
