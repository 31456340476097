import { SyncStateFragment, SyncStatus } from '@stigg-types/apiTypes';
import { useState } from 'react';
import { InformationTooltip, Text, CircularProgress, GridFlex, Link, Icon } from '@stigg-components';
import { t } from 'i18next';
import { MultipleBillingIdsDrawer } from '../modules/integrations/components/MultipleBillingIdsDrawer';

function getSyncStatus(syncStates: SyncStateFragment[]): SyncStatus {
  if (syncStates.some((syncState) => syncState.status === SyncStatus.Pending)) {
    return SyncStatus.Pending;
  }

  if (syncStates.some((syncState) => syncState.status === SyncStatus.Error)) {
    return SyncStatus.Error;
  }

  if (syncStates.some((syncState) => syncState.status === SyncStatus.Success)) {
    return SyncStatus.Success;
  }

  return SyncStatus.NoSyncRequired;
}

export function MultipleIntegrationIdLink({ syncStates }: { syncStates: SyncStateFragment[] }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const syncStatus = getSyncStatus(syncStates);

  const getIcon = (finalStatus: SyncStatus) => {
    switch (finalStatus) {
      case SyncStatus.Error:
        return (
          <InformationTooltip
            $padding={2}
            arrow
            title={<Text.B2>{t('integrations.syncError')}</Text.B2>}
            placement="top">
            <GridFlex.RowCenter>
              <Icon icon="Error" color="error" size={20} />
            </GridFlex.RowCenter>
          </InformationTooltip>
        );
      case SyncStatus.Pending:
        return (
          <InformationTooltip $padding={2} arrow title={<Text.B2>{t('integrations.syncing')}</Text.B2>} placement="top">
            <CircularProgress
              thickness={1.5}
              size="18px"
              sx={{ color: (theme) => theme.itamar.palette.action.active }}
            />
          </InformationTooltip>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Link variant="body2" onClick={() => setIsDrawerOpen(true)}>
        <GridFlex.RowCenter gap={2}>
          {t('integrations.multipleIntegrations', { count: syncStates.length })}
          {getIcon(syncStatus)}
        </GridFlex.RowCenter>
      </Link>
      <MultipleBillingIdsDrawer syncStates={syncStates} isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
}
