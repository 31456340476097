import React from 'react';
import { Text, GridFlex, Form } from '@stigg-components';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { useIntegrations } from '@stigg-common';
import { BillingProviderPicker } from './BillingProviderPicker';
import { SuspendedPaymentMethod } from './SuspendedPaymentMethod';

export interface AddPaymentMethodFormValues {
  billingProvider: string | undefined;
}

export type AddPaymentMethodProps = {
  customer: CustomerResponseFragment;
  onSuccess: () => void;
  onCancel: () => void;
  addButtonText?: string;
  cancelButtonText?: string;
  additionalCustomControls?: React.ReactNode;
  integrationId?: string;
};

export function AddPaymentMethod({
  customer,
  onSuccess,
  onCancel,
  addButtonText,
  cancelButtonText,
  additionalCustomControls,
  integrationId,
}: AddPaymentMethodProps) {
  const { hasMultipleBillingIntegrations } = useIntegrations();

  const initialValues: AddPaymentMethodFormValues = {
    billingProvider: integrationId || undefined,
  };

  return (
    <GridFlex.Column sx={{ height: '100%', width: '100%' }} overflow="hidden">
      <Text.H3 mt={1} mb={2}>
        {t('customers.paymentMethod.addPaymentMethod')}
      </Text.H3>
      <Form
        hideSaveButton
        withStickyFooter
        footerSx={{ height: 0, padding: 0 }}
        initialValues={initialValues}
        onSubmit={() => {}}
        fields={[
          {
            type: 'select',
            id: 'billingProvider',
            hide: () => !hasMultipleBillingIntegrations || !!integrationId,
            gridProps: {
              mb: 2,
            },
            label: t('customers.paymentMethod.selectBillingProvider'),
            values: BillingProviderPicker(),
          },
          {
            type: 'custom',
            hide: (values: AddPaymentMethodFormValues) =>
              hasMultipleBillingIntegrations ? !values.billingProvider : false,
            render: (props) => (
              <SuspendedPaymentMethod
                customer={customer}
                onSuccess={onSuccess}
                onCancel={onCancel}
                addButtonText={addButtonText}
                cancelButtonText={cancelButtonText}
                additionalCustomControls={additionalCustomControls}
                formRenderProps={props}
              />
            ),
          },
        ]}
      />
    </GridFlex.Column>
  );
}
