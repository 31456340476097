import { Differ, DiffResult } from 'json-diff-kit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { dumpEnvironmentForMergeComparisonAction, resetEnvironmentComparison } from '../../../accountsSlice';

export type EnvironmentDiff = readonly [DiffResult[], DiffResult[]];

const differ = new Differ({
  arrayDiffMethod: 'unorder-lcs',
  recursiveEqual: true,
});

function countSingleDiffChanges(diff: DiffResult[], shouldCountChanges: boolean): number {
  return diff.reduce(
    ({ changes, currentDiffType }, change) => {
      if (
        currentDiffType !== change.type &&
        (change.type === 'remove' || change.type === 'add' || (change.type === 'modify' && shouldCountChanges))
      ) {
        return { changes: changes + 1, currentDiffType: change.type };
      }

      return { changes, currentDiffType: change.type };
    },
    { changes: 0, currentDiffType: 'equal' as DiffResult['type'] },
  ).changes;
}

function countDiffChanges(diff: EnvironmentDiff): number {
  const [left, right] = diff;
  const leftChanges = countSingleDiffChanges(left, true);
  const rightChanges = countSingleDiffChanges(right, false);
  return leftChanges + rightChanges;
}

export const useEnvironmentDiff = (
  sourceEnvironmentSlug?: string,
  destinationEnvironmentSlug?: string,
  mergeIsValid = true,
) => {
  const [diff, setDiff] = useState<EnvironmentDiff | null>(null);
  const [changes, setChanges] = useState(0);
  const {
    preMergeDump,
    postMergeDump,
    isLoading: isLoadingDiff,
  } = useSelector((state: RootState) => state.accountReducer.environmentComparison);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetEnvironmentComparison());
    if (sourceEnvironmentSlug && destinationEnvironmentSlug && mergeIsValid) {
      void dispatch(
        dumpEnvironmentForMergeComparisonAction({
          sourceEnvironmentSlug,
          destinationEnvironmentSlug,
        }),
      );
    }
  }, [sourceEnvironmentSlug, destinationEnvironmentSlug, dispatch, mergeIsValid]);

  useEffect(() => {
    if (postMergeDump && preMergeDump) {
      const newDiff = differ.diff(preMergeDump, postMergeDump);
      setDiff(newDiff);
      setChanges(countDiffChanges(newDiff));
    } else {
      setDiff(null);
      setChanges(0);
    }
  }, [preMergeDump, postMergeDump]);

  return { diff, changes, isLoadingDiff };
};
