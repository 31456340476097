import {
  Alert,
  Button,
  Grid,
  GridFlex,
  InformationTooltip,
  Link,
  Text,
  useScrollableAnchor,
  ConfirmationDialog,
  EmptyCardContent,
  PageCard,
  CustomDrawer,
  Icon,
} from '@stigg-components';
import {
  AddonFragment,
  PlanCompatibleAddonFragment,
  PlanCompatiblePackageGroupFragment,
  PlanFragment,
  PricingType,
  SetPlanCompatiblePackageGroups,
} from '@stigg-types/apiTypes';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import Table from '../../../../../../components/table/Table';
import { useAppDispatch } from '../../../../../../redux/store';
import { useNavigation } from '../../../../../navigation/useNavigation';
import {
  addCompatibleAddonsToPlanAction,
  fetchPlanByRefIdAction,
  removeCompatibleAddonsFromPlanAction,
  setCompatibleAddonGroupsToPlanAction,
} from '../../../plansSlice';
import {
  AddCompatibleAddonOrAddonGroupDialog,
  AddonOrAddonGroupOption,
  NewAddonAndAddonGroupsFormFields,
} from './AddCompatibleAddonOrAddonGroupDialog';
import { useAddonMissingCurrencies } from '../hooks/useAddonMissingCurrencies';
import { CompatiblePackageGroup, isAddon, isPackageGroup } from '../../../../common/packageUtils';
import { createCompatibleAddonsHeadCells } from './compatibleAddonsHeadCells';

export type AddonCurrencyMissingAlertProps = {
  plan?: PlanFragment;
  addonsWithMissingCurrency?: PlanCompatibleAddonFragment[];
};

type CompatibleAddonsProps = {
  plan: PlanFragment;
  readonly: boolean;
};

export function CompatibleAddons({ plan, readonly }: CompatibleAddonsProps) {
  const dispatch = useAppDispatch();
  const { elementRef } = useScrollableAnchor({ anchor: 'planCompatibleAddons' });
  const navigation = useNavigation();
  const { addonsWithMissingCurrenciesAlert, addonsWithMissingCurrency } = useAddonMissingCurrencies({ plan });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addonToRevoke, setAddonToRevoke] = useState<{ id: string; isAddon: boolean }>({ id: '', isAddon: true });
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const handleDeleteDialogClose =
    (entityId: string, isAddon: boolean, plan: PlanFragment) => async (shouldDelete: boolean) => {
      if (shouldDelete) {
        if (isAddon) {
          await dispatch(
            removeCompatibleAddonsFromPlanAction({
              planRefId: plan.refId,
              planId: plan.id,
              addonIds: [entityId],
              versionNumber: plan.versionNumber,
            }),
          );
        } else {
          const packageGroups =
            plan.compatiblePackageGroups?.filter((group) => group.packageGroupId !== entityId) || [];

          const inputPackageGroups = packageGroups.map((group) => {
            return {
              packageGroupId: group.packageGroupId,
              options: {
                minItems: group.options.minItems,
                freeItems: group.options.freeItems,
              },
            };
          });

          await dispatch(
            setCompatibleAddonGroupsToPlanAction({
              planRefId: plan.refId,
              packageGroups: inputPackageGroups,
              versionNumber: plan.versionNumber,
              planId: plan.id,
            }),
          );
        }
      }

      setDeleteDialogOpen(false);
    };

  const onSubmitAddons = async (values: NewAddonAndAddonGroupsFormFields, plan: PlanFragment) => {
    const newAddonGroupsToAddIds: SetPlanCompatiblePackageGroups['packageGroups'] = values.addedEntities
      .filter((entity) => isPackageGroup(entity.entity))
      .map((group) => {
        const { entity, minItems, freeItems } = group;
        return {
          packageGroupId: (entity as CompatiblePackageGroup).packageGroupId,
          options: {
            minItems,
            freeItems,
          },
        };
      });

    const existingPackageGroups: SetPlanCompatiblePackageGroups['packageGroups'] = (
      plan.compatiblePackageGroups || []
    ).map(({ options, packageGroupId }) => {
      return {
        packageGroupId,
        options: {
          minItems: options?.minItems,
          freeItems: options?.freeItems,
        },
      };
    });

    const addonsToAddIds = values.addedEntities
      .filter((entity) => isAddon(entity.entity))
      .map((addon) => (addon.entity as AddonFragment).id);

    const packageGroups: SetPlanCompatiblePackageGroups['packageGroups'] = [
      ...newAddonGroupsToAddIds,
      ...existingPackageGroups,
    ];

    if (!isEmpty(addonsToAddIds)) {
      await dispatch(
        addCompatibleAddonsToPlanAction({
          planRefId: plan.refId,
          planId: plan.id,
          addonIds: addonsToAddIds,
          versionNumber: plan.versionNumber,
          skipFetchingPlan: true,
        }),
      );
    }

    await dispatch(
      setCompatibleAddonGroupsToPlanAction({
        planRefId: plan.refId,
        packageGroups,
        versionNumber: plan.versionNumber,
        planId: plan.id,
        skipFetchingPlan: true,
      }),
    );

    await dispatch(fetchPlanByRefIdAction({ refId: plan.refId, versionNumber: plan.versionNumber, silentFetch: true }));
  };

  const isPlanFree = plan.pricingType === PricingType.Free;
  const compatibleAddonAndAddonGroups: AddonOrAddonGroupOption[] = [
    ...(plan.compatibleAddons || []),
    ...(plan.compatiblePackageGroups || []),
  ];

  return (
    <PageCard ref={elementRef}>
      <Grid container justifyContent="space-between">
        <Text.H3>{t('addons.compatibleAddonsTitle')}</Text.H3>
        {!readonly && (
          <InformationTooltip
            arrow
            placement="left"
            title={isPlanFree ? <Text.B2>{t('plans.freePlanCompatibleAddons') || ''}</Text.B2> : ''}>
            <div>
              <Button
                disabled={isPlanFree}
                color="primary"
                $outlined
                onClick={() => setAddDialogOpen(true)}
                startIcon={<Icon icon="Add" type="materialIcons" />}>
                {t('sharedComponents.addSaveButton')}
              </Button>
            </div>
          </InformationTooltip>
        )}
      </Grid>

      <Text.Sub2 mb={4}>{t('addons.compatibleAddonsSubtitle')}</Text.Sub2>
      {isEmpty(compatibleAddonAndAddonGroups) ? (
        <EmptyCardContent>
          <Text.B2>{isPlanFree ? t('plans.freePlanCompatibleAddons') : t('plans.compatibleAddonsEmpty')}</Text.B2>
          {!readonly && !isPlanFree && (
            <Link variant="body2" ml={1} onClick={() => setAddDialogOpen(true)}>
              {t('plans.compatibleAddonsEmptyLink')}
            </Link>
          )}
        </EmptyCardContent>
      ) : (
        <GridFlex.Column>
          {isPlanFree && (
            <Alert mb={4} severity="warning">
              {t('plans.freePlanCompatibleAddons')}
            </Alert>
          )}
          {addonsWithMissingCurrenciesAlert}
          <Table
            label={t('addons.compatibleAddonsTitle')}
            headCells={createCompatibleAddonsHeadCells(
              setAddonToRevoke,
              setDeleteDialogOpen,
              navigation,
              readonly,
              addonsWithMissingCurrency,
            )}
            data={compatibleAddonAndAddonGroups}
            isCellDisabled={(_: AddonOrAddonGroupOption, { id }) => isPlanFree && id !== 'options'}
          />
        </GridFlex.Column>
      )}

      <CustomDrawer
        title={t('addons.addCompatibleAddon')}
        isOpen={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}>
        <AddCompatibleAddonOrAddonGroupDialog
          plan={plan}
          excludedAddonIds={plan.compatibleAddons?.map((addon: PlanCompatibleAddonFragment) => addon.id) || []}
          excludedAddonGroupIds={
            plan.compatiblePackageGroups?.map((group: PlanCompatiblePackageGroupFragment) => group.packageGroupId) || []
          }
          onSubmit={(values) => onSubmitAddons(values, plan)}
          setDialogOpen={setAddDialogOpen}
        />
      </CustomDrawer>
      <ConfirmationDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose(addonToRevoke.id, addonToRevoke.isAddon, plan)}
        confirmationButtonText={t('plans.removeButtonText')}
        title={addonToRevoke.isAddon ? t('plans.removeCompatibleAddon') : t('plans.removeCompatibleAddonGroup')}
        content={
          addonToRevoke.isAddon
            ? t('plans.removeCompatibleAddonDialogContent')
            : t('plans.removeCompatibleAddonGroupDialogContent')
        }
      />
    </PageCard>
  );
}
