import { gql } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { RegisterMemberMutation, RegisterMemberMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch } from '../../../redux/store';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { fetchEnvironmentsAction } from '../accountsSlice';

const REGISTER_MEMBER = gql`
  mutation RegisterMemberMutation {
    registerMember {
      id
      email
      memberships {
        email
        id
        userId
        memberStatus
        account {
          id
          displayName
        }
        cubejsToken
      }
    }
  }
`;

async function registerMember(_: void, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<RegisterMemberMutation, RegisterMemberMutationVariables>({
        mutation: REGISTER_MEMBER,
      });

      if (!response.data) {
        throw new Error('Invalid response');
      }

      await dispatch(fetchEnvironmentsAction({}));

      return response.data.registerMember;
    },
    {
      // no need for success message because we reload the whole page
      // successMessage: t('accounts.api.successMemReg'),
      // todo currently the detailed reason comes as string from the backend until we have some custom errors.
      failureMessageHandler: (err: ApolloError) => err.message,
    },
    dispatch,
  );
}

export { registerMember };
