import { Box, Icon, GridFlex, Text, FullScreenDialogLayout, Link } from '@stigg-components';
import { styled } from '@stigg-theme';
import { IntegrationListItemFragment, VendorIdentifier } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { Dialog } from '../../../components/Dialog';
import { AddIntegration } from './AddIntegration';
import { IntegrationPage } from './IntegrationPage';

const IconWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.itamar.palette.background.default};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`;

export function AddIntegrationModal({
  isOpen,
  onCancel,
  selectedVendorIdentifier,
  onIntegrationClick,
  activeIntegrations,
  onBack,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onBack: () => void;
  selectedVendorIdentifier?: VendorIdentifier | null;
  onIntegrationClick: (vendorIdentifier: VendorIdentifier) => void;
  activeIntegrations?: IntegrationListItemFragment[];
}) {
  const title = selectedVendorIdentifier
    ? `${t('integrations.addIntegration')} / ${t('integrations.integrationNames', { context: selectedVendorIdentifier })}`
    : t('integrations.addIntegration');

  return (
    <Dialog
      fullScreen
      isOpen={isOpen}
      onCancel={onCancel}
      content={
        <FullScreenDialogLayout
          variant="grey"
          title={
            <GridFlex.RowCenter columnGap={2}>
              <IconWrapper>
                <Icon type="reactFeather" icon="Zap" color="active" overrideStroke />
              </IconWrapper>
              <Text.B2 color="secondary">{title}</Text.B2>
            </GridFlex.RowCenter>
          }>
          <GridFlex.Column
            $fullHeight
            $fullWidth
            alignItems="center"
            sx={{ backgroundColor: (theme) => theme.itamar.palette.background.paper, overflow: 'auto' }}>
            <GridFlex.Column mt={8} width={1200} $fullHeight alignItems="center">
              {selectedVendorIdentifier ? (
                <GridFlex.Column alignItems="flex-end" maxWidth={800} pb={8}>
                  <IntegrationPage vendorIdentifier={selectedVendorIdentifier} />
                  {onBack && (
                    <Link mt={4} variant="body2" onClick={onBack}>
                      {t('integrations.backToAllIntegrations')}
                    </Link>
                  )}
                </GridFlex.Column>
              ) : (
                <AddIntegration
                  title={t('integrations.addIntegration')}
                  withOuterBorder={false}
                  onAddIntegration={onIntegrationClick}
                  activeIntegrations={activeIntegrations}
                />
              )}
            </GridFlex.Column>
          </GridFlex.Column>
        </FullScreenDialogLayout>
      }
      padding={0}
    />
  );
}
