import { t } from 'i18next';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';
import { useAuth0 } from '@auth0/auth0-react';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { AccountAccessRole, EnvironmentAccessRole, Member } from '@stigg-types/apiTypes';
import { Link, CustomDrawer, Form, OptionsDropdown, Text, useExitConfirmationDialog } from '@stigg-components';
import { Trash2 } from 'react-feather';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAccountPermissionCheck } from '@stigg-permissions';
import DrawerDetailsFooter from '../../../components/drawer/DrawerDetailsFooter';
import { TooltipFields } from '../../../components/InformationTooltip';
import { AccountPermissionActions } from '../../rbac/consts';
import { setRolesFields } from './setRolesFields';
import { useBooleanEntitlement } from '../../../doggo/hooks/useBooleanEntitlement';
import { StiggFeature } from '../../../doggo/StiggFeature';
import { Navigation, useNavigation } from '../../navigation/useNavigation';
import { SettingsPageTabs } from '../types/settingsPageTabs';

type EditMemberDialogProps = {
  onRemove: (member: Member) => void;
  open: boolean;
  isCurrentMember: boolean;
  handleClose: () => void;
  member: Member | null;
  handleSubmit: (values: any) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  onPrevious?: () => void;
  onNext?: () => void;
};

export type MemberFormFields = {
  displayName: string;
  email: string;
  accountRole?: AccountAccessRole;
  productionAccess?: EnvironmentAccessRole;
  nonProductionAccess?: EnvironmentAccessRole;
};

function disabledRolePickerProps(
  isCurrentUser: boolean,
  allowChangeAccessLevel: boolean,
  hasRbacEntitlement: boolean,
  navigation: Navigation,
): TooltipFields | undefined {
  if (!hasRbacEntitlement) {
    return {
      placement: 'top',
      title: (
        <Text.B2 color="primary">
          <Trans
            i18nKey="accounts.rbacUpgradePlan"
            components={[
              <Link
                onClick={() => {
                  navigation.navigateTo(navigation.appRoutes.settingsPage({ selectedTab: SettingsPageTabs.Billing }), {
                    isGlobal: true,
                  });
                }}
              />,
            ]}
          />
        </Text.B2>
      ),
    };
  }
  if (!allowChangeAccessLevel) {
    return {
      placement: 'top',
      title: <Text.B2 color="primary">{t('permissions.changeAccessLevelDenied')}</Text.B2>,
    };
  }
  if (isCurrentUser) {
    return {
      placement: 'top',
      title: <Text.B2 color="primary">{t('permissions.changeSelfAccountRole')}</Text.B2>,
    };
  }

  return undefined;
}

const validationSchema = () =>
  Yup.object().shape({
    displayName: Yup.string().required(t('fieldValidationMessages.required')),
    accountRole: Yup.mixed<AccountAccessRole>()
      .oneOf(Object.values(AccountAccessRole))
      .required(t('fieldValidationMessages.required')),
    productionAccess: Yup.mixed<EnvironmentAccessRole>()
      .oneOf(Object.values(EnvironmentAccessRole))
      .required(t('fieldValidationMessages.required')),
    nonProductionAccess: Yup.mixed<EnvironmentAccessRole>()
      .oneOf(Object.values(EnvironmentAccessRole))
      .required(t('fieldValidationMessages.required')),
  });

function EditMemberForm({
  handleClose,
  member,
  handleSubmit,
  setHasUnsavedChanges,
  isCurrentMember,
  onPrevious,
  onNext,
}: Omit<EditMemberDialogProps, 'open' | 'onRemove'>) {
  const { rbacRollout } = useFlags<FeatureFlags>();
  const { hasAccess: hasRbacEntitlement } = useBooleanEntitlement(StiggFeature.RBAC);
  const navigation = useNavigation();
  const { user } = useAuth0();
  const allowChangeAccessLevel = useAccountPermissionCheck(AccountPermissionActions.InviteUser);

  const isCurrentUser = member?.email === user?.email;

  if (!member) {
    return null;
  }

  const initialValues: MemberFormFields = {
    displayName: member.user?.name || '',
    email: member.email,
    accountRole: member.accessRoles?.accountRole,
    nonProductionAccess: member.accessRoles?.nonProductionRole,
    productionAccess: member.accessRoles?.productionRole,
  };

  return (
    <Form
      enableReinitialize
      disableSaveOnNoChanges
      withStickyFooter
      initialValues={initialValues}
      submitButtonText={t('sharedComponents.editSaveButton')}
      onSubmit={handleSubmit}
      onCancel={handleClose}
      validationSchema={validationSchema()}
      withFooterSpacing
      footerComponent={<DrawerDetailsFooter onPrevious={onPrevious} onNext={onNext} />}
      setHasUnsavedChanges={setHasUnsavedChanges}
      fields={[
        {
          type: 'text',
          id: 'displayName',
          label: t('accounts.displayName'),
          disabled: !isCurrentMember,
        },
        {
          type: 'text',
          id: 'email',
          label: t('accounts.email'),
          disabled: true,
        },
        ...setRolesFields<MemberFormFields>({
          hide: !rbacRollout,
          accountRoleDisabled: isCurrentMember || !allowChangeAccessLevel || !hasRbacEntitlement,
          environmentRoleDisabled: !allowChangeAccessLevel || !hasRbacEntitlement,
          accountTooltip: disabledRolePickerProps(
            isCurrentUser,
            allowChangeAccessLevel,
            hasRbacEntitlement,
            navigation,
          ),
          environmentTooltip: disabledRolePickerProps(false, allowChangeAccessLevel, hasRbacEntitlement, navigation),
        }),
      ]}
    />
  );
}

export function EditMemberDrawer({
  open,
  handleClose,
  member,
  handleSubmit,
  isCurrentMember,
  onRemove,
  onNext,
  onPrevious,
}: Omit<EditMemberDialogProps, 'setHasUnsavedChanges'>) {
  const allowDeleteUser = useAccountPermissionCheck(AccountPermissionActions.InviteUser);
  const { exitConfirmationDialog, onExit, setHasUnsavedChanges } = useExitConfirmationDialog({
    onClose: handleClose,
    shouldConfirm: open && !isCurrentMember,
  });

  const drawerOptionsDropdown = allowDeleteUser ? (
    <OptionsDropdown
      options={[
        {
          icon: Trash2,
          text: t('sharedComponents.remove'),
          disabled: isCurrentMember,
          onClick: () => {
            if (member) {
              onRemove(member);
            }
          },
        },
      ]}
    />
  ) : null;

  return (
    <>
      <CustomDrawer
        title={t('accounts.editTeamMember')}
        onClose={onExit}
        isOpen={open}
        variant="persistent"
        additionalHeaderComponents={drawerOptionsDropdown}
        BackdropProps={{ invisible: true }}>
        <EditMemberForm
          handleClose={onExit}
          member={member}
          handleSubmit={handleSubmit}
          setHasUnsavedChanges={setHasUnsavedChanges}
          isCurrentMember={isCurrentMember}
          onNext={onNext}
          onPrevious={onPrevious}
        />
      </CustomDrawer>

      {exitConfirmationDialog}
    </>
  );
}
