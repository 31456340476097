import { ReactNode } from 'react';
import { GridFlex } from '@stigg-components';

export const ROW_HEIGHT = '40px';
export const LABEL_WIDTH = '230px';

export function IntegrationDetailsRow({
  label,
  value,
  alignLabel = 'center',
}: {
  label?: ReactNode;
  value: ReactNode;
  alignLabel?: 'flex-start' | 'center' | 'flex-end';
}) {
  return (
    <GridFlex.RowCenter minHeight={ROW_HEIGHT}>
      <GridFlex.Row width={LABEL_WIDTH} alignSelf={alignLabel}>
        {label}
      </GridFlex.Row>
      <GridFlex.Item>{value}</GridFlex.Item>
    </GridFlex.RowCenter>
  );
}
