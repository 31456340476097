import React, { Suspense } from 'react';
import { CustomRenderProps, GridFlex, Loader } from '@stigg-components';
import { AddPaymentMethodFormValues, AddPaymentMethodProps } from './AddPaymentMethod';
import { useIntegrations } from '@stigg-common';

const AddPaymentMethodContainer = React.lazy(() => import('./AddPaymentMethodContainer'));

interface SuspendedPaymentMethodProps extends AddPaymentMethodProps {
  formRenderProps: CustomRenderProps<AddPaymentMethodFormValues>;
}

export function SuspendedPaymentMethod({
  customer,
  onSuccess,
  onCancel,
  addButtonText,
  cancelButtonText,
  additionalCustomControls,
  formRenderProps,
}: SuspendedPaymentMethodProps): React.ReactNode {
  const { values } = formRenderProps;
  const { billingIntegrations } = useIntegrations();
  const fallbackBillingIntegration = billingIntegrations ? billingIntegrations[0] : null;

  if (!fallbackBillingIntegration || !fallbackBillingIntegration.integrationId) {
    return null;
  }

  return (
    <GridFlex.ColumnMiddle $fullWidth $fullHeight overflow="hidden">
      <Suspense fallback={<Loader />}>
        <AddPaymentMethodContainer
          customer={customer}
          integrationId={values.billingProvider || fallbackBillingIntegration.integrationId}
          onSuccess={onSuccess}
          onCancel={onCancel}
          addButtonText={addButtonText}
          cancelButtonText={cancelButtonText}
          additionalCustomControls={additionalCustomControls}
        />
      </Suspense>
    </GridFlex.ColumnMiddle>
  );
}
