import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductFilterItem, fetchSubscriptionsOverviewFilters } from './queries/fetchFilters';
import { TimeRangeLabel } from './components/constants';

import { createAppAsyncThunk } from '../../../redux/createAppAsyncThunk';

export type ProductFilter = 'ALL_PRODUCTS' | string;

export type TimeRangeFilter = {
  label: TimeRangeLabel;
  sinceTs: number;
  untilTs: number;
  durationMs: number;
  isRelativeFromNow: boolean;
};

export interface SubscriptionsOverviewSlice {
  isLoading: boolean;
  productFilterItems: ProductFilterItem[];
  productFilter: ProductFilter;
  timeRangeFilter?: TimeRangeFilter;
}

export const fetchSubscriptionsOverviewFiltersAction = createAppAsyncThunk(
  'fetchSubscriptionsOverviewFilters',
  fetchSubscriptionsOverviewFilters,
);

const initialState: SubscriptionsOverviewSlice = {
  isLoading: false,
  productFilterItems: [],
  productFilter: 'ALL_PRODUCTS',
};

const subscriptionsOverviewSlice = createSlice({
  name: 'subscriptionsOverview',
  initialState,
  reducers: {
    setProductFilter: (state, action: PayloadAction<string>) => {
      state.productFilter = action.payload;
    },
    setTimeRangeFilter: (state, action: PayloadAction<TimeRangeFilter>) => {
      state.timeRangeFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionsOverviewFiltersAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubscriptionsOverviewFiltersAction.fulfilled, (state, { payload }) => {
        state.productFilterItems = payload.products;
        state.isLoading = false;
      })
      .addCase(fetchSubscriptionsOverviewFiltersAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setProductFilter, setTimeRangeFilter } = subscriptionsOverviewSlice.actions;

export default subscriptionsOverviewSlice.reducer;
