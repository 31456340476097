import { t } from 'i18next';
import { useIntercom } from 'react-use-intercom';
import { styled } from '@stigg-theme';
import { Box, Text, GridFlex, Button } from '@stigg-components';
import { VendorIdentifier } from '@stigg-types/apiTypes';

export const ContactUsContainer = styled(GridFlex.RowCenter)`
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  gap: ${({ theme }) => theme.spacing(10)};
  background-color: ${({ theme }) =>
    theme.isLightTheme ? theme.itamar.palette.white : theme.itamar.palette.background.darkBackground};
  padding: 32px;
  margin-top: 24px;
`;

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.itamar.palette.background.paper};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  padding: ${({ theme }) => theme.spacing(4)};
`;

type IntegrationContactUsStateProps = {
  headerComponent: React.ReactNode;
  integrationType: VendorIdentifier;
  betaAccess?: boolean;
};

const getContactUsParams = ({
  integrationType,
  betaAccess,
}: Pick<IntegrationContactUsStateProps, 'integrationType' | 'betaAccess'>): {
  contactUsText: string;
  contactUsButtonText: string;
} => {
  if (betaAccess) {
    return {
      contactUsText: t('integrations.contactUs.betaIntegration', {
        integrationName: t('integrations.integrationNames', { context: integrationType }),
      }),
      contactUsButtonText: t('integrations.contactUs.betaIntegrationButtonText'),
    };
  }

  return {
    contactUsText: t('integrations.contactUs.addonIntegration', {
      integrationName: t('integrations.integrationNames', { context: integrationType }),
    }),
    contactUsButtonText: t('integrations.contactUs.addonIntegrationButtonText'),
  };
};

export function IntegrationContactUsState({
  headerComponent,
  integrationType,
  betaAccess,
}: IntegrationContactUsStateProps) {
  const intercom = useIntercom();

  const handleContactUsClick = () => {
    intercom.showNewMessage(
      t('integrations.contactUs.intercomContactUsBetaIntegration', {
        integrationName: t('integrations.integrationNames', { context: integrationType }),
      }),
    );
  };

  const { contactUsText, contactUsButtonText } = getContactUsParams({ integrationType, betaAccess });

  return (
    <>
      {headerComponent && <StyledBox>{headerComponent}</StyledBox>}
      <ContactUsContainer>
        <GridFlex.Column flex={1}>
          <Text.B2 color="primary">{contactUsText}</Text.B2>
        </GridFlex.Column>
        <Button variant="contained" onClick={handleContactUsClick}>
          {contactUsButtonText}
        </Button>
      </ContactUsContainer>
    </>
  );
}
