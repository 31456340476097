export const ZUORA_DEVELOPMENT_TENANTS = [
  {
    name: 'US API Sandbox',
    baseUrl: 'https://rest.apisandbox.zuora.com',
  },
  {
    name: 'US Performance Test',
    baseUrl: 'https://rest.pt1.zuora.com',
  },
  {
    name: 'US Cloud API Sandbox',
    baseUrl: 'https://rest.sandbox.na.zuora.com',
  },
  {
    name: 'US Central Sandbox',
    baseUrl: 'https://rest.test.zuora.com',
  },
  {
    name: 'EU API Sandbox',
    baseUrl: 'https://rest.sandbox.eu.zuora.com',
  },
  {
    name: 'EU Central Sandbox',
    baseUrl: 'https://rest.test.eu.zuora.com',
  },
];

export const ZUORA_PRODUCTION_TENANTS = [
  {
    name: 'US Production',
    baseUrl: 'https://rest.zuora.com',
  },
  {
    name: 'US Cloud Production',
    baseUrl: 'https://rest.na.zuora.com',
  },
  {
    name: 'EU Production',
    baseUrl: 'https://rest.eu.zuora.com',
  },
];

export const AVAILABLE_ZUORA_TENANTS = [...ZUORA_DEVELOPMENT_TENANTS, ...ZUORA_PRODUCTION_TENANTS];
