import React, { useEffect } from 'react';
import { motion, AnimatePresence, Variants } from 'framer-motion';

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? -400 : 400,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction > 0 ? -400 : 400,
      opacity: 0,
    };
  },
};

export const AnimatedStep: React.FC<{
  activeStep: number;
  prevStepIndex: React.MutableRefObject<number>;
  children: React.ReactNode;
}> = React.memo(({ children, activeStep, prevStepIndex }) => {
  useEffect(() => {
    return () => {
      prevStepIndex.current = activeStep;
    };
  }, [activeStep, prevStepIndex]);

  return (
    <motion.div
      custom={-1}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: 'spring',
        stiffness: 400,
        delay: 0,
        damping: 30,
      }}>
      {children}
    </motion.div>
  );
});

export function Wizard({ activeStep, children }: { activeStep: number; children: React.ReactNode }) {
  const reactChildren = React.Children.toArray(children);

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {reactChildren[activeStep]}
    </AnimatePresence>
  );
}
