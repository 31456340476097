import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Select,
  MenuItem,
  ExternalLink,
  GridFlex,
  InformationTooltip,
  Text,
  Skeleton,
  Link,
} from '@stigg-components';
import { formatDate } from '@stigg-common';
import { styled } from '@stigg-theme';
import {
  BillingPeriod,
  Environment,
  IntegrationFragment,
  StripeCredentialsFragment,
  StripeProduct as ApiStripeProduct,
  StripeProductPrice,
} from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { Grid as GridTiles, AlertTriangle } from 'react-feather';
import { t } from 'i18next';
import Table, { HeadCell } from '../../../../../components/table/Table';
import { useAppDispatch, RootState } from '../../../../../redux/store';
import { searchStripeProductsAction } from '../../../integrationsSlice';
import { useImportContext } from '../hooks/useStripeWizardImportContext';
import { getIconColor } from '../../../../../theme';
import Search from '../../../../../components/Search';
import { getProductBillingLinkUrl } from '../stripeBillingUrl';
import { currencyPriceFormatter } from '../../../../packages/pricing/components/currency/currencyUtils';

export type StripeProductListItem = {
  id: string;
  name: string;
  price: string;
  prices: StripeProductPrice[];
  updatedAt: Date;
  isSelected: boolean;
  notSupportedForImport: boolean;
  isSynced: boolean;
  isInvalidForImport: boolean;
  environmentId?: string | null;
};

const GridTilesIcon = styled(GridTiles)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

const AlertTriangleIcon = styled(AlertTriangle)`
  position: absolute;
  left: -40px;
`;

export const headCells = (
  environments: Environment[],
  isTestMode: boolean,
): Array<HeadCell<StripeProductListItem, any>> => [
  {
    id: 'name',
    alignment: 'left',
    label: t('integrations.stripeProductNameColumn'),
    render: (stripeProduct) => (
      <InformationTooltip
        arrow
        $padding={2}
        placement="top"
        title={<Text.B2>{t('integrations.openLinkInStripe')}</Text.B2>}>
        <Box width="fit-content">
          <Link
            onClick={() => window.open(getProductBillingLinkUrl(isTestMode, stripeProduct.id), '_blank')}
            color={stripeProduct.isInvalidForImport ? 'disabled' : 'primary'}>
            {stripeProduct.name}
          </Link>
        </Box>
      </InformationTooltip>
    ),
  },
  {
    id: 'price',
    alignment: 'left',
    label: t('integrations.stripeProductPriceColumn'),
    render: (stripeProduct) => {
      let alreadyImportToEnvironment: Environment | undefined;
      if (stripeProduct.isSynced && stripeProduct.environmentId) {
        alreadyImportToEnvironment = environments.find((environment) => environment.id === stripeProduct.environmentId);
      }
      return (
        <GridFlex.RowCenter sx={{ position: 'relative' }}>
          {stripeProduct.notSupportedForImport || stripeProduct.isSynced ? (
            <InformationTooltip
              arrow
              placement="top"
              title={
                <>
                  <Text.B2 mb={1}>
                    {stripeProduct.notSupportedForImport
                      ? t('integrations.unsupportedProductToImportTooltip')
                      : t('integrations.alreadyImportedToAnotherStiggAccount', {
                          environmentName: alreadyImportToEnvironment?.displayName
                            ? `"${alreadyImportToEnvironment?.displayName}"`
                            : '',
                        })}
                  </Text.B2>
                  {stripeProduct.notSupportedForImport && (
                    <ExternalLink
                      label={t('sharedComponents.learnMore')}
                      url={t('integrations.stripeSupportedPricingModelsLink')}
                    />
                  )}
                </>
              }>
              <AlertTriangleIcon color={getIconColor('warning')} />
            </InformationTooltip>
          ) : null}

          <Text.B2 color={stripeProduct.isInvalidForImport ? 'disabled' : 'primary'}>{stripeProduct.price}</Text.B2>
        </GridFlex.RowCenter>
      );
    },
  },
  {
    id: 'updatedAt',
    alignment: 'left',
    label: t('integrations.stripeProductUpdatedColumn'),
    render: (stripeProduct) => (
      <Text.B2 color={stripeProduct.isInvalidForImport ? 'disabled' : 'primary'}>
        {formatDate(stripeProduct.updatedAt, { formatType: 'longDate' })}
      </Text.B2>
    ),
  },
];

function getPriceDescription(stripeProduct: ApiStripeProduct) {
  const { prices } = stripeProduct;
  if (!prices.length) {
    return t('integrations.stripeProductWithNoPrices');
  }
  if (stripeProduct.prices.length > 1) {
    return t('integrations.stripeProductPrices', { pricesCount: stripeProduct.prices.length });
  }

  const { billingPeriod, amount } = stripeProduct.prices[0];
  return `${currencyPriceFormatter({ amount })} ${billingPeriod === BillingPeriod.Annually ? '/ year' : '/ month'}`;
}

export function mapStripeProduct(stripeProduct: ApiStripeProduct, isSelected: boolean): StripeProductListItem {
  const isInvalidForImport = stripeProduct.notSupportedForImport || stripeProduct.isSynced;
  return {
    name: stripeProduct.name,
    id: stripeProduct.id,
    price: getPriceDescription(stripeProduct),
    prices: stripeProduct.prices,
    isSelected: isInvalidForImport ? false : isSelected,
    updatedAt: stripeProduct.updatedAt,
    notSupportedForImport: stripeProduct.notSupportedForImport,
    isSynced: stripeProduct.isSynced,
    isInvalidForImport: stripeProduct.notSupportedForImport || stripeProduct.isSynced,
    environmentId: stripeProduct.environmentId,
  };
}

export function ImportProductsStep({ integration }: { integration: IntegrationFragment }) {
  const credentials = integration.credentials as StripeCredentialsFragment;
  const { isTestMode } = credentials;
  const theme = useTheme();
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const importContext = useImportContext();
  const products = useMemo(() => importContext?.productsData.products || [], [importContext]);
  const searchProductsResults = useMemo(() => importContext?.productsData.searchProductsResults || [], [importContext]);
  const productsNextPage = importContext?.productsData.nextPage;
  const productsTotalCount = importContext?.productsData.totalCount;
  const headerSelectionChecked = importContext?.productsData.headerSelectionChecked;
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const stiggProducts = useSelector((state: RootState) => state.productReducer.products);

  const fetchStripeProducts = useCallback(
    async (nextPage?: string) => {
      setIsLoadingProducts(true);
      const result = await dispatch(searchStripeProductsAction({ nextPage })).unwrap();
      importContext.setProductsData((prevState) => ({
        ...prevState,
        products: [
          ...prevState.products,
          ...result.products.map((stripeProduct) =>
            mapStripeProduct(
              stripeProduct,
              prevState.searchBlacklistProducts.some((blackProduct) => blackProduct.id === stripeProduct.id)
                ? false
                : prevState.searchWhitelistProducts.some((blackProduct) => blackProduct.id === stripeProduct.id)
                  ? true
                  : headerSelectionChecked,
            ),
          ),
        ],
        usageBasedProductPresent: prevState.usageBasedProductPresent || result.usageBasedProductPresent || false,
        totalCount: result.totalCount,
        nextPage: result.nextPage,
      }));
      setIsLoadingProducts(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, headerSelectionChecked],
  );

  useEffect(() => {
    // Fetch the initial products page only if there are no products yet
    if (isEmpty(products)) {
      void fetchStripeProducts();
    }
  }, [products, fetchStripeProducts]);

  const onSelectionChanged = () => {
    importContext.setProductsData((prevState) => {
      const newHeaderSelection = !prevState.headerSelectionChecked;
      return {
        ...prevState,
        products: products.map((product) => ({
          ...product,
          isSelected: product.isInvalidForImport ? false : newHeaderSelection,
        })),
        searchBlacklistProducts: [],
        searchWhitelistProducts: [],
        headerSelectionChecked: newHeaderSelection,
      };
    });
  };
  const onSelectItemChanged = (itemIndex: number, checked: boolean) => {
    if (search) {
      importContext.setProductsData((prevState) => {
        const itemChanged = prevState.searchProductsResults[itemIndex];
        const products = prevState.products.map((product) => ({
          ...product,
          isSelected: product.id === itemChanged.id ? checked : product.isSelected,
        }));
        const searchProductsResults = prevState.searchProductsResults.map((product, index) => ({
          ...product,
          isSelected: index === itemIndex ? checked : product.isSelected,
        }));
        if (prevState.headerSelectionChecked) {
          return {
            ...prevState,
            products,
            searchProductsResults,
            searchBlacklistProducts: checked
              ? prevState.searchBlacklistProducts.filter((product) => product.id !== itemChanged.id)
              : [...prevState.searchBlacklistProducts, itemChanged],
          };
        }
        return {
          ...prevState,
          products,
          searchProductsResults,
          searchWhitelistProducts: checked
            ? [...prevState.searchWhitelistProducts, itemChanged]
            : prevState.searchWhitelistProducts.filter((product) => product.id !== itemChanged.id),
        };
      });
    } else {
      importContext.setProductsData((prevState) => {
        let isAllUnselected = true;
        const products = prevState.products.map((product, index) => {
          const isSelected = index === itemIndex ? checked : product.isSelected;
          if (isSelected) {
            isAllUnselected = false;
          }
          return {
            ...product,
            isSelected,
          };
        });

        return {
          ...prevState,
          products,
          // if the single selection changed was the last checked and
          // it's unselected now we need to update headerSelectionChecked
          headerSelectionChecked: isAllUnselected ? false : prevState.headerSelectionChecked,
        };
      });
    }
  };

  const handleProductChange = (e: any) => {
    const { value: productId } = e.target;
    const currentSelectedProduct = stiggProducts.find((product) => product.id === productId);
    if (currentSelectedProduct) {
      if (importContext.setSelectedProduct) {
        importContext.setSelectedProduct(currentSelectedProduct);
      }
    }
  };

  const searchProducts = useCallback(
    async (searchTerm: string) => {
      importContext.setProductsData((prevState) => ({
        ...prevState,
        searchProductsResults: [],
      }));
      if (searchTerm) {
        setIsLoadingProducts(true);
        const result = await dispatch(searchStripeProductsAction({ searchTerm })).unwrap();
        importContext.setProductsData((prevState) => ({
          ...prevState,
          searchProductsResults: result.products.map((stripeProduct) => {
            // In case we already fetch the product we should use the existing product selection
            const fetchedProduct = products.find((product) => product.id === stripeProduct.id);
            return mapStripeProduct(stripeProduct, fetchedProduct ? fetchedProduct.isSelected : headerSelectionChecked);
          }),
        }));
        setIsLoadingProducts(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, products, headerSelectionChecked],
  );

  const fetchMoreProducts = useCallback(async () => {
    if (productsNextPage) {
      await fetchStripeProducts(productsNextPage);
    }
  }, [fetchStripeProducts, productsNextPage]);

  return (
    <GridFlex.Column>
      <Text.H3 mb={1}>{t('integrations.importProductsHeader')}</Text.H3>
      <GridFlex.RowCenter justifyContent="space-between" mb={8}>
        <Text.B2>{t('integrations.productFamilyText')}</Text.B2>
        <GridFlex.Item width={60} marginLeft="auto">
          <Select
            width={225}
            placeholder={t('integrations.productFamilyPlaceholder')}
            onChange={handleProductChange}
            value={importContext.selectedProduct?.id}
            renderValue={(value) => (
              <GridFlex.RowCenter>
                <GridTilesIcon />
                <Text.B2 ml={2}>{stiggProducts.find((feature) => feature.id === value)?.displayName}</Text.B2>
              </GridFlex.RowCenter>
            )}>
            {(stiggProducts || []).map((product) => (
              <MenuItem key={product.id} value={product.id}>
                <GridFlex.Column>
                  <Text.B2 mr={2}>{product.displayName}</Text.B2>
                </GridFlex.Column>
              </MenuItem>
            ))}
          </Select>
        </GridFlex.Item>
      </GridFlex.RowCenter>
      <GridFlex.RowCenter $fullWidth justifyContent="space-between" alignItems="flex-end" mb={4}>
        {productsTotalCount ? (
          <Text.B2 color="secondary">
            {headerSelectionChecked
              ? `${productsTotalCount - importContext.unSelectedProducts.length} / ${productsTotalCount} ${t(
                  'integrations.selectedProducts',
                )}`
              : `${importContext.selectedProducts.length} / ${productsTotalCount} ${t(
                  'integrations.selectedProducts',
                )}`}
          </Text.B2>
        ) : (
          <Skeleton animation="wave" width={200} height={15} />
        )}
        <Box width={300}>
          <Search
            searchOnChange
            variant="outlined"
            placeholder="Search by product name"
            handleSearchFunc={(searchTerm) => {
              setSearch(searchTerm);
              void searchProducts(searchTerm);
            }}
            searchedStr={search}
          />
        </Box>
      </GridFlex.RowCenter>
      <Table
        isLoading={isLoadingProducts}
        headCells={headCells(environments, isTestMode)}
        label={t('customers.table')}
        headerRowColor={theme.isLightTheme ? '#F2F4F8' : theme.itamar.palette.background.darkBackground2}
        data={isEmpty(search) ? products : searchProductsResults}
        enableRowSelection
        hideHeaderSelection={!isEmpty(search)}
        onSelectAllToggle={onSelectionChanged}
        onItemSelectionToggle={onSelectItemChanged}
        maxHeight={500}
        infiniteScrollOptions={{ hasMore: !!productsNextPage, loadMore: fetchMoreProducts }}
        stickyHeader
        isRowDisabled={(stripeProduct: StripeProductListItem) => stripeProduct.isInvalidForImport}
      />
    </GridFlex.Column>
  );
}
