import { gql } from '@apollo/client';
import { FetchCouponSyncStatesQuery, FetchCouponSyncStatesQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { SYNC_STATE_FRAGMENT } from '../../packages/plans/queries/syncStateFragment';

const QUERY = gql`
  query FetchCouponSyncStates($filter: CouponFilter!) {
    coupons(filter: $filter) {
      edges {
        node {
          billingId
          billingLinkUrl
          syncStates {
            ...SyncStateFragment
          }
        }
      }
    }
  }
  ${SYNC_STATE_FRAGMENT}
`;

export function watchCouponSyncStates(couponId: string, pollInterval: number) {
  return apolloClient.watchQuery<FetchCouponSyncStatesQuery, FetchCouponSyncStatesQueryVariables>({
    query: QUERY,
    fetchPolicy: 'no-cache',
    pollInterval,
    variables: {
      filter: {
        id: {
          eq: couponId,
        },
      },
    },
  });
}
