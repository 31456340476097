import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Alert, Box, Button, Text, CircularProgress, Grid, Switch } from '@stigg-components';
import { styled } from '@stigg-theme';
import { t } from 'i18next';
import { encode } from 'querystring';
import { useSelector } from 'react-redux';
import { Environment, EnvironmentType, VendorIdentifier } from '@stigg-types/apiTypes';
import { RootState, useAppDispatch } from '../../../redux/store';
import { createIntegrationAction } from '../integrationsSlice';
import { useNavigation } from '../../navigation/useNavigation';
import Loader from '../../../components/Loader';
import config from '../../../env.config';
import { useQueryParam } from '../../common/useQueryParam';
import { HelpTooltip } from '../../../components/HelpTooltip';

const STRIPE_AUTHORIZE_ENDPOINT_URL = 'https://connect.stripe.com/oauth/authorize';

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    height: 54px;
    width: 100%;
  }
`;

const ConnectButton = styled(Button)`
  height: 36px;
  width: 100%;
`;

const redirectToStripeConnect = (isTestMode: boolean, currentEnvironment: Environment) => {
  const responseType = 'code';
  const scope = 'read_write';
  const clientId = isTestMode ? config.stripeTestClientId : config.stripeClientId;
  const redirectUri = `${window.location.origin}/redirects/stripe`;

  const state = JSON.stringify({ environmentSlug: currentEnvironment.slug, isTestMode });

  const authUrlParams = encode({
    response_type: responseType,
    client_id: clientId,
    scope,
    redirect_uri: redirectUri,
    state,
  });
  const authUrl = `${STRIPE_AUTHORIZE_ENDPOINT_URL}?${authUrlParams}`;
  window.location.href = authUrl;
};

export function StripeConnect() {
  const [isTestModeSwitch, setIsTestModeSwitch] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const { user } = useAuth0();

  const dispatch = useAppDispatch();
  const navigation = useNavigation();

  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const currentEnvironment = environments.find((x) => x.id === currentEnvironmentId)!;
  const createLoading = useSelector((state: RootState) => state.integrations.createLoading);
  const updateLoading = useSelector((state: RootState) => state.integrations.updateLoading);
  const shouldDisableSwitch = !user?.email?.endsWith('@stigg.io');
  const isSwitchDisabled = isConnecting || shouldDisableSwitch;
  const isTestMode = shouldDisableSwitch ? currentEnvironment.type !== EnvironmentType.Production : isTestModeSwitch;

  const { value: authorizationCodeQueryParam } = useQueryParam('authorizationCode');
  const isTestModeQueryParam = useQueryParam('isTestMode').value === 'true';

  const createIntegration = async () => {
    const integrationData = {
      vendorIdentifier: VendorIdentifier.Stripe,
      stripeCredentials: {
        authorizationCode: authorizationCodeQueryParam!,
        isTestMode: isTestModeQueryParam,
      },
    };
    await dispatch(createIntegrationAction(integrationData));
  };

  useEffect(() => {
    if (authorizationCodeQueryParam) {
      void createIntegration();

      // remove the authorization code from URL
      navigation.navigateTo(
        navigation.appRoutes.integrationsPage({ vendorIdentifier: VendorIdentifier.Stripe.toLowerCase() }),
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectStripe = () => {
    setIsConnecting(true);
    redirectToStripeConnect(isTestMode, currentEnvironment);
  };

  return createLoading || updateLoading ? (
    <StyledAlert icon={false} severity="info" mt={4}>
      <Loader size={20} text={t('integrations.stripe.connecting')} />
    </StyledAlert>
  ) : (
    <Box>
      <Grid item>
        <Grid display="flex" alignItems="center">
          <Switch
            disabled={isSwitchDisabled}
            checked={isTestMode}
            onChange={(_, checked) => setIsTestModeSwitch(checked)}
          />
          <Text.B2 color="primary">{t('integrations.stripe.useTestAccount')}</Text.B2>

          <HelpTooltip $maxWidth={280}>
            <Text.B2 color="primary">
              {shouldDisableSwitch
                ? isTestMode
                  ? t('integrations.stripe.nonProdStripeTestAccountTooltip')
                  : t('integrations.stripe.prodStripeTestAccountTooltip')
                : t('integrations.stripe.testAccountTooltip')}
            </Text.B2>
          </HelpTooltip>
        </Grid>
      </Grid>

      <ConnectButton
        color="stripe"
        variant="contained"
        size="medium"
        onClick={connectStripe}
        disabled={isConnecting}
        sx={{ mt: 4 }}>
        {isConnecting ? <CircularProgress size={20} color="inherit" /> : <>Connect with Stripe</>}
      </ConnectButton>
    </Box>
  );
}
