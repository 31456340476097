import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { executeOperationSafely } from '@stigg-common';
import { WorkflowsLoginMutation, WorkflowsLoginMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { RootState } from '../../../redux/store';
import { gql } from '@apollo/client';

export const WORKFLOWS_LOGIN = gql`
  mutation WorkflowsLogin($input: WorkflowsLoginInput!) {
    workflowsLogin(input: $input) {
      projectId
      token
    }
  }
`;

export const useWorkflowLogin = ({ showWorkflows }: { showWorkflows: boolean }) => {
  const dispatch = useDispatch();
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId) as string;

  if (!environmentId) {
    throw new Error('environment Id must be set');
  }

  return useQuery({
    queryKey: ['workflows-login', environmentId],
    queryFn: () =>
      executeOperationSafely(
        () =>
          apolloClient.mutate<WorkflowsLoginMutation, WorkflowsLoginMutationVariables>({
            mutation: WORKFLOWS_LOGIN,
            variables: {
              input: {
                environmentId,
              },
            },
          }),
        { failureMessageHandler: () => t('workflows.loginError') },
        dispatch,
      ),
    enabled: !!environmentId && showWorkflows,
    select: (response) => response.data?.workflowsLogin,
    retry: 0, // we automatically retry in apollo client
    refetchOnWindowFocus: false,
  });
};
