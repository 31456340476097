import { gql } from '@apollo/client';
import { t } from 'i18next';
import { CreateAccountMutation, CreateAccountMutationVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const ADD_ACCOUNT = gql`
  mutation CreateAccountMutation($input: String!) {
    createAccount(accountName: $input) {
      id
      userId
      memberStatus
      email
      account {
        id
        displayName
      }
    }
  }
`;

async function addAccount(
  { noSuccessMessage, displayName }: { noSuccessMessage?: boolean; displayName: string },
  { dispatch }: { dispatch: AppDispatch },
) {
  return executeOperationSafely(
    async () => {
      const response = await apolloClient.mutate<CreateAccountMutation, CreateAccountMutationVariables>({
        mutation: ADD_ACCOUNT,
        variables: { input: displayName },
      });

      if (!response.data) {
        throw new Error('Invalid response');
      }

      return response.data.createAccount;
    },
    {
      successMessage: noSuccessMessage ? undefined : t('accounts.api.successAddAcct'),
      failureMessageHandler: () => t('accounts.api.failAddAcct'),
    },
    dispatch,
  );
}

export { addAccount };
