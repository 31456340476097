import { t } from 'i18next';
import styled from 'styled-components';
import { FormRenderProps, GridFlex, Icon, Text } from '@stigg-components';
import { CustomerResponseFragment, PaymentCollectionMethod } from '@stigg-types/apiTypes';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { ChargeCollectionMethod } from './ChargeCollectionMethod';
import { InvoiceCollectionMethod } from './InvoiceCollectionMethod';

const PaymentStyledContainer = styled(GridFlex.RowCenter)`
  .MuiSvgIcon-root {
    margin-right: 0;
  }
`;

type PaymentCollectionMethodContentProps = {
  form: FormRenderProps<SubscriptionFormFields>;
  customer: CustomerResponseFragment;
};

type PaymentCollectionMethodOption = {
  render: () => JSX.Element;
  renderDetails?: (props: PaymentCollectionMethodContentProps) => JSX.Element;
  isDisabled?: (values: SubscriptionFormFields) => boolean;
};

export const COLLECTION_METHOD_OPTIONS: Record<PaymentCollectionMethod, PaymentCollectionMethodOption> = {
  [PaymentCollectionMethod.Charge]: {
    render: () => (
      <PaymentStyledContainer gap={2}>
        <Icon icon="Check" color="active" overrideStroke size={18} />
        <Text.B2>{t('subscriptionForm.paymentMethod.payment')}</Text.B2>
      </PaymentStyledContainer>
    ),
    renderDetails: (props) => <ChargeCollectionMethod {...props} />,
  },
  [PaymentCollectionMethod.Invoice]: {
    render: () => (
      <PaymentStyledContainer gap={2}>
        <Icon icon="ReceiptOutlined" type="materialIcons" color="active" size={18} />
        <Text.B2>{t('subscriptionForm.paymentMethod.invoice')}</Text.B2>
      </PaymentStyledContainer>
    ),
    renderDetails: (props) => <InvoiceCollectionMethod {...props} />,
  },
  [PaymentCollectionMethod.None]: {
    render: () => (
      <PaymentStyledContainer gap={2}>
        <Icon icon="Close" color="active" size={18} />
        <Text.B2>{t('subscriptionForm.paymentMethod.custom')}</Text.B2>
      </PaymentStyledContainer>
    ),
    // this is disabled for credit card trial as backend was not implemented yet (in scheduler)
    isDisabled: (values: SubscriptionFormFields) => !!values.isTrial,
  },
};
