import { Flex, Icon, Icons, Text } from '@stigg-components';
import { styled } from '@stigg-theme';
const EntityTileContainer = styled(Flex.Row)`
  min-height: 64px;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  gap: ${({ theme }) => theme.spacing(2)};
  flex: 1 0;
  border: ${({ theme }) => `${theme.itamar.border.border} ${theme.itamar.border.borderColor}`};
  border-radius: ${({ theme }) => theme.itamar.border.radius};
`;

interface EntityTileProps {
  icon: Icons;
  title: string;
  description: string;
}

export const EntityTile = ({ icon, title, description }: EntityTileProps) => {
  return (
    <EntityTileContainer>
      <Flex.ColumnCenter>
        <Icon icon={icon} color="default" overrideStroke />
      </Flex.ColumnCenter>
      <Flex.Column>
        <Text.B2>{title}</Text.B2>
        <Text.Sub2>{description}</Text.Sub2>
      </Flex.Column>
    </EntityTileContainer>
  );
};
