import { t } from 'i18next';
import { AppDispatch, RootState } from 'src/redux/store';
import {
  UpdateIntegrationInput,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables,
} from '@stigg-types/apiTypes';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_INTEGRATION } from './updateIntegrationMutation';
import { executeOperationSafely } from '@stigg-common';
import { apolloClient } from '../../../ApolloClient';
import { ErrorCode } from '@stigg-types/apiTypes';
import { fetchIntegrationByIdAction, fetchIntegrationsAction } from '../integrationsSlice';

const mutationFunction = (
  dispatch: AppDispatch,
  integrationId: string | undefined,
  integrationData: UpdateIntegrationInput,
  noSuccessMessage?: boolean,
) => {
  return executeOperationSafely(
    async () => {
      if (!integrationId) {
        throw new Error('Integration ID is required');
      }

      const integration = {
        ...integrationData,
      };

      const response = await apolloClient.mutate<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>({
        mutation: UPDATE_INTEGRATION,
        variables: { input: { id: integrationId, update: integration } },
      });
      if (!response.data) {
        throw new Error('Invalid response');
      }
      const newIntegration = response.data.updateOneIntegration;
      return newIntegration;
    },
    {
      successMessage: noSuccessMessage ? undefined : t('integrations.api.successUpdate'),
      failureMessageHandler: (err) => {
        // TODO: change this string comparison to use explicit error code instead
        if (err.graphQLErrors[0]?.message === 'Zuora credentials are not valid') {
          return t('integrations.api.invalidCredentials');
        }
        return t('integrations.api.failUpdate');
      },
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('integrations.api.alreadyExists', {
          vendorIdentifier: integrationData.vendorIdentifier,
        }),
      },
    },
    dispatch,
  );
};

interface UpdateIntegrationProps {
  integrationId?: string;
  skipFetch?: boolean;
  noSuccessMessage?: boolean;
}

export function useUpdateIntegration({ integrationId, skipFetch, noSuccessMessage }: UpdateIntegrationProps) {
  const dispatch = useDispatch();
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId || '');

  return useMutation({
    mutationFn: (integrationData: UpdateIntegrationInput) =>
      mutationFunction(dispatch, integrationId, integrationData, noSuccessMessage),
    onSuccess: async () => {
      if (!skipFetch) {
        await dispatch(fetchIntegrationByIdAction({ integrationId }));
        await dispatch(fetchIntegrationsAction({ environmentId: currentEnvironmentId }));
      }
    },
  });
}
