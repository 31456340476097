import moment from 'moment';
import {
  BillableFeature,
  DateRange,
  Money,
  Plan,
  PreviewSubscription,
  SubscriptionEstimationAddon,
  SubscriptionPreviewV2,
} from '@stigg/react-sdk';
import { Currency, BillingModel, BillingPeriod } from '@stigg-types/apiTypes';
import { mockCheckoutState } from './mockCheckoutState';

const ZERO_COST: Money = {
  amount: 0,
  currency: Currency.Usd,
};

const mockBillingPeriod = (period?: BillingPeriod): DateRange => {
  return {
    start: moment().toDate(),
    end: moment()
      .add(1, period === BillingPeriod.Monthly ? 'month' : 'year')
      .toDate(),
  };
};

const defaultPreviewSubscription = (): SubscriptionPreviewV2 => {
  return {
    immediateInvoice: {
      subTotal: ZERO_COST,
      total: ZERO_COST,
      totalExcludingTax: ZERO_COST,
    },
    recurringInvoice: {
      subTotal: ZERO_COST,
      total: ZERO_COST,
      totalExcludingTax: ZERO_COST,
    },
    billingPeriodRange: mockBillingPeriod(),
  };
};

const flatFeeCost = (plan: Plan, billingPeriod: BillingPeriod | undefined) => {
  const filteredPrice = plan.pricePoints?.find(
    (price) => price.billingPeriod === billingPeriod && price.pricingModel === BillingModel.FlatFee,
  );

  return filteredPrice?.amount || 0;
};

const addonCost = (plan: Plan, billingPeriod: BillingPeriod, addonId: string, quantity: number) => {
  const addon = plan.compatibleAddons?.find((addon) => addon.id === addonId);

  const price = addon?.pricePoints.find((price) => price.billingPeriod === billingPeriod);

  return (price?.amount || 0) * quantity;
};

const billableFeatureCost = (plan: Plan, billingPeriod: BillingPeriod, featureId: string, quantity: number) => {
  const price = plan.pricePoints.find(
    (price) => price.feature?.featureId === featureId && price.billingPeriod === billingPeriod,
  );

  if (!price) return 0;

  const { tiers } = price;

  if (tiers) {
    const quantityTier = tiers.find((tier) => tier.upTo && tier.upTo >= quantity);
    const priceTier = quantityTier || tiers[tiers.length - 1]; // maybe need to multiply by amount
    let amount = 0;

    if (priceTier.unitPrice) {
      amount += quantity * priceTier.unitPrice.amount;
    }
    if (priceTier.flatPrice) {
      amount += priceTier.flatPrice.amount;
    }

    return amount;
  }

  return (price?.amount || 0) * quantity;
};

const mockTotalPrice = (
  plan: Plan,
  billingPeriod: BillingPeriod,
  addons: SubscriptionEstimationAddon[],
  features: BillableFeature[],
) => {
  const currency = plan.pricePoints?.[0].currency || Currency.Usd;

  const totalFlatFeeCost = flatFeeCost(plan, billingPeriod);
  const totalFeaturesCost = features.reduce(
    (total, feature) => total + billableFeatureCost(plan, billingPeriod, feature.featureId, feature.quantity || 0),
    0,
  );
  const totalAddonsCost = addons.reduce(
    (total, addon) => total + addonCost(plan, billingPeriod, addon.addonId, addon.quantity || 0),
    0,
  );

  const cost: Money = {
    amount: totalFlatFeeCost + totalFeaturesCost + totalAddonsCost,
    currency: currency || Currency.Usd,
  };

  return {
    subTotal: cost,
    total: cost,
    totalExcludingTax: cost,
  };
};

export function mockPreviewSubscription(input: PreviewSubscription): SubscriptionPreviewV2 {
  const {
    planId,
    billingPeriod = BillingPeriod.Monthly,
    billableFeatures: features = [],
    addons = [],
    promotionCode,
  } = input;

  if (promotionCode) {
    throw new Error('Error: Invalid promotion code');
  }

  const data = mockCheckoutState({ planId });

  if (!data || !data.plan) {
    return defaultPreviewSubscription();
  }

  const total = mockTotalPrice(data.plan, billingPeriod, addons, features);

  if (!total) {
    return defaultPreviewSubscription();
  }

  return {
    immediateInvoice: {
      ...total,
      proration: {
        prorationDate: moment().toDate(),
        credit: ZERO_COST,
        debit: total.total,
        netAmount: total.total,
      },
    },
    recurringInvoice: { ...total },
    billingPeriodRange: mockBillingPeriod(billingPeriod),
  };
}
