import { useRef, createContext, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Ability } from '@casl/ability';
import {
  useIsRbacEnabled,
  AccountPermissionActions,
  EnvironmentPermissionActions,
  PermissionResource,
} from '@stigg-permissions';
import { Permit, PermitCheckSchema } from 'permit-fe-sdk';
import { RootState } from '../redux/store';
import config from '../env.config';
import Loader from '../components/Loader';

export const PermissionsContext = createContext<any>(null); // TODO: add types
export let permitState: PermitCheckSchema;

export default function PermissionsProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();
  const checkRbacPermissions = useIsRbacEnabled();
  const environments = useSelector((state: RootState) => state.accountReducer.environments);
  const [ability, setAbility] = useState<any>(undefined);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(checkRbacPermissions);
  const isLoadingUser = useSelector((state: RootState) => state.authReducer.isLoading);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const shouldShowLoader = useRef(true);

  useEffect(() => {
    const getAbility = async (email: string) => {
      const token = await getAccessTokenSilently();
      permitState = Permit({
        loggedInUser: email,
        backendUrl: `${config.restUrl}/permissions/check`,
        customRequestHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      permitState.reset();
      const accountAccess = Object.values(AccountPermissionActions).map((permission) => ({
        action: permission,
        resource: PermissionResource.Account,
      }));
      const environmentAccess = environments.flatMap((environment) =>
        Object.values(EnvironmentPermissionActions).map((permission) => ({
          action: permission,
          resource: { type: PermissionResource.Environment, key: environment.id },
        })),
      );

      await permitState.loadLocalStateBulk([...accountAccess, ...environmentAccess]);
      const caslConfig = permitState.getCaslJson();
      return caslConfig && caslConfig.length ? new Ability(caslConfig) : undefined;
    };

    if (checkRbacPermissions && !isLoadingUser && user?.email) {
      // Showing the loader only for the first time when the permissions are being loaded
      if (shouldShowLoader.current) {
        setIsLoadingPermissions(true);

        shouldShowLoader.current = false;
      }
      void getAbility(user.email).then((caslAbility) => {
        setAbility(caslAbility);
        setIsLoadingPermissions(false);
      });
    }
  }, [checkRbacPermissions, isLoadingUser, user, getAccessTokenSilently, environments]);

  return isLoadingPermissions ? (
    <Loader useAnimationLoader />
  ) : (
    <PermissionsContext.Provider value={ability}>{children}</PermissionsContext.Provider>
  );
}
