import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { updateItemById } from '@stigg-common';
import { FetchPlanSyncStatesQuery, PlanFragment, PlansFragment } from '@stigg-types/apiTypes';
import { fetchPlans } from './queries';
import {
  createPlan,
  removeCompatibleAddonsFromPlan,
  updatePlan,
  addCompatibleAddonsToPlan,
  publishPlan,
  createPlanDraft,
  discardPlanDraft,
} from './mutations';
import { fetchPlanByRefId } from './queries/fetchPlanByRefId';

import { createAppAsyncThunk } from '../../../redux/createAppAsyncThunk';
import { setCompatibleAddonGroupsToPlan } from './mutations/setCompatibleAddonGroupsToPlan';
import { PackageListFragment } from '../common/components/Packages';

type ArchiveDialogProps = {
  isOpen: boolean;
  plan?: PackageListFragment;
};

export interface PlansState {
  isLoading: boolean;
  isLoadingPlan: boolean;
  isSilentLoadingPlan: boolean;
  isLoadingDialogPlans: boolean;
  isUpdatingPlan: boolean;
  plans: PlansFragment;
  plan?: PlanFragment;
  dialogPlans: PlansFragment;
  archiveDialog: ArchiveDialogProps;
}

const initialState: PlansState = {
  isLoading: false,
  isLoadingPlan: false,
  isSilentLoadingPlan: false,
  isUpdatingPlan: false,
  plans: {} as PlansFragment,
  dialogPlans: {} as PlansFragment,
  isLoadingDialogPlans: false,
  plan: {} as PlanFragment,
  archiveDialog: {
    isOpen: false,
    plan: undefined,
  },
};

const fetchDialogPlansAction = createAppAsyncThunk('fetchDialogPlans', fetchPlans);
const fetchPlansAction = createAppAsyncThunk('fetchPlans', fetchPlans);
const createPlanAction = createAppAsyncThunk('createPlan', createPlan);
const fetchPlanByRefIdAction = createAppAsyncThunk('fetchPlanByRefId', fetchPlanByRefId);
const updatePlanAction = createAppAsyncThunk('updatePlan', updatePlan);
const removeCompatibleAddonsFromPlanAction = createAppAsyncThunk(
  'removeCompatibleAddonsFromPlan',
  removeCompatibleAddonsFromPlan,
);
const addCompatibleAddonsToPlanAction = createAppAsyncThunk('addCompatibleAddonsToPlan', addCompatibleAddonsToPlan);

const setCompatibleAddonGroupsToPlanAction = createAppAsyncThunk(
  'setCompatibleAddonGroupsToPlan',
  setCompatibleAddonGroupsToPlan,
);
const publishPlanAction = createAppAsyncThunk('publishPlan', publishPlan);
const createPlanDraftAction = createAppAsyncThunk('createPlanDraft', createPlanDraft);
const discardPlanDraftAction = createAppAsyncThunk('discardPlanDraft', discardPlanDraft);

type PlanSyncState = FetchPlanSyncStatesQuery['plans']['edges'][number]['node'];

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    resetPlansState: () => initialState,
    resetPlan: (state) => {
      state.plan = {} as PlanFragment;
    },
    setArchiveDialog: (state, action: PayloadAction<ArchiveDialogProps>) => {
      state.archiveDialog = action.payload;
    },
    updatePlanSyncState: (state, action: { payload: PlanSyncState }) => {
      state.plan = merge(state.plan, action.payload);
    },
    setIsLoadingDialogPlans: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDialogPlans = action.payload;
    },
    clearDialogPlans: (state) => {
      state.isLoadingDialogPlans = false;
      state.dialogPlans = {} as PlansFragment;
    },
    removePlanById: (state, action: PayloadAction<{ id: string }>) => {
      remove(state.plans.edges, (plan) => plan.node.id === action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlansAction.pending, (state, { meta }) => {
        if (!isEmpty(meta.arg.search)) {
          state.plans = {} as PlansFragment;
        }
        if (isEmpty(state.plans.edges) && !meta?.arg?.silentFetch) {
          state.isLoading = true;
        }
      })
      .addCase(fetchPlansAction.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchPlansAction.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchDialogPlansAction.pending, (state) => {
        state.isLoadingDialogPlans = true;
      })
      .addCase(fetchDialogPlansAction.fulfilled, (state, action) => {
        state.dialogPlans = action.payload;
        state.isLoadingDialogPlans = false;
      })
      .addCase(fetchDialogPlansAction.rejected, (state) => {
        state.isLoadingDialogPlans = false;
      });

    builder
      .addCase(fetchPlanByRefIdAction.pending, (state, action) => {
        state.isLoadingPlan = !action.meta.arg.silentFetch;
        state.isSilentLoadingPlan = !!action.meta.arg.silentFetch;
      })
      .addCase(fetchPlanByRefIdAction.fulfilled, (state, { payload }) => {
        state.plan = payload || ({} as PlanFragment);
        state.isLoadingPlan = false;
        state.isSilentLoadingPlan = false;
      })
      .addCase(fetchPlanByRefIdAction.rejected, (state) => {
        state.isLoadingPlan = false;
        state.isSilentLoadingPlan = false;
      });

    builder
      .addCase(updatePlanAction.pending, (state, action) => {
        state.isUpdatingPlan = !action.meta.arg.silentFetch;
      })
      .addCase(updatePlanAction.fulfilled, (state, { payload }) => {
        state.plan = merge(state.plan, payload);
        updateItemById(state.plans, payload);
        state.isUpdatingPlan = false;
      })
      .addCase(updatePlanAction.rejected, (state) => {
        state.isUpdatingPlan = false;
      });

    builder.addCase(discardPlanDraftAction.fulfilled, (state, { payload }) => {
      remove(state.plans.edges, (plan) => plan.node.refId === payload.refId);
    });

    builder
      .addCase(createPlanDraftAction.pending, (state) => {
        state.plan = {} as PlanFragment;
        state.isLoadingPlan = true;
      })
      .addCase(createPlanDraftAction.fulfilled, (state) => {
        state.isLoadingPlan = false;
      })
      .addCase(createPlanDraftAction.rejected, (state) => {
        state.isLoadingPlan = false;
      });
  },
});

const {
  resetPlansState,
  resetPlan,
  setArchiveDialog,
  updatePlanSyncState,
  setIsLoadingDialogPlans,
  clearDialogPlans,
  removePlanById,
} = plansSlice.actions;

export {
  resetPlansState,
  resetPlan,
  removeCompatibleAddonsFromPlanAction,
  addCompatibleAddonsToPlanAction,
  setCompatibleAddonGroupsToPlanAction,
  updatePlanAction,
  fetchPlansAction,
  fetchDialogPlansAction,
  createPlanAction,
  fetchPlanByRefIdAction,
  publishPlanAction,
  createPlanDraftAction,
  discardPlanDraftAction,
  setArchiveDialog,
  updatePlanSyncState,
  setIsLoadingDialogPlans,
  clearDialogPlans,
  removePlanById,
};

export default plansSlice.reducer;
