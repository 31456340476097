import { t } from 'i18next';
import { useModal } from '@stigg-common';
import { CustomerResponseFragment } from '@stigg-types/apiTypes';
import { Alert, Field, FormRenderProps, GridFlex, Link, PaymentDetails, Text } from '@stigg-components';
import { SubscriptionFormFields } from '../../SubscriptionForm.types';
import { AddPaymentMethod } from '../../../../payment/AddPaymentMethod';
import { SEARCH_COMPONENT_WIDTH } from '../coupon/consts';
import { AdditionalInputLayout } from '../AdditionalInputLayout';

type ChargeCollectionMethodProps = {
  form: FormRenderProps<SubscriptionFormFields>;
  customer: CustomerResponseFragment;
};

function DefaultPaymentMethodDetails({ customer, form }: Pick<ChargeCollectionMethodProps, 'customer' | 'form'>) {
  const {
    defaultPaymentMethodType,
    defaultPaymentMethodLast4Digits,
    defaultPaymentExpirationMonth,
    defaultPaymentExpirationYear,
  } = customer;

  const defaultPaymentMethodField: Field<SubscriptionFormFields> = {
    type: 'custom',
    render: () => (
      <PaymentDetails
        paymentMethodType={defaultPaymentMethodType}
        last4Digits={defaultPaymentMethodLast4Digits}
        expirationMonth={defaultPaymentExpirationMonth}
        expirationYear={defaultPaymentExpirationYear}
        iconSize={24}
        iconWrapperProps={{
          sx: {
            height: 36,
            width: 36,
            backgroundColor: (theme) => theme.itamar.palette.background.emptyState,
            borderRadius: (theme) => theme.itamar.border.radius,
            display: 'flex',
            justifyContent: 'center',
          },
        }}
      />
    ),
  };

  return <AdditionalInputLayout isSet setLabel={''} fields={[defaultPaymentMethodField]} formRenderProps={form} />;
}

function AddPaymentMethodDetails({ form, customer }: ChargeCollectionMethodProps) {
  const { validateForm } = form;
  const [AddPaymentMethodModal, setIsAddPaymentMethodModalOpen] = useModal();

  const setPaymentMethodField: Field<SubscriptionFormFields> = {
    type: 'custom',
    render: () => (
      <>
        <Alert severity="error" icon={false} p={2} py={0} sx={{ width: SEARCH_COMPONENT_WIDTH }}>
          <GridFlex.RowMiddle gap={2}>
            <Text.B2 color="error.main" ml={2}>
              {t('subscriptionForm.paymentMethod.addPaymentRequired')}
            </Text.B2>
            <Link onClick={() => setIsAddPaymentMethodModalOpen(true)}>
              {t('subscriptionForm.paymentMethod.addPayment')}
            </Link>
          </GridFlex.RowMiddle>
        </Alert>
        <AddPaymentMethodModal>
          <AddPaymentMethod
            customer={customer}
            onSuccess={async () => {
              setIsAddPaymentMethodModalOpen(false);
              await validateForm();
            }}
            onCancel={() => setIsAddPaymentMethodModalOpen(false)}
            integrationId={form.values.billingIntegrationId}
          />
        </AddPaymentMethodModal>
      </>
    ),
  };

  return <AdditionalInputLayout isSet setLabel={''} fields={[setPaymentMethodField]} formRenderProps={form} />;
}

export function ChargeCollectionMethod({ customer, form }: ChargeCollectionMethodProps) {
  const { defaultPaymentMethodId } = customer;
  return defaultPaymentMethodId ? (
    <DefaultPaymentMethodDetails customer={customer} form={form} />
  ) : (
    <AddPaymentMethodDetails customer={customer} form={form} />
  );
}
