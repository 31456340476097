import { t } from 'i18next';
import {
  Box,
  Button,
  ExternalLink,
  FadeBox,
  FormRenderProps,
  GridFlex,
  PageCard,
  Text,
  useScrollableAnchor,
} from '@stigg-components';
import {
  PackageStatus,
  PricingType,
  ProductFragment,
  ProductPlanFragment,
  ProductSettingsFragment,
  ProductUpdateInput,
  SubscriptionCancellationTime,
  SubscriptionEndSetup,
  SubscriptionStartSetup,
  SubscriptionUpdateUsageCutoffBehavior,
} from '@stigg-types/apiTypes';
import { useConfirmationDialog } from '@stigg-common';
import React, { useCallback, useMemo } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import {
  SubscriptionCancellationTimeConfiguration,
  SubscriptionCancellationTimeFormFieldsNames,
} from './SubscriptionCancellationTimeConfiguration';
import {
  SubscriptionEndSetupConfiguration,
  SubscriptionEndSetupFormFieldsNames,
} from './SubscriptionEndSetupConfiguration';
import { updateProductAction } from '../../../productsSlice';
import { useAppDispatch } from '../../../../../redux/store';
import {
  SubscriptionStartSetupConfiguration,
  SubscriptionStartSetupFormFieldsNames,
} from './SubscriptionStartSetupConfiguration';
import { ConfirmationDialogContent } from './ConfirmationDialogContent';
import { useFindActiveExperiment } from '../../../../experiemnts/components/commom/useFindActiveExperiment';
import { CantEditCustomerJourneyActiveExperiment } from './CantEditCustomerJourneyActiveExperiment';
import { TooltipFields } from '../../../../../components/InformationTooltip';
import { CustomerJourneySectionTitle } from './CustomerJourneySectionTitle';
import { customerJourneyValidationJson } from './customerJourneyValidation';
import { EditProductSettingsFields, EditProductSettingsFormFields } from './types';
import { SubscriptionAutoCancellationConfiguration } from './SubscriptionAutoCancellationConfiguration';
import {
  SubscriptionDowngradeBehavior,
  SubscriptionUpdateSetupConfiguration,
} from './SubscriptionUpdateSetupConfiguration';
import { UsageResetCutoffConfiguration, UsageResetCutoffFormFieldsNames } from './UsageResetCutoffConfiguration';

export const EditProductSettingsFormFieldsNames = [
  ...SubscriptionStartSetupFormFieldsNames,
  ...SubscriptionEndSetupFormFieldsNames,
  ...SubscriptionCancellationTimeFormFieldsNames,
  ...UsageResetCutoffFormFieldsNames,
];
const validationSchema = Yup.object().shape(customerJourneyValidationJson);

export function calculateInitialCustomerJourneyFormValues(
  plans: Pick<ProductPlanFragment, 'id' | 'refId' | 'pricingType' | 'status' | 'defaultTrialConfig'>[],
  productSettings: ProductSettingsFragment,
  autoCancellationRules?: ProductFragment['autoCancellationRules'],
  usageResetConfiguration?: ProductFragment['subscriptionUpdateUsageResetCutoffRule'],
): EditProductSettingsFormFields {
  // pre-select first plan for easier UX
  let subscriptionStartTrialPlan = plans
    .filter((plan) => plan.pricingType !== PricingType.Free)
    .filter((plan) => plan.status !== PackageStatus.Draft)
    .find((plan) => plan.defaultTrialConfig);
  let subscriptionStartFreePlan = plans
    .filter((plan) => plan.pricingType === PricingType.Free)
    .find((plan) => plan.status !== PackageStatus.Draft);
  let subscriptionEndDowngradePlan = plans
    .filter((plan) => plan.pricingType === PricingType.Free)
    .find((plan) => plan.status !== PackageStatus.Draft);

  switch (productSettings.subscriptionStartSetup) {
    case SubscriptionStartSetup.TrialPeriod:
      subscriptionStartTrialPlan = plans.find((plan) => plan.refId === productSettings.subscriptionStartPlan?.refId);
      break;
    case SubscriptionStartSetup.FreePlan:
      subscriptionStartFreePlan = plans.find((plan) => plan.refId === productSettings.subscriptionStartPlan?.refId);
      break;
    default:
      break;
  }

  switch (productSettings.subscriptionEndSetup) {
    case SubscriptionEndSetup.DowngradeToFree:
      subscriptionEndDowngradePlan = plans.find((plan) => plan.refId === productSettings.downgradePlan?.refId);
      break;
    default:
      break;
  }

  return {
    subscriptionStartSetup: productSettings.subscriptionStartSetup,
    subscriptionStartTrialPlanId: subscriptionStartTrialPlan?.id,
    subscriptionStartFreePlanId: subscriptionStartFreePlan?.id,
    subscriptionEndSetup: productSettings.subscriptionEndSetup,
    subscriptionDowngradeBehavior: productSettings.prorateAtEndOfBillingPeriod
      ? SubscriptionDowngradeBehavior.EndOfBillingPeriod
      : SubscriptionDowngradeBehavior.Immediately,
    subscriptionCancellationTime: productSettings.subscriptionCancellationTime,
    downgradePlanId: subscriptionEndDowngradePlan?.id,
    defaultTrialConfig: subscriptionStartTrialPlan?.defaultTrialConfig,
    usageResetCutoffBehavior: usageResetConfiguration?.behavior || SubscriptionUpdateUsageCutoffBehavior.AlwaysReset,
    autoCancellationRules:
      autoCancellationRules?.map(({ sourcePlan, targetPlan }) => ({
        targetPlanId: targetPlan.refId,
        sourcePlanId: sourcePlan.refId,
        targetPlanDisplayName: targetPlan.displayName,
        sourcePlanDisplayName: sourcePlan.displayName,
      })) || [],
  };
}

export function prepareProductSettings(values: EditProductSettingsFormFields): EditProductSettingsFields {
  let subscriptionStartPlanId: string | null = null;
  let downgradePlanId: string | null = null;

  switch (values.subscriptionStartSetup) {
    case SubscriptionStartSetup.TrialPeriod:
      subscriptionStartPlanId = values.subscriptionStartTrialPlanId || null;
      break;
    case SubscriptionStartSetup.FreePlan:
      subscriptionStartPlanId = values.subscriptionStartFreePlanId || null;
      break;
    default:
      break;
  }

  if (values.subscriptionEndSetup === SubscriptionEndSetup.DowngradeToFree) {
    downgradePlanId = values.downgradePlanId || null;
  }

  return {
    subscriptionEndSetup: values.subscriptionEndSetup || SubscriptionEndSetup.CancelSubscription,
    subscriptionCancellationTime: values.subscriptionCancellationTime || SubscriptionCancellationTime.Immediate,
    downgradePlanId,
    subscriptionStartSetup: values.subscriptionStartSetup || SubscriptionStartSetup.PlanSelection,
    subscriptionStartPlanId,
    prorateAtEndOfBillingPeriod:
      values.subscriptionDowngradeBehavior === SubscriptionDowngradeBehavior.EndOfBillingPeriod,
  };
}

type CustomerJourneyProps = {
  product: ProductFragment;
  showAutoCancellationRules: boolean;
  isReadOnly: boolean;
};

export function CustomerJourney({
  product,
  showAutoCancellationRules,
  isReadOnly: externalIsReadOnly,
}: CustomerJourneyProps) {
  const dispatch = useAppDispatch();
  const { elementRef } = useScrollableAnchor({ anchor: 'customerJourney' });
  const { subscriptionUpdateUsageResetControl } = useFlags<FeatureFlags>();

  const activeExperiment = useFindActiveExperiment(product.refId);
  const isReadOnly = Boolean(activeExperiment) || externalIsReadOnly;
  const readOnlyTooltip: TooltipFields | undefined = useMemo(
    () =>
      activeExperiment?.refId
        ? { title: <CantEditCustomerJourneyActiveExperiment productRefId={activeExperiment.refId} /> }
        : undefined,
    [activeExperiment?.refId],
  );

  const onSubmit = useCallback(
    async (values: EditProductSettingsFormFields, actions: FormikHelpers<EditProductSettingsFormFields>) => {
      const productSettings = prepareProductSettings(values);
      const autoCancellationRules: ProductUpdateInput['autoCancellationRules'] | undefined =
        values.autoCancellationRules
          ?.filter(({ sourcePlanId, targetPlanId }) => sourcePlanId !== undefined && targetPlanId !== undefined)
          .map(({ sourcePlanId, targetPlanId }) => {
            return {
              targetPlanId: targetPlanId!,
              sourcePlanId: sourcePlanId!,
            };
          });

      await dispatch(
        updateProductAction({
          productId: product.id,
          updatePayload: {
            productSettings,
            autoCancellationRules,
            usageResetCutoffRule: { behavior: values.usageResetCutoffBehavior },
          },
          refId: product.refId,
          successMessage: t('products.customerJourney.successMessage'),
          failureMessage: t('products.customerJourney.failMessage'),
        }),
      );
      actions.setSubmitting(false);
    },
    [dispatch, product.id, product.refId],
  );

  const initialValues: EditProductSettingsFormFields = useMemo(
    () =>
      calculateInitialCustomerJourneyFormValues(
        product.plans,
        product.productSettings,
        product.autoCancellationRules,
        product.subscriptionUpdateUsageResetCutoffRule,
      ),
    [product],
  );

  const {
    values,
    touched,
    isValid,
    dirty,
    errors,
    resetForm,
    handleChange,
    handleBlur,
    submitForm,
    setFieldValue,
    setFieldTouched,
    validateForm,
    setValues,
    setTouched,
    getFieldMeta,
    getFieldProps,
  } = useFormik<EditProductSettingsFormFields>({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit,
  });

  const formRenderProps: FormRenderProps<EditProductSettingsFormFields> = {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
    resetForm,
    setTouched,
    setValues,
    getFieldMeta,
    getFieldProps,
  };

  const [ConfirmDialog, showConfirmDialog, confirmDialogProps] = useConfirmationDialog({
    title: t('products.customerJourney.confirmationDialog.title'),
    width: 750,
    content: <ConfirmationDialogContent />,
    confirmButtonColor: 'primary',
    confirmButtonText: t('sharedComponents.publishChanges'),
    handleConfirm: submitForm,
  });

  if (isEmpty(product.plans)) {
    return null;
  }

  return (
    <PageCard ref={elementRef}>
      <GridFlex.Row alignItems="start" mb={4}>
        <GridFlex.Column item flex={1}>
          <Text.H3 mb={2}>{t('products.customerJourney.title')}</Text.H3>
          <Box>
            <Text.Sub2 mr={2} display="inline">
              {t('products.customerJourney.subtitle')}
            </Text.Sub2>
            <ExternalLink label={t('sharedComponents.learnMore')} url={t('products.customerJourney.learnMoreUrl')} />
          </Box>
        </GridFlex.Column>
        <FadeBox className={!dirty ? 'hidden' : ''}>
          <GridFlex.Row>
            <Button onClick={() => resetForm()} sx={{ mr: 3 }} $outlined color="primary">
              {t('products.cancelButton')}
            </Button>
            <Button variant="contained" color="primary" disabled={!isValid} onClick={() => showConfirmDialog(true)}>
              {t('sharedComponents.publishChanges')}
            </Button>
          </GridFlex.Row>
        </FadeBox>
      </GridFlex.Row>

      <GridFlex.Column>
        <GridFlex.Item mt={3}>
          <CustomerJourneySectionTitle icon="JourneyStart" iconType="custom" iconSize={24} title="Journey start">
            <SubscriptionStartSetupConfiguration
              // @ts-ignore FIX-ME
              formRenderProps={formRenderProps}
              productPlans={product.plans}
              productId={product.id}
              readonly={isReadOnly}
              readonlyTooltip={readOnlyTooltip}
            />
          </CustomerJourneySectionTitle>
        </GridFlex.Item>

        <GridFlex.Item mt={3}>
          <CustomerJourneySectionTitle icon="RefreshIcon" iconType="custom" iconSize={24} title="Subscription update">
            <SubscriptionUpdateSetupConfiguration
              // @ts-ignore FIX-ME
              formRenderProps={formRenderProps}
              readonly={isReadOnly}
              readonlyTooltip={readOnlyTooltip}
            />
            {subscriptionUpdateUsageResetControl && (
              <UsageResetCutoffConfiguration
                // @ts-ignore FIX-ME
                formRenderProps={formRenderProps}
                readonly={isReadOnly}
                readonlyTooltip={readOnlyTooltip}
              />
            )}
          </CustomerJourneySectionTitle>
        </GridFlex.Item>

        <GridFlex.Item mt={3}>
          <CustomerJourneySectionTitle icon="JourneyEnd" iconType="custom" iconSize={24} title="Journey end" lastChild>
            <SubscriptionCancellationTimeConfiguration
              // @ts-ignore FIX-ME
              formRenderProps={formRenderProps}
              readonly={isReadOnly}
              readonlyTooltip={readOnlyTooltip}
            />
            <SubscriptionEndSetupConfiguration
              // @ts-ignore FIX-ME
              formRenderProps={formRenderProps}
              productPlans={product.plans}
              productId={product.id}
              readonly={isReadOnly}
              readonlyTooltip={readOnlyTooltip}
            />
            {showAutoCancellationRules && (
              // @ts-ignore FIX-ME
              <SubscriptionAutoCancellationConfiguration formRenderProps={formRenderProps} product={product} />
            )}
          </CustomerJourneySectionTitle>
        </GridFlex.Item>
      </GridFlex.Column>

      <ConfirmDialog {...confirmDialogProps} />
    </PageCard>
  );
}
