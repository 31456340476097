import { useState } from 'react';
import { t } from 'i18next';
import { GridFlex, Text, PageCardWithEvenPadding, Switch, ConfirmationDialog } from '@stigg-components';
import { IntegrationFragment, StripeCredentialsFragment, VendorIdentifier } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../redux/store';
import { updateIntegrationAction } from '../../integrationsSlice';

export function StripeFooterTax({ integration }: { integration: IntegrationFragment }) {
  const dispatch = useAppDispatch();
  const credentials = integration?.credentials as StripeCredentialsFragment;
  const [isTaxDialogOpen, setIsTaxDialogOpen] = useState(false);

  const handleTaxChange = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      await dispatch(
        updateIntegrationAction({
          integrationId: integration!.id,
          integrationData: {
            vendorIdentifier: VendorIdentifier.Stripe,
            stripeCredentials: {
              isTaxEnabled: !credentials!.isTaxEnabled,
            },
          },
        }),
      );
    }
    setIsTaxDialogOpen(false);
  };

  return (
    <GridFlex.Column mt={6}>
      <Text.B1 color="primary" $bold>
        {t('integrations.settings')}
      </Text.B1>
      <PageCardWithEvenPadding cardProps={{ marginTop: 4, marginBottom: 6 }}>
        <GridFlex.RowCenter justifyContent="space-between">
          <Text.H6>{t('integrations.stripe.textCollectTax')}</Text.H6>
          <Switch onChange={() => setIsTaxDialogOpen(true)} checked={!!credentials?.isTaxEnabled} />
        </GridFlex.RowCenter>
      </PageCardWithEvenPadding>

      <ConfirmationDialog
        open={isTaxDialogOpen}
        handleClose={handleTaxChange}
        confirmButtonColor={credentials?.isTaxEnabled ? 'error' : 'primary'}
        confirmationButtonText={
          credentials?.isTaxEnabled ? t('integrations.stripe.disable') : t('integrations.stripe.enable')
        }
        title={
          credentials?.isTaxEnabled
            ? t('integrations.stripe.taxDialogDisableTitle')
            : t('integrations.stripe.taxDialogEnableTitle')
        }
        content={
          credentials?.isTaxEnabled
            ? t('integrations.stripe.taxDialogDisableContent')
            : t('integrations.stripe.taxDialogEnableContent')
        }
      />
    </GridFlex.Column>
  );
}
