import { t } from 'i18next';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { WizardAccordion, PageCard, Text } from '@stigg-components';
import { IntegrationFragment, SnowflakeCredentialsInput, VendorIdentifier } from '@stigg-types/apiTypes';
import { useAppDispatch } from '../../../../redux/store';
import { useSnowflakeIntegrationWizardSteps } from './useSnowflakeIntegrationWizardSteps';
import { createIntegrationAction } from '../../integrationsSlice';
import { IntegrationContactUsState } from '../IntegrationContactUsState';
import { SETUP_SQL_VARS } from './constants';
import { useBooleanEntitlement } from '../../../../doggo/hooks/useBooleanEntitlement';
import { StiggFeature } from '../../../../doggo/StiggFeature';

type SnowflakeIntegrationPageProps = {
  integration: IntegrationFragment | null;
  headerComponent: React.ReactNode;
  isReadOnly: boolean;
};

const StyledPageCard = styled(PageCard)`
  border-color: ${({ theme }) => theme.itamar.palette.other.outlineBorder};
`;

export function SnowflakeIntegrationPage({ integration, headerComponent, isReadOnly }: SnowflakeIntegrationPageProps) {
  const dispatch = useAppDispatch();
  const hasIntegration = !!integration?.credentials;
  const [isLoading, setIsLoading] = useState(false);
  const entitlement = useBooleanEntitlement(StiggFeature.SNOWFLAKE_INTEGRATION);

  const credentials: SnowflakeCredentialsInput = {
    host: '',
    username: SETUP_SQL_VARS.USER,
    database: SETUP_SQL_VARS.DATABASE,
    warehouse: SETUP_SQL_VARS.WAREHOUSE,
    role: SETUP_SQL_VARS.ROLE,
    schemaName: SETUP_SQL_VARS.SCHEMA,
    ...(integration ? { ...integration.credentials, password: '********' } : { password: '' }),
  };

  const steps = useSnowflakeIntegrationWizardSteps({
    hasIntegration,
    credentials,
    isLoading,
    onAddIntegration: async (credentials) => {
      setIsLoading(true);

      try {
        await dispatch(
          createIntegrationAction({
            vendorIdentifier: VendorIdentifier.Snowflake,
            snowflakeCredentials: credentials,
          }),
        ).unwrap();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        setIsLoading(false);
      }
    },
  });

  if (!hasIntegration && !entitlement.hasAccess) {
    return <IntegrationContactUsState headerComponent={headerComponent} integrationType={VendorIdentifier.Snowflake} />;
  }

  return (
    <>
      <StyledPageCard cardProps={{ p: 4, marginBottom: 6 }}>{headerComponent}</StyledPageCard>

      {isReadOnly && !hasIntegration ? null : (
        <>
          <Text.H6 mb={4}>{t('integrations.snowflake.integrateWithStiggTitle')}</Text.H6>

          <WizardAccordion
            layoutProps={{ maxWidth: '100%' }}
            uniqueFlowId="snowflake-connect-stigg"
            steps={steps}
            isStepByStep={!hasIntegration}
          />
        </>
      )}
    </>
  );
}
