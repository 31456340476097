import { t } from 'i18next';
import toLower from 'lodash/toLower';
import { Trans } from 'react-i18next';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Autocomplete, Button, Divider, Flex, Icon, ListItem, Paper, Text, TextField } from '@stigg-components';
import { EnvironmentType } from '@stigg-types/apiTypes';
import { useState } from 'react';
import { EnvironmentSelectItem, ENVIRONMENT_SELECT_ITEM_HEIGHT } from '../environments/EnvironmentSelectItem';

export const ENVIRONMENT_SELECT_DROPDOWN_HEIGHT = 280;
export const ENVIRONMENT_SELECT_WIDTH = 360;

export interface EnvironmentOptionType {
  // Slug does not exist when selecting a "new" environment
  slug?: string;
  displayName: string;
  type?: EnvironmentType;
  missingWritePermission?: boolean;
}

interface EnvironmentsAutocompleteSearchProps {
  envOptions: EnvironmentOptionType[];
  selectedEnvironment: EnvironmentOptionType | null;
  sourceEnvironmentName: string;
  setSelectedEnvironment: (option: EnvironmentOptionType | null) => void;
  allowCreatingNewEnvironment?: boolean;
}

const filter = createFilterOptions<EnvironmentOptionType>();

const isSameEnvironmentName = (a: string, b: string) => toLower(a) === toLower(b);

export const EnvironmentsAutocompleteSearch = ({
  envOptions,
  selectedEnvironment,
  sourceEnvironmentName,
  setSelectedEnvironment,
  allowCreatingNewEnvironment,
}: EnvironmentsAutocompleteSearchProps) => {
  const [search, setSearch] = useState('');

  const environmentAlreadyExists = (inputValue: string): boolean =>
    isSameEnvironmentName(inputValue, sourceEnvironmentName) ||
    !!envOptions.find((envOption) => isSameEnvironmentName(envOption.displayName, inputValue));

  return (
    <Autocomplete
      value={selectedEnvironment}
      onChange={(_, newValue) => {
        setSelectedEnvironment(newValue);
      }}
      filterOptions={filter}
      ListboxProps={{ style: { padding: 0, maxHeight: ENVIRONMENT_SELECT_DROPDOWN_HEIGHT } }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={envOptions}
      sx={{ width: ENVIRONMENT_SELECT_WIDTH }}
      onInputChange={(_, value) => setSearch(value || '')}
      getOptionLabel={(option) =>
        option.slug ? option.displayName : t('accounts.copyMergeEnvironments.newEnvironment')
      }
      getOptionDisabled={(option) => !!option.missingWritePermission}
      PaperComponent={(paperProps) => {
        const { children, ...restPaperProps } = paperProps;
        const envNameAlreadyExists = environmentAlreadyExists(search);
        const textColor = envNameAlreadyExists ? 'disabled' : 'primary.main';

        return (
          <Paper {...restPaperProps} sx={{ boxShadow: (theme) => theme.itamar.palette.shadow.lightShadow, mt: 2 }}>
            {children}

            {allowCreatingNewEnvironment ? (
              <>
                <Divider />
                <Button
                  onMouseDown={() => {
                    if (!envNameAlreadyExists) {
                      setSelectedEnvironment({
                        displayName: !search ? '' : search,
                      });
                    }
                  }}
                  variant="text"
                  fullWidth
                  sx={{
                    height: ENVIRONMENT_SELECT_ITEM_HEIGHT,
                    justifyContent: 'flex-start',
                    borderRadius: 0,
                    cursor: envNameAlreadyExists ? 'default' : 'pointer',
                  }}>
                  <Flex.RowCenter gap={2}>
                    {!envNameAlreadyExists && <Icon icon="Plus" color="primary.main" />}
                    <Text.B2 color={textColor}>
                      <Trans
                        i18nKey={
                          envNameAlreadyExists
                            ? 'accounts.copyMergeEnvironments.sidebar.newEnvExistsText'
                            : 'accounts.copyMergeEnvironments.sidebar.selectNewEnvText'
                        }
                        values={{ environmentName: search }}
                        components={{ strong: <Text.B2 color={textColor} display="inline" $bold /> }}
                      />
                    </Text.B2>
                  </Flex.RowCenter>
                </Button>
              </>
            ) : null}
          </Paper>
        );
      }}
      renderOption={(props, option, state) => {
        const { inputValue } = state;
        const envNameAlreadyExists = environmentAlreadyExists(inputValue);
        const isDisabled = !!option.missingWritePermission;

        return (
          <ListItem
            {...props}
            sx={{ padding: 0 }}
            aria-disabled={
              isDisabled ||
              (isSameEnvironmentName(option.displayName, state.inputValue) && !option.slug && envNameAlreadyExists)
            }>
            {option.slug ? (
              <EnvironmentSelectItem
                displayName={option.displayName}
                type={option.type}
                missingWritePermission={option.missingWritePermission}
              />
            ) : null}
          </ListItem>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={false}
            touched={false}
            placeholder={t('accounts.copyMergeEnvironments.sidebar.selectPlaceholder')}
            textFieldProps={params}
            InputProps={params.InputProps}
          />
        );
      }}
    />
  );
};
