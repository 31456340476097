import { gql } from '@apollo/client';
import { FetchAddonSyncStatesQuery, FetchAddonSyncStatesQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../../ApolloClient';
import { SYNC_STATE_FRAGMENT } from '../../plans/queries/syncStateFragment';

const QUERY = gql`
  query FetchAddonSyncStates($filter: AddonFilter!) {
    addons(filter: $filter) {
      edges {
        node {
          id
          prices {
            id
            crmId
            crmLinkUrl
          }
          billingId
          billingLinkUrl
          syncStates {
            ...SyncStateFragment
          }
        }
      }
    }
  }
  ${SYNC_STATE_FRAGMENT}
`;

export function watchAddonSyncStates(addonId: string, pollInterval: number) {
  return apolloClient.watchQuery<FetchAddonSyncStatesQuery, FetchAddonSyncStatesQueryVariables>({
    query: QUERY,
    fetchPolicy: 'no-cache',
    pollInterval,
    variables: {
      filter: {
        id: {
          eq: addonId,
        },
      },
    },
  });
}
