import { AsyncThunk } from '@reduxjs/toolkit';
import { PageInfo } from '@stigg-types/apiTypes';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../modules/common/pagination';
import { useRef } from 'react';

export type Paging = {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
};

type UseTablePaginationProps = {
  dispatch: any;
  pageNum: number;
  pageInfo: PageInfo;
  setPageNum: (pageNum: number) => void;
  fetchAction: AsyncThunk<any, any, any>;
  dispatchArgs: any;
  pageSize?: number;
};

// page can change up or down and both triggers this func.
// nextPage > pageNum => paging up than we will give it an "after" prop otherwise a before gl prop
function getPagingParams(nextPage: number, pageNum: number, pageInfo: PageInfo, pageSize: number): Paging {
  return nextPage > pageNum
    ? {
        after: pageInfo.endCursor,
        first: pageSize,
      }
    : { before: pageInfo.startCursor, last: pageSize };
}

export function useTablePagination({
  dispatch,
  pageNum,
  pageInfo,
  setPageNum,
  fetchAction,
  dispatchArgs,
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
}: UseTablePaginationProps) {
  const previousPagePaginationParamsRef = useRef<Paging | null>(null);

  const pageChangeFunc = (_: any, nextPage: number) => {
    const pagingParams = getPagingParams(nextPage, pageNum, pageInfo, pageSize);
    previousPagePaginationParamsRef.current = pagingParams;

    dispatch(fetchAction({ paging: { ...pagingParams }, ...dispatchArgs }));
    setPageNum(nextPage);
  };

  const refreshPageFunc = () => {
    const pagingParams = previousPagePaginationParamsRef.current;
    dispatch(
      fetchAction({
        ...(pagingParams ? { paging: { ...pagingParams } } : {}),
        ...dispatchArgs,
      }),
    );
  };

  return { pageChangeFunc, refreshPageFunc };
}

const createPageChangeFunc = (
  dispatch: any,
  pageNum: number,
  pageInfo: PageInfo,
  setPageNum: (pageNum: number) => void,
  fetchAction: AsyncThunk<any, any, any>,
  dispatchArgs: any = {},
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
) => {
  return (_: any, nextPage: number) => {
    const pagingParams = getPagingParams(nextPage, pageNum, pageInfo, pageSize);
    dispatch(fetchAction({ paging: { ...pagingParams }, ...dispatchArgs }));
    setPageNum(nextPage);
  };
};

export default createPageChangeFunc;
