import { gql } from '@apollo/client';
import { t } from 'i18next';
import { IntegrationFilter, FetchIntegrationsQuery, FetchIntegrationsQueryVariables } from '@stigg-types/apiTypes';
import { apolloClient } from '../../../ApolloClient';
import { executeOperationSafely } from '../../common/executeOperationSafely';
import { AppDispatch } from '../../../redux/store';

const FETCH_INTEGRATIONS = gql`
  query FetchIntegrations($paging: CursorPaging, $sorting: [IntegrationSort!], $filter: IntegrationFilter!) {
    integrations(paging: $paging, sorting: $sorting, filter: $filter) {
      totalCount
      edges {
        node {
          ...IntegrationListItemFragment
        }
      }
    }
  }

  fragment IntegrationListItemFragment on Integration {
    id
    vendorIdentifier
    vendorType
    isDefault
    integrationId
    credentials {
      ... on SalesforceCredentials {
        domain
      }
      ... on StripeCredentials {
        accountId
        isTestMode
        accountDisplayName
      }
      ... on ZuoraCredentials {
        baseUrl
      }
      ... on HubspotCredentials {
        hubDomain
      }
      ... on Auth0Credentials {
        clientDomain
        clientId
        clientSecret
        applicationId
        applicationType
        applicationName
        individualInitialPlanId
        individualSubscriptionStartSetup
        organizationInitialPlanId
        organizationSubscriptionStartSetup
      }
    }
  }
`;

export type FetchIntegrationsProps = {
  environmentId: string;
};

async function fetchIntegrations(props: FetchIntegrationsProps, { dispatch }: { dispatch: AppDispatch }) {
  return executeOperationSafely(
    async () => {
      const filter: IntegrationFilter = {
        environmentId: { eq: props.environmentId },
      };
      const response = await apolloClient.query<FetchIntegrationsQuery, FetchIntegrationsQueryVariables>({
        query: FETCH_INTEGRATIONS,
        fetchPolicy: 'network-only',
        variables: {
          filter,
        },
      });
      return response.data.integrations.edges.map((edge) => edge.node);
    },
    {
      failureMessageHandler: () => t('integrations.api.failFetch'),
    },
    dispatch,
  );
}

export { fetchIntegrations };
