import { t } from 'i18next';
import { useState } from 'react';
import { SubscriptionScheduleStatus } from '@stigg-types/apiTypes';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import { useConfirmationDialog } from '@stigg-common';
import { Text, Alert } from '@stigg-components';
import { useCancelFutureUpdatesDialog } from './useCancelFutureUpdatesDialog';
import { markInvoiceAsPaidAction } from '../../../customersSlice';
import { useAppDispatch } from '../../../../../redux/store';

export function useSubscriptionPageDialogs({ subscription }) {
  const dispatch = useAppDispatch();
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = useState(false);
  const [editSubscriptionDialogOpen, setEditSubscriptionDialogOpen] = useState(false);
  const [endTrialSubscriptionDialogOpen, setEndTrialSubscriptionDialogOpen] = useState(false);
  const [cancelFutureUpdatesStatus, setCancelFutureUpdatesStatus] = useState<ActiveFutureUpdateStatus>(
    SubscriptionScheduleStatus.Scheduled,
  );

  const [CancelScheduledUpdatesDialog, showCancelScheduledUpdatesDialog, CancelScheduledUpdatesDialogProps] =
    useCancelFutureUpdatesDialog({ subscription, futureUpdateStatus: cancelFutureUpdatesStatus });

  const [MarkInvoiceAsPaidDialog, setMarkInvoiceAsPaidDialogOpen, markInvoiceAsPaidDialogProps] = useConfirmationDialog(
    {
      title: t('subscriptions.markInvoiceAsPaidDialogTitle'),
      width: 720,
      content: (
        <>
          <Text.B2>{t('subscriptions.markInvoiceAsPaidDialogContent1')}</Text.B2>
          <Alert sx={{ alignItems: 'center', mt: 6 }} severity="warning">
            <Text.B2 color="warning">{t('subscriptions.markInvoiceAsPaidDialogContent2')}</Text.B2>
          </Alert>
        </>
      ),
      handleConfirm: async () => {
        await dispatch(markInvoiceAsPaidAction({ subscriptionId: subscription.refId }));
      },
      confirmButtonText: t('subscriptions.markInvoiceAsPaidDialogConfirm'),
      confirmButtonColor: 'primary',
    },
  );

  return {
    cancelSubscriptionDialogOpen,
    setCancelSubscriptionDialogOpen,
    editSubscriptionDialogOpen,
    setEditSubscriptionDialogOpen,
    setCancelFutureUpdatesStatus,
    endTrialSubscriptionDialogOpen,
    setEndTrialSubscriptionDialogOpen,
    CancelScheduledUpdatesDialog,
    CancelScheduledUpdatesDialogProps,
    showCancelScheduledUpdatesDialog,
    MarkInvoiceAsPaidDialog,
    setMarkInvoiceAsPaidDialogOpen,
    markInvoiceAsPaidDialogProps,
  };
}
