import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ImportIntegrationTaskResultFragment } from '@stigg-types/apiTypes';
import { usePollImportIntegrationTask } from '../../../../../modules/integrations/components/stripe/hooks/usePollImportIntegrationTask';
import { RootState, useAppDispatch } from '../../../../../redux/store';
import { removeImportTaskPollingId } from '../../../../../modules/application/applicationSlice';
import { fetchImportIntegrationTasksAction } from '../../../../../modules/integrations/integrationsSlice';
import { isTaskFinished } from './taskUtils';

export function useCurrentImportTask() {
  const dispatch = useAppDispatch();
  const importPollingTaskId = useSelector((state: RootState) => state.application.importPollingTaskId);
  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const [importTask, setImportTask] = useState<ImportIntegrationTaskResultFragment | null>(null);
  const onData = useCallback(
    (importTask: ImportIntegrationTaskResultFragment) => {
      setImportTask(importTask);
      if (isTaskFinished(importTask.status)) {
        dispatch(removeImportTaskPollingId());
      }
    },
    [dispatch, setImportTask],
  );
  const onError = useCallback(() => {
    dispatch(removeImportTaskPollingId());
  }, [dispatch]);

  useEffect(() => {
    if (environmentId) {
      void dispatch(fetchImportIntegrationTasksAction({ inProgressTasks: true, environmentId }));
    }
  }, [dispatch, environmentId]);

  usePollImportIntegrationTask(importPollingTaskId, onData, onError);

  return importTask;
}
