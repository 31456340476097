import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { GridFlex, Text, LinearProgress } from '@stigg-components';
import { useTheme } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import * as S from './StripeImport.style';
import { StripeImportWizard } from './StripeImportWizard';
import { ImportStripeStatus } from './ImportStripeStatus';
import ImportStripeIconLight from '@assets/images/integrations/import-from-stripe.light.png';
import ImportStripeIconDark from '@assets/images/integrations/import-from-stripe.dark.png';
import { IntegrationFragment, TaskStatus } from '@stigg-types/apiTypes';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { usePollImportIntegrationTask } from './hooks/usePollImportIntegrationTask';
import { isTaskFinished, isTaskInProgress } from '../../../../components/layout/navbar/components/tasks/taskUtils';
import { fetchImportIntegrationTasksAction } from '../../integrationsSlice';
import { Dialog } from '../../../../components/Dialog';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';

export function StripeFooterActions({ integration }: { integration: IntegrationFragment }) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { showImportFromStripe } = useFlags<FeatureFlags>();
  const [taskProgress, setTaskProgress] = useState(0);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  const environmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const { importTasks } = useSelector((state: RootState) => state.integrations);
  const inProgressTask = importTasks.find((task) => isTaskInProgress(task.status));
  usePollImportIntegrationTask(inProgressTask ? inProgressTask.id : null, (task) => {
    setTaskProgress(task.progress);
    if (environmentId && isTaskFinished(task.status)) {
      void dispatch(fetchImportIntegrationTasksAction({ environmentId }));
    }
  });

  const finishedTasks = importTasks.filter((task) => !isTaskInProgress(task.status));

  useEffect(() => {
    if (environmentId && integration && !isImportDialogOpen) {
      void dispatch(fetchImportIntegrationTasksAction({ environmentId }));
    }
  }, [dispatch, integration, isImportDialogOpen, environmentId]);

  const openImportDialog = () => {
    if (inProgressTask) {
      return;
    }
    setIsImportDialogOpen(true);
  };

  const closeImportDialog = () => {
    setIsImportDialogOpen(false);
  };

  if (!showImportFromStripe) {
    return null;
  }

  return (
    <GridFlex.Column>
      <Text.B1 color="primary" $bold>
        {t('integrations.actions')}
      </Text.B1>
      <S.ImportContainer container alignItems="center" onClick={openImportDialog} $inProgressTask={!!inProgressTask}>
        <S.ImportStripeImage
          $originalSize={!theme.isLightTheme}
          src={theme.isLightTheme ? ImportStripeIconLight : ImportStripeIconDark}
          alt="import-stripe"
        />

        <GridFlex.Column justifyContent="center" ml={4} flex={1} $fullWidth sx={{ height: '100%' }}>
          <Text.H6 mb={2}>{t('integrations.importData')}</Text.H6>
          <Text.B2 color="secondary">
            <Trans i18nKey="integrations.stripeImportProductsDescription" />
          </Text.B2>
          {inProgressTask && (
            <GridFlex.RowCenter mt={2} justifyContent="space-between">
              <Text.B2 color="secondary">Import in progress</Text.B2>
              <GridFlex.RowCenter>
                <LinearProgress
                  variant="determinate"
                  sx={{ width: 300 }}
                  value={inProgressTask.status === TaskStatus.Pending ? 0 : taskProgress}
                />
                <Text.B2 ml={2}>{taskProgress}%</Text.B2>
              </GridFlex.RowCenter>
            </GridFlex.RowCenter>
          )}
        </GridFlex.Column>
      </S.ImportContainer>
      {finishedTasks.length > 0 ? (
        <>
          <Text.H6 mt={6} mb={4}>
            {t('integrations.recentStripeImports')}
          </Text.H6>
          <GridFlex.Column sx={{ maxHeight: 300, overflow: 'auto' }}>
            {finishedTasks.map((task) => (
              <ImportStripeStatus key={task.id} task={task} />
            ))}
          </GridFlex.Column>
        </>
      ) : null}

      <Dialog
        fullScreen
        isOpen={isImportDialogOpen}
        onCancel={closeImportDialog}
        content={<StripeImportWizard integration={integration} closeImportDialog={closeImportDialog} />}
        padding={0}
      />
    </GridFlex.Column>
  );
}
