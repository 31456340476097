import { useEffect, useRef, createContext, useContext, useState, Dispatch, SetStateAction, ReactNode } from 'react';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';

export type BreadCrumbMenuItem = {
  to: string;
  refId: string;
  displayName: string;
};

export type Breadcrumb = {
  to?: string;
  title: string | ReactNode;

  // Inline Select
  menuItems?: BreadCrumbMenuItem[];
  onAddEntityClick?: () => void;
  onAddEntityText?: string;
};

export type BreadcrumbsContextType = {
  breadcrumbsState: Breadcrumb[];
  setBreadcrumbsState: Dispatch<SetStateAction<Breadcrumb[]>>;
};

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
  breadcrumbsState: [],
  setBreadcrumbsState: () => {},
});

export const useBreadcrumbsContext = () => useContext(BreadcrumbsContext);

export function BreadcrumbsProvider({ children }) {
  const [breadcrumbsState, setBreadcrumbsState] = useState<Breadcrumb[]>([]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbsState, setBreadcrumbsState }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}

export function useBreadcrumbs(...breadcrumbs: Array<Breadcrumb | null>) {
  const { setBreadcrumbsState } = useBreadcrumbsContext();
  const prevValue = useRef<any>(null);

  if (!isEqual(breadcrumbs, prevValue.current)) {
    prevValue.current = breadcrumbs;
  }

  useEffect(() => {
    setBreadcrumbsState(compact(breadcrumbs));

    return () => {
      setBreadcrumbsState([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue.current]);
}
