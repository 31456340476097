import { Box, Grid, ExternalLink, GridFlex, InformationTooltip, MenuItem, Select, Text } from '@stigg-components';
import { t } from 'i18next';

import { styled } from '@stigg-theme';
import { AlertTriangle } from 'react-feather';

import CycleFull from '@assets/icons/CycleFull.svg?react';
import CycleHalf from '@assets/icons/CycleHalf.svg?react';
import { useCallback, useState } from 'react';

enum ResetPeriodFilterOptions {
  LAST_COMPLETED_PERIOD = 'LAST_COMPLETED_PERIOD',
  CURRENT_PERIOD = 'CURRENT_PERIOD',
}

const TOOLTIP_MAX_WIDTH = 250;

export const CycleFullIcon = styled(CycleFull)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;
export const CycleHalfIcon = styled(CycleHalf)`
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;
export const WarningIcon = styled(AlertTriangle)`
  color: ${({ theme }) => theme.itamar.palette.warning.main};
  height: 22px;
  width: 22px;
`;

const IncompletePeriodTooltipContent = () => (
  <GridFlex.RowCenter alignItems="flex-start">
    <Grid item mr={3}>
      <WarningIcon />
    </Grid>
    <GridFlex.Column>
      <Text.B2 mb={1}>{t('dashboards.featureInspector.incompletePeriodTooltipText')}</Text.B2>
      <ExternalLink
        label={t('sharedComponents.learnMore')}
        url={t('dashboards.featureInspector.periodSelectCurrentLearnMoreUrl')}
      />
    </GridFlex.Column>
  </GridFlex.RowCenter>
);

export function ResetPeriodFilter({
  showUncompletedPeriod,
  onChange,
}: {
  showUncompletedPeriod: boolean;
  onChange: (showUncompletedPeriod: boolean) => void;
}) {
  const [isSelectHover, setIsSelectHover] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isTooltipHover, setIsTooltipHover] = useState(false);

  const onCloseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setIsSelectHover(false);
  }, []);

  return (
    <InformationTooltip
      $maxWidth={TOOLTIP_MAX_WIDTH}
      arrow
      placement="top"
      title={<IncompletePeriodTooltipContent />}
      open={showUncompletedPeriod && (isSelectHover || isTooltipHover) && !isSelectOpen}
      PopperProps={{
        onMouseEnter: () => setIsTooltipHover(true),
        onMouseLeave: () => setIsTooltipHover(false),
      }}>
      <Box
        maxWidth={265}
        mr={4}
        onMouseEnter={() => setIsSelectHover(true)}
        onMouseLeave={() => setIsSelectHover(false)}>
        <Select
          value={
            showUncompletedPeriod
              ? ResetPeriodFilterOptions.CURRENT_PERIOD
              : ResetPeriodFilterOptions.LAST_COMPLETED_PERIOD
          }
          onOpen={() => setIsSelectOpen(true)}
          onSelect={onCloseSelect}
          onClose={onCloseSelect}
          onChange={(e: any) => onChange(e.target.value === ResetPeriodFilterOptions.CURRENT_PERIOD)}
          renderValue={() => (
            <GridFlex.RowCenter alignItems="center">
              {showUncompletedPeriod ? <CycleHalfIcon /> : <CycleFullIcon />}
              <Text.B2 color="primary" ml={2} mr={2}>
                {showUncompletedPeriod
                  ? t('dashboards.featureInspector.showUncompletedPeriodSelectOption')
                  : t('dashboards.featureInspector.showCompletedPeriodSelectOption')}
              </Text.B2>
              {showUncompletedPeriod ? <WarningIcon /> : null}
            </GridFlex.RowCenter>
          )}>
          <MenuItem value={ResetPeriodFilterOptions.LAST_COMPLETED_PERIOD}>
            <GridFlex.RowCenter alignItems="center">
              <CycleFullIcon />
              <Text.B2 ml={2} mr={2}>
                {t('dashboards.featureInspector.showCompletedPeriodSelectOption')}
              </Text.B2>
            </GridFlex.RowCenter>
          </MenuItem>
          <MenuItem value={ResetPeriodFilterOptions.CURRENT_PERIOD}>
            <InformationTooltip
              $maxWidth={TOOLTIP_MAX_WIDTH}
              arrow
              placement="left"
              title={<IncompletePeriodTooltipContent />}>
              <GridFlex.RowCenter $fullWidth alignItems="center" justifyContent="space-between">
                <GridFlex.RowCenter alignItems="center">
                  <CycleHalfIcon />
                  <Text.B2 ml={2} mr={2}>
                    {t('dashboards.featureInspector.showUncompletedPeriodSelectOption')}
                  </Text.B2>
                </GridFlex.RowCenter>
                <WarningIcon />
              </GridFlex.RowCenter>
            </InformationTooltip>
          </MenuItem>
        </Select>
      </Box>
    </InformationTooltip>
  );
}
