import { t } from 'i18next';
import { Plan, SubscriptionDataFragment, SubscriptionEndSetup, TrialEndBehavior } from '@stigg-types/apiTypes';
import { Alert, Icon, Link } from '@stigg-components';
import { getDateRemainingDays } from '@stigg-common';
import React from 'react';
import { Trans } from 'react-i18next';
import { Navigation, useNavigation } from '../../../../../../navigation/useNavigation';

export const getBeforeTrialEndText = (subscription: SubscriptionDataFragment | null): string => {
  const { remainingDays, isFewMinutesRemaining } = getDateRemainingDays(subscription?.trialEndDate);
  const prefix = isFewMinutesRemaining
    ? t('subscriptions.trialBanner.minutesRemaining')
    : t('subscriptions.trialBanner.daysRemaining', { count: remainingDays });

  return t('subscriptions.trialBanner.prefix', {
    daysRemaining: prefix,
  });
};

export const getAfterTrialEndText = (
  navigation: Navigation,
  trialEndBehavior?: TrialEndBehavior | null,
  subscriptionEndSetup?: SubscriptionEndSetup | null,
  downgradePlan?: Partial<Plan> | null,
): any => {
  if (trialEndBehavior === TrialEndBehavior.ConvertToPaid) {
    return t('subscriptions.trialBanner.convertToPaidSuffix');
  }

  switch (subscriptionEndSetup) {
    case SubscriptionEndSetup.DowngradeToFree:
      return (
        <Trans
          i18nKey="subscriptions.trialBanner.downgradeToFreeSuffix"
          t={t}
          values={{ planName: downgradePlan?.displayName }}
          components={[
            <Link onClick={() => navigation.navigateTo(navigation.appRoutes.planPage(downgradePlan?.refId || ''))} />,
          ]}
        />
      );

    case SubscriptionEndSetup.CancelSubscription:
      return t('subscriptions.trialBanner.cancelSubscriptionSuffix');
    default:
      return '';
  }
};

export function InTrialStatus({ subscription }: { subscription: SubscriptionDataFragment }) {
  const navigation = useNavigation();

  return (
    <Alert severity="info" icon={<Icon type="custom" icon="HourglassEmpty" color="default" overrideStroke />}>
      {getBeforeTrialEndText(subscription)}{' '}
      {getAfterTrialEndText(
        navigation,
        subscription.trialConfiguration?.trialEndBehavior,
        subscription.plan.product.productSettings.subscriptionEndSetup,
        subscription.plan.product.productSettings.downgradePlan,
      )}
    </Alert>
  );
}
