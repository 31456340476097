import { PageCard } from '../PageCard';
import styled from 'styled-components';

// TODO: consider making this the default behavior for PageCard
export const PageCardWithEvenPadding = styled(PageCard)`
  /* Target the MUI Card component */
  & > div {
    padding: 16px;
  }

  /* Remove the extra bottom padding from CardContent */
  & > div:last-child {
    padding-bottom: 16px;
  }
`;
