import { useTheme } from '@mui/material/styles';
import {
  CustomDrawer,
  Divider,
  Grid,
  GridFlex,
  PageHeader,
  ConfirmationDialog,
  useHideIntercom,
} from '@stigg-components';
import { useEnvironmentPermissionCheck, EnvironmentPermissionActions } from '@stigg-permissions';
import { Feature, FeatureFragment } from '@stigg-types/apiTypes';
import { useModal, useQueryParam } from '@stigg-common';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { Server } from 'react-feather';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@stigg-types/featureFlags';
import { EmptyList } from '../../../components/EmptyList';
import Search from '../../../components/Search';
import createPageChangeFunc from '../../../components/table/gqlTableHelper';
import Table from '../../../components/table/Table';
import { RootState, useAppDispatch } from '../../../redux/store';
import { appRoutes, useNavigation } from '../../navigation/useNavigation';
import {
  resetSelectedFeature,
  deleteFeatureByIdAction,
  fetchFeatureByRefIdAction,
  fetchFeaturesAction,
} from '../featuresSlice';
import { FeatureDetailsTitle, SideNavFeatureDetails } from './featureDetails/SidenavFeatureDetails';
import { SideNavCreateFeature } from './createFeature/SidenavCreateFeature';
import { headCells } from './headCells';
import { ArchiveFeature } from './createFeature/ArchiveFeature';

function Features() {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const [pageNum, setPageNum] = useState(0);
  const [search, setSearch] = useState('');
  const { pageInfo, totalCount, edges: features } = useSelector((state: RootState) => state.featuresReducer.features);
  const currentFeature = useSelector((state: RootState) => state.featuresReducer.feature);
  const newlyCreatedFeatureId = useSelector((state: RootState) => state.featuresReducer.newlyCreatedFeatureId);
  const [isFeatureSidenavOpen, setIsFeatureSidenavOpen] = useState(false);
  const currentEnvironmentId = useSelector((state: RootState) => state.accountReducer.currentEnvironmentId);
  const isLoading = useSelector((state: RootState) => state.featuresReducer.isLoading);
  const [ArchiveFeatureModal, setArchiveDialogOpen] = useModal({ title: t('features.archive') });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCreateFeatureDialogOpen, setIsCreateFeatureDialogOpen] = useState(false);
  const [featureToDelete, setFeatureToDelete] = useState<FeatureFragment | null>(null);
  const [featureToArchive, setFeatureToArchive] = useState<FeatureFragment | null>(null);
  const { shouldUseFeatureArchiveInsteadOfDelete } = useFlags<FeatureFlags>();

  const allowFeaturesWrite = useEnvironmentPermissionCheck(EnvironmentPermissionActions.WriteEnvironment);

  const theme = useTheme();

  const { refId } = useParams<{ refId?: string }>();
  const { value: createQueryParam } = useQueryParam('create');

  useEffect(() => {
    if (refId) {
      const feature = features?.find((feature) => feature.node.refId === refId)?.node;
      if (feature) {
        void dispatch(fetchFeatureByRefIdAction(refId));
        setIsFeatureSidenavOpen(!!feature);
      }
    } else if (createQueryParam) {
      setIsCreateFeatureDialogOpen(true);
      setIsFeatureSidenavOpen(true);
    } else {
      void dispatch(resetSelectedFeature());
      setIsFeatureSidenavOpen(false);
    }
  }, [refId, createQueryParam, features, dispatch]);

  useEffect(() => {
    return () => {
      if (isFeatureSidenavOpen) {
        setIsFeatureSidenavOpen(false);
      }
    };
  }, [dispatch, isFeatureSidenavOpen]);

  const pageChangeFunc = createPageChangeFunc(dispatch, pageNum, pageInfo, setPageNum, fetchFeaturesAction, {
    environmentId: currentEnvironmentId,
    search,
  });

  const onNewClick = () => {
    setIsCreateFeatureDialogOpen(true);
    navigation.navigateTo(appRoutes.createFeature());
  };

  const onToggleFeatureDetails = useCallback(
    (feature: Feature | null) => {
      setIsCreateFeatureDialogOpen(false);
      if (feature) {
        navigation.navigateTo(appRoutes.featurePage(feature.refId));
      } else {
        setIsFeatureSidenavOpen(false);
        navigation.navigateTo(appRoutes.featuresPage());
      }
    },
    [navigation],
  );

  const onDeleteClick = (feature: FeatureFragment) => {
    setFeatureToDelete(feature);
    setDeleteDialogOpen(true);
  };

  const onArchiveClick = (feature: FeatureFragment) => {
    setFeatureToArchive(feature);
    setArchiveDialogOpen(true);
  };

  const handleDialogClose = async (shouldDelete: boolean) => {
    if (shouldDelete && featureToDelete?.id) {
      await dispatch(deleteFeatureByIdAction(featureToDelete.refId));
      onToggleFeatureDetails(null);
    }
    setDeleteDialogOpen(false);
  };

  const handleArchiveClose = () => {
    setFeatureToArchive(null);
    setArchiveDialogOpen(false);
    setIsFeatureSidenavOpen(false);
    void dispatch(resetSelectedFeature());
  };

  const onFeatureCreated = (createAnotherFeatureSelected: boolean) => {
    if (createAnotherFeatureSelected) {
      setIsCreateFeatureDialogOpen(true);
      setIsFeatureSidenavOpen(true);
    } else {
      setIsCreateFeatureDialogOpen(false);
    }
  };

  useEffect(() => {
    if (currentEnvironmentId) {
      void dispatch(
        fetchFeaturesAction({
          environmentId: currentEnvironmentId,
        }),
      );
    }
  }, [dispatch, currentEnvironmentId]);

  useEffect(() => {
    if (newlyCreatedFeatureId && isFeatureSidenavOpen) {
      const newFeature = features.find((feature) => feature.node.id === newlyCreatedFeatureId)?.node;
      if (newFeature) {
        void dispatch(fetchFeatureByRefIdAction(newFeature.refId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyCreatedFeatureId]);

  useHideIntercom(isFeatureSidenavOpen);

  return (
    <>
      <PageHeader
        title={t('features.featuresTitle')}
        buttonTitle={t('features.newFeatureButton')}
        buttonClick={allowFeaturesWrite ? onNewClick : undefined}
      />
      <Divider mt={4} />
      <GridFlex.Column container mb={10}>
        <Grid my={5} maxWidth={400}>
          <Search
            variant="outlined"
            searchOnChange
            handleSearchFunc={(searchStr) => {
              setSearch(searchStr);
              if (searchStr) {
                setPageNum(0);
              }
              void dispatch(fetchFeaturesAction({ search: searchStr, environmentId: currentEnvironmentId || '' }));
            }}
            searchedStr={search}
            placeholder={t('features.searchPlaceholder')}
          />
        </Grid>
        {isEmpty(features) && !isLoading ? (
          <EmptyList
            title={!isEmpty(search) ? t('features.emptySearchText') : t('features.emptyStateText')}
            linkText={isEmpty(search) ? t('features.emptyStateTextActionLinkText') : ''}
            onLinkClick={allowFeaturesWrite ? onNewClick : undefined}
            icon={Server}
          />
        ) : (
          <Table
            isLoading={isLoading}
            rowHeight={65}
            label={t('features.featuresTableLabel')}
            headCells={headCells(
              onDeleteClick,
              onArchiveClick,
              shouldUseFeatureArchiveInsteadOfDelete,
              !allowFeaturesWrite,
            )}
            data={((isLoading ? [] : features) || []).map((feature) => feature.node)}
            totalCount={totalCount}
            pageNum={pageNum}
            pageChangeFunc={pageChangeFunc}
            onRowClick={(feature: Feature) => onToggleFeatureDetails(feature)}
            rowColor={(feature) =>
              feature && feature?.id === currentFeature?.id ? theme.itamar.palette.action.selected : null
            }
          />
        )}
      </GridFlex.Column>

      <CustomDrawer
        // @ts-ignore FIX-ME
        title={
          isCreateFeatureDialogOpen ? t('features.createFeature') : <FeatureDetailsTitle feature={currentFeature} />
        }
        description={isCreateFeatureDialogOpen ? t('features.createFeatureSubtitle') : undefined}
        isOpen={isFeatureSidenavOpen}
        onClose={() => onToggleFeatureDetails(null)}
        variant="persistent"
        BackdropProps={{ invisible: true }}>
        {isCreateFeatureDialogOpen ? (
          <SideNavCreateFeature onSubmit={onFeatureCreated} onClose={() => onToggleFeatureDetails(null)} />
        ) : (
          <SideNavFeatureDetails
            feature={currentFeature}
            onClose={() => onToggleFeatureDetails(null)}
            onDelete={onDeleteClick}
            shouldUseFeatureArchiveInsteadOfDelete={shouldUseFeatureArchiveInsteadOfDelete}
            onArchive={onArchiveClick}
          />
        )}
      </CustomDrawer>

      {!shouldUseFeatureArchiveInsteadOfDelete ? (
        <ConfirmationDialog
          open={deleteDialogOpen}
          handleClose={handleDialogClose}
          title={t('features.delete')}
          content={t('features.deleteConfirmation')}
        />
      ) : (
        featureToArchive && (
          <ArchiveFeatureModal>
            <ArchiveFeature
              featureId={featureToArchive.id}
              featureName={featureToArchive.displayName}
              onClose={handleArchiveClose}
            />
          </ArchiveFeatureModal>
        )
      )}
    </>
  );
}

export default Features;
