import { useEffect } from 'react';
import { Link, GridFlex, Text } from '@stigg-components';
import { useModal } from '@stigg-common';
import BlockedAccountIllustration from '@assets/images/blockedExperiment/BlockedDudelessAccountIllustration.svg?react';
import { useAnalytics } from '../../common/useAnalytics';
import { useLogout } from '../hooks/useLogout';

const BLURRED_BACKGROUND_ASSET =
  'https://res.cloudinary.com/dsdqolxnn/image/upload/v1719169692/stigg/blocked/bluredBackground_t3tibx.png';

function BlockedPage() {
  const [PrepEnvModal, setPrepEnvModalOpen] = useModal({ closeable: false });

  const logout = useLogout();
  const { track } = useAnalytics();
  const handleSignOut = () => {
    track('Signed Out');
    logout();
  };
  useEffect(() => {
    setPrepEnvModalOpen(true);
  });

  return (
    <GridFlex.Column
      height="100%"
      width="100%"
      style={{ backgroundSize: 'cover', backgroundImage: `url(${BLURRED_BACKGROUND_ASSET})` }}>
      <PrepEnvModal>
        <GridFlex.Column gap={8}>
          <Text.H1>Let’s switch to your work email</Text.H1>
          <GridFlex.Item alignSelf="center">
            <BlockedAccountIllustration />
          </GridFlex.Item>
          <Text.B1>
            Hi there! We’ve noticed that you’ve signed up using a personal email address. To ensure a more professional
            and secure experience, we kindly ask that you use your work email address instead.
          </Text.B1>
          <Link onClick={handleSignOut}>Sign in with a work email address</Link>
        </GridFlex.Column>
      </PrepEnvModal>
    </GridFlex.Column>
  );
}

export default BlockedPage;
