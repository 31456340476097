import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionMigrationTaskFragment } from '@stigg-types/apiTypes';
import { isTaskInProgress } from '../../components/layout/navbar/components/tasks/taskUtils';
import { NavbarControls } from '../common/useToggleNavbarControls';
import { fetchImportIntegrationTasksAction } from '../integrations/integrationsSlice';
import { publishAddonAction } from '../packages/addons/addonSlice';
import { publishPlanAction } from '../packages/plans/plansSlice';
import { fetchSubscriptionMigrationTasks } from './tasks/queries/fetchSubscriptionMigrationTasks';

import { createAppAsyncThunk } from '../../redux/createAppAsyncThunk';
import { mergeEnvironmentAction } from '../accounts/accountsSlice';

export interface ApplicationState {
  editMode: boolean;
  // Storing it as string because we store it in local storage and for some reason boolean doesn't work well
  isDrawerExpanded: string;
  isRightDrawerOpen: boolean;
  rightDrawerWidth: number | null;
  isMobileDrawerOpen: boolean;
  isTopBannerOpen: boolean;
  navbarControls: NavbarControls | null;
  isTasksProgressTooltipOpen: boolean;
  subscriptionMigrationTasks: SubscriptionMigrationTaskFragment[];
  isLoadingSubscriptionMigrationTasks: boolean;
  subscriptionMigrationPollingTaskIds: string[];
  importPollingTaskId: string | null;
}

export const initialState: ApplicationState = {
  editMode: false,
  isDrawerExpanded: 'true',
  isRightDrawerOpen: false,
  rightDrawerWidth: null,
  isMobileDrawerOpen: false,
  isTopBannerOpen: false,
  navbarControls: null,
  isTasksProgressTooltipOpen: false,
  subscriptionMigrationTasks: [],
  isLoadingSubscriptionMigrationTasks: false,
  subscriptionMigrationPollingTaskIds: [],
  importPollingTaskId: null,
};

const fetchSubscriptionMigrationTasksAction = createAppAsyncThunk(
  'fetchSubscriptionMigrationTasksAction',
  fetchSubscriptionMigrationTasks,
);

const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setIsDrawerExpanded: (state, action: PayloadAction<boolean>) => {
      state.isDrawerExpanded = String(action.payload);
    },
    setIsRightDrawerOpen: (state, action: PayloadAction<{ isOpen: boolean; width?: number }>) => {
      const { isOpen, width = null } = action.payload;
      state.isRightDrawerOpen = isOpen;
      state.rightDrawerWidth = isOpen ? width : null;
    },
    setIsMobileDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isMobileDrawerOpen = action.payload;
    },
    setIsTopBannerOpen: (state, action: PayloadAction<boolean>) => {
      state.isTopBannerOpen = action.payload;
    },
    setIsTasksProgressTooltipOpen: (state, action: PayloadAction<boolean>) => {
      state.isTasksProgressTooltipOpen = action.payload;
    },
    setNavbarControls: (state, action: PayloadAction<NavbarControls>) => {
      state.navbarControls = action.payload;
    },
    addImportTaskPollingId: (state, action: PayloadAction<string>) => {
      state.importPollingTaskId = action.payload;
    },
    removeImportTaskPollingId: (state) => {
      state.importPollingTaskId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionMigrationTasksAction.pending, (state) => {
        state.isLoadingSubscriptionMigrationTasks = true;
      })
      .addCase(fetchSubscriptionMigrationTasksAction.fulfilled, (state, { payload }) => {
        state.subscriptionMigrationTasks = payload;
        state.subscriptionMigrationPollingTaskIds = payload
          .filter((task) => isTaskInProgress(task.status))
          .map((task) => task.id);
        state.isLoadingSubscriptionMigrationTasks = false;
      })
      .addCase(fetchSubscriptionMigrationTasksAction.rejected, (state) => {
        state.isLoadingSubscriptionMigrationTasks = false;
      });

    builder.addCase(publishPlanAction.fulfilled, (state, { payload }) => {
      if (payload?.taskId) {
        state.subscriptionMigrationPollingTaskIds.push(payload.taskId);
      }
    });
    builder.addCase(publishAddonAction.fulfilled, (state, { payload }) => {
      if (payload?.taskId) {
        state.subscriptionMigrationPollingTaskIds.push(payload.taskId);
      }
    });
    builder.addCase(fetchImportIntegrationTasksAction.fulfilled, (state, { payload }) => {
      const inProgressTask = payload.find((task) => isTaskInProgress(task.status));
      if (inProgressTask) {
        state.importPollingTaskId = inProgressTask.id;
      }
    });
    builder.addCase(mergeEnvironmentAction.fulfilled, (state, { payload }) => {
      if (payload.taskIds) {
        state.subscriptionMigrationPollingTaskIds.push(...payload.taskIds);
        state.isTasksProgressTooltipOpen = true;
      }
    });
  },
});

const {
  setEditMode,
  setIsDrawerExpanded,
  setIsRightDrawerOpen,
  setIsMobileDrawerOpen,
  setIsTopBannerOpen,
  setIsTasksProgressTooltipOpen,
  setNavbarControls,
  addImportTaskPollingId,
  removeImportTaskPollingId,
} = applicationSlice.actions;

export {
  setEditMode,
  setIsDrawerExpanded,
  setIsRightDrawerOpen,
  setIsMobileDrawerOpen,
  setIsTopBannerOpen,
  fetchSubscriptionMigrationTasksAction,
  setIsTasksProgressTooltipOpen,
  setNavbarControls,
  addImportTaskPollingId,
  removeImportTaskPollingId,
};
export default applicationSlice.reducer;
