import { t } from 'i18next';
import { SyncStateFragment } from '@stigg-types/apiTypes';
import { CustomDrawer, GridFlex } from '@stigg-components';
import { BillingIdCard } from './BillingIdCard';

type MultipleBillingIdsDrawer = {
  isOpen: boolean;
  onClose: () => void;
  syncStates: SyncStateFragment[];
};

export function MultipleBillingIdsDrawer({ syncStates, isOpen, onClose }: MultipleBillingIdsDrawer) {
  return (
    <CustomDrawer
      title={t('integrations.billingIntegrationsTitle')}
      isOpen={isOpen}
      onClose={onClose}
      variant="persistent"
      slotProps={{
        backdrop: { invisible: true },
      }}>
      <GridFlex.Column rowGap={4}>
        {syncStates.map((syncState) => (
          <BillingIdCard key={syncState.syncedEntityId} syncState={syncState} />
        ))}
      </GridFlex.Column>
    </CustomDrawer>
  );
}
