import { t } from 'i18next';
import { CardHeaderLayout, DetailsLayout, GridFlex, SubscriptionsOptionsDropdown, PageCard } from '@stigg-components';
import { useSelector } from 'react-redux';
import { AwsChip, hasOutdatedPackagesUtil, isActiveSubscriptionUtil } from '@stigg-common';
import {
  PaymentCollection,
  SubscriptionCancelReason,
  SubscriptionDataFragment,
  SubscriptionScheduleStatus,
  SubscriptionScheduleType,
  SubscriptionStatus,
} from '@stigg-types/apiTypes';
import { ActiveFutureUpdateStatus } from '@stigg-common/types';
import { InTrialStatus, OutdatedPackages, ScheduledToCancellation } from './components';
import { useSubscriptionDetailsLayout } from './useSubscriptionDetailsLayout';
import { useSubscriptionData } from './useSubscriptionData';
import {
  ExtraSubscriptionStatuses,
  SubscriptionStatusChip,
} from '../../customerPage/customerSubscriptions/SubscriptionStatusChip';
import { SubscriptionStatusDescription } from './SubscriptionStatusDescription';
import { usePollSubscriptionSyncStates } from '../../customerPage/usePollSubscriptionSyncStates';
import { SubscriptionExperimentNote } from './components/SubscriptionExperimentNote';
import { SubscriptionScheduledUpdatesBanner } from './components/subscriptionUpdates/SubscriptionScheduledUpdatesBanner';
import { RootState } from '../../../../../redux/store';
import { hasFutureUpdates } from '../utils/futureUpdates.utils';
import { PaymentCollectionBanner } from './components/paymentCollection/PaymentCollectionBanner';
import { SubscriptionBudgetExceededBanner } from './components/SubscriptionBudgetExceededBanner';
import { isInvoicePendingPayment } from './components/paymentCollection/PaymentCollectionBanner.utils';

export function SubscriptionInfo({
  subscription,
  onMigrateSubscriptionToLatest,
  onCancelSubscriptionClick,
  onUpdateSubscriptionClick,
  onCancelSubscriptionFutureUpdatesClick,
  onEndTrialSubscriptionClick,
  onTriggerSubscriptionUsageSync,
  onShowEntitlementsSummary,
  onMarkInvoiceAsPaidClick,
  isReadOnly,
}: {
  subscription: SubscriptionDataFragment;
  onMigrateSubscriptionToLatest: () => void;
  onCancelSubscriptionClick: () => void;
  onUpdateSubscriptionClick: () => void;
  onEndTrialSubscriptionClick: () => void;
  onCancelSubscriptionFutureUpdatesClick: (futureUpdateStatus: ActiveFutureUpdateStatus) => void;
  onTriggerSubscriptionUsageSync: () => void;
  onShowEntitlementsSummary: () => void;
  onMarkInvoiceAsPaidClick: () => void;
  isReadOnly: boolean;
}) {
  const account = useSelector((state: RootState) => state.accountReducer.account);
  const subscriptionData = useSubscriptionData(subscription);
  const subscriptionDetails = useSubscriptionDetailsLayout(subscription, subscriptionData, onMarkInvoiceAsPaidClick);
  const { hasOutdatedPackages, hasOutdatedPrice, outdatedPackageDescription } = hasOutdatedPackagesUtil(subscription);

  usePollSubscriptionSyncStates(subscription.id, subscription.subscriptionId, subscription.paymentCollection);

  const isAwsManagedSubscription = !!subscription.plan.awsMarketplacePlanDimension;
  const hasScheduledUpdates = hasFutureUpdates(subscription, SubscriptionScheduleStatus.Scheduled);
  const hasScheduleMigration = subscription.futureUpdates.some(
    (scheduleUpdate) => scheduleUpdate.subscriptionScheduleType === SubscriptionScheduleType.MigrateToLatest,
  );

  const cancellationDateAlert = subscription.cancellationDate ? (
    <ScheduledToCancellation
      subscription={subscription}
      subscriptionBillingAnchor={account?.subscriptionBillingAnchor}
      onCancelSubscriptionFutureUpdatesClick={hasScheduledUpdates ? undefined : onCancelSubscriptionFutureUpdatesClick}
    />
  ) : null;

  return (
    <PageCard>
      <GridFlex.Column gap={4}>
        <CardHeaderLayout
          displayName={t('subscriptions.details')}
          chip={{
            component: (
              <GridFlex.Row gap={4}>
                {subscription.status !== SubscriptionStatus.PaymentPending && (
                  <SubscriptionStatusChip
                    dataTestId="subscription-status"
                    status={subscription.status}
                    cancellationDate={subscription.cancellationDate}
                  />
                )}
                {subscription.paymentCollection !== PaymentCollection.NotRequired && (
                  <SubscriptionStatusChip
                    dataTestId="payment-status"
                    status={
                      subscription.paymentCollection === PaymentCollection.Processing ||
                      isInvoicePendingPayment(subscription.latestInvoice?.errorMessage)
                        ? PaymentCollection.Processing
                        : ExtraSubscriptionStatuses.PaymentError
                    }
                  />
                )}
                {hasScheduledUpdates && <SubscriptionStatusChip status={ExtraSubscriptionStatuses.ScheduledUpdate} />}
                {isAwsManagedSubscription && <AwsChip height={28} />}
              </GridFlex.Row>
            ),
          }}
          options={{
            component: (
              <SubscriptionsOptionsDropdown
                subscription={subscription}
                onCancelSubscriptionClick={onCancelSubscriptionClick}
                onMigrateSubscriptionToLatest={onMigrateSubscriptionToLatest}
                onUpdateSubscriptionClick={onUpdateSubscriptionClick}
                onEndTrialSubscriptionClick={onEndTrialSubscriptionClick}
                onTriggerSubscriptionUsageSync={onTriggerSubscriptionUsageSync}
              />
            ),
          }}
          hideOptions={isReadOnly || subscription.status === SubscriptionStatus.Canceled}
        />

        {[SubscriptionStatus.Active, SubscriptionStatus.InTrial].includes(subscription.status)
          ? cancellationDateAlert
          : null}
        {subscription.status === SubscriptionStatus.InTrial ? <InTrialStatus subscription={subscription} /> : null}

        <PaymentCollectionBanner
          subscription={subscription}
          onCancelSubscriptionFutureUpdatesClick={onCancelSubscriptionFutureUpdatesClick}
        />
        {subscription.status === SubscriptionStatus.Canceled &&
          subscription.cancelReason === SubscriptionCancelReason.PendingPaymentExpired && (
            <SubscriptionStatusDescription
              title={t('subscriptions.subscriptionCancelledBecausePendingPaymentExpired')}
            />
          )}
        {(hasOutdatedPackages || hasOutdatedPrice) && !hasScheduleMigration && (
          <OutdatedPackages
            isActiveSubscription={isActiveSubscriptionUtil(subscription.status)}
            outdatedPackageDescription={outdatedPackageDescription}
            migrateSubscriptionToLatest={onMigrateSubscriptionToLatest}
            hasOutdatedPackages={hasOutdatedPackages}
          />
        )}
        {subscription.experimentInfo && (
          <SubscriptionExperimentNote
            status={subscription.experimentInfo.status}
            refId={subscription.experimentInfo.id}
          />
        )}
        {hasScheduledUpdates && (
          <SubscriptionScheduledUpdatesBanner
            subscription={subscription}
            onCancelSubscriptionFutureUpdatesClick={onCancelSubscriptionFutureUpdatesClick}
          />
        )}
        {!!subscription.budgetExceeded && (
          <SubscriptionBudgetExceededBanner onShowEntitlementsSummary={onShowEntitlementsSummary} />
        )}
        <DetailsLayout titleWidth={140} details={subscriptionDetails} />
      </GridFlex.Column>
    </PageCard>
  );
}
