import { gql } from '@apollo/client';
import { t } from 'i18next';
import {
  CreateIntegrationInput,
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables,
  ErrorCode,
  Integration,
} from '@stigg-types/apiTypes';
import { executeOperationSafely } from '@stigg-common';
import { apolloClient } from '../../../ApolloClient';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchIntegrationByIdAction, fetchIntegrationsAction } from '../integrationsSlice';

const CREATE_INTEGRATION = gql`
  mutation CreateIntegration($input: CreateOneIntegrationInput!) {
    createOneIntegration(input: $input) {
      id
    }
  }
`;

type IntegrationDataInput = Omit<CreateIntegrationInput, 'environmentId'> & {
  noSuccessMessage?: boolean;
  skipFetch?: boolean;
};

async function createIntegration(
  integrationInput: IntegrationDataInput,
  { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState },
): Promise<Partial<Integration> | undefined> {
  const { skipFetch, noSuccessMessage, ...integrationData } = integrationInput;
  return executeOperationSafely(
    async () => {
      const { accountReducer } = getState();
      if (!accountReducer.currentEnvironmentId) {
        throw new Error('environment Id must be set');
      }

      const integration = {
        ...integrationData,
        environmentId: accountReducer.currentEnvironmentId,
      };
      const response = await apolloClient.mutate<CreateIntegrationMutation, CreateIntegrationMutationVariables>({
        mutation: CREATE_INTEGRATION,
        variables: { input: { integration } },
      });
      if (!response.data) {
        throw new Error('Invalid response');
      }
      const newIntegration = response.data.createOneIntegration;
      if (!skipFetch) {
        await dispatch(fetchIntegrationByIdAction({ integrationId: newIntegration.id, silentFetch: true }));
      }

      await dispatch(fetchIntegrationsAction({ environmentId: accountReducer.currentEnvironmentId }));

      return newIntegration;
    },
    {
      successMessage: noSuccessMessage || skipFetch ? undefined : t('integrations.api.successCreate'),
      failureMessageHandler: (err) => {
        // TODO: change this string comparison to use explicit error code instead
        if (err.graphQLErrors[0]?.message === 'Zuora credentials are not valid') {
          return t('integrations.api.invalidCredentials');
        }
        if (err.graphQLErrors[0]?.extensions.code === 'BillingIntegrationAlreadyExistsError') {
          return err.graphQLErrors[0]?.message || t('integrations.api.failCreate');
        }
        return t('integrations.api.failCreate');
      },
      expectedErrorsMessage: {
        [ErrorCode.DuplicatedEntityNotAllowed]: t('integrations.api.alreadyExists', {
          vendorIdentifier: integrationData.vendorIdentifier,
        }),
        [ErrorCode.BadUserInput]: t('integrations.api.invalidConnectionDetails'),
      },
    },
    dispatch,
  );
}

export { createIntegration };
