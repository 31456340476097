import SalesforceIcon from '@assets/images/integrations/salesforce.png';
import SalesforceDarkIcon from '@assets/images/integrations/salesforce.dark.png';
import StripeIcon from '@assets/images/integrations/stripe.png';
import StripeDarkIcon from '@assets/images/integrations/stripe.dark.png';
import Auth0Icon from '@assets/images/integrations/auth0.png';
import Auth0DarkIcon from '@assets/images/integrations/auth0.dark.png';
import OpenFgaIcon from '@assets/images/integrations/openFga.png';
import OpenFgaDarkIcon from '@assets/images/integrations/openFga.dark.png';
import AWSMarketplaceIcon from '@assets/images/integrations/aws.png';
import AWSMarketplaceDarkIcon from '@assets/images/integrations/aws.dark.png';
import StripeSmallIcon from '@assets/images/integrations/stripe-small.png';
import HubspotSmallIcon from '@assets/images/integrations/hubspot.png';
import ZuoraIcon from '@assets/images/integrations/zuora.png';
import ZuoraDarkIcon from '@assets/images/integrations/zuora.dark.png';
import StripeSvg from '@assets/icons/Stripe.svg?react';
import HubSpotSvg from '@assets/icons/Hubspot1.svg?react';
import AWSMarketplaceSvg from '@assets/icons/AWSMarketplace.svg?react';
import HubspotIcon from '@assets/images/integrations/hubspot.png';
import HubspotDarkIcon from '@assets/images/integrations/hubspot.dark.png';
import SnowflakeIcon from '@assets/images/integrations/snowflake.png';
import SnowflakeDarkIcon from '@assets/images/integrations/snowflake.dark.png';
import BigQueryIcon from '@assets/images/integrations/bigquery.png';
import BigQueryDarkIcon from '@assets/images/integrations/bigquery.dark.png';
import { VendorType } from '@stigg-types/apiTypes';
import { t } from 'i18next';
import { getStiggTheme } from '../../theme';
import { AvailableIntegration } from '../integrations/models';
import { VendorIdentifier } from '../integrations/types';
import {
  StyledBigQuerySvg,
  StyledSalesforceSvg,
  StyledSnowflakeSvg,
  StyledZuoraSvg,
} from '../integrations/styledIntegrationIcons';

type enabledIntegrations = {
  stripeSecretBillingEnabled?: boolean;
  auth0IntegrationEnabled?: boolean;
  openFgaIntegrationEnabled?: boolean;
};

export const getAvailableIntegrations: (
  hasStripeIntegration: boolean,
  flags: enabledIntegrations,
) => AvailableIntegration[] = (hasStripeIntegration: boolean, flags: enabledIntegrations) => {
  const { theme } = getStiggTheme();
  const { stripeSecretBillingEnabled, auth0IntegrationEnabled, openFgaIntegrationEnabled } = flags;
  const isLight = theme.isLightTheme;

  return [
    {
      vendorIdentifier: VendorIdentifier.Stripe,
      name: 'Stripe',
      vendorType: VendorType.Billing,
      getDescription: () => t('integrations.stripeDescription'),
      icon: isLight ? StripeIcon : StripeDarkIcon,
      iconSmall: StripeSmallIcon,
      iconSvg: StripeSvg,
      docsUrl: 'https://docs.stigg.io/docs/stripe',
      isDisabled: stripeSecretBillingEnabled,
      recommended: !hasStripeIntegration,
      allowMultiple: true,
    },
    {
      vendorIdentifier: VendorIdentifier.Zuora,
      name: 'Zuora',
      vendorType: VendorType.Billing,
      beta: true,
      getDescription: () => t('integrations.zuoraDescription'),
      iconSvg: StyledZuoraSvg,
      icon: isLight ? ZuoraIcon : ZuoraDarkIcon,
      docsUrl: 'https://docs.stigg.io/docs/zuora',
      allowMultiple: true,
    },
    {
      vendorIdentifier: VendorIdentifier.AwsMarketplace,
      vendorType: VendorType.Marketplace,
      name: 'AWS Marketplace',
      getDescription: () => t('integrations.awsMarketplaceDescription'),
      icon: isLight ? AWSMarketplaceIcon : AWSMarketplaceDarkIcon,
      iconSvg: AWSMarketplaceSvg,
      docsUrl: 'https://docs.stigg.io/docs/aws-marketplace',
    },
    {
      vendorIdentifier: VendorIdentifier.Salesforce,
      vendorType: VendorType.Crm,
      name: 'Salesforce',
      getDescription: () => t('integrations.salesforceDescription'),
      icon: isLight ? SalesforceIcon : SalesforceDarkIcon,
      iconSvg: StyledSalesforceSvg,
      docsUrl: 'https://docs.stigg.io/docs/salesforce',
    },
    {
      vendorIdentifier: VendorIdentifier.Hubspot,
      vendorType: VendorType.Crm,
      name: 'HubSpot',
      beta: true,
      getDescription: () => t('integrations.hubspotDescription'),
      icon: isLight ? HubspotIcon : HubspotDarkIcon,
      iconSmall: HubspotSmallIcon,
      iconSvg: HubSpotSvg,
      docsUrl: 'https://docs.stigg.io/docs/hubspot',
    },
    {
      vendorIdentifier: VendorIdentifier.Snowflake,
      vendorType: VendorType.DataExport,
      name: 'Snowflake',
      getDescription: () => t('integrations.snowflakeDescription'),
      icon: isLight ? SnowflakeIcon : SnowflakeDarkIcon,
      iconSvg: StyledSnowflakeSvg,
      docsUrl: 'https://docs.stigg.io/docs/snowflake',
    },
    {
      vendorIdentifier: VendorIdentifier.BigQuery,
      vendorType: VendorType.DataExport,
      name: 'BigQuery',
      getDescription: () => t('integrations.bigQueryDescription'),
      icon: isLight ? BigQueryIcon : BigQueryDarkIcon,
      iconSvg: StyledBigQuerySvg,
      docsUrl: 'https://docs.stigg.io/docs/bigquery',
    },
    {
      vendorIdentifier: VendorIdentifier.Auth0,
      vendorType: VendorType.Auth,
      name: 'Auth0',
      getDescription: () => t('integrations.auth0Description'),
      icon: isLight ? Auth0Icon : Auth0DarkIcon,
      docsUrl: 'https://docs.stigg.io/docs/auth0',
      hidden: !auth0IntegrationEnabled,
    },
    {
      vendorIdentifier: VendorIdentifier.OpenFga,
      vendorType: VendorType.Auth,
      name: 'OpenFGA',
      getDescription: () => t('integrations.openFga.description'),
      icon: isLight ? OpenFgaIcon : OpenFgaDarkIcon,
      docsUrl: 'https://docs.stigg.io/docs/open-fga',
      hidden: !openFgaIntegrationEnabled,
    },
  ];
};
