import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { FullScreenDialogLayout, Text, GridFlex, Box, Icon, Link } from '@stigg-components';
import { Dialog } from '../../../components/Dialog';
import { IntegrationPage } from './IntegrationPage';
import { VendorIdentifier } from '../types';
import { RootState } from '../../../redux/store';
import { IntegrationFragment } from '../../../types/apiTypes';

type IntegrationSetupModalProps = {
  isOpen: boolean;
  onClose: () => void;
  vendorIdentifier?: VendorIdentifier | null;
  integrationId?: string | null;
};

function IntegrationPageModalTitle({
  integration,
  vendorIdentifier,
  isLoadingIntegration,
}: {
  integration: IntegrationFragment | null;
  vendorIdentifier?: VendorIdentifier | null;
  isLoadingIntegration: boolean;
}) {
  const getTitle = () => {
    if (integration || isLoadingIntegration) {
      const integrationName = integration
        ? ` / ${t('integrations.vendorType', { context: integration?.vendorType })} / ${t('integrations.integrationNames', { context: integration?.vendorIdentifier })}`
        : '';
      return `${t('breadCrumbs.integrations')}${integrationName}`;
    }

    return `${t('integrations.addIntegration')} / ${t('integrations.integrationNames', { context: vendorIdentifier })}`;
  };

  return (
    <GridFlex.RowCenter columnGap={2}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.itamar.palette.background.default,
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
          width: 40,
        }}>
        <Icon type="reactFeather" icon="Zap" color="active" overrideStroke />
      </Box>
      <Text.B2 color="secondary">{getTitle()}</Text.B2>
    </GridFlex.RowCenter>
  );
}

export function IntegrationPageModal({ isOpen, onClose, vendorIdentifier, integrationId }: IntegrationSetupModalProps) {
  const integration = useSelector((state: RootState) => state.integrations.integration);
  const isLoadingIntegration = useSelector((state: RootState) => state.integrations.isLoadingIntegration);

  return (
    <Dialog
      fullScreen
      isOpen={isOpen}
      onCancel={onClose}
      content={
        <FullScreenDialogLayout
          variant="grey"
          title={
            <IntegrationPageModalTitle
              integration={integration}
              vendorIdentifier={vendorIdentifier}
              isLoadingIntegration={isLoadingIntegration}
            />
          }>
          <GridFlex.Column
            $fullHeight
            $fullWidth
            alignItems="center"
            pt={8}
            sx={{ backgroundColor: (theme) => theme.itamar.palette.background.paper, overflow: 'auto' }}>
            <GridFlex.Column alignItems="flex-end" maxWidth={800} pb={8}>
              <IntegrationPage vendorIdentifier={vendorIdentifier} integrationId={integrationId} />
              {!isLoadingIntegration && integration ? (
                <Link mt={4} variant="body2" onClick={onClose}>
                  {t('integrations.backToActiveIntegrations')}
                </Link>
              ) : null}
            </GridFlex.Column>
          </GridFlex.Column>
        </FullScreenDialogLayout>
      }
      padding={0}
    />
  );
}
