/* eslint-disable no-restricted-imports */
import { forwardRef, PropsWithChildren } from 'react';
import isNil from 'lodash/isNil';
import styled from 'styled-components/macro';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { spacing } from '@mui/system';
import { ButtonColor } from '@stigg-theme';
import { buttonStyle } from './Buttons.style';

// due to the fact color is a props coming from MuiButton when using it inside the styled tag
// we will have a color props that based on both ButtonColor & MuiButton['color']
// in order to make color $typesafe by our guidelines I've added the $typesafeColor prop
// and I Omit it before exporting the component, so any outside user won't know about it

type StyledButtonType = {
  $typesafeColor?: ButtonColor; // look at the first comment
  textColor?: ButtonColor;
  color?: ButtonColor;
  $outlined?: boolean;
  $disabled?: boolean;
  hoverColor?: ButtonColor | 'disabled';
  fontSize?: '12px' | '14px';
  mt?: number;
  mb?: number;
};

type TypesafeButtonProps = Omit<MuiButtonProps, 'color'> & Omit<StyledButtonType, '$typesafeColor' | '$disabled'>;

export type ButtonProps = TypesafeButtonProps;

const SpacedButton = styled(MuiButton)(spacing);
const StyledButton = styled(SpacedButton)<StyledButtonType>`
  ${({ $typesafeColor, textColor, fontSize, $outlined, $disabled, hoverColor }) =>
    buttonStyle($typesafeColor, $outlined, $disabled, textColor, hoverColor, true, undefined, fontSize)}
`;

const TypesafeButton = forwardRef<HTMLButtonElement, PropsWithChildren<TypesafeButtonProps>>(
  ({ color, textColor, fontSize, $outlined, disabled, hoverColor, ...props }, ref) => (
    <StyledButton
      $typesafeColor={color}
      $disabled={disabled}
      $outlined={$outlined ?? (props.variant === 'outlined' || props.variant === 'text' || isNil(props.variant))}
      disabled={disabled}
      textColor={textColor}
      fontSize={fontSize}
      hoverColor={hoverColor}
      {...props}
      ref={ref}
    />
  ),
);

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  ({ color, textColor, fontSize, disabled, hoverColor, ...props }, ref) => (
    <TypesafeButton
      color={color}
      textColor={textColor}
      fontSize={fontSize}
      disabled={disabled}
      hoverColor={hoverColor}
      {...props}
      ref={ref}
    />
  ),
);
