import { ReactElement, useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Search as SearchIcon } from 'react-feather';
import { t } from 'i18next';
import { getIconColor, styled } from '@stigg-theme';
import { IconButton, TextField, GridFlex, Icon, CircularProgress } from '@stigg-components';
import debounce from 'lodash/debounce';
import { DEFAULT_INPUT_HEIGHT } from './input.utils';

export type SearchComponentProps = {
  handleSearchFunc: (searchStr: string) => void;
  isLoading?: boolean;
  autoFocus?: boolean;
  searchedStr?: string;
  placeholder?: string;
  shouldMaskFromHjAndFs?: boolean;
  searchOnChange?: boolean;
  textFieldClassName?: string;
  variant?: 'standard' | 'outlined';
  onKeyDown?: (e: KeyboardEvent) => void;
  hideSearchIcon?: boolean;
};

const StyledSearchIcon = styled(SearchIcon)`
  opacity: 0.5;
  margin-right: 8px;
  color: ${({ theme }) => theme.itamar.palette.text.primary};
`;

const SearchTextField = styled(TextField)`
  input {
    &::placeholder {
      color: ${({ theme }) => theme.itamar.palette.text.disabled};
      opacity: 1;
    }
  }
  fieldset {
    transition: all 0.2s ease-in-out;
    border-color: ${({ theme }) =>
      theme.isLightTheme ? theme.itamar.palette.other.outlineBorder : theme.itamar.palette.border.primary};
  }
  &:hover fieldset {
    border-color: ${({ theme }) => theme.itamar.palette.other.standardInputLine} !important;
  }
  & .Mui-focused fieldset {
    border-color: ${({ theme }) => theme.itamar.palette.primary.outlinedHoverBackground} !important;
  }
`;

const ClearIconButton = styled(IconButton)`
  padding: 0;
  &.MuiButtonBase-root:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.itamar.palette.text.primary};
  }
  color: ${({ theme }) => theme.itamar.palette.action.active};
`;

function Search(props: SearchComponentProps) {
  const {
    placeholder = t('sharedComponents.search'),
    handleSearchFunc,
    autoFocus,
    isLoading,
    searchedStr,
    searchOnChange = false,
    textFieldClassName,
    shouldMaskFromHjAndFs,
    variant = 'standard',
    onKeyDown,
    hideSearchIcon = false,
  } = props;
  // To persist the search string in the search bar after search has been performed.
  const [search, setSearch] = useState(searchedStr);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((search: string) => handleSearchFunc(search), 350),
    [handleSearchFunc],
  );
  const handleOnchange = (e: any) => {
    setSearch(e.target.value);
    if (searchOnChange) {
      debouncedSearch(e.target.value);
    }
  };
  // currently our base behaviour is to execute search only on pressing enter or if searchOnChange is passed
  const handleSearch = (e: any) => {
    if (e.key === t('sharedComponents.enter') && !searchOnChange) {
      handleSearchFunc(e.target.value);
    }
  };

  const clearSearch = () => {
    setSearch('');
    handleSearchFunc('');
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && search) {
      clearSearch();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  let endAdornment: ReactElement | undefined;
  if (isLoading) {
    endAdornment = <CircularProgress size={20} sx={{ color: getIconColor('active') }} />;
  } else if (!isEmpty(search)) {
    endAdornment = (
      <ClearIconButton onClick={clearSearch}>
        <Icon icon="Close" size={20} />
      </ClearIconButton>
    );
  }

  const textFieldProps: any =
    variant === 'standard'
      ? { InputProps: { disableUnderline: true } }
      : {
          InputProps: {
            sx: { height: DEFAULT_INPUT_HEIGHT },
            startAdornment: hideSearchIcon ? undefined : <StyledSearchIcon />,
            endAdornment,
          },
        };

  return (
    <GridFlex.RowCenter item $fullWidth>
      {variant === 'standard' && <StyledSearchIcon />}
      <SearchTextField
        autoFocus={autoFocus}
        fullWidth
        onChange={handleOnchange}
        value={search}
        placeholder={placeholder}
        onKeyUp={handleSearch}
        onKeyDown={handleKeyDown}
        id="input-with-sx"
        variant={variant || 'standard'}
        shouldMaskFromHj={shouldMaskFromHjAndFs}
        className={textFieldClassName}
        startAdornment={<StyledSearchIcon />}
        {...textFieldProps}
      />
    </GridFlex.RowCenter>
  );
}

export default Search;
