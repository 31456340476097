import { GridFlex, Text, LinearProgress } from '@stigg-components';
import styled from 'styled-components/macro';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { sliderClasses } from '@mui/material/Slider';
import numeral from 'numeral';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Slider } from './index';

export const PERCENT_FORMAT = '0.[00]%';
const StyledSlider = styled(Slider)`
  height: 16px;
  padding: 0px;

  &.${sliderClasses.colorPrimary} {
    color: ${({ theme }) => theme.itamar.palette.grey[200]};
    background: ${({ theme }) => `linear-gradient(
      225.2deg,
      ${theme.itamar.palette.primary.light} 3.06%,
      ${theme.itamar.palette.primary.main} 100%
    )`};
    border: ${({ theme }) => theme.itamar.border.border};
    border-color: ${({ theme }) => theme.itamar.palette.other.backdropOverlay};
  }

  &.${sliderClasses.thumb} {
    height: 24px;
    width: 24px;
  }

  & .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    color: #ffffff;
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    border: 2px solid ${({ theme }) => theme.itamar.palette.other.focusBorder};
  }
`;
const StyledLinearProgress = styled(LinearProgress)`
  height: 16px;
  border-radius: ${({ theme }) => theme.itamar.border.radius};
  border: ${({ theme }) => theme.itamar.border.border};
  border-color: ${({ theme }) => theme.itamar.palette.other.backdropOverlay};

  &.${linearProgressClasses.colorPrimary} {
    background: ${({ theme }) => `linear-gradient(
      225.2deg,
      ${theme.itamar.palette.primary.light} 3.06%,
      ${theme.itamar.palette.primary.main} 100%
    )`};
    border: ${({ theme }) => theme.itamar.border.border};
    border-color: ${({ theme }) => theme.itamar.palette.other.backdropOverlay};
  }

  & .${linearProgressClasses.barColorPrimary} {
    background-color: #f0f1f6;
  }
`;

function prepareValue(percentage: number, reverse: boolean) {
  const value = Math.max(0, Math.min(100, percentage));
  return reverse ? 100 - value : value;
}

type SplitRateProps = {
  percentage: number;
  spacing?: number;
  reverse?: boolean;
  hideSplit?: boolean;
  variant: 'slider' | 'linearProgress';
  setValue?: (value: number) => void;
};

export function SplitRate({
  percentage,
  spacing = 3,
  reverse = false,
  hideSplit = false,
  variant,
  setValue,
}: SplitRateProps) {
  const value = prepareValue(percentage, reverse);
  const [sliderValue, setSliderValue] = useState(value);
  useEffect(() => {
    setSliderValue(value);
  }, [value]);
  const rest = 100 - sliderValue;
  const progressComponent =
    variant === 'slider' ? (
      <StyledSlider
        defaultValue={50}
        valueLabelDisplay="off"
        value={sliderValue}
        onChange={(e: any) => setSliderValue(e.target.value)}
        onChangeCommitted={() => setValue && setValue(sliderValue)}
      />
    ) : (
      <StyledLinearProgress variant="determinate" value={sliderValue} />
    );
  return (
    <GridFlex.RowCenter container spacing={spacing}>
      {!hideSplit && (
        <GridFlex.Item>
          <Text.B2 color="primary">{numeral(sliderValue / 100).format(PERCENT_FORMAT)}</Text.B2>
        </GridFlex.Item>
      )}

      <GridFlex.Item flex={1}>{progressComponent}</GridFlex.Item>
      {!hideSplit && (
        <GridFlex.Item>
          <Text.B2 color="primary">{numeral(rest / 100).format(PERCENT_FORMAT)}</Text.B2>
        </GridFlex.Item>
      )}
    </GridFlex.RowCenter>
  );
}
